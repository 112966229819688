html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif
}

body {
  margin: 0;
  font-family: Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif !important;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline
}

audio:not([controls]) {
  display: none;
  height: 0
}

[hidden], template {
  display: none
}

a {
  color: white !important;
}

a {
  background-color: transparent
}

a:active, a:hover {
  outline: 0
}

abbr[title] {
  border-bottom: 1px dotted
}

b, strong {
  font-weight: 700
}

dfn {
  font-style: italic
}

h1 {
  font-size: 2em;
  margin: .67em 0
}

mark {
  background: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -.5em
}

sub {
  bottom: -.25em
}

img {
  border: 0
}

svg:not(:root) {
  overflow: hidden
}

figure {
  margin: 1em 40px
}

hr {
  box-sizing: content-box;
  height: 0
}

pre {
  overflow: auto
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0
}

button {
  overflow: visible
}

button, select {
  text-transform: none
}

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer
}

button[disabled], html input[disabled] {
  cursor: default
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input {
  line-height: normal
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  height: auto
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em
}

legend {
  border: 0;
  padding: 0
}

textarea {
  overflow: auto
}

optgroup {
  font-weight: 700
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

td, th {
  padding: 0
}

b, h1, h2, h3, h4, h5, h6, optgroup, strong {
  font-weight: 500
}

* {
  letter-spacing: 0 !important
}

.splash {
  display: flex;
  position: relative;
  height: 100vh;
}

.splash img {
  margin: auto;
  display: block;
  width: 45%;
}

.extended-diacritics-language {
  line-height: 1.4
}

.is-greek-and-safari .btn-flat, .is-greek-and-safari .button, .is-greek-and-safari .jawBoneContainer .menu, .is-greek-and-safari .jawBoneNavigations, .is-greek-and-safari .maturity-rating .maturity-number, .is-greek-and-safari .nf-flat-button .nf-icon-button-tooltip, .is-greek-and-safari .nf-icon-button .nf-icon-button-label, .is-greek-and-safari .nf-icon-button .nf-icon-button-tooltip, .is-greek-and-safari .nfDropDown.theme-lakira .label, .is-greek-and-safari .profile-button a, .is-greek-and-safari .profile-button span {
  text-transform: none
}

.fill-container {
  height: 100%;
  width: 100%
}

.screen-reader-text {
  left: -9999px;
  position: absolute;
  top: -9999px
}

.visually-hidden {
  clip: rect(1px 1px 1px 1px) !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important
}

@font-face {
  font-family: nf-icon;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/nf-icon-v1-93.woff) format("woff")
}

[class*=" icon-"], [class^=icon-] {
  speak: none;
  font-family: nf-icon;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  transform: translateZ(0)
}

.icon-logoUpdate:before {
  content: "\e5d0"
}

.icon-close:before {
  content: "\e762"
}

.icon-search:before {
  content: "\e636"
}

.icon-circle-solid:before {
  content: "\e622"
}

.icon-star-25-percent:before {
  content: "\e637"
}

.icon-star-50-percent:before {
  content: "\e638"
}

.icon-star-75-percent:before {
  content: "\e639"
}

.icon-star:before {
  content: "\e640"
}

.icon-add:before {
  content: "\e641"
}

.icon-play:before {
  content: "\e646"
}

.icon-plus:before {
  content: "\f049"
}

.icon-leftArrow:before {
  content: "\e659"
}

.icon-rightArrow:before {
  content: "\e658"
}

.icon-kids:before {
  content: "\e691"
}

.icon-tvuiAdd:before {
  content: "\e716"
}

.icon-TvRatings:before {
  content: "\e733"
}

.icon-Talent:before {
  content: "\e734"
}

.icon-Awards:before {
  content: "\e736"
}

.icon-BoxOffice:before {
  content: "\e737"
}

.icon-round-x:before {
  content: "\e747"
}

.icon-globe:before {
  content: "\e896"
}

.icon-warning:before {
  content: "\e620"
}

.icon-rightCaret:before {
  content: "\e867"
}

.icon-leftCaret:before {
  content: "\e868"
}

.icon-disc:before {
  content: "\e871"
}

.icon-spinner:before {
  content: "\e765"
}

.icon-plainCheck:before {
  content: "\e804"
}

.icon-plainX:before {
  content: "\e807"
}

.icon-facebook:before {
  content: "\e628"
}

.icon-error:before {
  content: "\e798"
}

.icon-valid:before {
  content: "\e804"
}

.icon-lock:before {
  content: "\e625"
}

.icon-info-inv:before {
  content: "\e748"
}

.icon-success-inv:before {
  content: "\e746"
}

.icon-warn-inv:before {
  content: "\e743"
}

.icon-visa:before {
  content: "\e901"
}

.icon-mastercard:before {
  content: "\e902"
}

.icon-discover:before {
  content: "\e903"
}

.icon-amex:before {
  content: "\e900"
}

.icon-paypal:before {
  content: "\e914"
}

.icon-nicam-AL:before {
  content: "\f000"
}

.icon-nicam-6:before {
  content: "\f001"
}

.icon-nicam-9:before {
  content: "\f004"
}

.icon-nicam-12:before {
  content: "\f002"
}

.icon-nicam-16:before {
  content: "\f003"
}

.icon-nicam-violence:before {
  content: "\f005"
}

.icon-nicam-fear-anxiety:before {
  content: "\f006"
}

.icon-nicam-profanity:before {
  content: "\f007"
}

.icon-nicam-discrimination:before {
  content: "\f008"
}

.icon-nicam-drug-or-alcohol:before {
  content: "\f009"
}

.icon-nicam-sex:before {
  content: "\f010"
}

.icon-androidPlayRing:before {
  content: "\e665"
}

.icon-akiraMyListAdd:before {
  content: "\e850"
}

.icon-akiraMyListRemove:before {
  content: "\e852"
}

.icon-akiraCaretDown:before {
  content: "\e854"
}

.icon-akiraCaretRight:before {
  content: "\e658"
}

.icon-akiraCaretLeft:before {
  content: "\e659"
}

.icon-button-play:before {
  content: "\e884"
}

.icon-button-play-reverse:before {
  content: "\e890"
}

.icon-button-mylist-add:before {
  content: "\e885"
}

.icon-button-mylist-add-reverse:before {
  content: "\e891"
}

.icon-button-mylist-added:before {
  content: "\e888"
}

.icon-button-mylist-added-reverse:before {
  content: "\e894"
}

.icon-button-remindme-add-reverse:before, .icon-button-remindme-add:before {
  content: "\e663"
}

.icon-button-remindme-added-reverse:before, .icon-button-remindme-added:before {
  content: "\e804"
}

.icon-button-share:before {
  content: "\e886"
}

.icon-button-share-reverse:before {
  content: "\e892"
}

.icon-button-episodes:before {
  content: "\e887"
}

.icon-button-episodes-reverse:before {
  content: "\e893"
}

.icon-button-spinner-reverse:before, .icon-button-spinner:before {
  content: "\e765"
}

.icon-button-minus:before {
  content: "\e889"
}

.icon-button-minus-reverse:before {
  content: "\e895"
}

.icon-button-audio-on:before {
  content: "\e88a"
}

.icon-button-audio-on-reverse:before {
  content: "\e88c"
}

.icon-button-audio-off:before {
  content: "\e88b"
}

.icon-button-audio-off-reverse:before {
  content: "\e88d"
}

.icon-button-replay:before {
  content: "\f095"
}

.icon-button-replay-reverse:before {
  content: "\f096"
}

.icon-button-notification-reverse:before, .icon-button-notification:before {
  content: "\e663"
}

.icon-thumb-down:before {
  content: "\e660"
}

.icon-thumb-up:before {
  content: "\e661"
}

.icon-thumb-down-line:before {
  content: "\f101"
}

.icon-thumb-up-line:before {
  content: "\f100"
}

.icon-thin-caret-left:before {
  content: "\e704"
}

.icon-thin-caret-right:before {
  content: "\e705"
}

.icon-long-arrow-left:before {
  content: "\e673"
}

.icon-long-arrow-right:before {
  content: "\e672"
}

.icon-short-arrow-right:before {
  content: "\e89B"
}

.icon-back:before {
  content: "\e635"
}

.icon-uniE5D0:before {
  content: "\E5D0"
}

.icon-uniE5D1:before {
  content: "\E5D1"
}

.icon-uniE600:before {
  content: "\E600"
}

.icon-uniE601:before {
  content: "\E601"
}

.icon-uniE602:before {
  content: "\E602"
}

.icon-uniE603:before {
  content: "\E603"
}

.icon-uniE604:before {
  content: "\E604"
}

.icon-uniE605:before {
  content: "\E605"
}

.icon-uniE606:before {
  content: "\E606"
}

.icon-uniE607:before {
  content: "\E607"
}

.icon-uniE608:before {
  content: "\E608"
}

.icon-uniE609:before {
  content: "\E609"
}

.icon-uniE610:before {
  content: "\E610"
}

.icon-uniE611:before {
  content: "\E611"
}

.icon-uniE612:before {
  content: "\E612"
}

.icon-uniE613:before {
  content: "\E613"
}

.icon-uniE614:before {
  content: "\E614"
}

.icon-uniE615:before {
  content: "\E615"
}

.icon-uniE616:before {
  content: "\E616"
}

.icon-uniE617:before {
  content: "\E617"
}

.icon-uniE618:before {
  content: "\E618"
}

.icon-uniE619:before {
  content: "\E619"
}

.icon-uniE620:before {
  content: "\E620"
}

.icon-uniE621:before {
  content: "\E621"
}

.icon-uniE622:before {
  content: "\E622"
}

.icon-uniE623:before {
  content: "\E623"
}

.icon-uniE624:before {
  content: "\E624"
}

.icon-uniE625:before {
  content: "\E625"
}

.icon-uniE626:before {
  content: "\E626"
}

.icon-uniE627:before {
  content: "\E627"
}

.icon-uniE628:before {
  content: "\E628"
}

.icon-uniE629:before {
  content: "\E629"
}

.icon-uniE630:before {
  content: "\E630"
}

.icon-uniE631:before {
  content: "\E631"
}

.icon-uniE632:before {
  content: "\E632"
}

.icon-uniE633:before {
  content: "\E633"
}

.icon-uniE634:before {
  content: "\E634"
}

.icon-uniE635:before {
  content: "\E635"
}

.icon-uniE636:before {
  content: "\E636"
}

.icon-uniE637:before {
  content: "\E637"
}

.icon-uniE638:before {
  content: "\E638"
}

.icon-uniE639:before {
  content: "\E639"
}

.icon-uniE640:before {
  content: "\E640"
}

.icon-uniE641:before {
  content: "\E641"
}

.icon-uniE642:before {
  content: "\E642"
}

.icon-uniE643:before {
  content: "\E643"
}

.icon-uniE644:before {
  content: "\E644"
}

.icon-uniE645:before {
  content: "\E645"
}

.icon-uniE646:before {
  content: "\E646"
}

.icon-uniE647:before {
  content: "\E647"
}

.icon-uniE648:before {
  content: "\E648"
}

.icon-uniE649:before {
  content: "\E649"
}

.icon-uniE650:before {
  content: "\E650"
}

.icon-uniE651:before {
  content: "\E651"
}

.icon-uniE652:before {
  content: "\E652"
}

.icon-uniE653:before {
  content: "\E653"
}

.icon-uniE654:before {
  content: "\E654"
}

.icon-uniE655:before {
  content: "\E655"
}

.icon-uniE656:before {
  content: "\E656"
}

.icon-uniE657:before {
  content: "\E657"
}

.icon-uniE658:before {
  content: "\E658"
}

.icon-uniE659:before {
  content: "\E659"
}

.icon-uniE660:before {
  content: "\E660"
}

.icon-uniE661:before {
  content: "\E661"
}

.icon-uniE662:before {
  content: "\E662"
}

.icon-uniE663:before {
  content: "\E663"
}

.icon-uniE664:before {
  content: "\E664"
}

.icon-uniE665:before {
  content: "\E665"
}

.icon-uniE666:before {
  content: "\E666"
}

.icon-uniE667:before {
  content: "\E667"
}

.icon-uniE668:before {
  content: "\E668"
}

.icon-uniE669:before {
  content: "\E669"
}

.icon-uniE670:before {
  content: "\E670"
}

.icon-uniE671:before {
  content: "\E671"
}

.icon-uniE672:before {
  content: "\E672"
}

.icon-uniE673:before {
  content: "\E673"
}

.icon-uniE674:before {
  content: "\E674"
}

.icon-uniE675:before {
  content: "\E675"
}

.icon-uniE676:before {
  content: "\E676"
}

.icon-uniE677:before {
  content: "\E677"
}

.icon-uniE678:before {
  content: "\E678"
}

.icon-uniE679:before {
  content: "\E679"
}

.icon-uniE680:before {
  content: "\E680"
}

.icon-uniE681:before {
  content: "\E681"
}

.icon-uniE682:before {
  content: "\E682"
}

.icon-uniE683:before {
  content: "\E683"
}

.icon-uniE684:before {
  content: "\E684"
}

.icon-uniE685:before {
  content: "\E685"
}

.icon-uniE687:before {
  content: "\E687"
}

.icon-uniE688:before {
  content: "\E688"
}

.icon-uniE689:before {
  content: "\E689"
}

.icon-uniE690:before {
  content: "\E690"
}

.icon-uniE691:before {
  content: "\E691"
}

.icon-uniE692:before {
  content: "\E692"
}

.icon-uniE693:before {
  content: "\E693"
}

.icon-uniE694:before {
  content: "\E694"
}

.icon-uniE695:before {
  content: "\E695"
}

.icon-uniE696:before {
  content: "\E696"
}

.icon-uniE697:before {
  content: "\E697"
}

.icon-uniE698:before {
  content: "\E698"
}

.icon-uniE699:before {
  content: "\E699"
}

.icon-uniE700:before {
  content: "\E700"
}

.icon-uniE701:before {
  content: "\E701"
}

.icon-uniE702:before {
  content: "\E702"
}

.icon-uniE703:before {
  content: "\E703"
}

.icon-uniE704:before {
  content: "\E704"
}

.icon-uniE705:before {
  content: "\E705"
}

.icon-uniE706:before {
  content: "\E706"
}

.icon-uniE707:before {
  content: "\E707"
}

.icon-uniE708:before {
  content: "\E708"
}

.icon-uniE709:before {
  content: "\E709"
}

.icon-uniE710:before {
  content: "\E710"
}

.icon-uniE711:before {
  content: "\E711"
}

.icon-uniE712:before {
  content: "\E712"
}

.icon-uniE713:before {
  content: "\E713"
}

.icon-uniE714:before {
  content: "\E714"
}

.icon-uniE715:before {
  content: "\E715"
}

.icon-uniE716:before {
  content: "\E716"
}

.icon-uniE718:before {
  content: "\E718"
}

.icon-uniE719:before {
  content: "\E719"
}

.icon-uniE720:before {
  content: "\E720"
}

.icon-uniE721:before {
  content: "\E721"
}

.icon-uniE722:before {
  content: "\E722"
}

.icon-uniE723:before {
  content: "\E723"
}

.icon-uniE724:before {
  content: "\E724"
}

.icon-uniE725:before {
  content: "\E725"
}

.icon-uniE726:before {
  content: "\E726"
}

.icon-uniE727:before {
  content: "\E727"
}

.icon-uniE728:before {
  content: "\E728"
}

.icon-uniE729:before {
  content: "\E729"
}

.icon-uniE730:before {
  content: "\E730"
}

.icon-uniE731:before {
  content: "\E731"
}

.icon-uniE732:before {
  content: "\E732"
}

.icon-uniE733:before {
  content: "\E733"
}

.icon-uniE734:before {
  content: "\E734"
}

.icon-uniE735:before {
  content: "\E735"
}

.icon-uniE736:before {
  content: "\E736"
}

.icon-uniE737:before {
  content: "\E737"
}

.icon-uniE738:before {
  content: "\E738"
}

.icon-uniE739:before {
  content: "\E739"
}

.icon-uniE740:before {
  content: "\E740"
}

.icon-uniE741:before {
  content: "\E741"
}

.icon-uniE742:before {
  content: "\E742"
}

.icon-uniE743:before {
  content: "\E743"
}

.icon-uniE744:before {
  content: "\E744"
}

.icon-uniE745:before {
  content: "\E745"
}

.icon-uniE746:before {
  content: "\E746"
}

.icon-uniE747:before {
  content: "\E747"
}

.icon-uniE748:before {
  content: "\E748"
}

.icon-uniE749:before {
  content: "\E749"
}

.icon-uniE750:before {
  content: "\E750"
}

.icon-uniE751:before {
  content: "\E751"
}

.icon-uniE752:before {
  content: "\E752"
}

.icon-uniE753:before {
  content: "\E753"
}

.icon-uniE754:before {
  content: "\E754"
}

.icon-uniE755:before {
  content: "\E755"
}

.icon-uniE756:before {
  content: "\E756"
}

.icon-uniE757:before {
  content: "\E757"
}

.icon-uniE758:before {
  content: "\E758"
}

.icon-uniE759:before {
  content: "\E759"
}

.icon-uniE760:before {
  content: "\E760"
}

.icon-uniE761:before {
  content: "\E761"
}

.icon-uniE762:before {
  content: "\E762"
}

.icon-uniE763:before {
  content: "\E763"
}

.icon-uniE764:before {
  content: "\E764"
}

.icon-uniE765:before {
  content: "\E765"
}

.icon-uniE766:before {
  content: "\E766"
}

.icon-uniE767:before {
  content: "\E767"
}

.icon-uniE775:before {
  content: "\E775"
}

.icon-uniE776:before {
  content: "\E776"
}

.icon-uniE777:before {
  content: "\E777"
}

.icon-uniE778:before {
  content: "\E778"
}

.icon-uniE779:before {
  content: "\E779"
}

.icon-uniE780:before {
  content: "\E780"
}

.icon-uniE781:before {
  content: "\E781"
}

.icon-uniE782:before {
  content: "\E782"
}

.icon-uniE783:before {
  content: "\E783"
}

.icon-uniE784:before {
  content: "\E784"
}

.icon-uniE785:before {
  content: "\E785"
}

.icon-uniE786:before {
  content: "\E786"
}

.icon-uniE787:before {
  content: "\E787"
}

.icon-uniE796:before {
  content: "\E796"
}

.icon-uniE797:before {
  content: "\E797"
}

.icon-uniE798:before {
  content: "\E798"
}

.icon-uniE799:before {
  content: "\E799"
}

.icon-uniE800:before {
  content: "\E800"
}

.icon-uniE801:before {
  content: "\E801"
}

.icon-uniE802:before {
  content: "\E802"
}

.icon-uniE803:before {
  content: "\E803"
}

.icon-uniE804:before {
  content: "\E804"
}

.icon-uniE805:before {
  content: "\E805"
}

.icon-uniE806:before {
  content: "\E806"
}

.icon-uniE807:before {
  content: "\E807"
}

.icon-uniE850:before {
  content: "\E850"
}

.icon-uniE851:before {
  content: "\E851"
}

.icon-uniE852:before {
  content: "\E852"
}

.icon-uniE853:before {
  content: "\E853"
}

.icon-uniE854:before {
  content: "\E854"
}

.icon-uniE855:before {
  content: "\E855"
}

.icon-uniE856:before {
  content: "\E856"
}

.icon-uniE857:before {
  content: "\E857"
}

.icon-uniE858:before {
  content: "\E858"
}

.icon-uniE859:before {
  content: "\E859"
}

.icon-uniE860:before {
  content: "\E860"
}

.icon-uniE861:before {
  content: "\E861"
}

.icon-uniE862:before {
  content: "\E862"
}

.icon-uniE863:before {
  content: "\E863"
}

.icon-uniE864:before {
  content: "\E864"
}

.icon-uniE865:before {
  content: "\E865"
}

.icon-uniE866:before {
  content: "\E866"
}

.icon-uniE867:before {
  content: "\E867"
}

.icon-uniE868:before {
  content: "\E868"
}

.icon-uniE869:before {
  content: "\E869"
}

.icon-uniE870:before {
  content: "\E870"
}

.icon-uniE871:before {
  content: "\E871"
}

.icon-uniE872:before {
  content: "\E872"
}

.icon-uniE873:before {
  content: "\E873"
}

.icon-uniE874:before {
  content: "\E874"
}

.icon-uniE875:before {
  content: "\E875"
}

.icon-uniE876:before {
  content: "\E876"
}

.icon-uniE877:before {
  content: "\E877"
}

.icon-uniE878:before {
  content: "\E878"
}

.icon-uniE879:before {
  content: "\E879"
}

.icon-uniE880:before {
  content: "\E880"
}

.icon-uniE881:before {
  content: "\E881"
}

.icon-uniE882:before {
  content: "\E882"
}

.icon-uniE883:before {
  content: "\E883"
}

.icon-uniE884:before {
  content: "\E884"
}

.icon-uniE885:before {
  content: "\E885"
}

.icon-uniE886:before {
  content: "\E886"
}

.icon-uniE887:before {
  content: "\E887"
}

.icon-uniE888:before {
  content: "\E888"
}

.icon-uniE889:before {
  content: "\E889"
}

.icon-uniE88A:before {
  content: "\E88A"
}

.icon-uniE88B:before {
  content: "\E88B"
}

.icon-uniE88C:before {
  content: "\E88C"
}

.icon-uniE88D:before {
  content: "\E88D"
}

.icon-uniE890:before {
  content: "\E890"
}

.icon-uniE891:before {
  content: "\E891"
}

.icon-uniE892:before {
  content: "\E892"
}

.icon-uniE893:before {
  content: "\E893"
}

.icon-uniE894:before {
  content: "\E894"
}

.icon-uniE895:before {
  content: "\E895"
}

.icon-uniE896:before {
  content: "\E896"
}

.icon-uniE897:before {
  content: "\E897"
}

.icon-uniE898:before {
  content: "\E898"
}

.icon-uniE89A:before {
  content: "\E89A"
}

.icon-uniE89B:before {
  content: "\E89B"
}

.icon-uniE89C:before {
  content: "\E89C"
}

.icon-uniE89D:before {
  content: "\E89D"
}

.icon-uniE8A1:before {
  content: "\E8A1"
}

.icon-uniE8A2:before {
  content: "\E8A2"
}

.icon-uniE8A3:before {
  content: "\E8A3"
}

.icon-uniE8A4:before {
  content: "\E8A4"
}

.icon-uniE8A5:before {
  content: "\E8A5"
}

.icon-uniE8A6:before {
  content: "\E8A6"
}

.icon-uniE8A7:before {
  content: "\E8A7"
}

.icon-uniE8A8:before {
  content: "\E8A8"
}

.icon-uniE900:before {
  content: "\E900"
}

.icon-uniE901:before {
  content: "\E901"
}

.icon-uniE902:before {
  content: "\E902"
}

.icon-uniE903:before {
  content: "\E903"
}

.icon-uniE904:before {
  content: "\E904"
}

.icon-uniE905:before {
  content: "\E905"
}

.icon-uniE906:before {
  content: "\E906"
}

.icon-uniE907:before {
  content: "\E907"
}

.icon-uniE908:before {
  content: "\E908"
}

.icon-uniE909:before {
  content: "\E909"
}

.icon-uniE910:before {
  content: "\E910"
}

.icon-uniE911:before {
  content: "\E911"
}

.icon-uniE912:before {
  content: "\E912"
}

.icon-uniE913:before {
  content: "\E913"
}

.icon-uniE914:before {
  content: "\E914"
}

.icon-uniE915:before {
  content: "\E915"
}

.icon-uniE916:before {
  content: "\E916"
}

.icon-uniE940:before {
  content: "\E940"
}

.icon-uniE941:before {
  content: "\E941"
}

.icon-uniE942:before {
  content: "\E942"
}

.icon-uniE943:before {
  content: "\E943"
}

.icon-uniF000:before {
  content: "\F000"
}

.icon-uniF001:before {
  content: "\F001"
}

.icon-uniF002:before {
  content: "\F002"
}

.icon-uniF003:before {
  content: "\F003"
}

.icon-uniF004:before {
  content: "\F004"
}

.icon-uniF005:before {
  content: "\F005"
}

.icon-uniF006:before {
  content: "\F006"
}

.icon-uniF007:before {
  content: "\F007"
}

.icon-uniF008:before {
  content: "\F008"
}

.icon-uniF009:before {
  content: "\F009"
}

.icon-uniF010:before {
  content: "\F010"
}

.icon-uniF011:before {
  content: "\F011"
}

.icon-uniF012:before {
  content: "\F012"
}

.icon-uniF013:before {
  content: "\F013"
}

.icon-uniF014:before {
  content: "\F014"
}

.icon-uniF015:before {
  content: "\F015"
}

.icon-uniF016:before {
  content: "\F016"
}

.icon-uniF017:before {
  content: "\F017"
}

.icon-uniF018:before {
  content: "\F018"
}

.icon-uniF019:before {
  content: "\F019"
}

.icon-uniF020:before {
  content: "\F020"
}

.icon-uniF021:before {
  content: "\F021"
}

.icon-uniF022:before {
  content: "\F022"
}

.icon-uniF023:before {
  content: "\F023"
}

.icon-uniF024:before {
  content: "\F024"
}

.icon-uniF025:before {
  content: "\F025"
}

.icon-uniF026:before {
  content: "\F026"
}

.icon-uniF027:before {
  content: "\F027"
}

.icon-uniF028:before {
  content: "\F028"
}

.icon-uniF029:before {
  content: "\F029"
}

.icon-uniF030:before {
  content: "\F030"
}

.icon-uniF031:before {
  content: "\F031"
}

.icon-uniF032:before {
  content: "\F032"
}

.icon-uniF033:before {
  content: "\F033"
}

.icon-uniF034:before {
  content: "\F034"
}

.icon-uniF035:before {
  content: "\F035"
}

.icon-uniF036:before {
  content: "\F036"
}

.icon-uniF037:before {
  content: "\F037"
}

.icon-uniF038:before {
  content: "\F038"
}

.icon-uniF039:before {
  content: "\F039"
}

.icon-uniF040:before {
  content: "\F040"
}

.icon-stacked-screens:before {
  content: "\F041"
}

.icon-stacked-screens-small:before {
  content: "\F099"
}

.icon-cross-device-screens:before {
  content: "\F042"
}

.icon-cross-device-screens-desktop:before {
  content: "\F098"
}

.icon-cross-device-screens-small:before {
  content: "\F097"
}

.icon-tv-screen:before {
  content: "\F043"
}

.icon-price-tag:before {
  content: "\F044"
}

.icon-cancel:before {
  content: "\F045"
}

.button-container {
  box-sizing: border-box;
  padding: 1em 0;
  width: 100%
}

.button-container.no-top-padding {
  padding-top: 0
}

.button-container.button-container-centered {
  text-align: center
}

.button-container.button-container-left {
  float: none;
  text-align: left
}

.button-container.button-container-left .button {
  margin-left: 0
}

.button-container.button-container-right {
  text-align: right
}

.button-container.button-container-right .button {
  margin-right: 0
}

@media only screen and (min-width: 360px) {
  .button-container {
    text-align: center
  }
}

.button {
  background: #f2f2f2;
  background: linear-gradient(180deg, #f2f2f2 0, #dbdbdb);
  border: 1px solid #ccc;
  border-radius: .2em;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2f2f2", endColorstr="#dbdbdb", GradientType=0);
  font-size: 1em;
  margin: 0 .5em;
  padding: .75em 2em
}

@media only screen and (max-width: 28.6875em) {
  .button {
    display: block;
    font-size: 1.2em;
    margin: 1em auto;
    padding: 1em;
    width: 100%
  }
}

.button.action-button {
  background: #1b76de;
  background: linear-gradient(180deg, #1b76de 0, #115aae);
  color: #fff;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b76de", endColorstr="#115aae", GradientType=0)
}

.button.action-button.disabled {
  background: #8dbaee;
  background: linear-gradient(180deg, #8dbaee 0, #88acd6);
  cursor: default;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8dbaee", endColorstr="#88acd6", GradientType=0)
}

.modal-backdrop {
  --bs-backdrop-bg: rgb(0 0 0 / 50%) !important;
  background-color: var(--bs-backdrop-bg) !important;
}

.modal-masflix {
  width: 870px !important;
  max-width: 900px !important;
}

.modal-masflix .previewModal--wrapper {
  top: 0;
}

.modal-masflix .previewModal--container {
  width: 100%;
  opacity: 1;
}

.modal-masflix .previewModal--player_container .previewModal--player-titleTreatmentWrapper {
  width: 100%;
}

.modal-masflix .previewModal--player_container .previewModal--player-titleTreatmentWrapper .previewModal--player-titleTreatment-left {
  bottom: 5%;
  width: 40%;
}


.chapter-detail {
  margin-top: 0.1rem;
  margin-bottom: 8px;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
}

.chapters-course {
  font-size: 14px;
  display: inline;
  font-weight: 500;
  padding: 2rem 0px 1rem;
  margin-right: 8px;
}

.body-modal-open {
  overflow: hidden
}

.nfmodal {
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, .65);
  bottom: 0;
  color: #333;
  left: 0;
  overflow: auto;
  overflow: scroll;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100
}

.nfmodal.opaque {
  background-color: #000
}

.nfmodal.opaque .nfdialog {
  color: #fff
}

.nfmodal.clearBackground, .nfmodal.opaque .nfdialog {
  background-color: transparent
}

.nfmodal.fluidWidth {
  text-align: center
}

.nfmodal.fluidWidth .nfdialog {
  display: inline-block;
  max-width: none;
  text-align: left
}

.nfdialog {
  background-color: #fff;
  color: inherit;
  font-size: .9em;
  margin: 12% auto 0;
  max-width: 640px;
  min-height: 200px;
  overflow: visible;
  padding-bottom: 20px;
  text-align: left
}

.nfdialog.small {
  min-height: 150px;
  padding-bottom: 0;
  width: 500px
}

.nfdialog.large {
  margin-top: 3%;
  max-width: 700px;
  min-height: 250px;
  width: 700px
}

@media only screen and (max-width: 790px) {
  .nfdialog.large, .nfdialog.small {
    width: 90%
  }
}

@media only screen and (max-width: 360px) {
  .nfdialog {
    width: 95%
  }
}

.nfdfoot, .nfdhead {
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 18px
}

.nfdfoot.unused, .nfdhead.unused {
  display: none
}

.nfdcontent, .nfdfoot, .nfdhead {
  padding: 6px 20px
}

.nfdhead {
  border-bottom-radius: 0;
  margin-bottom: 10px;
  position: relative
}

.nfdhead h1 {
  color: inherit;
  font-weight: 400;
  margin: 10px 20px 0 0;
  padding: 0
}

.nfdhead h3 {
  margin: 5px 0 0
}

.opaque .nfdhead h1 {
  font-size: 3.5vw;
  text-align: center
}

@media screen and (max-width: 800px) {
  .opaque .nfdhead h1 {
    font-size: 30px
  }
}

.buttonbar {
  margin-bottom: 25px
}

.buttonbar, .nfdfoot {
  overflow: hidden;
  text-align: right
}

.buttonbar .svf-button, .nfdfoot .svf-button {
  display: inline-block;
  float: none;
  margin-left: 1em;
  margin-right: 0;
  min-width: 8em;
  text-align: center
}

.buttonbar .leftLink, .nfdfoot .leftLink {
  float: left;
  margin-left: 0;
  margin-right: 1em
}

.buttonbar .rightLink, .nfdfoot .rightLink {
  float: right;
  margin-left: 1em;
  margin-right: 0
}

.nfdcontent {
  padding: 6px 20px 5px
}

@media only screen and (max-width: 360px) {
  .nfdcontent {
    padding: 6px 10px 5px
  }
}

.nfdcontent em {
  color: #ae1d00;
  font-style: normal;
  font-weight: 500
}

.nfdcontent .topImg {
  display: block;
  margin: 15px auto 0;
  min-height: 300px;
  min-width: 400px
}

.nfdclose {
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 30px
}

.nfdclose, .nfdclose:hover {
  text-decoration: none
}

.nfdclose:before {
  color: #666;
  content: "\00D7";
  font-size: 40px
}

.btn-bar {
  clear: both;
  padding: 0 0 1em
}

.btn-bar.hilite {
  background-color: #e5e5e5;
  padding: 10px
}

.btn-bar.hilite b, .btn-bar.hilite em {
  font-style: normal;
  font-weight: 500
}

.btn-bar.noHilite b, .btn-bar.noHilite em {
  font-style: normal;
  font-weight: 400
}

.btn-bar.top-padding {
  padding-top: 1em
}

.btn-bar-left .btn {
  margin-left: 0
}

.btn-bar-right .btn {
  margin-right: 0
}

.btn {
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6, #ddd);
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .2);
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  letter-spacing: .1px;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle
}

.btn .btn-text {
  position: relative;
  top: 1px
}

.btn.disabled {
  cursor: default;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  opacity: .5
}

.btn.waiting, .btn.waiting.akira-button, .btn.waiting.akira-button-red, .btn.waiting:disabled, .btn.waiting:disabled.akira-button, .btn.waiting:disabled.akira-button-red {
  color: hsla(0, 0%, 100%, 0)
}

.btn.waiting span, .btn.waiting.akira-button span, .btn.waiting.akira-button-red span, .btn.waiting:disabled span, .btn.waiting:disabled.akira-button span, .btn.waiting:disabled.akira-button-red span {
  opacity: 0
}

.btn.hover, .btn:hover {
  background: #eaeaea;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .2);
  text-decoration: none
}

.btn.active, .btn:active {
  background: #c2c2c2
}

.btn.disabled {
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6, #ddd);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .2)
}

.btn .waitIndicator {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.btn .waitIndicator .basic-spinner {
  left: 50%;
  position: absolute;
  top: 50%
}

.action-button, .btn-blue, .btn-submit {
  background-color: #0080ff;
  background-image: linear-gradient(180deg, #0080ff, #0277ec);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55);
  color: #fff
}

.action-button.hover, .action-button:hover, .btn-blue.hover, .btn-blue:hover, .btn-submit.hover, .btn-submit:hover {
  background: #2490fd;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55)
}

.action-button.active, .action-button:active, .btn-blue.active, .btn-blue:active, .btn-submit.active, .btn-submit:active {
  background: #0166c9
}

.action-button.disabled, .btn-blue.disabled, .btn-submit.disabled {
  background-color: #0080ff;
  background-image: linear-gradient(180deg, #0080ff, #0272e2);
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .3), 0 1px 0 rgba(0, 0, 0, .55)
}

.btn-black {
  background-color: #393939;
  background-image: linear-gradient(180deg, #393939, #000);
  color: #fff
}

.btn-black.hover, .btn-black:hover {
  background: #464646
}

.btn-black.active, .btn-black:active {
  background: #000
}

.btn-black.disabled {
  background-color: #3a3a3a;
  background-image: linear-gradient(180deg, #3a3a3a, #393939);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55)
}

.btn-red {
  background-color: #009779;
  background-image: linear-gradient(180deg, #009779, #db0510);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .45);
  color: #fff
}

.btn-red .basic-spinner {
  box-shadow: inset 2px 2px 0 #fff
}

.btn-red.hover, .btn-red:focus, .btn-red:hover {
  background: #f40612;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .45)
}

.btn-red:focus {
  box-shadow: 0 0 0 .085em #85050b
}

.btn-red.active, .btn-red:active {
  background: #bb0a12
}

.btn-red.disabled {
  background-color: #009779;
  background-image: linear-gradient(180deg, #009779, #db0510)
}

.btn-orange, .btn-red.disabled {
  box-shadow: 0 1px 0 rgba(0, 0, 0, .45)
}

.btn-orange {
  background-color: #ffa00a;
  background-image: linear-gradient(180deg, #ffa00a, #f09300);
  color: #000
}

.btn-orange.hover, .btn-orange:hover {
  background: #ffb43d;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .45)
}

.btn-orange.active, .btn-orange:active {
  background: #d68300
}

.btn-orange.disabled {
  background-color: #009779;
  background-image: linear-gradient(180deg, #009779, #db0510);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .45)
}

.btn-gray, .btn-plain {
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6, #ddd);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .2);
  color: #000
}

.btn-gray.hover, .btn-gray:hover, .btn-plain.hover, .btn-plain:hover {
  background: #eaeaea;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .2)
}

.btn-gray.active, .btn-gray:active, .btn-plain.active, .btn-plain:active {
  background: #c2c2c2
}

.btn-gray.disabled, .btn-plain.disabled {
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6, #ddd);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .2)
}

.btn-dark-gray {
  background-color: #8c8c8c;
  background-image: linear-gradient(180deg, #8c8c8c, #7a7a7a);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55);
  color: #fff
}

.btn-dark-gray.hover, .btn-dark-gray:hover {
  background: #878787;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55)
}

.btn-dark-gray.active, .btn-dark-gray:active {
  background: #555
}

.btn-dark-gray.disabled {
  background-color: #8c8c8c;
  background-image: linear-gradient(180deg, #8c8c8c, #7a7a7a);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55)
}

.btn-darker-gray {
  background-color: #3a3a3a;
  background-image: linear-gradient(180deg, #3a3a3a, #393939);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55);
  color: #fff
}

.btn-darker-gray.hover, .btn-darker-gray:hover {
  background: #464646;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55)
}

.btn-darker-gray.active, .btn-darker-gray:active {
  background: #444
}

.btn-darker-gray.disabled {
  background-color: #3a3a3a;
  background-image: linear-gradient(180deg, #3a3a3a, #393939);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55)
}

.btn-outline {
  background: transparent;
  border: 1px solid #666;
  box-shadow: none;
  color: #666
}

.btn-outline.hover, .btn-outline:hover {
  background: none;
  border-color: #333;
  color: #333
}

.btn-outline.active, .btn-outline:active {
  border-color: #1a1a1a;
  color: #1a1a1a
}

.btn-outline.disabled {
  border-color: #999;
  color: #999
}

.btn-white-outline {
  background: transparent;
  border: 1px solid hsla(0, 0%, 100%, .75);
  box-shadow: none;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .15)
}

.btn-white-outline.hover, .btn-white-outline:hover {
  background: transparent;
  border: 1px solid #fff;
  box-shadow: none
}

.btn-white-outline.active, .btn-white-outline:active {
  background: transparent;
  border: 1px solid hsla(0, 0%, 100%, .55)
}

.btn-white-outline.disabled {
  background: none;
  border: 1px solid hsla(0, 0%, 100%, .75);
  box-shadow: none
}

.btn-white-outline.btn-medium {
  text-shadow: 0 1px 1px rgba(0, 0, 0, .25)
}

.btn-white-outline.btn-small {
  text-shadow: 0 1px 1px rgba(0, 0, 0, .35)
}

.btn-white {
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6, #ddd);
  box-shadow: 0 1px 0 rgba(1, 1, 0, .2);
  color: #fff
}

.btn-white.hover, .btn-white:hover {
  background: transparent;
  border: 1px solid #fff;
  box-shadow: none
}

.btn-white.active, .btn-white:active {
  background: transparent;
  border: 1px solid hsla(0, 0%, 100%, .55)
}

.btn-white.disabled {
  background: none;
  border: 1px solid hsla(0, 0%, 100%, .75);
  box-shadow: none
}

.btn.btn-retro-signup {
  background-color: #1872d9;
  background-image: linear-gradient(180deg, #1872d9, #135aac);
  border: 1px solid #265890;
  box-shadow: inset 0 1px 0 0 #5e9de4;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  min-width: 250px;
  padding: 15px 40px;
  text-shadow: 1px 1px 1px #000
}

.btn.btn-retro-signup:hover {
  background: #115099;
  background-color: #1b7ff2;
  background-image: linear-gradient(180deg, #1b7ff2, #115099);
  box-shadow: 0 4px 10px -6px #010101, inset 0 1px 0 0 #60a6f6
}

.btn.btn-retro-signup:active {
  background: #0d3c73;
  background-color: #115099;
  background-image: linear-gradient(180deg, #115099, #0d3c73);
  border: 1px solid #1e5292;
  box-shadow: inset 0 1px 0 0 #073b7e;
  color: #b3b3b3
}

.btn-block {
  display: block;
  margin-left: 0;
  margin-right: 0
}

.btn {
  font-size: 1em;
  margin: .5em .5em .5em 0;
  min-height: 50px;
  min-width: 74px;
  padding: 24.5px 2em;
  position: relative
}

@media only screen and (max-width: 500px) {
  .btn {
    margin: .5em auto;
    width: 100%
  }
}

.btn.btn-x-large {
  font-size: 26px;
  min-height: 72px;
  min-width: 124px;
  padding: 23px 2em
}

@media only screen and (max-width: 500px) {
  .btn.btn-x-large {
    margin: .5em auto;
    width: 100%
  }
}

.btn.btn-large {
  font-size: 20px;
  min-height: 48px;
  min-width: 112px;
  padding: 14px 2em
}

@media only screen and (max-width: 500px) {
  .btn.btn-large {
    margin: .5em auto;
    width: 100%
  }
}

.btn.btn-medium {
  font-size: 16px;
  min-height: 37px;
  min-width: 104px;
  padding: 10.5px 2em
}

@media only screen and (max-width: 500px) {
  .btn.btn-medium {
    margin: .5em auto;
    width: 100%
  }
}

.btn.btn-select {
  font-size: 16px;
  min-height: 27px;
  min-width: 104px;
  padding: 5.5px 2em
}

.btn.btn-select.t-size-SMALL {
  font-size: 10px;
  line-height: 9px;
  vertical-align: middle
}

.btn.btn-select.t-size-MEDIUM {
  font-size: 15px;
  line-height: 15px;
  vertical-align: middle
}

.btn.btn-select.t-size-LARGE {
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle
}

@media only screen and (max-width: 500px) {
  .btn.btn-select {
    margin: .5em auto;
    width: 30%
  }
}

.btn.btn-small {
  display: block;
  line-height: 50px;
  margin: .5em auto;
  padding: 0;
  width: 100%
}

@media only screen and (min-width: 500px) {
  .btn.btn-small {
    display: inline-block;
    font-size: 13px;
    line-height: 1em;
    margin-right: .5em;
    min-height: 37px;
    min-width: 98px;
    padding: 12px 1em;
    width: auto
  }
}

.akira-button {
  background: #333
}

.akira-button.btn-medium {
  padding: .5em 1em
}

.akira-button:disabled {
  background-color: #333;
  color: #333
}

.akira-button:disabled.waiting {
  color: #333
}

.akira-button.akira-button-red:disabled {
  background-color: rgba(229, 9, 20, .3);
  color: #4d4d4d
}

.outline-btn {
  background-color: transparent;
  border: 2px solid #009779;
  border-radius: 2px;
  box-sizing: border-box;
  color: #009779;
  cursor: pointer;
  display: inline-block;
  padding: 13px 2em;
  text-decoration: none
}

.outline-btn:focus, .outline-btn:hover {
  background-color: rgba(50, 50, 50, .05);
  text-decoration: none
}

.outline-btn:active {
  background-color: rgba(50, 50, 50, .15)
}

.outline-btn.outline-btn-white {
  border-color: #fff;
  color: #fff
}

.outline-btn.outline-btn-white:hover {
  background-color: hsla(0, 0%, 86%, .3);
  text-decoration: none
}

.outline-btn.outline-btn-white:active {
  background-color: hsla(0, 0%, 86%, .45)
}

.basic-spinner {
  animation: full-rotation 1s linear infinite;
  animation-direction: normal;
  background-image: url(https://assets.nflxext.com/ffe/siteui/common/site-spinner-240.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  height: 57px;
  margin: 0 auto;
  opacity: 1;
  width: 57px
}

.basic-spinner.isHidden {
  display: none
}

.basic-spinner.basic-spinner-light {
  background-image: url(https://assets.nflxext.com/ffe/siteui/common/site-spinner-240-light.png)
}

.center-fixed {
  left: 50%;
  position: fixed;
  top: 50%;
  z-index: 2000
}

.center-absolute {
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: 1
}

.ua-ie-7 .basic-spinner, .ua-ie-8 .basic-spinner, .ua-ie-9 .basic-spinner {
  background-image: url(https://assets.nflxext.com/ffe/siteui/common/site-spinner-100.gif)
}

@keyframes full-rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg)
  }

  to {
    transform: translate(-50%, -50%) rotate(1turn)
  }
}

.basic-spinner-region {
  position: relative
}

.basic-spinner-region.isLoading:before {
  background-color: hsla(0, 0%, 100%, .5);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.uma {
  background-color: rgba(0, 0, 0, .97)
}

.uma .message {
  background-color: transparent;
  color: #dcdcdc
}

.uma .simple-notification {
  top: 0
}

.message {
  background-color: transparent;
  border-bottom: 1px solid #333
}

.message .msgContent {
  margin: 0 auto;
  max-width: 700px;
  padding-left: 50px;
  padding-top: 1px
}

.message .msgContent p {
  margin: 0 0 15px
}

.message .btn-bar {
  padding-bottom: 19px;
  padding-top: 0
}

.message .btn-bar .akira-button {
  margin-bottom: 16px;
  padding: .7em 1em;
  text-decoration: none
}

.message .message-headline {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin: 35px 0 5px;
  position: relative;
  vertical-align: middle
}

.message .message-headline:before {
  font-family: nf-icon;
  font-size: 21px;
  left: -1.5em;
  margin-right: .25em;
  position: absolute;
  top: -6px;
  vertical-align: middle
}

.message a {
  color: #fff;
  text-decoration: underline
}

.message p {
  line-height: 1.3em
}

.message.error .test:before {
  color: #5fa53f;
  content: "\e5d0"
}

.message.error .message-headline:before {
  color: #b00500;
  content: "\e743"
}

.message.warn .message-headline:before {
  color: #ffa00a;
  content: "\e743"
}

.message.progress .message-headline:before {
  color: #009779;
  content: "\e765"
}

.message.confirm .message-headline:before {
  color: #5fa53f;
  content: "\e802"
}

.message.info .message-headline:before {
  color: #03aefd;
  content: "\e799"
}

.dark-background .uma {
  border-color: transparent;
  box-shadow: none
}

.dark-background .message {
  border-bottom: 1px solid #333
}

.dark-background .message .message-headline {
  color: #fff
}

.messageBox .message {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 1em;
  max-width: 700px;
  padding: 0 0 0 30px
}

.messageBox .message.error {
  border-color: #ee001c
}

.messageBox .message.warn .headline:before {
  border-color: #ffa00a
}

.messageBox .message.progress .headline:before {
  border-color: #009779
}

.messageBox .message.confirm .headline:before {
  border-color: #5fa53f
}

.messageBox .message.info .headline:before {
  border-color: #03aefd
}

.messageBox .message .message-headline {
  font-size: 1em;
  margin: 5px 0
}

.messageBox .message .message-headline:before {
  font-size: 14pt;
  height: 16px;
  left: -24px;
  top: -3px;
  width: 16px
}

@media screen and (max-width: 740px) {
  body>.uma {
    border-top: none;
    padding: 2px 0 0
  }

  .message {
    margin: 0 6px
  }

  .message .message-headline {
    line-height: 1.05em;
    margin: 5px 0;
    padding-left: 1.75em
  }

  .message .message-headline:before {
    left: 0;
    top: -1px
  }

  .message .msgContent {
    padding-left: 0
  }

  .message p {
    font-size: .9em
  }

  .message .button-container {
    padding: 0
  }
}

.buttonBar {
  padding-bottom: 25px;
  text-align: center
}

.buttonBar.button-container-left {
  text-align: left
}

.buttonBar.button-container-right {
  text-align: right
}

.outer {
  background-color: rgba(0, 0, 0, .65);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0
}

.inner {
  background-color: #fff;
  border: 1px solid #999;
  color: #999;
  margin: 3% auto 0;
  width: 600px
}

.inner section {
  padding: 0 10px 10px
}

.inner section h2 {
  font-size: 1.33em;
  font-weight: 400
}

.inner footer, .inner header {
  color: #333;
  padding: 10px
}

.inner footer h1, .inner header h1 {
  margin: 0
}

.inner header {
  color: #666;
  font-size: 1.4em;
  padding-right: 30px;
  position: relative
}

.inner header h1 {
  font-size: inherit
}

.inner header .closebutton {
  background-color: #ddd;
  border: none;
  border-radius: 50%;
  color: #fff;
  font-size: 1.23em;
  height: 24px;
  line-height: 3px;
  padding: 0;
  position: absolute;
  right: 10px;
  text-align: center;
  text-decoration: none;
  top: .15em;
  width: 24px
}

.inner footer {
  color: #333;
  text-align: right
}

.inner footer button {
  margin-left: 1em
}

.message.whatsapp .msgContent {
  min-height: 9em
}

.message.whatsapp .message-headline:before {
  background-image: url(https://assets.nflxext.com/ffe/siteui/uma/WhatsApp_Logo.png);
  background-repeat: no-repeat;
  background-size: 2em 2em;
  content: "";
  height: 2em;
  left: -2.5em;
  width: 2em
}

.extended-diacritics-language .message p {
  line-height: 1.6
}

@keyframes simpleNotificationAnimation {
  0% {
    opacity: 0;
    transform: scale(0)
  }

  to {
    opacity: 1;
    transform: scale(1)
  }
}

.simple-notification {
  left: 0;
  position: absolute;
  text-align: center;
  top: 70px;
  width: 100%
}

.simple-notification p {
  display: inline-block;
  margin: 0;
  padding: .3em .8em .5em
}

.simple-notification.success p {
  background: #65b539
}

.simple-notification.error p, .simple-notification.warning p {
  background: #eab000
}

.simple-notification.info p {
  background: #007cff
}

.simple-notification span {
  display: inline-block;
  vertical-align: middle
}

.simple-notification.animated {
  animation-duration: .33s;
  animation-iteration-count: 1;
  animation-name: simpleNotificationAnimation;
  animation-timing-function: ease-in-out
}

.simple-notification-message {
  font-weight: 500;
  margin: 0 0 0 .5em
}

.slideshow {
  background-color: #f3f3f3;
  color: #4d4d4d;
  margin: 0 -20px -26px;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0
}

@media only screen and (max-width: 860px) {
  .slideshow {
    display: none
  }
}

.slideshow li, .slideshow ul {
  margin: 0;
  padding: 0
}

.slideshow .ss_viewport {
  margin: 0 20px;
  overflow: hidden;
  text-align: left
}

.slideshow .ss_container {
  width: 400%
}

.slideshow .ss_container .ss_item {
  background: transparent url(https://assets.nflxext.com/en_us/UmaTest/Benefits_Master_3.png) no-repeat 100% 0;
  box-sizing: border-box;
  display: inline-block;
  height: 140px;
  padding: 30px 220px 30px 0;
  width: 25%
}

.slideshow .ss_container .ss_item h3 {
  font-size: 20px
}

.slideshow .ss_container .ss_item.d2 {
  background-position: right -130px;
  padding-right: 250px
}

.slideshow .ss_container .ss_item.d3 {
  background-position: right -260px
}

.slideshow .ss_container .ss_item.d4 {
  background-position: right -400px
}

.pagination-indicator li button {
  display: none;
}

.slideshow .nav.dots {
  text-align: center
}

.slideshow .nav.dots a {
  border: 1px solid #999;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 0 2px;
  outline: 0;
  text-indent: -999em;
  width: 8px
}

.slideshow.d1 .nav.dots a.d1 {
  background-color: #999
}

.slideshow.d1 .ss_container {
  margin-left: 0
}

.slideshow.d2 .nav.dots a.d2 {
  background-color: #999
}

.slideshow.d2 .ss_container {
  margin-left: -100%
}

.slideshow.d3 .nav.dots a.d3 {
  background-color: #999
}

.slideshow.d3 .ss_container {
  margin-left: -200%
}

.slideshow.d4 .nav.dots a.d4 {
  background-color: #999
}

.slideshow.d4 .ss_container {
  margin-left: -300%
}

.btn-bar {
  box-sizing: border-box;
  padding: 1em 0;
  width: 100%
}

.btn-bar.no-top-padding {
  padding-top: 0
}

.btn-bar.btn-bar-center {
  text-align: center
}

.btn-bar.button-container-left {
  float: none;
  text-align: left
}

.btn-bar.button-container-left .button {
  margin-left: 0
}

.btn-bar.button-container-right {
  text-align: right
}

.btn-bar.button-container-right .button {
  margin-right: 0
}

@media only screen and (min-width: 360px) {
  .btn-bar {
    text-align: center
  }
}

.btn-bar-left {
  text-align: left
}

.btn-bar-left .btn {
  margin-right: 1em
}

.btn-bar-right {
  text-align: right
}

.btn-bar-right .btn {
  margin-left: 1em
}

.btn-bar-center {
  text-align: center
}

.btn-bar-center .btn {
  margin-left: .5em;
  margin-right: .5em
}

.btn-bar-flex {
  display: flex;
  flex-direction: row;
  justify-content: center
}

.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #000;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  box-sizing: border-box;
  color: #b3b3b3;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  letter-spacing: 1px;
  line-height: 32px;
  padding: 0 10px;
  position: relative;
  text-transform: uppercase
}

.button:focus {
  outline: 0
}

.button:hover {
  background-color: #333;
  cursor: pointer
}

.button.action-button {
  background-color: #ccc;
  border: 1px solid #ccc;
  color: #000
}

.button.action-button:hover {
  background-color: #fff;
  border-color: #fff
}

.button.action-button:hover.disabled {
  background-color: #ccc;
  border-color: #ccc;
  cursor: default
}

.button.responsive {
  font-size: 1.4vw;
  height: 3vw;
  letter-spacing: .15vw;
  line-height: 2.9vw;
  padding: 0 2vw
}

.nf-modal {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 16px;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: 100
}

@media screen and (min-width: 1280px) {
  .nf-modal {
    font-size: 1.25vw
  }
}

@media screen and (min-width: 2560px) {
  .nf-modal {
    font-size: 32px
  }
}

.nf-modal .nf-modal-background {
  background-color: rgba(0, 0, 0, .7);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1
}

.nf-modal .nf-modal-content {
  border-top: 4px solid #009779;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100%
}

@media screen and (min-width: 500px) {
  .nf-modal .nf-modal-content {
    height: auto;
    max-height: 70vh;
    max-width: 1280px;
    min-height: 10vh;
    min-width: 500px;
    width: 50%
  }
}

.nf-modal-content-wrapper {
  display: flex;
  flex-direction: column
}

@media screen and (min-width: 500px) {
  .nf-modal-content-wrapper {
    max-height: 70vh;
    min-height: 10vh
  }
}

.nf-modal-content-wrapper .nf-modal-dismiss, .nf-modal-content-wrapper .nf-modal-footer, .nf-modal-content-wrapper .nf-modal-header {
  flex: 0 0 auto;
  flex-basis: auto;
  height: auto
}

.nf-modal-content-wrapper .nf-modal-header {
  padding: 0 15px
}

.nf-modal-content-wrapper .nf-modal-body {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 15px
}

.nf-modal-content-wrapper .nf-modal-footer {
  padding: 0 15px
}

.nf-modal-content-wrapper .nf-modal-dismiss {
  min-height: .5em;
  text-align: right;
  text-align: end
}

.nf-modal-content-wrapper .nf-modal-dismiss .nf-modal-dismiss-btn {
  background: none;
  border: none;
  font-size: 1.2em;
  padding: .5em .5em 0
}

.nf-modal-content-wrapper .nf-modal-dismiss .nf-modal-dismiss-btn:focus {
  outline: auto
}

.nf-modal-content-wrapper .nf-modal-header .nf-modal-heading {
  margin-top: 0
}

.nf-modal-content-wrapper .nf-modal-footer .nf-modal-button-bar {
  padding: 15px 0
}

.nf-modal-content-wrapper .nf-modal-hr {
  border: 0;
  border-top: 1px solid #333;
  display: block;
  height: 1px;
  margin: 0;
  padding: 0
}

.nf-modal-background.modal-enter {
  opacity: 0;
  transition: opacity .25s cubic-bezier(.32, .94, .6, 1) 67ms
}

.nf-modal-background.modal-enter-active {
  opacity: 1
}

.nf-modal-content.modal-enter {
  opacity: 0;
  transform: scale(.88);
  transition: opacity .25s cubic-bezier(.32, .94, .6, 1) .1s, transform 533ms cubic-bezier(.32, .94, .6, 1)
}

.nf-modal-content.modal-enter-active {
  opacity: 1;
  transform: scale(1)
}

.nf-modal-background.modal-exit {
  opacity: 1;
  transition: opacity .35s cubic-bezier(.32, .94, .6, 1)
}

.nf-modal-background.modal-exit-active {
  opacity: 0
}

.nf-modal-content.modal-exit {
  opacity: 1;
  transform: scale(1);
  transition: opacity 317ms cubic-bezier(.32, .94, .6, 1) 117ms, transform 317ms cubic-bezier(.32, .94, .6, 1) 67ms
}

.nf-modal-content.modal-exit-active {
  opacity: 0;
  transform: scale(.88)
}

.nf-modal.reaction-rating-modal {
  align-items: flex-start;
  padding-top: 2rem
}

.nf-modal.reaction-rating-modal .nf-modal-content {
  border-top: none;
  max-height: none;
  width: 80%
}

.nf-modal.reaction-rating-modal .nf-modal-content .nf-modal-content-wrapper {
  max-height: none
}

.nf-modal.reaction-rating-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-dismiss-btn {
  position: fixed;
  right: 4rem;
  top: 4rem
}

.nf-modal.reaction-rating-modal .nf-modal-hr {
  display: none
}

.nf-modal.reaction-rating-modal .nf-modal-body {
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 80vh;
  justify-content: center;
  overflow: hidden
}

.reactions-modal {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.reactions-modal .reaction-description {
  margin: 2rem;
  text-align: center
}

.reactions-modal .reaction-description h2 {
  font-size: 4.2rem;
  margin-bottom: 1rem
}

.reactions-modal .reaction-description p {
  color: hsla(0, 0%, 100%, .7);
  font-size: 2rem
}

.reactions-modal .reaction-options {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  width: 75%
}

.reactions-modal .reaction-options .reaction-option {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.reactions-modal .reaction-options .reaction-option.reaction-thumbs-down-first {
  order: -1
}

.reactions-modal .reaction-options .reaction-option svg {
  flex-grow: 0;
  height: 5rem;
  width: 5rem
}

.reactions-modal .reaction-options .reaction-option p {
  font-size: 1.6rem;
  max-width: 14rem;
  text-align: center
}

.reactions-modal .reaction-button {
  margin-bottom: 1rem
}

.reactions-modal .reaction-button button {
  background-color: hsla(0, 0%, 9%, .7) !important
}

.reactions-modal .reaction-button button:hover {
  background-color: hsla(0, 0%, 9%, .3) !important
}

#appMountPoint.reaction-modal-blur {
  filter: blur(5px)
}

.plan-select-loading-container {
  height: 200px
}

.plan-select-loading-spinner {
  left: 50%;
  position: absolute;
  top: 42%
}

.plan-select-back-arrow-container {
  cursor: pointer;
  left: 8px;
  position: absolute;
  top: 8px
}

.plan-select-back-arrow-container .svg-icon {
  height: 48px;
  width: 48px
}

.plan-select-back-arrow-button {
  background: transparent;
  border: 0;
  left: 8px;
  padding: 6px;
  position: absolute;
  top: 8px
}

.plan-select-back-arrow-button svg {
  height: 36px;
  width: 36px
}

.plan-select-back-arrow.svg-icon {
  color: #999;
  display: block
}

.plan-select-container {
  margin: 72px 0 24px
}

.plan-select-confirm-container {
  margin: 24px 0
}

.plan-select-disclaimer {
  color: #ccc;
  font-size: 12px;
  margin: 12px 0
}

.plan-select-disclaimer-label {
  align-items: start;
  display: flex
}

.plan-select-disclaimer-checkbox {
  margin: 4px 25px
}

.plan-select-disclaimer-text {
  text-align: left
}

[dir=rtl] .plan-select-back-arrow.svg-icon {
  transform: rotate(-180deg)
}

.plan-select__fieldset {
  border: 0;
  margin: 0;
  padding: 0
}

.plan-select__radio {
  opacity: 0;
  position: absolute
}

.plan-select__radio:focus+.plan-select-row {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color
}

.price-change-modal .plan-select-container {
  margin: 30px 0 24px
}

.price-change-modal .uma-modal-title {
  font-size: 1.5em
}

.price-change-modal .uma-modal-cta-row-2, .price-change-modal .uma-modal-footer {
  font-size: 16px
}

@media screen and (min-width: 500px) {
  .price-change-modal .nf-modal-content {
    max-width: 1000x;
    width: 70%
  }
}

.plan-select-row {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid #737373;
  cursor: pointer;
  display: flex;
  padding: 10px 0;
  position: relative;
  text-align: left;
  z-index: 1
}

.plan-select-row:last-child {
  border-bottom: 1px solid #737373
}

.plan-select-row.plan-select-row-selected {
  border: 1px solid #8c8c8c;
  margin-bottom: -1px;
  z-index: 2
}

.plan-select-row.plan-select-row-selected:last-child {
  margin-bottom: 0
}

.plan-select-row-checkmark-container {
  flex: 0 0 65px
}

.plan-select-row .plan-select-row-checkmark.svg-icon {
  color: #5fa53f;
  display: block;
  height: 32px;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 32px
}

.plan-select-row-header {
  font-size: 20px
}

.plan-select-row-info {
  font-size: 16px
}

.plan-select-row-info-text {
  color: #ccc;
  display: block;
  margin-right: 5px
}

.plan-select-row-info-price {
  display: inline-block;
  font-weight: 500
}

.plan-select-summary {
  margin: 80px 0;
  text-align: center
}

.plan-select-summary-close-btn {
  color: #999;
  cursor: pointer;
  font-size: 24px;
  padding: 8px;
  position: absolute;
  right: 8px;
  top: 8px
}

.change-plan-loading-container {
  height: 200px
}

.change-plan-loading-spinner {
  left: 50%;
  position: absolute;
  top: 42%
}

.change-plan-back-arrow-container {
  cursor: pointer;
  left: 8px;
  position: absolute;
  top: 8px
}

.change-plan-back-arrow-container .svg-icon {
  height: 48px;
  width: 48px
}

.change-plan-back-arrow-button {
  background: transparent;
  border: 0;
  left: 8px;
  padding: 6px;
  position: absolute;
  top: 8px
}

.change-plan-back-arrow-button svg {
  height: 36px;
  width: 36px
}

.change-plan-back-arrow.svg-icon {
  color: #999;
  display: block
}

.change-plan-container {
  margin: 72px 0 24px
}

.change-plan-confirm-container {
  margin: 24px 0
}

.change-plan-disclaimer {
  color: #ccc;
  font-size: 12px;
  margin: 12px 0
}

[dir=rtl] .change-plan-back-arrow.svg-icon {
  transform: rotate(-180deg)
}

.change-plan-row {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid #737373;
  cursor: pointer;
  display: flex;
  padding: 10px 0;
  position: relative;
  text-align: left;
  z-index: 1
}

.change-plan-row:last-child {
  border-bottom: 1px solid #737373
}

.change-plan-row.change-plan-row-selected {
  border: 1px solid #8c8c8c;
  margin-bottom: -1px;
  z-index: 2
}

.change-plan-row.change-plan-row-selected:last-child {
  margin-bottom: 0
}

.change-plan-row-checkmark-container {
  flex: 0 0 65px
}

.change-plan-row-checkmark.svg-icon {
  color: #5fa53f;
  display: block;
  height: 32px;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 32px
}

.change-plan-row-header {
  font-size: 20px
}

.change-plan-row-info {
  font-size: 16px
}

.change-plan-row-info-text {
  color: #ccc;
  display: block;
  margin-right: 5px
}

.change-plan-row-info-price {
  display: inline-block;
  font-weight: 500
}

.change-plan-summary {
  margin: 80px 0;
  text-align: center
}

.change-plan-summary-close-btn {
  color: #999;
  cursor: pointer;
  font-size: 24px;
  padding: 8px;
  position: absolute;
  right: 8px;
  top: 8px
}

.content-parity-modal .change-plan-container {
  margin: 30px 0 24px
}

.content-parity-modal .uma-modal-title {
  font-size: 1.5em
}

.content-parity-modal .uma-modal-cta-row-2, .content-parity-modal .uma-modal-footer {
  font-size: 16px
}

@media screen and (min-width: 500px) {
  .content-parity-modal .nf-modal-content {
    max-width: 1000x;
    width: 70%
  }
}

.change-plan-disclaimer-container {
  display: flex
}

.change-plan-disclaimer-container.disclaimer-checkbox-required {
  text-align: left
}

.change-plan-checkbox-container {
  display: flex;
  justify-content: flex-end;
  margin: 12px;
  width: 45px
}

.change-plan-checkbox-container>div {
  display: flex
}

.demoCollectionUma .mainHeader {
  height: 68px;
  padding: 20px 60px;
  text-align: left
}

.demoCollectionUma .mainHeader .logo {
  color: #009779;
  font-size: 25px
}

.demoCollectionUma .demoCollectionContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
  padding-left: 10px;
  padding-right: 10px
}

.demoCollectionUma .demoCollectionFormContainer {
  max-width: 522px;
  text-align: left
}

.demoCollectionUma .demoCollectionFormContainer .header {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 44px
}

.demoCollectionUma .demoCollectionFormContainer .demoCollectionForm {
  border-bottom: 1px solid hsla(240, 0%, 43%, .2);
  display: flex;
  gap: 20px;
  padding-bottom: .5em
}

.demoCollectionUma .demoCollectionFormContainer .profileAvatar {
  padding-top: 21px
}

.demoCollectionUma .demoCollectionFormContainer .profileAvatar img {
  border-radius: 4px;
  height: 115px;
  width: 115px
}

.demoCollectionUma .demoCollectionFormContainer .profileName {
  color: hsla(0, 0%, 100%, .7);
  font-size: 18px;
  text-align: center
}

.demoCollectionUma .demoCollectionFormContainer .description {
  border-bottom: 1px solid hsla(240, 0%, 43%, .2);
  color: hsla(0, 0%, 100%, .7);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  padding-bottom: 21px
}

.demoCollectionUma .demoCollectionFormContainer .formContent {
  flex-grow: 1
}

.demoCollectionUma .demoCollectionFormContainer .formSubHeader {
  color: hsla(0, 0%, 100%, .4);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px
}

.demoCollectionUma .demoCollectionFormContainer .formSection {
  padding-bottom: 21px;
  padding-top: 21px
}

.demoCollectionUma .demoCollectionFormContainer .formSection:first-child {
  border-bottom: 1px solid hsla(240, 0%, 43%, .2)
}

.demoCollectionUma .demoCollectionFormContainer .formSelectContainer {
  padding-bottom: 3px
}

.demoCollectionUma .demoCollectionFormContainer label {
  display: none
}

.demoCollectionUma .demoCollectionFormContainer select::-ms-expand {
  display: none
}

.demoCollectionUma .demoCollectionFormContainer select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: hsla(0, 0%, 9%, .7);
  background-image: linear-gradient(45deg, transparent 50%, currentColor 0), linear-gradient(135deg, currentColor 50%, transparent 0);
  background-position: right 15px top 1.2em, right 10px top 1.2em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px;
  border: 1px solid hsla(0, 0%, 50%, .7);
  border-radius: 4px;
  box-sizing: border-box;
  color: hsla(0, 0%, 100%, .4);
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  line-height: inherit;
  padding: .7em 2em .7em .5em
}

.demoCollectionUma .demoCollectionFormContainer select.selected {
  color: hsla(0, 0%, 100%, .7)
}

.demoCollectionUma .demoCollectionFormContainer button {
  background: #fff;
  border: 0;
  border-radius: 4px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 21px;
  margin-top: 21px;
  padding: .5em 1.5em
}

.demoCollectionUma .demoCollectionFormContainer button:disabled, .demoCollectionUma .demoCollectionFormContainer button[disabled] {
  background: #878787;
  color: #1f1f1f
}

.demoCollectionUma .firstCheckboxContainer {
  padding-top: 20px
}

.demoCollectionUma .formCheckboxContainer {
  border: none;
  color: hsla(0, 0%, 100%, .7);
  display: flex;
  padding-bottom: 20px
}

.demoCollectionUma .formCheckboxContainer input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: .1em solid hsla(0, 0%, 50%, .7);
  border-radius: .15em;
  color: #fff;
  display: grid;
  height: 1.3em;
  margin: 0;
  place-content: center;
  transform: translateY(.15em);
  width: 1.3em
}

.demoCollectionUma .formCheckboxContainer input[type=checkbox]:before {
  box-shadow: inset 1em 1em #fff;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0, 43% 62%);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0, 43% 62%);
  content: "";
  height: .65em;
  transform: scale(0);
  transform-origin: bottom left;
  width: .65em
}

.demoCollectionUma .formCheckboxContainer input[type=checkbox]:checked:before {
  transform: scale(1)
}

.demoCollectionUma .formCheckboxContainer .formCheckboxLabel {
  color: hsla(0, 0%, 100%, .7);
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  padding-left: 1em
}

.demoCollectionUma .formCheckboxContainer .formCheckboxLabel a {
  color: hsla(0, 0%, 100%, .7)
}

.demoCollectionUma .formDisclaimer {
  background: #141414;
  bottom: 0;
  color: #8c8c8c;
  font-size: 16px;
  font-weight: 400;
  padding: 24px 0;
  position: absolute;
  right: 40px;
  text-align: right;
  width: 100%
}

.demoCollectionUma .formDisclaimer a {
  color: #8c8c8c
}

.uma-demographicSimplicity {
  height: 100vh;
  width: 100vw
}

.uma-demographicSimplicity .nf-modal-content {
  border: 0;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  width: 100vw
}

.uma-demographicSimplicity .nf-modal-content .nf-modal-content-wrapper {
  background-color: #141414;
  height: 100vh;
  max-height: 100vh;
  width: 100vw
}

.uma-demographicSimplicity .nf-modal-content .nf-modal-content-wrapper .nf-modal-body {
  padding: 0;
  width: 100vw
}

.mobile-only-container {
  margin: 48px 0;
  text-align: center
}

.mobile-only-container .uma-modal-title {
  font-size: 2.2rem
}

.mobile-only-container b {
  color: #fff
}

.mobile-only-confirm-container {
  display: flex;
  justify-content: center;
  margin: 24px 0
}

.mobile-only-confirm-container>.cancel-button {
  margin-right: 12px
}

.uma-modal {
  color: #fff;
  z-index: 30
}

.uma-modal .nf-modal-content-wrapper .nf-modal-hr {
  display: none
}

.uma-modal .nf-modal-content-wrapper .nf-modal-body {
  padding: 0 10%
}

.uma-modal .nf-modal-content-wrapper {
  background-color: #1d1b1c;
  text-align: center
}

@media screen and (max-width: 499px) {
  .uma-modal .nf-modal-content-wrapper {
    max-height: 100vh
  }
}

.uma-modal-title {
  font-size: 2.6rem;
  font-weight: 500;
  margin: 0;
  padding-bottom: 1.5em
}

.uma-modal-body {
  padding: 0 1.5em 1.5em
}

.uma-modal-cta-row-1 {
  padding-bottom: 1.5em
}

.uma-modal-cta-row-1.btn-bar-center {
  padding-bottom: 0
}

.uma-modal-cta-row-1.btn-bar-center .uma-cta-btn {
  margin-bottom: 1.5em
}

.uma-modal-cta-row-2 {
  font-size: .8rem;
  padding-bottom: 1.5em
}

.uma-modal-footer {
  color: #ccc;
  font-size: .8rem;
  padding: 1.5em 0
}

.uma-copy-link {
  color: inherit;
  text-decoration: underline
}

.message .btn-bar .uma-cta-btn.akira-button {
  margin-bottom: 0
}

.uma-banner-body {
  font-size: 14px;
  padding-bottom: 1.5em
}

.two-section-uma {
  background: rgba(0, 0, 0, .85);
  overflow: auto
}

@supports ((-webkit-backdrop-filter: blur(8px)) or (backdrop-filter:blur(8px))) {
  .two-section-uma {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, .75)
  }
}

.two-section-uma.uma-modal .nf-modal-content-wrapper {
  background-color: transparent;
  height: 100vh;
  max-height: 100vh
}

.two-section-uma.uma-modal .nf-modal-content-wrapper .nf-modal-body {
  padding: .3rem
}

.two-section-uma .nf-modal-dismiss {
  display: none
}

.uma-cta-btn.two-section-uma-btn {
  background: hsla(240, 0%, 43%, .7);
  border: none;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .25);
  color: #fff;
  font-size: 2.03rem;
  line-height: 3.04rem;
  margin: 0 0 2.32rem;
  padding: 1.11rem 2.21rem;
  position: relative;
  width: 60%
}

@media screen and (min-width: 540px) {
  .uma-cta-btn.two-section-uma-btn {
    font-size: 1.66rem;
    line-height: 2.12rem;
    margin: 0 .735rem;
    padding: 1.11rem 2.58rem;
    width: auto
  }
}

.uma-cta-btn.two-section-uma-btn:active {
  background: hsla(240, 0%, 43%, .7)
}

.uma-cta-btn.two-section-uma-btn:active:before {
  border: 2px solid #fff;
  border-radius: 8px;
  box-sizing: content-box;
  content: "";
  display: block;
  height: 100%;
  left: -4px;
  padding: 2px;
  position: absolute;
  top: -4px;
  width: 100%
}

.uma-cta-btn.two-section-uma-btn:focus, .uma-cta-btn.two-section-uma-btn:hover {
  background: hsla(240, 0%, 43%, .4)
}

.offer-context .uma-cta-btn.two-section-uma-btn {
  background-color: #fff;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-size: 1.66rem;
  font-weight: 500;
  margin: 0 2px;
  text-decoration: none;
  width: 100%
}

.offer-context .uma-cta-btn.two-section-uma-btn:hover {
  background: hsla(0, 0%, 100%, .7);
  color: #000
}

.offer-context .uma-cta-btn.two-section-uma-btn:active {
  background-color: #fff;
  color: #000
}

.offer-context .uma-cta-btn.two-section-uma-btn .waitIndicator {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0
}

.offer-context .uma-cta-btn.two-section-uma-btn .waitIndicator .basic-spinner {
  left: 50%;
  position: absolute;
  top: 50%
}

.offer-context .uma-cta-btn.two-section-uma-btn.loading .content {
  opacity: 0
}

.offer-context .uma-cta-btn.two-section-uma-btn.loading .waitIndicator {
  opacity: 1
}

.offer-context .uma-cta-btn.cta-link-btn {
  background: transparent;
  border: 2px solid transparent;
  box-sizing: border-box;
  color: hsla(0, 0%, 100%, .7);
  display: inline-block;
  font-size: 2.03rem;
  min-height: auto;
  min-width: auto;
  padding: .25em .5em;
  width: auto
}

@media screen and (min-width: 540px) {
  .offer-context .uma-cta-btn.cta-link-btn {
    font-size: 1.66rem
  }
}

.offer-context .uma-cta-btn.cta-link-btn:focus, .offer-context .uma-cta-btn.cta-link-btn:hover {
  text-decoration: underline
}

.offer-context .uma-cta-btn.cta-link-btn:active {
  border: 2px solid #fff;
  border-radius: 4px;
  text-decoration: none
}

.collections-uma .nf-modal-content-wrapper {
  background-color: transparent;
  max-height: none
}

.collections-uma .nf-modal-content {
  border-top: none;
  height: auto;
  max-height: none;
  max-width: none;
  width: 90%
}

.collections-uma .nf-modal-dismiss {
  display: none
}

.collections-uma .modal-body-background {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain
}

.collections-uma .modal-body-vignette {
  align-items: center;
  display: flex;
  min-height: 50vw
}

.collections-uma .modal-body-vignette.show-gradient {
  background: linear-gradient(0deg, #000 0, #000 35%, transparent)
}

.collections-uma .title-list {
  bottom: 5%;
  display: flex;
  list-style-type: none;
  margin: 0 5%;
  max-width: 1150px;
  padding: 0;
  position: absolute;
  width: 80vw
}

.collections-uma .title-list li {
  flex-grow: 1
}

.collections-uma .title-list img {
  margin: 0 1vw;
  max-width: 150px;
  width: 10vw
}

.collections-uma .modal-body-copy {
  padding: 15% 10% 12%;
  width: 100%
}

.collections-uma .nf-modal-content-wrapper .nf-modal-body {
  padding: 0
}

.collections-uma .uma-modal-title {
  font-size: 3.5vw
}

.collections-uma .uma-modal-body {
  font-size: 1.2vw;
  padding: 0 15vw 1.5rem
}

.collections-uma .uma-modal-headline {
  font-size: .8vw;
  letter-spacing: 5px;
  margin-bottom: 1.2vw;
  opacity: .75
}

.collections-uma .btn.btn-small {
  font-size: 1.2vw;
  line-height: 1vw;
  min-height: 2.7vw
}

.collections-uma .btn-white {
  background-color: #e6e6e6;
  background-image: linear-gradient(180deg, #e6e6e6, #ddd);
  border: 1px solid transparent;
  box-shadow: 0 1px 0 rgba(1, 1, 0, .2);
  color: #000
}

.collections-uma .btn-white.hover, .collections-uma .btn-white:hover {
  background: #000;
  border: 1px solid #fff;
  box-shadow: none;
  color: #fff
}

.collections-uma .btn-white.active, .collections-uma .btn-white:active {
  background: #000;
  border: 1px solid hsla(0, 0%, 100%, .55);
  color: #fff
}

.collections-uma .btn-white.disabled {
  background: none;
  border: 1px solid hsla(0, 0%, 100%, .75);
  box-shadow: none
}

@media screen and (min-aspect-ratio: 16/9) {
  .collections-uma .nf-modal-content {
    width: 125vh
  }

  .collections-uma .uma-modal-title {
    font-size: 6.2vh
  }

  .collections-uma .uma-modal-body {
    font-size: 2.2vh;
    padding: 0 13.5vh 1.5rem
  }

  .collections-uma .uma-modal-headline {
    font-size: 1.5vh;
    margin-bottom: 2.2vh
  }

  .collections-uma .modal-body-vignette {
    min-height: 70vh
  }

  .collections-uma .btn-bar {
    padding: .2vh
  }

  .collections-uma .btn.btn-small {
    font-size: 2.2vh;
    line-height: 2vh;
    min-height: 2.7vh
  }
}

.pin-input-invalid {
  animation: shakeit .3s 1
}

.uma-modal-input {
  margin-top: 10px
}

.uma-modal-input .pin-input-container {
  margin-bottom: 24px
}

.uma-modal-input .pin-input-container input {
  background: hsla(0, 0%, 100%, .1);
  border: 0;
  border-bottom: 1px solid hsla(0, 0%, 100%, .9);
  border-radius: 4px 4px 0 0;
  font-size: 32px;
  height: 60px;
  line-height: 37px;
  margin-right: 4px;
  text-align: center;
  width: 48px
}

.uma-modal-input .nudge-verify-btn {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
  width: 308px
}

.uma-modal-input .otp-error-btn {
  padding: 12px 28px !important;
  width: auto
}

.uma-promo-message {
  border-bottom: none;
  min-height: 40px;
  padding: .25em 0
}

.uma-promo-message__section {
  height: 40px;
  padding-top: 4px
}

.uma-promo-message__section, .uma-promo-message__section .btn-bar {
  display: flex;
  flex-direction: row
}

.uma-promo-message__section .btn-promotion {
  background: #fff;
  border-radius: 2px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  min-height: 28px;
  text-align: center;
  width: 151px
}

.uma-promo-message__section .btn-promotion.hover, .uma-promo-message__section .btn-promotion:hover {
  color: #fff
}

.uma-promo-message__section .btn-gray {
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  min-height: 28px;
  text-align: center;
  width: 151px
}

.uma-promo-banner__main {
  font-size: 13px;
  font-weight: 500;
  height: 16px;
  letter-spacing: 0;
  padding: 1em;
  width: auto
}

.uma-promo-banner__main>div {
  display: flex;
  flex-direction: row;
  position: relative
}

.uma-promo-banner__main svg {
  height: 30px;
  left: -35px;
  position: absolute;
  top: -8px;
  width: 30px
}

.uma-promo-banner__icon {
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  bottom: 8px;
  height: 33px;
  position: relative;
  right: 5px;
  width: 33px
}

.uma-promo-banner__icon.magenta {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/banner/S_Magenta.png)
}

.uma-promo-banner__icon.azure {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/banner/S_Azure.png)
}

.uma-promo-banner__icon.lime {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/banner/S_Lime.png)
}

.uma-promo-banner__icon.violet {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/banner/S_Violet.png)
}

.uma-promo-banner__icon.checkmark {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/banner/S_Checkmark_White.png);
  background-size: 70%
}

.uma-promo-banner__title {
  width: max-content;
  width: intrinsic;
  width: -moz-max-content;
  width: -webkit-max-content
}

.promo-uma-modal.white .nf-modal-content {
  background: #fff;
  border-radius: .5em;
  color: #000;
  min-width: 350px;
  width: 30%
}

.promo-uma-modal.white .nf-modal-content .nf-modal-content-wrapper {
  padding: .85em
}

.promo-uma-modal.white .nf-modal-content .spinner-takeover {
  background: hsla(0, 0%, 100%, .65);
  border-radius: .3em;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.promo-uma-modal.white .nf-modal-content .spinner-takeover .basic-spinner {
  left: 50%;
  position: absolute;
  top: 50%
}

.promo-uma-modal.white .nf-modal-content .nf-modal-back-btn, .promo-uma-modal.white .nf-modal-content .nf-modal-dismiss .nf-modal-dismiss-btn {
  background: none;
  border: none;
  color: rgba(0, 0, 0, .45);
  font-size: .85em;
  line-height: 1.5;
  outline: none;
  padding: .5em .5em 0
}

.promo-uma-modal.white .nf-modal-content .nf-modal-back-btn.focus-visible, .promo-uma-modal.white .nf-modal-content .nf-modal-dismiss .nf-modal-dismiss-btn.focus-visible {
  outline: auto
}

.promo-uma-modal.white .nf-modal-content .nf-modal-back-btn:focus, .promo-uma-modal.white .nf-modal-content .nf-modal-back-btn:hover, .promo-uma-modal.white .nf-modal-content .nf-modal-dismiss .nf-modal-dismiss-btn:focus, .promo-uma-modal.white .nf-modal-content .nf-modal-dismiss .nf-modal-dismiss-btn:hover {
  color: #000
}

.promo-uma-modal.white .nf-modal-content .phone-number-input {
  margin: 0 auto
}

.promo-uma-modal.white .nf-modal-content .phone-number-input a {
  text-decoration: none
}

.promo-uma-modal.white .nf-modal-content .phone-number-input .ui-select-wrapper-link .ui-select-current {
  background: transparent
}

.promo-uma-modal.white .nf-modal-content .phone-number-input .ui-select-wrapper {
  border-bottom-left-radius: .3em;
  border-top-left-radius: .3em
}

.promo-uma-modal.white .nf-modal-content .phone-number-input .ui-text-input {
  border-bottom-right-radius: .3em;
  border-top-right-radius: .3em
}

.promo-uma-modal.white .nf-modal-content .phone-number-input .flag-select-item-list {
  max-height: 125px;
  max-width: 320px
}

.promo-uma-modal.white .nf-modal-content .pin-input-container {
  border: none;
  margin: 0 0 40px;
  white-space: nowrap
}

.promo-uma-modal.white .nf-modal-content .pin-input-container .pin-number-input {
  background: none;
  border: none;
  border-bottom: 4px solid #b3b3b3;
  color: #4d4d4d;
  font-size: 42px;
  margin: 0 .15em;
  padding: 0;
  text-align: center;
  width: 1.5em;
  width: 1.5ch
}

.promo-uma-modal.white .nf-modal-content .pin-input-container .pin-number-input:focus {
  border-bottom-color: -webkit-focus-ring-color
}

@media only screen and (max-width: 500px) {
  .promo-uma-modal.white .nf-modal-content .pin-input-container .pin-number-input {
    font-size: 30px;
    margin: 0 7px
  }
}

.promo-uma-modal.white .nf-modal-content .error-message {
  color: #b00500;
  font-size: 12px;
  margin-bottom: 1em
}

.promo-uma-modal.white .form-collect-phone-number, .promo-uma-modal.white .form-verify-phone-number {
  padding-bottom: 1em
}

.promo-uma-modal.white .form-collect-phone-number .input-message, .promo-uma-modal.white .form-verify-phone-number .input-message {
  align-items: center;
  display: flex
}

.promo-uma-modal.white .form-collect-phone-number .btn-bar, .promo-uma-modal.white .form-verify-phone-number .btn-bar {
  padding: 0
}

.promo-uma-modal.white .form-collect-phone-number .btn-bar-flex>.uma-cta-btn, .promo-uma-modal.white .form-verify-phone-number .btn-bar-flex>.uma-cta-btn {
  margin: 0;
  position: relative;
  width: auto
}

.promo-uma-modal.white .form-collect-phone-number .btn-bar-flex>.uma-cta-btn:after, .promo-uma-modal.white .form-verify-phone-number .btn-bar-flex>.uma-cta-btn:after {
  border-left: 1px solid #979797;
  content: " ";
  height: 1em;
  left: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%)
}

.promo-uma-modal.white .form-collect-phone-number .btn-bar-flex>.uma-cta-btn:last-child:after, .promo-uma-modal.white .form-verify-phone-number .btn-bar-flex>.uma-cta-btn:last-child:after {
  display: none
}

.promo-uma-modal.white .confirmation-phone-number .icon-complete {
  color: #41b957;
  height: 5em;
  margin: 0 auto;
  width: 5em
}

.promo-uma-modal.white .uma-modal-headline {
  font-size: 1.15em;
  line-height: 1.35;
  margin-bottom: 1em;
  margin-top: 1.5em
}

.promo-uma-modal.white .uma-modal-input-error {
  color: #b00500
}

.promo-uma-modal.white .uma-copy-link {
  color: inherit
}

.promo-uma-modal.white .uma-cta-btn:not(:hover):not(:focus) {
  box-shadow: none
}

.promo-uma-modal.white .btn-gray {
  color: #444
}

.promo-uma-modal.white .btn-gray:hover {
  color: #000
}

.promo-uma-modal.white .form-collect-phone-number {
  padding: 0 3%
}

.promo-uma-modal.white .form-collect-phone-number .uma-cta-btn {
  margin: .25em .5em
}

.promo-uma-modal.magenta .nf-modal-content {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/modal/Magenta_modal.png);
  border-radius: .3em
}

.promo-uma-modal.magenta .nf-modal-background {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/modal/Magenta_tex.png)
}

.promo-uma-modal.azure .nf-modal-content {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/modal/Azure_Modal.png);
  border-radius: .3em
}

.promo-uma-modal.azure .nf-modal-background {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/modal/Azure_Tex.png)
}

.promo-uma-modal.lime .nf-modal-content {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/modal/L_Modal.png);
  border-radius: .3em
}

.promo-uma-modal.lime .nf-modal-background {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/modal/L_Tex.png)
}

.promo-uma-modal.violet .nf-modal-content {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/modal/Violet_Modal.png);
  border-radius: .3em
}

.promo-uma-modal.violet .nf-modal-background {
  background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/referral/promo/modal/Violet_Tex.png)
}

.promo-uma-modal.azure .akira-button-red, .promo-uma-modal.azure .akira-button-red.hover, .promo-uma-modal.azure .akira-button-red:hover, .promo-uma-modal.lime .akira-button-red, .promo-uma-modal.lime .akira-button-red.hover, .promo-uma-modal.lime .akira-button-red:hover, .promo-uma-modal.magenta .akira-button-red, .promo-uma-modal.magenta .akira-button-red.hover, .promo-uma-modal.magenta .akira-button-red:hover, .promo-uma-modal.violet .akira-button-red, .promo-uma-modal.violet .akira-button-red.hover, .promo-uma-modal.violet .akira-button-red:hover {
  background-color: #fff;
  color: #1a1a1a
}

.promo-uma-modal .nf-modal-background {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 45% auto;
  opacity: 90%
}

.promo-uma-modal .nf-modal-back {
  left: .85em;
  position: absolute;
  top: .85em
}

.promo-uma-modal .nf-modal-back .icon-thin-caret-left {
  font-weight: 500
}

.promo-uma-modal .nf-modal-content {
  background-repeat: no-repeat;
  background-size: cover;
  border-top: none;
  min-width: 300px;
  width: 25%
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper {
  background: transparent
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .uma-modal-icon {
  margin-bottom: 1em
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .uma-modal-icon.-icon-chat, .promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .uma-modal-icon.-icon-shield {
  height: 66px
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .uma-modal-icon.-icon-alert-triangle {
  color: #ffa53d;
  height: 66px
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-body {
  padding: 0 5%
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-body .uma-modal-title {
  font-size: 3.25rem
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-body .uma-modal-subtitle {
  font-weight: 500;
  padding-bottom: 1.5rem
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-body .uma-modal-cta-row-2 {
  margin-top: -10px;
  padding-top: 0
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-body .nfTextField {
  box-sizing: border-box;
  font-size: 15px;
  min-height: 35px;
  width: 80%
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-body .btn-gray {
  background: transparent;
  border: none;
  box-shadow: none
}

.promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-body .btn-gray.hover, .promo-uma-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-body .btn-gray:hover {
  background: transparent
}

.promo-uma-modal .nf-modal-content .uma-cta-btn {
  border-radius: 2px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  min-height: 28px;
  text-align: center;
  width: 80%
}

.phone-number-entry {
  background-color: #e5e5e5;
  padding: 1em 1em 0
}

.phone-number-input {
  align-items: flex-start;
  display: inline-block;
  display: flex;
  flex-direction: row
}

@media screen and (min-width: 500px) {
  .phone-number-input {
    max-width: 320px
  }
}

.phone-number-input__text {
  display: inline-block;
  flex: 1;
  margin-left: -1px
}

.ui-select-wrapper.country-select .ui-select-current {
  height: 42px;
  padding: 13px 24px 13px 10px
}

.ui-label, .ui-label-no-margin {
  color: grey;
  display: block;
  font-size: 1em;
  font-weight: 400
}

@media only screen and (max-width: 740px) {
  .ui-label, .ui-label-no-margin {
    font-size: .875em
  }
}

.ui-label-no-margin.inline, .ui-label.inline {
  display: inline-block
}

.ui-label-no-margin.inline .ui-text-input, .ui-label.inline .ui-text-input {
  margin-bottom: 0
}

.ui-label-no-margin.nmHomeTextInput .ui-label-text, .ui-label.nmHomeTextInput .ui-label-text {
  margin-right: 1ex
}

.ui-label-no-margin.nmHomeTextInput .input-password-input, .ui-label-no-margin.nmHomeTextInput .ui-text-input, .ui-label.nmHomeTextInput .input-password-input, .ui-label.nmHomeTextInput .ui-text-input {
  background-color: rgba(0, 0, 0, .8);
  border-color: #4d4d4d;
  color: #fff;
  font-size: 24px;
  height: 60px
}

.ui-label {
  margin-bottom: 6px
}

.icon-error {
  color: #b00500
}

.icon-valid {
  color: #5fa53f
}

.ui-input-wrapper {
  display: inline-block;
  margin: 0;
  padding: 0
}

@media only screen and (max-width: 500px) {
  .ui-input-wrapper {
    display: block
  }
}

.ui-input-label {
  padding-bottom: 32px;
  position: relative
}

.ui-readonly-value {
  color: #333;
  margin-bottom: 20px;
  padding: 10px 0
}

.input-password-input, .ui-text-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-size: 16px;
  height: 44px;
  max-width: 500px;
  padding: 10px 11px;
  width: 100%
}

.input-password-input.large, .ui-text-input.large {
  padding: 14px 11px
}

@media only screen and (max-width: 740px) {
  .input-password-input.large, .ui-text-input.large {
    font-size: 1em;
    padding: 11px
  }
}

.input-password-input.small, .ui-text-input.small {
  font-size: 14px;
  padding: 5px 8px
}

@media only screen and (max-width: 740px) {
  .input-password-input.small, .ui-text-input.small {
    font-size: 1em;
    padding: 8px 11px
  }
}

.input-password-input:focus, .ui-text-input:focus {
  border-color: grey;
  outline: none
}

.error>.input-password-input, .error>.ui-text-input, .input-password-input.error, .input-password-inputerror:focus, .ui-text-input.error, .ui-text-inputerror:focus {
  border-color: #b00500
}

.input-password-input.valid, .ui-text-input.valid, .valid>.input-password-input, .valid>.ui-text-input {
  border-color: #5fa53f
}

.input-password-input.inline, .ui-text-input.inline {
  display: inline-block;
  margin: 0 12px 0 0;
  max-width: 100%;
  vertical-align: middle;
  width: auto
}

.input-password-input.inline.medium, .ui-text-input.inline.medium {
  margin-right: 8px
}

.input-password-input.inline.small, .ui-text-input.inline.small {
  margin-right: 4px
}

.ui-input-label .ui-text-input+.icon-lock {
  bottom: 20px;
  color: #9e9e9e;
  font-size: 1.2em;
  position: absolute;
  right: 10px
}

.floating-label.ui-input-label {
  padding-bottom: 0
}

.floating-label .ui-label-text {
  left: 11px;
  position: absolute;
  top: 18px
}

.floating-label.active .ui-label-text {
  font-size: .8em;
  top: 3px
}

.floating-label .ui-text-input.error:focus {
  border-color: #b00500
}

.floating-label .ui-text-input.valid:focus {
  border-color: #5fa53f
}

.floating-label .ui-text-input:focus {
  border-color: #0f84fa
}

.floating-label .ui-text-input.medium {
  padding: 15px 11px 5px
}

.input-message {
  bottom: 0;
  color: #999;
  font-size: 12px;
  height: 32px;
  left: 0;
  position: absolute
}

.input-message.error {
  color: #b00500
}

.input-message a {
  color: inherit;
  text-decoration: underline
}

.dark .input-message.error {
  color: #fff
}

.dark .input-message.warning {
  color: #f7f7f7
}

.ui-select-wrapper {
  border: 1px solid #b3b3b3;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 100%
}

.ui-select-wrapper:after {
  color: #000;
  content: "\e898";
  font-family: nf-icon;
  font-size: 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 17px
}

.ui-select-wrapper.ui-select-wrapper-open:after {
  transform: rotate(180deg)
}

@media only screen AND (min-width: 740px) {
  .ui-select-wrapper {
    max-width: 600px
  }
}

.ui-select-wrapper .native-select {
  bottom: 0;
  height: 100%;
  opacity: 0;
  position: absolute
}

.ui-select-wrapper-link {
  color: #333;
  display: block;
  margin: 0;
  padding: 0;
  text-decoration: none
}

.ui-select-wrapper-link:hover {
  text-decoration: none
}

.ui-select-current {
  background: #fff;
  height: 100%;
  margin: 0;
  padding: 10px 20px 10px 10px;
  position: relative;
  width: 100%
}

.ui-select-current:after {
  content: "";
  visibility: hidden
}

.ui-select-options-hidden {
  display: none
}

.ui-select-item-highlighted {
  background-color: #f3f3f3
}

.ui-select-item-selected {
  font-weight: 500
}

.ui-select-item {
  list-style: none;
  margin: 0;
  padding: 10px
}

.ui-select-item-link {
  align-items: center;
  color: #333;
  display: flex;
  text-decoration: none
}

.ui-select-item-link:hover {
  text-decoration: none
}

.ui-select-item:hover {
  background-color: #f3f3f3
}

.ui-select-options {
  background: #fff;
  border: 1px solid #ccc;
  left: -1px;
  margin: -1px 0 0;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: 2
}

.ui-select-wrapper {
  display: inline-block;
  position: relative
}

.ui-select-wrapper>.ui-label {
  margin-bottom: 0
}

.ui-select-wrapper .select-arrow {
  display: inline-block;
  position: relative;
  width: 100%
}

.ui-select-wrapper .select-arrow:after {
  color: #000;
  content: "\e898";
  font-family: nf-icon;
  font-size: 6px;
  pointer-events: none;
  position: absolute
}

.ui-select-wrapper .select-arrow.medium:after {
  right: 10px;
  top: 18px
}

.ui-select-wrapper .select-arrow.medium:before {
  left: 15px;
  top: 11px
}

.ui-select-wrapper .select-arrow.prefix.globe:before {
  content: "\e896"
}

.ui-select-wrapper .select-arrow.prefix:before {
  color: #000;
  font-family: nf-icon;
  font-size: 16px;
  pointer-events: none;
  position: absolute
}

.ui-select-wrapper .select-arrow.prefix .ui-select.medium {
  line-height: 1.7;
  padding-left: 50px;
  text-indent: 2px
}

.ui-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  font-size: 16px;
  width: 100%
}

.ui-select::-ms-expand {
  display: none
}

.ui-select.error {
  border-color: #b00500
}

.ui-select.valid {
  border-color: #5fa53f
}

.ui-select.medium {
  padding: 12px
}

.nf-flag {
  background-image: url(https://assets.nflxext.com/ffe/siteui/common/icons/flags/flag-sprite-v5.png);
  background-repeat: no-repeat;
  display: block
}

.nf-flag.nf-flag-ad {
  background-position: -5px -5px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ae {
  background-position: -5px -26px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-af {
  background-position: -5px -47px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ag {
  background-position: -5px -68px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ai {
  background-position: -5px -89px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-al {
  background-position: -5px -110px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-am {
  background-position: -5px -131px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-an {
  background-position: -5px -152px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ao {
  background-position: -5px -173px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ar {
  background-position: -5px -194px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-as {
  background-position: -5px -215px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-at {
  background-position: -5px -236px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-au {
  background-position: -5px -257px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-aw {
  background-position: -5px -278px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ax {
  background-position: -5px -299px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-az {
  background-position: -5px -320px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ba {
  background-position: -5px -341px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bb {
  background-position: -5px -362px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bd {
  background-position: -5px -383px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-be {
  background-position: -5px -404px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bf {
  background-position: -5px -425px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bg {
  background-position: -5px -446px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bh {
  background-position: -5px -467px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bi {
  background-position: -5px -488px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bj {
  background-position: -5px -509px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bl {
  background-position: -5px -530px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bm {
  background-position: -5px -551px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bn {
  background-position: -5px -572px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bo {
  background-position: -5px -593px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bq {
  background-position: -5px -614px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-br {
  background-position: -5px -635px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bs {
  background-position: -5px -656px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bt {
  background-position: -5px -677px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bv {
  background-position: -5px -698px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bw {
  background-position: -5px -719px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-by {
  background-position: -5px -740px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-bz {
  background-position: -5px -761px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ca {
  background-position: -5px -782px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-catalonia {
  background-position: -5px -803px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cc {
  background-position: -5px -824px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cd {
  background-position: -5px -845px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cf {
  background-position: -5px -866px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cg {
  background-position: -5px -887px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ch {
  background-position: -5px -908px;
  height: 11px;
  width: 11px
}

.nf-flag.nf-flag-ci {
  background-position: -5px -929px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ck {
  background-position: -5px -950px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cl {
  background-position: -5px -971px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cm {
  background-position: -5px -992px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cn {
  background-position: -5px -1013px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-co {
  background-position: -5px -1034px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cr {
  background-position: -5px -1055px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cs {
  background-position: -5px -1076px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cu {
  background-position: -5px -1097px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cv {
  background-position: -5px -1118px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cw {
  background-position: -5px -1139px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cx {
  background-position: -5px -1160px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cy {
  background-position: -5px -1181px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-cz {
  background-position: -5px -1202px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-de {
  background-position: -5px -1223px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-dj {
  background-position: -5px -1244px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-dk {
  background-position: -5px -1265px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-dm {
  background-position: -5px -1286px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-do {
  background-position: -5px -1307px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-dz {
  background-position: -5px -1328px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ec {
  background-position: -5px -1349px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ee {
  background-position: -5px -1370px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-eg {
  background-position: -5px -1391px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-eh {
  background-position: -5px -1412px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-england {
  background-position: -5px -1433px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-er {
  background-position: -5px -1454px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-es {
  background-position: -5px -1475px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-et {
  background-position: -5px -1496px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-europeanunion {
  background-position: -5px -1517px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-fam {
  background-position: -5px -1538px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-fi {
  background-position: -5px -1559px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-fj {
  background-position: -5px -1580px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-fk {
  background-position: -5px -1601px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-fm {
  background-position: -5px -1622px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-fo {
  background-position: -5px -1643px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-fr {
  background-position: -5px -1664px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ga {
  background-position: -5px -1685px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gb {
  background-position: -5px -1706px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gd {
  background-position: -5px -1727px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ge {
  background-position: -5px -1748px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gf {
  background-position: -5px -1769px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gg {
  background-position: -5px -1790px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gh {
  background-position: -5px -1811px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gi {
  background-position: -5px -1832px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gl {
  background-position: -5px -1853px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gm {
  background-position: -5px -1874px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gn {
  background-position: -5px -1895px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gp {
  background-position: -5px -1916px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gq {
  background-position: -5px -1937px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gr {
  background-position: -5px -1958px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gs {
  background-position: -5px -1979px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gt {
  background-position: -5px -2000px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gu {
  background-position: -5px -2021px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gw {
  background-position: -5px -2042px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-gy {
  background-position: -5px -2063px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-hk {
  background-position: -5px -2084px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-hm {
  background-position: -5px -2105px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-hn {
  background-position: -5px -2126px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-hr {
  background-position: -5px -2147px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ht {
  background-position: -5px -2168px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-hu {
  background-position: -5px -2189px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-id {
  background-position: -5px -2210px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ie {
  background-position: -5px -2231px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-il {
  background-position: -5px -2252px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-im {
  background-position: -5px -2273px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-in {
  background-position: -5px -2294px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-io {
  background-position: -5px -2315px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-iq {
  background-position: -5px -2336px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ir {
  background-position: -5px -2357px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-is {
  background-position: -5px -2378px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-it {
  background-position: -5px -2399px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-je {
  background-position: -5px -2420px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-jm {
  background-position: -5px -2441px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-jo {
  background-position: -5px -2462px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-jp {
  background-position: -5px -2483px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ke {
  background-position: -5px -2504px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-kg {
  background-position: -5px -2525px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-kh {
  background-position: -5px -2546px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ki {
  background-position: -5px -2567px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-km {
  background-position: -5px -2588px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-kn {
  background-position: -5px -2609px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-kp {
  background-position: -5px -2630px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-kr {
  background-position: -5px -2651px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-kw {
  background-position: -5px -2672px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ky {
  background-position: -5px -2693px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-kz {
  background-position: -5px -2714px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-la {
  background-position: -5px -2735px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-lb {
  background-position: -5px -2756px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-lc {
  background-position: -5px -2777px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-li {
  background-position: -5px -2798px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-lk {
  background-position: -5px -2819px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-lr {
  background-position: -5px -2840px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ls {
  background-position: -5px -2861px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-lt {
  background-position: -5px -2882px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-lu {
  background-position: -5px -2903px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-lv {
  background-position: -5px -2924px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ly {
  background-position: -5px -2945px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ma {
  background-position: -5px -2966px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mc {
  background-position: -5px -2987px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-md {
  background-position: -5px -3008px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-me {
  background-position: -5px -3029px;
  height: 12px;
  width: 16px
}

.nf-flag.nf-flag-mf {
  background-position: -5px -3051px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mg {
  background-position: -5px -3072px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mh {
  background-position: -5px -3093px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mk {
  background-position: -5px -3114px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ml {
  background-position: -5px -3135px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mm {
  background-position: -5px -3156px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mn {
  background-position: -5px -3177px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mo {
  background-position: -5px -3198px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mp {
  background-position: -5px -3219px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mq {
  background-position: -5px -3240px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mr {
  background-position: -5px -3261px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ms {
  background-position: -5px -3282px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mt {
  background-position: -5px -3303px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mu {
  background-position: -5px -3324px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mv {
  background-position: -5px -3345px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mw {
  background-position: -5px -3366px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mx {
  background-position: -5px -3387px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-my {
  background-position: -5px -3408px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-mz {
  background-position: -5px -3429px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-na {
  background-position: -5px -3450px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-nc {
  background-position: -5px -3471px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ne {
  background-position: -5px -3492px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-nf {
  background-position: -5px -3513px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ng {
  background-position: -5px -3534px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ni {
  background-position: -5px -3555px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-nl {
  background-position: -5px -3576px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-no {
  background-position: -5px -3597px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-np {
  background-position: -5px -3618px;
  height: 11px;
  width: 9px
}

.nf-flag.nf-flag-nr {
  background-position: -5px -3639px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-nu {
  background-position: -5px -3660px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-nz {
  background-position: -5px -3681px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-om {
  background-position: -5px -3702px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pa {
  background-position: -5px -3723px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pe {
  background-position: -5px -3744px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pf {
  background-position: -5px -3765px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pg {
  background-position: -5px -3786px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ph {
  background-position: -5px -3807px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pk {
  background-position: -5px -3828px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pl {
  background-position: -5px -3849px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pm {
  background-position: -5px -3870px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pn {
  background-position: -5px -3891px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pr {
  background-position: -5px -3912px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ps {
  background-position: -5px -3933px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pt {
  background-position: -5px -3954px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-pw {
  background-position: -5px -3975px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-py {
  background-position: -5px -3996px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-qa {
  background-position: -5px -4017px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-re {
  background-position: -5px -4038px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ro {
  background-position: -5px -4059px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-rs {
  background-position: -5px -4080px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ru {
  background-position: -5px -4101px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-rw {
  background-position: -5px -4122px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sa {
  background-position: -5px -4143px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sb {
  background-position: -5px -4164px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sc {
  background-position: -5px -4185px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-scotland {
  background-position: -5px -4206px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sd {
  background-position: -5px -4227px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-se {
  background-position: -5px -4248px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sg {
  background-position: -5px -4269px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sh {
  background-position: -5px -4290px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-si {
  background-position: -5px -4311px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sj {
  background-position: -5px -4332px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sk {
  background-position: -5px -4353px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sl {
  background-position: -5px -4374px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sm {
  background-position: -5px -4395px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sn {
  background-position: -5px -4416px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-so {
  background-position: -5px -4437px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sr {
  background-position: -5px -4458px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ss {
  background-position: -5px -4479px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-st {
  background-position: -5px -4500px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sv {
  background-position: -5px -4521px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sx {
  background-position: -5px -4542px;
  height: 11px;
  width: 15px
}

.nf-flag.nf-flag-sy {
  background-position: -5px -4563px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-sz {
  background-position: -5px -4584px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tc {
  background-position: -5px -4605px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-td {
  background-position: -5px -4626px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tf {
  background-position: -5px -4647px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tg {
  background-position: -5px -4668px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-th {
  background-position: -5px -4689px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tj {
  background-position: -5px -4710px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tk {
  background-position: -5px -4731px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tl {
  background-position: -5px -4752px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tm {
  background-position: -5px -4773px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tn {
  background-position: -5px -4794px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-to {
  background-position: -5px -4815px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tr {
  background-position: -5px -4836px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tt {
  background-position: -5px -4857px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tv {
  background-position: -5px -4878px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tw {
  background-position: -5px -4899px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-tz {
  background-position: -5px -4920px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ua {
  background-position: -5px -4941px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ug {
  background-position: -5px -4962px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-um {
  background-position: -5px -4983px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-us {
  background-position: -5px -5004px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-uy {
  background-position: -5px -5025px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-uz {
  background-position: -5px -5046px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-va {
  background-position: -5px -5067px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-vc {
  background-position: -5px -5088px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ve {
  background-position: -5px -5109px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-vg {
  background-position: -5px -5130px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-vi {
  background-position: -5px -5151px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-vn {
  background-position: -5px -5172px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-vu {
  background-position: -5px -5193px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-wales {
  background-position: -5px -5214px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-wf {
  background-position: -5px -5235px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ws {
  background-position: -5px -5256px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-ye {
  background-position: -5px -5277px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-yt {
  background-position: -5px -5298px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-za {
  background-position: -5px -5319px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-zm {
  background-position: -5px -5340px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-zw {
  background-position: -5px -5361px;
  height: 11px;
  width: 16px
}

.nf-flag.nf-flag-xk {
  background-position: -5px -5382px;
  height: 11px;
  width: 16px
}

.ui-select-wrapper.country-select {
  border: 1px solid #b3b3b3;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 95px
}

.ui-select-wrapper.country-select .ui-select-current {
  align-items: center;
  box-sizing: inherit;
  display: flex;
  height: 100%
}

.ui-select-wrapper.country-select .ui-select-current .country-name {
  display: none
}

.ui-select-wrapper-link {
  box-sizing: inherit;
  height: 100%
}

.country-name {
  box-sizing: inherit;
  float: left;
  padding-left: 26px
}

.country-code {
  box-sizing: inherit;
  color: #999;
  font-style: normal
}

.country-select-code {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  min-width: 32px;
  padding: 0 4px;
  text-align: center;
  transform: translateX(5px)
}

.flag-select-option {
  box-sizing: inherit
}

.flag-select-item-list {
  box-sizing: inherit;
  max-height: 380px;
  max-width: 100vw;
  width: 350px
}

.country-select-flag {
  box-sizing: inherit
}

.currently-selected-code {
  box-sizing: inherit;
  left: 70px;
  position: absolute;
  top: 13px;
  z-index: 2
}

.seBox {
  color: #f7f7f7;
  margin: 8.5em auto 0;
  width: 45em
}

.seHeader {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 2em
}

.seNflxButton {
  border: 1px solid #b2b2b2;
  display: inline-block;
  font-weight: 500;
  margin-top: 20px;
  min-width: 80px;
  padding: 5px 10px;
  text-align: center
}

.seHomeLink {
  color: #b2b2b2;
  text-decoration: none
}

.nfButton {
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  padding: 5px
}

.nfButton.black {
  background-color: #000;
  border: 1px solid #111;
  color: #fff
}

.sub-menu.theme-lakira {
  background-color: rgba(0, 0, 0, .9);
  border: 1px solid hsla(0, 0%, 100%, .15);
  box-sizing: border-box;
  color: #fff;
  cursor: default;
  font-size: 13px;
  line-height: 21px;
  opacity: 0;
  position: absolute;
  top: 71px;
  transition: opacity 150ms ease-in-out;
}

.sub-menu.theme-lakira.visible {
  opacity: 1;
}

.sub-menu.theme-lakira.use-scroll {
  max-height: 75vh
}

.sub-menu.theme-lakira a, .sub-menu.theme-lakira div, .sub-menu.theme-lakira li, .sub-menu.theme-lakira span, .sub-menu.theme-lakira ul {
  box-sizing: border-box
}

@media screen and (max-width: 950px) {
  .sub-menu.theme-lakira {
    top: 64px
  }
}

.sub-menu.theme-lakira.sub-menu-top {
  bottom: 71px;
  top: auto
}

@media screen and (max-width: 950px) {
  .sub-menu.theme-lakira.sub-menu-top {
    bottom: 64px;
    top: auto
  }
}

.sub-menu.theme-lakira .sub-menu-list-special {
  border-right: 1px solid #4d4d4d;
  font-weight: 500;
  margin-right: 5px
}

.sub-menu.theme-lakira>.topbar {
  background: #e5e5e5;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: -2px
}

.sub-menu.theme-lakira.sub-menu-top>.topbar {
  bottom: -2px;
  top: auto
}

.sub-menu.theme-lakira .sub-menu-list, .sub-menu.theme-lakira .sub-menu-list-special {
  cursor: default;
  height: auto;
  padding: 1.1rem
}

.sub-menu.theme-lakira .sub-menu-list-special.multi-column, .sub-menu.theme-lakira .sub-menu-list.multi-column {
  display: table-cell
}

.sub-menu-item span {
  padding: 0 5px 0 20px;
}

.sub-menu-item svg {
  color: #b3b3b3
}

.sub-menu.theme-lakira .sub-menu-item, .sub-menu.theme-lakira .sub-menu-item-special {
  cursor: default;
  display: block;
  line-height: 24px
}

.sub-menu.theme-lakira .sub-menu-link {
  color: #fff;
  display: inline-block;
  text-transform: none;
  width: 100%
}

.sub-menu.theme-lakira .sub-menu-link:hover {
  text-decoration: underline
}

.sub-menu.theme-lakira.open-left {
  bottom: 0;
  right: 1.5em;
  top: auto
}

.sub-menu.theme-lakira.open-left:before {
  background: none
}

.sub-menu.theme-lakira.open-bottom-left {
  left: -330px;
  margin-right: 100px;
  top: 0
}

.sub-menu.theme-lakira.open-bottom-left:before {
  background: none
}

.nf-icon-button {
  color: #fff;
  display: inline-block;
  position: relative
}

.nf-icon-button .nf-icon-button-icon {
  background-color: rgba(0, 0, 0, .25);
  border-radius: 50%;
  display: inline-block;
  font-size: 2vw;
  transition: transform .15s ease;
  vertical-align: middle
}

.nf-icon-button .nf-icon-button-label {
  display: inline-block;
  font-size: 1.1vw;
  font-weight: 500;
  margin-left: .6vw;
  margin-right: 1.7vw;
  vertical-align: middle;
  white-space: nowrap
}

.nf-icon-button.hovered .nf-icon-button-icon, .nf-icon-button:focus .nf-icon-button-icon, .nf-icon-button:hover .nf-icon-button-icon {
  background-color: rgba(0, 0, 0, .8);
  transform: scale(1.15)
}

.nf-icon-button.hovered .nf-icon-button-tooltip, .nf-icon-button:focus .nf-icon-button-tooltip, .nf-icon-button:hover .nf-icon-button-tooltip {
  display: block
}

.nf-icon-button .nf-icon-button-tooltip {
  background: #fff;
  color: #000;
  display: none;
  font-size: .9vw;
  font-weight: 500;
  left: 25%;
  margin-left: -4vw;
  margin-top: 6px;
  padding: 2px 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 100%;
  width: 8.5vw
}

.nf-icon-button .nf-icon-button-tooltip:before {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 3px 3px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -2px;
  margin-top: -3px;
  position: absolute;
  top: 0;
  width: 0
}

@keyframes buttonFlash {
  0% {
    background: rgba(0, 0, 0, .5)
  }

  12% {
    background: hsla(0, 0%, 100%, .7)
  }

  to {
    background: rgba(0, 0, 0, .5)
  }
}

.nf-svg-button-wrapper {
  align-items: center;
  color: #fff;
  display: flex;
  position: relative
}

.nf-svg-button-wrapper .nf-svg-button {
  align-items: center;
  display: flex;
  height: 1.2em;
  justify-content: center;
  pointer-events: auto;
  transition: transform .35s cubic-bezier(.86, 0, .07, 1);
  width: 1.2em
}

.nf-svg-button-wrapper .nf-svg-button:focus, .nf-svg-button-wrapper .nf-svg-button:hover {
  outline: none;
  transform: scale(1.13);
  transition: transform .35s cubic-bezier(.86, 0, .07, 1)
}

.nf-svg-button-wrapper .nf-svg-button svg {
  fill: #fff;
  color: #fff;
  height: 1rem;
  width: 1rem
}

.nf-svg-button-wrapper .nf-svg-button.simpleround {
  background: rgba(0, 0, 0, .5);
  border: .1em solid hsla(0, 0%, 100%, .5);
  border-radius: 50%;
  margin: .25em;
  padding: .3em
}

.nf-svg-button-wrapper .nf-svg-button.simpleround.clicked {
  animation-duration: .4s;
  animation-iteration-count: 1;
  animation-name: buttonFlash;
  animation-timing-function: linear
}

.nf-svg-button-wrapper .nf-svg-button.simpleround:focus, .nf-svg-button-wrapper .nf-svg-button.simpleround:hover {
  background: rgba(0, 0, 0, .7);
  border-color: #fff;
  outline: none
}

.nf-svg-button-wrapper .nf-svg-button-label {
  display: inline-block;
  font-size: 1.1vw;
  font-weight: 500;
  margin-left: .6vw;
  margin-right: 1.7vw;
  vertical-align: middle;
  white-space: nowrap
}

.nf-svg-button-wrapper:focus {
  outline: none
}

.nf-svg-button-wrapper.hovered .nf-svg-button-tooltip, .nf-svg-button-wrapper:hover .nf-svg-button-tooltip {
  display: block
}

.nf-svg-button-wrapper .nf-svg-button-tooltip {
  background: #fff;
  color: #000;
  display: none;
  font-size: .9vw;
  font-weight: 500;
  left: 50%;
  margin-top: 6px;
  padding: 2px 0;
  position: absolute;
  text-align: center;
  top: 100%;
  transform: translateX(-50%);
  width: 8.5vw
}

.nf-svg-button-wrapper .nf-svg-button-tooltip:before {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 3px 3px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -2px;
  margin-top: -3px;
  position: absolute;
  top: 0;
  width: 0
}

@keyframes spinAnimation {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(1turn)
  }
}

.akira-spinner {
  animation-duration: .75s;
  animation-iteration-count: infinite;
  animation-name: spinAnimation;
  animation-timing-function: linear;
  color: #009779;
  display: inline-block;
  font-size: 3vw
}

.akira-button {
  background-color: #333;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  margin: 0;
  padding: .5em 1em
}

.akira-button:active, .akira-button:hover {
  background-color: #4d4d4d
}

.akira-button.disabled, .akira-button:disabled {
  background-color: #333;
  color: #333
}

.akira-button.akira-button-red {
  background-color: #009779;
  color: #fff
}

.akira-button.akira-button-red:active, .akira-button.akira-button-red:hover {
  background-color: #ee000c
}

.akira-button.akira-button-red.disabled, .akira-button.akira-button-red:disabled {
  background-color: rgba(229, 9, 20, .3);
  color: #4d4d4d
}

.nf-button {
  border-radius: 4px;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .3);
  cursor: pointer;
  display: inline-block;
  font-family: arial, helvetica, sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 4px 7px;
  text-decoration: none
}

.nf-button:focus {
  outline: none
}

.nf-button:hover {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .3), 0 2px 1px rgba(1, 1, 1, .3);
  text-decoration: none
}

.nf-button:active {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .3);
  text-decoration: none
}

.nf-button .icon {
  display: inline-block;
  margin-right: 7px;
  vertical-align: middle
}

.nf-button-white {
  background-color: #fff
}

.nf-button-white:hover {
  background: linear-gradient(#fff, #ddd)
}

.nf-button-white:active {
  background: linear-gradient(#cbcbcb, #b3b3b3)
}

.nf-button-silver {
  background-color: #e5e5e5;
  background-image: linear-gradient(180deg, #e5e5e5, #cbcbcb);
  border: 1px solid #b3b3b3
}

.nf-button-silver:hover {
  background: linear-gradient(#fff, #e5e5e5)
}

.nf-button-silver:active {
  background: linear-gradient(#cbcbcb, #b3b3b3)
}

.nf-button-red {
  background-color: #d30b03;
  background-image: linear-gradient(180deg, #d30b03, #a50709);
  border: 1px solid #810507;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .3);
  color: #fff
}

.nf-button-red:hover {
  background: linear-gradient(#ed0b0f, #930709)
}

.nf-button-red:active {
  background: linear-gradient(#9c0b0e, #850708)
}

.nf-button-blue {
  background-color: #1771d9;
  background-image: linear-gradient(180deg, #1771d9, #1359ab);
  border: 1px solid #0f4585;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .3);
  color: #fff
}

.nf-button-blue:hover {
  background: linear-gradient(#1b7ff1, #114f99)
}

.nf-button-blue:active {
  background: linear-gradient(#114f99, #0d3b73)
}

.nf-button-dark {
  background-color: #646464;
  background-image: linear-gradient(180deg, #646464, #191919);
  border: 1px solid #323232;
  border-top-color: #505050;
  color: #fff
}

.nf-button-dark:hover {
  background-color: #4b4b4b
}

.nf-button-dark:active {
  background-color: #2f2f2f
}

.nf-button-medium {
  padding: 10px 20px
}

.btn-flat {
  background-color: rgba(0, 0, 0, .4);
  background-image: none;
  border: 1px solid hsla(0, 0%, 100%, .4);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
  color: #fff;
  font-size: 1.1vw;
  font-weight: 500;
  margin-right: .75em;
  padding: .57em 1.35em
}

.btn-flat .nf-icon-button-icon, .btn-flat .playRing {
  background-color: transparent;
  font-size: inherit;
  margin-right: .71em;
  transition: none
}

.btn-flat .icon-button-mylist-added, .btn-flat .icon-button-mylist-added-reverse, .btn-flat .icon-button-remindme-added, .btn-flat .icon-button-remindme-added-reverse {
  transform: scale(1.5)
}

.btn-flat .nf-icon-button-label {
  margin: 0;
  padding: 0;
  top: auto
}

.btn-flat:hover {
  background-color: rgba(51, 51, 51, .4)
}

.btn-flat:hover .nf-icon-button-icon {
  transform: scale(1)
}

.btn-flat:hover .nf-icon-button-icon.icon-button-mylist-added, .btn-flat:hover .nf-icon-button-icon.icon-button-mylist-added-reverse, .btn-flat:hover .nf-icon-button-icon.icon-button-remindme-added, .btn-flat:hover .nf-icon-button-icon.icon-button-remindme-added-reverse {
  transform: scale(1.5)
}

.btn-flat-primary {
  background-color: #009779;
  border-color: #00ae8b
}

.btn-flat-primary:hover {
  background-color: #00ae8b
}

.icon-button-mylist-no-ring .icon-button-mylist-add-reverse:before, .icon-button-mylist-no-ring .icon-button-mylist-add:before {
  content: "\e641"
}

.icon-button-mylist-no-ring .icon-button-mylist-added-reverse:before, .icon-button-mylist-no-ring .icon-button-mylist-added:before {
  content: "\e804"
}

.icon-button-mylist-sharp .mylist-button .nf-icon-button-icon, .icon-button-mylist-sharp .mylist-button.hovered .nf-icon-button-icon {
  background-color: transparent
}

.icon-button-mylist-sharp .icon-button-mylist-add, .icon-button-mylist-sharp .icon-button-mylist-add-reverse {
  font-size: 1.2vw
}

.icon-button-mylist-sharp .icon-button-play:before {
  content: "\e646"
}

.icon-button-mylist-sharp .icon-button-mylist-add-reverse:before, .icon-button-mylist-sharp .icon-button-mylist-add:before {
  content: "\f018"
}

.icon-button-mylist-sharp .icon-button-mylist-added-reverse:before, .icon-button-mylist-sharp .icon-button-mylist-added:before {
  content: "\e804"
}

.nf-flat-button {
  background-color: rgba(0, 0, 0, .4);
  background-image: none;
  border: 1px solid hsla(0, 0%, 100%, .4);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
  color: #fff;
  font-size: 1vw;
  font-weight: 500;
  margin-right: .75em;
  padding: .57em 1.35em
}

.nf-flat-button .nf-flat-button-icon {
  background-color: transparent;
  font-size: inherit;
  margin-right: .71em;
  vertical-align: middle
}

.nf-flat-button .nf-flat-button-icon.empty-label {
  margin-right: 0
}

.nf-flat-button .nf-flat-button-icon.svg-icon {
  border: none;
  font-size: inherit;
  height: inherit;
  margin: inherit;
  width: 1.2vw
}

.nf-flat-button.no-icon .nf-flat-button-icon {
  display: none
}

.nf-flat-button .nf-flat-button-text {
  margin: 0;
  padding: 0;
  top: auto;
  vertical-align: middle;
  white-space: nowrap
}

.nf-flat-button .nf-icon-button-tooltip {
  background: #fff;
  color: #000;
  display: none;
  font-size: .8vw;
  font-weight: 500;
  left: .5vw;
  margin-left: -3.5vw;
  margin-top: 6px;
  padding: 2px 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: -2.5vw;
  width: 10vw
}

.nf-flat-button .nf-icon-button-tooltip:hover {
  display: block
}

.nf-flat-button .nf-icon-button-tooltip:before {
  border: initial;
  content: normal
}

.nf-flat-button .nf-icon-button-tooltip:after {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 3px 3px 0;
  bottom: 0;
  content: "";
  height: 0;
  left: 50%;
  margin-bottom: -3px;
  margin-left: -2px;
  position: absolute;
  width: 0
}

.nf-flat-button:focus, .nf-flat-button:hover {
  background-color: rgba(51, 51, 51, .4);
  outline: none;
  transform: scale(1.05);
  transition: transform .35s cubic-bezier(.86, 0, .07, 1)
}

.nf-flat-button:focus .nf-flat-button-icon, .nf-flat-button:hover .nf-flat-button-icon {
  transform: scale(1)
}

.nf-flat-button:focus {
  border-color: #fff
}

.nf-flat-button .nf-flat-button-icon-close, .nf-flat-button .nf-flat-button-icon-episodes, .nf-flat-button .nf-flat-button-icon-info, .nf-flat-button .nf-flat-button-icon-mylist-add, .nf-flat-button .nf-flat-button-icon-mylist-added, .nf-flat-button .nf-flat-button-icon-play, .nf-flat-button .nf-flat-button-icon-remindme-add, .nf-flat-button .nf-flat-button-icon-remindme-added, .nf-flat-button .nf-flat-button-icon-restart {
  font-family: nf-icon
}

.nf-flat-button .nf-flat-button-icon-play:before {
  content: "\e646"
}

.nf-flat-button .nf-flat-button-icon-episodes:before {
  content: "\e678"
}

.nf-flat-button .nf-flat-button-icon-mylist-add:before {
  content: "\e641"
}

.nf-flat-button .nf-flat-button-icon-mylist-added:before {
  content: "\e804"
}

.nf-flat-button .nf-flat-button-icon-remindme-add:before {
  content: "\e663"
}

.nf-flat-button .nf-flat-button-icon-remindme-added:before {
  content: "\e804"
}

.nf-flat-button .nf-flat-button-icon-restart:before {
  content: "\e752"
}

.nf-flat-button .nf-flat-button-icon-info:before {
  content: "\e748"
}

.nf-flat-button .nf-flat-button-icon-close:before {
  content: "\e762"
}

.nf-flat-button-rounded {
  border-radius: .2vw
}

.nf-flat-button-uppercase {
  text-transform: uppercase
}

.nf-flat-button-white {
  border-width: 0;
  box-shadow: 0 .6vw 1vw -.4vw rgba(0, 0, 0, .35);
  color: #000;
  font-size: 1.1vw;
  font-weight: 500;
  height: 1.2vw;
  line-height: 1vw;
  padding: .75em 2.3em
}

.nf-flat-button-white, .nf-flat-button-white:focus, .nf-flat-button-white:hover {
  background-color: #e6e6e6
}

.nf-flat-button-black-transparent {
  background-color: rgba(51, 51, 51, .4);
  border-radius: .2vw;
  border-width: 0;
  box-shadow: none;
  color: #fff;
  font-size: 1.1vw;
  font-weight: 500;
  height: 1.2vw;
  line-height: 1vw;
  padding: .75em 2.3em
}

.nf-flat-button-black-transparent:hover {
  background-color: #e6e6e6;
  box-shadow: 0 .6vw 1vw -.4vw rgba(0, 0, 0, .35);
  color: #000
}

.nf-flat-button-black-transparent.ignore-hover:hover {
  background-color: rgba(51, 51, 51, .4);
  color: #fff
}

.nf-flat-button-primary {
  background-color: #009779;
  border-color: #00ae8b
}

.nf-flat-button-primary:hover {
  background-color: #00ae8b
}

.mylist-button:not(:disabled):focus, .playLink:not(:disabled):focus {
  outline: none;
  transform: scale(1.05);
  transition: transform .35s cubic-bezier(.86, 0, .07, 1)
}

.mylist-button:not(:disabled):focus .nf-flat-button-primary, .playLink:not(:disabled):focus .nf-flat-button-primary {
  background-color: #00ae8b
}

.playLink.isToolkit:not(:disabled):focus {
  transform: none;
  transition: none
}

.playLink.isToolkit:not(:disabled):focus>button:before {
  border: 2px solid #fff;
  border-radius: 8px;
  box-sizing: content-box;
  content: "";
  display: block;
  height: 100%;
  left: -4px;
  padding: 2px;
  position: absolute;
  top: -4px;
  width: 100%
}

.playLink.isToolkit:not(:disabled):focus.isRound>button:before {
  border-radius: 50%
}

.nf-flat-button-type-borderless {
  background: transparent;
  border: .1em solid transparent;
  box-shadow: none;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  text-transform: none
}

.nf-flat-button-type-borderless:focus {
  border-color: #fff
}

.nf-flat-button-type-focusable-static {
  border: .1em solid transparent;
  font-size: inherit
}

.nf-flat-button-type-focusable-static:focus {
  border-color: #fff
}

.netflix-sans-font-loaded {
  font-family: Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif
}

.netflix-sans-font-loaded.nkufi-font-loaded {
  font-family: NKufi, Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif
}

.netflix-sans-font-loaded.graphik-font-loaded {
  font-family: GraphikTH, Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif
}

/*@font-face {
  font-display: optional;
  font-family: Netflix Sans;
  font-weight: 100;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Th.woff2) format("woff2"),url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Th.woff) format("woff")
}

@font-face {
  font-display: optional;
  font-family: Netflix Sans;
  font-weight: 300;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Lt.woff2) format("woff2"),url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Lt.woff) format("woff")
}*/

@font-face {
  font-display: optional;
  font-family: Netflix Sans;
  font-weight: 400;
  src: url(../fonts/NetflixSans_W_Rg.woff2) format("woff2")
}

@font-face {
  font-display: optional;
  font-family: Netflix Sans;
  font-weight: 500;
  src: url(../fonts/NetflixSans_W_Md.woff2) format("woff2")
}

@font-face {
  font-display: optional;
  font-family: Netflix Sans;
  font-weight: 700;
  src: url(../fonts/NetflixSans_W_Bd.woff2) format("woff2")
}

/*@font-face {
  font-display: optional;
  font-family: Netflix Sans;
  font-weight: 900;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Blk.woff2) format("woff2"),url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Blk.woff) format("woff")
}*/

.billboard-row .billboard .billboard-motion {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.billboard-row .billboard .billboard-motion .ActionButtons {
  height: 2.4vw;
  margin: 0 1.1vw 0 0;
  position: relative;
  width: 2.4vw
}

.billboard-row .billboard .billboard-motion.dismiss-static .static-image {
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  opacity: 0
}

.billboard-row .billboard .billboard-motion .audio-btn.global-supplemental-audio-toggle .nf-svg-button {
  border-width: .1vw;
  box-sizing: border-box;
  height: 2.4vw;
  margin: 0;
  padding: 0;
  width: 2.4vw
}

.billboard-row .billboard .billboard-motion .audio-btn.global-supplemental-audio-toggle .svg-icon {
  height: 1.6vw;
  width: 1.6vw
}

.billboard-row .billboard .Billboard__replay-trailer-button.svg-icon {
  background: rgba(0, 0, 0, .25);
  border: .1vw solid hsla(0, 0%, 100%, .5);
  border-radius: 50%;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 2.4vw;
  padding: .3vw;
  pointer-events: auto;
  position: absolute;
  transition: transform .15s ease;
  width: 2.4vw
}

.billboard-row .billboard .Billboard__replay-trailer-button.svg-icon:focus, .billboard-row .billboard .Billboard__replay-trailer-button.svg-icon:hover {
  border-color: #fff;
  outline: 0;
  transform: scale(1.13)
}

.billboard.episodic-billboard .title-area {
  display: flex;
  font-size: 1.4em;
  justify-content: flex-start;
  padding-right: 1em
}

.billboard.episodic-billboard .title-area .title-text-area {
  margin-right: 0;
  margin-top: 1vw;
  max-width: 25em;
  padding: 0;
  position: relative;
  transition: opacity .5s, margin .5s;
  z-index: 2
}

.billboard.episodic-billboard .title-area .title-text-area.hide {
  margin-right: -100%;
  opacity: 0
}

.billboard.episodic-billboard .title-area .episodic-title {
  color: #fff;
  font-size: 1.6vw;
  font-weight: 500;
  margin: 0;
  padding: 0
}

.billboard.episodic-billboard .info .logo-and-text .synopsis {
  font-size: 1.35vw
}

.billboard.episodic-billboard .new-badge {
  font-size: 1em;
  line-height: 1.9em;
  margin: 0
}

.reminderBadge--wrapper {
  align-items: center;
  display: flex;
  font-size: 1.3rem;
  font-weight: 500;
  left: 4%;
  margin-bottom: 1.5vw;
  position: absolute;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, .45);
  top: 5.5vw;
  z-index: 11
}

.reminderBadge--wrapper .reminderBadge--icon {
  align-items: center;
  background: #009779;
  border-radius: 100%;
  display: inline-flex;
  height: 3vw;
  justify-content: center;
  margin-right: 1vw;
  width: 3vw
}

.reminderBadge--wrapper .reminderBadge--icon .remindme-add {
  height: 1.7vw;
  margin-top: .3vw;
  transform: translateY(.3vw);
  width: 1.7vw
}

.volatile-billboard-animations-container {
  display: block;
  position: relative;
  z-index: 1
}

.volatile-billboard-animations-container .billboard-row {
  left: 0;
  position: relative;
  right: 0;
  top: 0
}

.volatile-billboard-animations-enter {
  animation-duration: .3s, .6s;
  animation-name: billboardOpacityFadeIn, billboardScaleFadeIn;
  animation-timing-function: linear, cubic-bezier(.23, 1, .32, 1);
  z-index: 1
}

@keyframes billboardOpacityFadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes billboardScaleFadeIn {
  0% {
    transform: scale(1.15)
  }

  to {
    transform: scale(1)
  }
}

.volatile-billboard-animations-leave {
  animation-duration: .4s;
  animation-name: billboardOpacityFadeOut;
  animation-timing-function: linear;
  z-index: 2
}

@keyframes billboardOpacityFadeOut {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.volatile-billboard-animations-leave.billboard-row {
  position: absolute
}

.lolomoRow {
  z-index: 1
}

.aro-genre .billboard-row .billboard .info {
  bottom: 30%
}

@media screen and (max-width: 1100px) {
  .aro-genre .billboard-row .billboard .info {
    bottom: 25%
  }
}

.billboard-row {
  background-color: #000;
  margin-bottom: 20px;
  padding-bottom: 40%;
  position: relative;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.billboard-row .billboard {
  background-color: #000;
  height: 56.25vw;
  position: absolute;
  width: 100%;
  z-index: 0
}

.billboard-row .billboard .button-layer {
  position: relative;
  z-index: 10
}

.billboard-row .billboard .play-hit-layer {
  z-index: 9
}

.billboard-row .billboard .full-screen, .billboard-row .billboard .hero-image-wrapper {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.billboard-row .billboard .hero-image-wrapper .image-layer {
  z-index: 5
}

.billboard-row .billboard .hero-image-wrapper .vignette-layer {
  left: 0;
  position: absolute;
  right: 0;
  z-index: 8
}

.billboard-row .billboard .hero-image-wrapper .static-image {
  background-position: 50%;
  background-size: cover;
  bottom: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .4s cubic-bezier(.665, .235, .265, .8) 0s;
  width: 100%
}

.billboard-row .billboard .hero-image-wrapper .hero-vignette {
  background-color: transparent;
  background-image: linear-gradient(180deg, hsla(0, 0%, 8%, 0) 0, hsla(0, 0%, 8%, .15) 15%, hsla(0, 0%, 8%, .35) 29%, hsla(0, 0%, 8%, .58) 44%, #141414 68%, #141414);
  background-position: 0 top;
  background-repeat: repeat-x;
  background-size: 100% 100%;
  bottom: -1px;
  height: 14.7vw;
  opacity: 1;
  top: auto;
  width: 100%
}

.billboard-row .billboard .hero-image-wrapper .trailer-vignette {
  background: linear-gradient(77deg, rgba(0, 0, 0, .6), transparent 85%);
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 26.09%;
  top: 0;
  transition: opacity .5s
}

.billboard-row .billboard.video-playing .hero-image-wrapper .trailer-vignette {
  opacity: .71
}

.billboard-row .billboard .bottom-layer {
  z-index: 2
}

.billboard-row .billboard .embedded-components {
  align-items: center;
  bottom: 35%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  right: 0
}

.billboard-row .billboard .embedded-components.evidence-immersive {
  bottom: 40%
}

.billboard-row .billboard .billboard-pane, .billboard-row .billboard .fill-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.billboard-row .billboard .billboard-pane {
  z-index: 3
}

.billboard-row .billboard .billboard-play-hitzone {
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 60%
}

.billboard-row .billboard .evidence-immersive-vignette {
  background: linear-gradient(180deg, hsla(0, 0%, 9%, 0), #161616 83.95%, #161616);
  bottom: 8%;
  left: 0;
  position: absolute;
  right: 0;
  top: 60%;
  z-index: 8
}

.billboard-row .billboard .maturity-rating {
  align-items: center;
  background-color: rgba(51, 51, 51, .6);
  border: 3px #dcdcdc;
  border-left-style: solid;
  box-sizing: border-box;
  display: flex;
  font-size: 1.1vw;
  height: 2.4vw;
  padding: .5vw 3.5vw .5vw .8vw
}

.billboard-row .billboard .maturity-rating .maturity-number {
  border-width: 0;
  padding: 0
}

.billboard-row .billboard .maturity-rating:empty {
  visibility: hidden
}

.billboard-row .billboard .info {
  bottom: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 4%;
  position: absolute;
  top: 0;
  width: 36%;
  z-index: 10
}

.billboard-row .billboard .info.evidence-immersive {
  bottom: 38%
}

@media screen and (min-width: 1500px) {
  .billboard-row .billboard .info {
    left: 60px
  }
}

.billboard-row .billboard .info .logo-and-text {
  transition: transform 1.5s cubic-bezier(.165, .84, .44, 1);
  width: 100%
}

.billboard-row .billboard .info .billboard-title {
  min-height: 13.2vw;
  position: relative
}

.billboard-row .billboard .info .billboard-title .title {
  bottom: 0;
  font-size: 4vw;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 4vw;
  margin-bottom: 10px;
  position: absolute
}

.billboard-row .billboard .info .billboard-title h5 {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px !important;
  margin: 0 0 0 15px;
}

.titleWrapper h2 {
  font-size: 28px;
  margin-bottom: 14px;
}

.billboard-row .billboard .info .billboard-title .title-logo {
  transform-origin: bottom left;
  width: 100%
}

.billboard-row .billboard .info .billboard-title .title-logo.awards {
  height: 13.2vw;
  max-width: 100%;
  width: auto
}

.billboard-row .billboard .info .billboard-title.isRtl .title-logo {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 0;
  padding-top: 40%
}

.billboard-row .billboard .info .synopsis {
  color: #fff;
  font-size: 1.2vw;
  font-weight: 400;
  line-height: normal;
  margin-top: .1vw;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, .45);
  width: 100%
}

.billboard-row .billboard .info .synopsis.no-supplemental {
  margin: .5vw 0 0
}

.billboard-row .billboard .info .maturity-rating .maturity-number {
  border-width: 0;
  font-size: 1.2em;
  font-weight: 500;
  padding: 0
}

.billboard-row .billboard .info .billboard-links {
  display: flex;
  line-height: 88%;
  margin-top: 1.5vw;
  white-space: nowrap
}

.billboard-row .billboard .info .billboard-links .nf-flat-button .nf-flat-button-icon.svg-icon.svg-icon-info {
  height: 1.4vw;
  margin-bottom: 0;
  width: 1.4vw
}

.billboard-row .billboard .info .billboard-links>a {
  align-items: center;
  display: flex;
  justify-content: center
}

.billboard-row .billboard .info .billboard-links>a, .billboard-row .billboard .info .billboard-links>button {
  flex-shrink: 0
}

.billboard-row .billboard .info .supplemental-message {
  color: #fff;
  font-size: 1.6vw;
  margin: 1vw 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, .45);
  transition: color 1s cubic-bezier(.165, .84, .44, 1)
}

.billboard-row .billboard .info .supplemental-message img {
  max-width: 100%
}

.billboard-row .billboard .mylist-button, .billboard-row .billboard .remindme-button {
  background-color: rgba(0, 0, 0, .4);
  background-image: none;
  border: 1px solid hsla(0, 0%, 100%, .4);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
  color: #fff;
  font-size: 1.1vw;
  font-weight: 500;
  margin-right: .75em;
  padding: .57em 1.35em
}

.billboard-row .billboard .mylist-button .nf-icon-button-icon, .billboard-row .billboard .mylist-button .playRing, .billboard-row .billboard .remindme-button .nf-icon-button-icon, .billboard-row .billboard .remindme-button .playRing {
  background-color: transparent;
  font-size: inherit;
  margin-right: .71em;
  transition: none
}

.billboard-row .billboard .mylist-button .icon-button-mylist-added, .billboard-row .billboard .mylist-button .icon-button-mylist-added-reverse, .billboard-row .billboard .mylist-button .icon-button-remindme-added, .billboard-row .billboard .mylist-button .icon-button-remindme-added-reverse, .billboard-row .billboard .remindme-button .icon-button-mylist-added, .billboard-row .billboard .remindme-button .icon-button-mylist-added-reverse, .billboard-row .billboard .remindme-button .icon-button-remindme-added, .billboard-row .billboard .remindme-button .icon-button-remindme-added-reverse {
  transform: scale(1.5)
}

.billboard-row .billboard .mylist-button .nf-icon-button-label, .billboard-row .billboard .remindme-button .nf-icon-button-label {
  margin: 0;
  padding: 0;
  top: auto
}

.billboard-row .billboard .mylist-button:hover, .billboard-row .billboard .remindme-button:hover {
  background-color: rgba(51, 51, 51, .4)
}

.billboard-row .billboard .mylist-button:hover .nf-icon-button-icon, .billboard-row .billboard .remindme-button:hover .nf-icon-button-icon {
  transform: scale(1)
}

.billboard-row .billboard .mylist-button:hover .nf-icon-button-icon.icon-button-mylist-added, .billboard-row .billboard .mylist-button:hover .nf-icon-button-icon.icon-button-mylist-added-reverse, .billboard-row .billboard .mylist-button:hover .nf-icon-button-icon.icon-button-remindme-added, .billboard-row .billboard .mylist-button:hover .nf-icon-button-icon.icon-button-remindme-added-reverse, .billboard-row .billboard .remindme-button:hover .nf-icon-button-icon.icon-button-mylist-added, .billboard-row .billboard .remindme-button:hover .nf-icon-button-icon.icon-button-mylist-added-reverse, .billboard-row .billboard .remindme-button:hover .nf-icon-button-icon.icon-button-remindme-added, .billboard-row .billboard .remindme-button:hover .nf-icon-button-icon.icon-button-remindme-added-reverse {
  transform: scale(1.5)
}

.billboard-row .billboard .icon-button-mylist-add-reverse:before, .billboard-row .billboard .icon-button-mylist-add:before {
  content: "\e641"
}

.billboard-row .billboard .icon-button-mylist-added-reverse:before, .billboard-row .billboard .icon-button-mylist-added:before {
  content: "\e804"
}

.billboard-links button {
  margin-bottom: 1rem;
  margin-right: 1rem
}

.ltr-podnco {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  opacity: 1;
  padding: 0.8rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: background-color, color;
  word-break: break-word;
  white-space: nowrap;
}

.ltr-podnco.color-primary {
  background-color: white;
  color: black;
}

.ltr-podnco.hasLabel {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.ltr-podnco.hasIcon {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.billboard-links .playLink button {
  margin-left: 0
}

.ltr-podnco.hasLabel.hasIcon {
  padding-left: 2rem;
  padding-right: 2.4rem;
}

.ltr-1st24vv {
  line-height: 0;
}

.ltr-1npqywr {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: relative;
  width: 1rem;
}

.ltr-1vh9doa {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-bottom: 2px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 0;
}

.ltr-18ezbm2 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  opacity: 1;
  padding: 0.8rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: background-color, color;
  word-break: break-word;
  white-space: nowrap;
}

.ltr-18ezbm2.hasLabel.hasIcon {
  padding-left: 2rem;
  padding-right: 2.4rem;
}

.ltr-18ezbm2.hasIcon {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.ltr-18ezbm2.hasLabel {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.ltr-18ezbm2.color-secondary {
  background-color: rgba(109, 109, 110, 0.7);
  color: white;
}

.bigRowItem .forward-leaning, .billboard-links.forward-leaning {
  margin-top: 1vw
}

.bigRowItem .forward-leaning .btn-flat, .bigRowItem .forward-leaning .nf-flat-button .annotation, .bigRowItem .forward-leaning .nf-flat-button .nf-flat-button-text, .bigRowItem .forward-leaning .nf-icon-button .nf-icon-button-label, .billboard-links.forward-leaning .btn-flat, .billboard-links.forward-leaning .nf-flat-button .annotation, .billboard-links.forward-leaning .nf-flat-button .nf-flat-button-text, .billboard-links.forward-leaning .nf-icon-button .nf-icon-button-label {
  text-transform: none
}

.bigRowItem .forward-leaning .btn-flat, .bigRowItem .forward-leaning .btn-flat-primary, .bigRowItem .forward-leaning .nf-flat-button, .bigRowItem .forward-leaning .nf-flat-button-primary, .bigRowItem .forward-leaning .nf-icon-button:not(.audio-btn), .billboard-links.forward-leaning .btn-flat, .billboard-links.forward-leaning .btn-flat-primary, .billboard-links.forward-leaning .nf-flat-button, .billboard-links.forward-leaning .nf-flat-button-primary, .billboard-links.forward-leaning .nf-icon-button:not(.audio-btn) {
  background-color: rgba(51, 51, 51, .4);
  border-radius: .2vw;
  border-width: 0;
  box-shadow: none;
  font-size: 1.1vw;
  height: 1.2vw;
  margin-bottom: .75em;
  padding: .75em 2.3em
}

.bigRowItem .forward-leaning .btn-flat-primary:hover, .bigRowItem .forward-leaning .btn-flat:hover, .bigRowItem .forward-leaning .nf-flat-button-primary:hover, .bigRowItem .forward-leaning .nf-flat-button:hover, .bigRowItem .forward-leaning .nf-icon-button:not(.audio-btn):hover, .billboard-links.forward-leaning .btn-flat-primary:hover, .billboard-links.forward-leaning .btn-flat:hover, .billboard-links.forward-leaning .nf-flat-button-primary:hover, .billboard-links.forward-leaning .nf-flat-button:hover, .billboard-links.forward-leaning .nf-icon-button:not(.audio-btn):hover {
  background-color: #e6e6e6;
  box-shadow: 0 .6vw 1vw -.4vw rgba(0, 0, 0, .35);
  color: #000;
  height: 1.2vw
}

.episode-badge, .new-badge {
  background-color: #009779;
  color: #fff;
  display: inline-block;
  font-size: .7em;
  font-weight: 500;
  height: 1.8em;
  line-height: 1.8em;
  margin-right: .6em;
  margin-top: .5em;
  padding: 0 .5em;
  vertical-align: bottom
}

.member-footer {
  color: grey;
  margin: 20px auto 0;
  max-width: 980px;
  padding: 0 4%
}

.member-footer .social-links {
  display: flex;
  margin-bottom: 1em
}

.member-footer .social-links .social-link {
  margin-right: 15px
}

.member-footer .social-links .social-link .svg-icon {
  fill: grey;
  height: 25px;
  width: 36px
}

.member-footer .member-footer-help {
  color: #fff;
  font-size: 16px;
  margin-bottom: 30px;
  padding: 0
}

.member-footer .member-footer-links {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 13px;
  margin: 0 0 14px;
  padding: 0
}

.member-footer .member-footer-link-wrapper {
  box-sizing: border-box;
  flex: 0 0 50%;
  list-style-type: none;
  margin-bottom: 16px;
  padding-right: 22px
}

@media (min-width: 800px) {
  .member-footer .member-footer-link-wrapper {
    flex-basis: 25%
  }
}

.member-footer .member-footer-link {
  color: grey;
  text-decoration: none
}

.member-footer .member-footer-link:hover {
  text-decoration: underline
}

.member-footer .member-footer-link-update {
  font-weight: 500
}

.member-footer .member-footer-copyright {
  font-size: 11px;
  margin-bottom: 15px
}

.member-footer .service-code {
  font-size: 13px;
  margin-bottom: 20px;
  padding: .5em
}

.member-footer .copy-text-block+.copy-text-block {
  margin-top: 4px
}

.member-footer .copy-text-block+.copy-text-block a {
  color: grey
}

.member-footer .copy-text-block+.copy-text-block a:hover {
  text-decoration: underline
}

.service-code {
  background: transparent;
  border: 1px solid grey;
  color: grey;
  font-size: 1.3rem;
  padding: .5rem
}

.service-code:hover {
  color: #fff;
  cursor: pointer
}

.sortGallery {
  float: right;
  font-size: 14px;
  text-align: right
}

.sortGallery .nfDropDown.theme-lakira {
  margin-left: 10px;
  min-width: 240px
}

.sortGallery ul.maturitySelectors li {
  display: inline-block;
  list-style: none;
  margin-left: 13px
}

.sortGallery ul.maturitySelectors li label {
  display: inline
}

.sortGallery ul.maturitySelectors li input {
  display: none
}

.sortGallery ul.maturitySelectors li input+label {
  border: 1px solid #333;
  border-radius: 0;
  display: inline-block;
  margin-right: 3px;
  padding: 6px;
  position: relative;
  top: 2px
}

.sortGallery ul.maturitySelectors li input:checked+label {
  border: 1px solid #333;
  color: #99a1a7
}

.sortGallery ul.maturitySelectors li input:checked+label:after {
  color: #fff;
  content: "\2714";
  font-size: 12px;
  left: 1px;
  position: absolute;
  top: -1px
}

.breadCrumbs {
  color: grey;
  font-size: 18px
}

.breadCrumbs ul {
  margin: 0;
  padding: 0
}

.breadCrumbs ul li {
  display: inline;
  list-style: none;
  padding-right: 10px;
  position: relative;
  z-index: 3
}

.breadCrumbs ul li a {
  color: grey
}

.breadCrumbs ul li:after {
  content: ">";
  padding-left: 10px
}

.aro-fallback-gallery {
  padding-top: 3vw
}

.aro-gallery-header {
  margin: 0 4%;
  min-height: 41px;
  padding-top: 3vw
}

@media screen and (min-width: 1500px) {
  .aro-gallery-header {
    margin: 0 60px
  }
}

.aro-gallery-header .genreTitle {
  font-size: 38px;
  font-weight: 500;
  line-height: 38px;
  margin-right: 15px
}

@media only screen and (max-width: 950px) {
  .aro-gallery-header .genreTitle {
    font-size: 22px;
    line-height: 22px
  }
}

@media only screen and (max-width: 740px) {
  .aro-gallery-header .genreTitle {
    font-size: 18px;
    line-height: 18px
  }
}

@media only screen and (max-width: 400px) {
  .aro-gallery-header .genreTitle {
    font-size: 14px;
    line-height: 14px
  }
}

.aro-gallery-header .subgenres {
  display: inline-block;
  margin-top: 7px;
  vertical-align: top
}

.aro-gallery-header .subgenres .sub-menu {
  padding: 5px 0;
  z-index: 20
}

.aro-gallery-header .subgenres .sub-menu .sub-menu-link {
  white-space: nowrap
}

.gallery .aro-gallery-header {
  padding-bottom: 3vw
}

.aro-genre-details {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start
}

.aro-genre-details .subgenres {
  margin: 0 30px
}

.aro-toggle {
  display: flex;
  margin-left: auto;
  z-index: 2
}

.aro-toggle .aro-row-toggle {
  align-items: center;
  background: rgba(0, 0, 0, .7);
  border: 1px solid #fff;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 44px
}

.aro-toggle .aro-grid {
  background: rgba(0, 0, 0, .1);
  border: 1px solid #fff;
  border-left: none;
  display: flex;
  opacity: .65;
  width: auto
}

.aro-toggle .aro-grid:hover {
  opacity: 1
}

.aro-toggle .aro-grid-toggle {
  align-items: center;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  width: 44px
}

.aro-toggle .aro-grid-toggle, .aro-toggle .aro-row-toggle {
  background-color: transparent;
  padding: 0
}

.aro-toggle .aro-grid-toggle:focus, .aro-toggle .aro-row-toggle:focus {
  outline: none
}

.aro-toggle .aro-grid-toggle:focus svg, .aro-toggle .aro-row-toggle:focus svg {
  color: #fff;
  transform: scale(1.35);
  transition: transform .35s cubic-bezier(.86, 0, .07, 1)
}

.aro-toggle .svg-icon-rows {
  fill: #fff;
  height: 13px;
  width: 13px
}

.aro-toggle .svg-icon-grid {
  color: #fff;
  height: 13px;
  width: 13px
}

.aro-toggle.grid-selected .aro-row-toggle {
  background: rgba(0, 0, 0, .1);
  border-right: none;
  cursor: pointer;
  opacity: .65
}

.aro-toggle.grid-selected .aro-row-toggle:hover {
  opacity: 1
}

.aro-toggle.grid-selected .aro-grid {
  background: rgba(0, 0, 0, .7);
  border-left: 1px solid #fff;
  opacity: 1
}

.aro-toggle.grid-selected .aro-grid-toggle {
  cursor: auto
}

.nfDropDown.theme-aro {
  min-width: 240px;
  position: relative;
  text-align: left
}

.nfDropDown.theme-aro .label {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, .7);
  border-radius: 0;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 28px;
  padding: 0 50px 0 6px;
  position: relative
}

.nfDropDown.theme-aro .label:hover {
  cursor: pointer
}

.nfDropDown.theme-aro .label:focus {
  outline: none
}

.nfDropDown.theme-aro .label .arrow {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  height: 0;
  position: absolute;
  right: 10px;
  top: 44%;
  width: 0
}

.nfDropDown.theme-aro .label.loading {
  visibility: hidden
}

.nfDropDown.theme-aro .sub-menu {
  background-color: rgba(0, 0, 0, .9);
  border: 1px solid #fff;
  font-size: 1rem;
  left: 0;
  margin: 0;
  opacity: 0;
  overflow-x: hidden;
  padding: 0;
  position: absolute;
  top: 2rem;
  z-index: 2
}

.nfDropDown.theme-aro .sub-menu .sub-menu-list, .nfDropDown.theme-aro .sub-menu .sub-menu-list-special {
  margin: 0;
  padding: 5px 0
}

.nfDropDown.theme-aro .sub-menu .sub-menu-item a {
  padding: 1px 20px 1px 10px
}

.nfDropDown.theme-aro .sub-menu .sub-menu-link {
  color: #fff;
  display: inline-block;
  padding: 1px 0;
  width: 100%
}

.nfDropDown.theme-aro .sub-menu .sub-menu-link:hover {
  text-decoration: underline
}

.nfDropDown.theme-aro .sub-menu::-webkit-scrollbar {
  background-color: #333;
  width: 10px
}

.nfDropDown.theme-aro .sub-menu::-webkit-scrollbar:hover {
  background-color: #4d4d4d
}

.nfDropDown.theme-aro .sub-menu::-webkit-scrollbar-thumb {
  background-color: grey;
  width: 6px
}

.nfDropDown.theme-aro .sub-menu::-webkit-scrollbar-thumb:hover {
  background-color: #ccc
}

.nfDropDown.theme-aro .sub-menu::-webkit-scrollbar-corner {
  background-color: #333
}

.nfDropDown.theme-aro.widthRestricted {
  display: inline-block;
  position: relative
}

.nfDropDown.theme-aro.widthRestricted .label {
  width: 100%
}

.nfDropDown.theme-aro.widthRestricted .sub-menu {
  min-width: 100%;
  white-space: nowrap
}

.nfDropDown.theme-aro.widthRestricted .sub-menu .sub-menu-list, .nfDropDown.theme-aro.widthRestricted .sub-menu .sub-menu-list-special {
  width: 100%
}

.galleryHeader .title {
  display: inline-block;
  line-height: 36px;
  margin-right: 20px
}

.galleryHeader .title .genreTitle {
  font-size: 26px
}

.galleryHeader .subgenres {
  display: inline-block;
  margin-top: 7px;
  vertical-align: top
}

.galleryHeader .subgenres .sub-menu {
  padding: 5px 0;
  z-index: 20
}

.galleryHeader .subgenres .sub-menu .sub-menu-link {
  white-space: nowrap
}

.title .numVideos {
  color: #666;
  font-size: 24px
}

.content-preview-watermark {
  color: #cdcdcd;
  font-size: 18px
}

.rowContainer {
  position: relative;
  z-index: 0
}

.rowContainer .rowContent {
  box-sizing: border-box;
  padding: 0
}

.rowContainer .rowContent .slick-slider {
  z-index: 2
}

.rowContainer .rowContent .slick-slider .slick-arrow {
  background: hsla(0, 0%, 8%, .5)
}

.rowContainer .rowContent .slider:hover .slick-arrow.active:hover {
  background: hsla(0, 0%, 8%, .7)
}

@media screen and (max-width: 499px) {
  .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 50%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 33.333333%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 25%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 20%
  }
}

@media screen and (min-width: 1400px) {
  .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 16.66666667%
  }
}

.lolomoRow, .rowContainer {
  transition: transform .54s cubic-bezier(.5, 0, .1, 1) 0s
}

.lolomoRow.jawBoneOpen~.lolomoRow, .rowContainer.jawBoneOpen~.rowContainer {
  transform: translate3d(0, 37vw, 0)
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .lolomoRow.jawBoneOpen~.lolomoRow, .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 42vw, 0)
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .lolomoRow.jawBoneOpen~.lolomoRow, .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 37vw, 0)
  }
}

@media screen and (min-width: 1400px) {
  .lolomoRow.jawBoneOpen~.lolomoRow, .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 32vw, 0)
  }
}

@media screen and (max-width: 499px) {
  .lolomoRow.bigRowOpen~.lolomoRow.jawBoneOpen~.lolomoRow, .lolomoRow.jawBoneOpen~.lolomoRow.bigRowOpen~.lolomoRow {
    transform: translate3d(0, 44vw, 0)
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .lolomoRow.bigRowOpen~.lolomoRow.jawBoneOpen~.lolomoRow, .lolomoRow.jawBoneOpen~.lolomoRow.bigRowOpen~.lolomoRow {
    transform: translate3d(0, 58vw, 0)
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .lolomoRow.bigRowOpen~.lolomoRow.jawBoneOpen~.lolomoRow, .lolomoRow.jawBoneOpen~.lolomoRow.bigRowOpen~.lolomoRow {
    transform: translate3d(0, 67vw, 0)
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .lolomoRow.bigRowOpen~.lolomoRow.jawBoneOpen~.lolomoRow, .lolomoRow.jawBoneOpen~.lolomoRow.bigRowOpen~.lolomoRow {
    transform: translate3d(0, 66vw, 0)
  }
}

@media screen and (min-width: 1400px) {
  .lolomoRow.bigRowOpen~.lolomoRow.jawBoneOpen~.lolomoRow, .lolomoRow.jawBoneOpen~.lolomoRow.bigRowOpen~.lolomoRow {
    transform: translate3d(0, 61vw, 0)
  }
}

.t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .rowContainer.jawBoneOpen~.rowContainer {
  transform: translate3d(0, 275%, 0)
}

@media screen and (max-width: 499px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 200%, 0)
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 295%, 0)
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 265%, 0)
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 275%, 0)
  }
}

@media screen and (min-width: 1400px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 295%, 0)
  }
}

.t_69f029 .originals-panels-row.jawBoneOpen~.lolomoRow {
  transform: translate3d(0, 75%, 0)
}

@media screen and (max-width: 499px) {
  .t_69f029 .originals-panels-row.jawBoneOpen~.lolomoRow {
    transform: translateZ(0)
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_69f029 .originals-panels-row.jawBoneOpen~.lolomoRow {
    transform: translate3d(0, 30%, 0)
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_69f029 .originals-panels-row.jawBoneOpen~.lolomoRow {
    transform: translate3d(0, 45%, 0)
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_69f029 .originals-panels-row.jawBoneOpen~.lolomoRow {
    transform: translate3d(0, 75%, 0)
  }
}

@media screen and (min-width: 1400px) {
  .t_69f029 .originals-panels-row.jawBoneOpen~.lolomoRow {
    transform: translate3d(0, 100%, 0)
  }
}

.t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow.originals-panels-row {
  transform: translate3d(0, 91%, 0)
}

@media screen and (max-width: 499px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow.originals-panels-row {
    transform: translate3d(0, 64%, 0)
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow.originals-panels-row {
    transform: translate3d(0, 95%, 0)
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow.originals-panels-row {
    transform: translate3d(0, 83%, 0)
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow.originals-panels-row {
    transform: translate3d(0, 91%, 0)
  }
}

@media screen and (min-width: 1400px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoRow.originals-panels-row {
    transform: translate3d(0, 100%, 0)
  }
}

.t_69f029 .lolomoRow.jawBoneOpen~.lolomoBigRow {
  transform: translate3d(0, 215%, 0)
}

@media screen and (max-width: 499px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoBigRow {
    transform: translate3d(0, 150%, 0)
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoBigRow {
    transform: translate3d(0, 225%, 0)
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoBigRow {
    transform: translate3d(0, 200%, 0)
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoBigRow {
    transform: translate3d(0, 215%, 0)
  }
}

@media screen and (min-width: 1400px) {
  .t_69f029 .lolomoRow.jawBoneOpen~.lolomoBigRow {
    transform: translate3d(0, 200%, 0)
  }
}

.t_69f029 .gallery .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .gallery .rowContainer.jawBoneOpen~.rowContainer {
  transform: translate3d(0, 350%, 0)
}

@media screen and (max-width: 499px) {
  .t_69f029 .gallery .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .gallery .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 240%, 0)
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_69f029 .gallery .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .gallery .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 355%, 0)
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_69f029 .gallery .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .gallery .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 310%, 0)
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_69f029 .gallery .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .gallery .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 350%, 0)
  }
}

@media screen and (min-width: 1400px) {
  .t_69f029 .gallery .lolomoRow.jawBoneOpen~.lolomoRow, .t_69f029 .gallery .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 385%, 0)
  }
}

.t_69f029 .lolomoRow.jawBoneOpen.has-large-density~.lolomoRow {
  transform: translate3d(0, 170%, 0)
}

@media screen and (max-width: 499px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-large-density~.lolomoRow {
    transform: translate3d(0, 55%, 0)
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-large-density~.lolomoRow {
    transform: translate3d(0, 100%, 0)
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-large-density~.lolomoRow {
    transform: translate3d(0, 145%, 0)
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-large-density~.lolomoRow {
    transform: translate3d(0, 150%, 0)
  }
}

@media screen and (min-width: 1400px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-large-density~.lolomoRow {
    transform: translate3d(0, 170%, 0)
  }
}

.t_69f029 .lolomoRow.jawBoneOpen.has-medium-density~.lolomoRow {
  transform: translate3d(0, 220%, 0)
}

@media screen and (max-width: 499px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-medium-density~.lolomoRow {
    transform: translate3d(0, 80%, 0)
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-medium-density~.lolomoRow {
    transform: translate3d(0, 130%, 0)
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-medium-density~.lolomoRow {
    transform: translate3d(0, 180%, 0)
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-medium-density~.lolomoRow {
    transform: translate3d(0, 210%, 0)
  }
}

@media screen and (min-width: 1400px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-medium-density~.lolomoRow {
    transform: translate3d(0, 225%, 0)
  }
}

.t_69f029 .lolomoRow.jawBoneOpen.has-small-density~.lolomoRow {
  transform: translate3d(0, 240%, 0)
}

@media screen and (max-width: 499px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-small-density~.lolomoRow {
    transform: translate3d(0, 100%, 0)
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-small-density~.lolomoRow {
    transform: translate3d(0, 150%, 0)
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-small-density~.lolomoRow {
    transform: translate3d(0, 220%, 0)
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-small-density~.lolomoRow {
    transform: translate3d(0, 230%, 0)
  }
}

@media screen and (min-width: 1400px) {
  .t_69f029 .lolomoRow.jawBoneOpen.has-small-density~.lolomoRow {
    transform: translate3d(0, 240%, 0)
  }
}

.lolomo {
  overflow-x: hidden
}

.lolomo.is-fullbleed {
  margin-top: -70px
}

.slick-slider {
  margin: 0;
  padding: 0 4%;
  position: relative;
  touch-action: pan-y
}

@media screen and (min-width: 1500px) {
  .slick-slider {
    padding: 0 60px
  }
}

.slick-slider .slick-list {
  overflow-x: visible !important;
  overflow: visible !important;
  padding-bottom: 1px
}

.slick-slider .slick-list .slick-track {
  white-space: nowrap
}

.slick-slider .slick-list .slick-track.animating {
  pointer-events: none;
  transition: transform .75s ease 0s
}

.slick-slider .slick-list .slick-track .slick-slide {
  box-sizing: border-box;
  display: inline-block;
  padding: 0 .2vw;
  position: relative;
  vertical-align: top;
  white-space: normal;
  z-index: 1
}

.slick-slider .slick-list .slick-track .slick-slide:hover {
  z-index: 2
}

.slick-slider .slick-list .slick-track .slick-slide:last-child {
  padding-right: 0
}

.slick-slider .slick-list .slick-track .slick-slide:first-child {
  padding-left: 0
}

.slick-slider .slick-list.showPeek {
  overflow-x: visible
}

.slick-slider .slick-arrow {
  font-size: 36px;
  line-height: 36px;
  height: auto;
  transform: none;
  bottom: 0;
  color: #fff;
  display: flex !important;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  width: 4%;
  z-index: 20;
}

.slick-prev:before {
  content: unset !important;
}

.slick-next:before {
  content: unset !important;
}

@media screen and (min-width: 1500px) {
  .slick-slider .slick-arrow {
    width: 60px
  }
}

.slick-slider .slick-arrow.active {
  cursor: pointer
}

.slick-slider .slick-arrow>.indicator-icon {
  align-self: center;
  opacity: 0;
  font-size: 2.5vw;
  height: auto;
  transition: ease-out .2s
}

@media screen and (min-width: 1500px) {
  .slick-slider .slick-arrow>.indicator-icon {
    font-size: 2em
  }
}

.slick-slider .slick-arrow.slick-prev {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  left: -2px
}

.slick-slider .slick-arrow.slick-prev .indicator-icon {
  transform-origin: 55% 50%
}

.slick-slider .slick-arrow.slick-next {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  right: 0
}

.slick-slider .slick-arrow.slick-next .indicator-icon {
  transform-origin: 45% 50%
}

.slider.preview-modal-open-in-row .indicator-icon, .slider.preview-modal-open-in-row .pagination-indicator {
  display: block
}

.slick-slider .slick-track:not(.animating) .sliderItemHidden {
  display: none
}

.slider-hover-trigger-layer:hover .slick-slider .slick-arrow .indicator-icon {
  opacity: 1;
}

.slider-hover-trigger-layer:hover .slick-slider .slick-arrow:hover .indicator-icon {
  color: #fff;
  font-weight: 500;
  transform: scale(1.25)
}

.slider-hover-trigger-layer .slick-slider .slick-arrow:focus .indicator-icon {
  color: #fff;
  display: block;
  font-weight: 500;
  transform: scale(1.25)
}

.slick-slider .slick-arrow:focus .indicator-icon {
  color: #fff;
  font-weight: 500;
  transform: scale(1.25)
}

.notification-pill {
  background-color: #b9090b;
  border: none;
  border-radius: 1000px;
  color: #fff;
  display: inline-block;
  font-size: .75em;
  font-weight: 500;
  line-height: 1;
  min-width: 1em;
  padding: .3em;
  text-align: center
}

.chunked-row-container .lolomoRow.lolomoPreview {
  margin-top: 1rem
}

.chunked-row-container .lolomoRow.lolomoPreview .loadingTitle {
  width: 25%
}

@media screen and (max-width: 800px) {
  .chunked-row-container .lolomoRow.lolomoPreview .loadingTitle {
    width: 50%
  }
}

.lolomoRow:last-of-type .chunked-row-container {
  border-bottom: none;
  padding-bottom: none
}

.chunked-row-container .chunked-row.four {
  display: flex;
  flex-flow: row wrap;
  margin: 0 4%
}

@media screen and (min-width: 1500px) {
  .chunked-row-container .chunked-row.four {
    margin-left: 60px
  }
}

.chunked-row-container .chunked-row.four .title-card-container {
  flex-basis: 24%;
  padding-bottom: 3rem;
  padding-right: 1.33%;
  position: relative
}

@media screen and (max-width: 800px) {
  .chunked-row-container .chunked-row.four .title-card-container {
    flex-basis: 49%;
    padding-right: 2%
  }

  .chunked-row-container .chunked-row.four .title-card-container:nth-child(2n) {
    padding-right: 0
  }
}

.chunked-row-container .chunked-row.four .title-card-container:nth-child(4n) {
  padding-right: 0
}

.chunked-row-container .more-container, .chunked-row-container .show-more {
  display: flex;
  justify-content: center
}

.chunked-row-container .show-more {
  align-items: center;
  background-color: #161616;
  border: 1px solid hsla(0, 0%, 100%, .6);
  border-radius: 25px;
  height: 3.3rem;
  margin-top: -1.65rem;
  width: 3.3rem
}

.chunked-row-container .divider {
  border-bottom: 2px solid #333;
  margin: 0 4%;
  padding-bottom: 1rem
}

@media screen and (min-width: 1500px) {
  .chunked-row-container .divider {
    margin-left: 60px
  }
}

.chunked-row-container .svg-icon-down-arrow-small {
  width: 1.3rem
}

.pinning-header .main-header {
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  padding: 0 4%;
  position: relative;
  transition: background-color .4s;
  z-index: 2
}

@media screen and (min-width: 1200px) {
  .pinning-header .main-header {
    font-size: 14px
  }
}

@media screen and (min-width: 1500px) {
  .pinning-header .main-header {
    padding: 0 60px
  }
}

.pinning-header .main-header.solid {
  border-bottom: 1px solid hsla(0, 0%, 100%, .2)
}

.pinning-header .main-header.has-billboard, .pinning-header .main-header.on-profiles-gate:not(.solid) {
  background-color: transparent
}

.pinning-header .main-header.menu-navigation {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .7) 10%, transparent)
}

.pinning-header .main-header.menu-navigation.has-billboard:not(.solid) {
  background-color: transparent
}

.pinning-header .main-header.menu-navigation:not(.has-billboard):not(.solid):not(.on-profiles-gate) {
  background-color: #141414
}

@media screen and (max-width: 800px) {
  .pinning-header .main-header.menu-navigation.search-focused .genre-menu-primary-navigation {
    display: none
  }
}

.pinning-header .main-header .logo {
  color: #009779;
  width: 94px;
  cursor: pointer;
  display: inline-block;
  font-size: 1.8em;
  margin-right: 5px;
  text-decoration: none;
  vertical-align: middle
}

@media screen and (min-width: 950px) {
  .pinning-header .main-header .logo {
    font-size: 25px
  }
}

@media screen and (min-width: 1150px) {
  .pinning-header .main-header .logo {
    margin-right: 25px
  }
}

.pinning-header .main-header .sign-out {
  align-items: center;
  display: flex;
  font-size: 1.2em;
  font-weight: 500;
  height: 100%;
  position: absolute;
  right: 4%
}

.pinning-header .tabbed-primary-navigation {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0
}

.pinning-header .tabbed-primary-navigation .active-tutorial-tab {
  pointer-events: none
}

.pinning-header .tabbed-primary-navigation .navigation-menu, .pinning-header .tabbed-primary-navigation .navigation-tab {
  list-style-type: none;
  margin-left: 18px
}

@media screen and (min-width: 1330px) {
  .pinning-header .tabbed-primary-navigation .navigation-menu, .pinning-header .tabbed-primary-navigation .navigation-tab {
    margin-left: 20px
  }
}

.pinning-header .tabbed-primary-navigation .navigation-menu .menu-trigger {
  align-items: center;
  display: flex;
  font-weight: 500;
  height: 100%
}

.pinning-header .tabbed-primary-navigation .navigation-menu .menu-trigger:after {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
  height: 0;
  margin-left: 5px;
  width: 0
}

.pinning-header .tabbed-primary-navigation .navigation-menu .sub-menu {
  margin-left: -90px
}

.pinning-header .tabbed-primary-navigation .navigation-menu .sub-menu .callout-arrow {
  border: 7px solid transparent;
  border-bottom-color: #e5e5e5;
  height: 0;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  top: -16px;
  width: 0
}

.pinning-header .tabbed-primary-navigation .navigation-menu .sub-menu .sub-menu-list {
  padding: 0
}

.pinning-header .tabbed-primary-navigation .navigation-menu .sub-menu .sub-menu-item a {
  align-items: center;
  color: #b3b3b3;
  display: flex;
  height: 50px;
  justify-content: center;
  position: relative;
  transition: background-color .4s;
  width: 260px
}

.pinning-header .tabbed-primary-navigation .navigation-menu .sub-menu .sub-menu-item a.current {
  color: #fff;
  cursor: default;
  font-weight: 500
}

.pinning-header .tabbed-primary-navigation .navigation-menu .sub-menu .sub-menu-item a:hover {
  background-color: hsla(0, 0%, 100%, .05)
}

.pinning-header .tabbed-primary-navigation .navigation-tab {
  display: none
}

.pinning-header .tabbed-primary-navigation .navigation-tab .active-tutorial-tab {
  color: #fff;
  cursor: default;
  font-weight: 500;
  z-index: 2
}

.pinning-header .tabbed-primary-navigation .navigation-tab .active-tutorial-tab:focus, .pinning-header .tabbed-primary-navigation .navigation-tab .active-tutorial-tab:hover {
  color: #fff
}

.pinning-header .tabbed-primary-navigation .navigation-tab a {
  align-items: center;
  color: #e5e5e5;
  display: flex;
  height: 100%;
  position: relative;
  transition: color .4s
}

.pinning-header .tabbed-primary-navigation .navigation-tab a.current {
  color: #fff;
  cursor: default;
  font-weight: 500
}

.pinning-header .tabbed-primary-navigation .navigation-tab a.current:focus, .pinning-header .tabbed-primary-navigation .navigation-tab a.current:hover {
  color: #fff
}

.pinning-header .tabbed-primary-navigation .navigation-tab a:focus, .pinning-header .tabbed-primary-navigation .navigation-tab a:hover {
  color: #b3b3b3
}

@media screen and (min-width: 885px) {
  .pinning-header .tabbed-primary-navigation:not(.has-dropdown-menu) .navigation-tab {
    display: block
  }

  .pinning-header .tabbed-primary-navigation:not(.has-dropdown-menu) .navigation-menu {
    display: none
  }
}

.pinning-header .secondary-navigation {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: flex-end;
  position: absolute;
  right: 4%;
  top: 0
}

@media screen and (min-width: 1500px) {
  .pinning-header .secondary-navigation {
    right: 60px
  }
}

.pinning-header .secondary-navigation .active-tutorial-tab {
  color: #fff;
  cursor: default;
  font-weight: 500;
  z-index: 2
}

.pinning-header .secondary-navigation .active-tutorial-tab:focus, .pinning-header .secondary-navigation .active-tutorial-tab:hover {
  color: #fff
}

.pinning-header .secondary-navigation .nav-element a {
  display: block
}

.pinning-header .secondary-navigation .nav-element a.sub-menu-link-icon {
  align-items: center;
  display: flex
}

.pinning-header .secondary-navigation .nav-element a.sub-menu-link-icon svg {
  color: #b3b3b3;
  padding: 0 13px 0 5px
}

.pinning-header .secondary-navigation .nav-element .account-drop-down a:focus {
  transform: none;
  transition: none
}

.pinning-header .secondary-navigation .nav-element:not(:last-child) {
  margin-right: 10px
}

@media screen and (min-width: 1330px) {
  .pinning-header .secondary-navigation .nav-element:not(:last-child) {
    margin-right: 15px
  }
}

.pinning-header .secondary-navigation .icon-search {
  vertical-align: middle
}

.pinning-header .secondary-navigation .notifications {
  position: relative
}

.pinning-header .secondary-navigation .notifications .sub-menu {
  top: 34px
}

@media screen and (min-width: 1150px) {
  .pinning-header .secondary-navigation .notifications .sub-menu {
    top: 38px
  }
}

.pinning-header .secondary-navigation .show-kids {
  display: none
}

.pinning-header .secondary-navigation.menu-navigation {
  font-size: 16px
}

.pinning-header .secondary-navigation:not(.menu-navigation) .search-icon {
  margin-right: 0
}

.pinning-header .secondary-navigation:not(.menu-navigation).search-focused .searchBox {
  margin-left: 100px
}

@media screen and (min-width: 1100px) {
  .pinning-header .secondary-navigation:not(.menu-navigation) .show-kids {
    display: block
  }

  .pinning-header .secondary-navigation:not(.menu-navigation) .account-drop-down .responsive-links {
    display: none
  }
}

@media screen and (max-width: 1100px) {
  .pinning-header .secondary-navigation:not(.menu-navigation) .show-kids {
    display: none
  }

  .pinning-header .secondary-navigation:not(.menu-navigation) .account-drop-down .responsive-links, .pinning-header .secondary-navigation:not(.menu-navigation) .kids-responsive-link {
    display: block
  }
}

.searchBox {
  display: inline-block;
  vertical-align: middle
}

@media screen and (max-width: 400px) {
  .searchBox {
    display: none
  }
}

.searchInput {
  align-items: center;
  background: rgba(0, 0, 0, .75);
  border: 1px solid hsla(0, 0%, 100%, .85);
  display: flex
}

.searchInput input {
  background: transparent;
  border: none;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  outline: none;
  padding: 7px 14px 7px 7px;
  width: 212px
}

.searchInput input:focus {
  outline: none
}

.searchInput input::-webkit-input-placeholder {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  opacity: 1
}

.searchInput input::-moz-placeholder {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  opacity: 1
}

.searchInput input:-ms-input-placeholder {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  opacity: 1
}

.searchInput input::-ms-clear {
  display: none
}

.searchInput .search-icon {
  padding: 0 6px
}

.searchInput .icon-close {
  cursor: pointer;
  font-size: 13px;
  margin: 0 6px
}

.searchInput .empty {
  visibility: hidden
}

.searchTab {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block
}

.searchTab span {
  color: #fff;
  line-height: 1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .3)
}

.searchTab .search-icon {
  margin-right: 10px
}

.searchTab .label {
  left: 20px
}

.searchTab:focus {
  transform: scale(1.2)
}

.searchInput input {
  height: 34px;
  line-height: 34px
}

.akira-header .account-menu-item, .pinning-header .account-menu-item {
  display: block;
  font-size: 12px;
  position: relative;
  z-index: 0
}

.akira-header .account-menu-item .kids-badge, .pinning-header .account-menu-item .kids-badge {
  display: none
}

.akira-header .account-menu-item .avatar-wrapper, .pinning-header .account-menu-item .avatar-wrapper {
  display: inline
}

.akira-header .account-menu-item.exit-button, .pinning-header .account-menu-item.exit-button {
  border: 1px solid #ccc;
  padding: 3px
}

.akira-header .account-menu-item.exit-button .account-dropdown-button .profile-name, .pinning-header .account-menu-item.exit-button .account-dropdown-button .profile-name {
  max-width: 140px
}

.akira-header .account-menu-item.exit-button-red, .pinning-header .account-menu-item.exit-button-red {
  background: #df242c;
  border: none;
  padding: 3px
}

.akira-header .account-menu-item.exit-button-red .account-dropdown-button .profile-icon, .pinning-header .account-menu-item.exit-button-red .account-dropdown-button .profile-icon {
  border: 1px solid #fff;
  border-radius: 4px
}

.akira-header .account-menu-item.exit-button-red .account-dropdown-button .profile-name, .pinning-header .account-menu-item.exit-button-red .account-dropdown-button .profile-name {
  color: #fff
}

.akira-header .account-menu-item.exit-button-red .account-dropdown-button .caret, .pinning-header .account-menu-item.exit-button-red .account-dropdown-button .caret {
  border-color: #fff transparent transparent
}

.akira-header .account-dropdown-button, .pinning-header .account-dropdown-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  width: 100%
}

@media screen and (max-width: 950px) {
  .akira-header .account-dropdown-button, .pinning-header .account-dropdown-button {
    height: 40px
  }
}

.akira-header .account-dropdown-button a, .pinning-header .account-dropdown-button a {
  position: relative;
  z-index: -1
}

.akira-header .account-dropdown-button .caret, .pinning-header .account-dropdown-button .caret {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  height: 0;
  margin-left: 10px;
  transition: transform 367ms cubic-bezier(.21, 0, .07, 1);
  width: 0
}

.akira-header .account-dropdown-button .caret.open, .pinning-header .account-dropdown-button .caret.open {
  transform: rotate(180deg)
}

@media screen and (max-width: 950px) {
  .akira-header .account-dropdown-button .caret, .pinning-header .account-dropdown-button .caret {
    display: none
  }
}

.akira-header .account-dropdown-button .avatar-wrapper, .pinning-header .account-dropdown-button .avatar-wrapper {
  position: relative
}

.akira-header .account-dropdown-button .profile-icon, .pinning-header .account-dropdown-button .profile-icon {
  border-radius: 4px;
  height: 32px;
  vertical-align: middle;
  width: 32px
}

.akira-header .account-dropdown-button .profile-link, .pinning-header .account-dropdown-button .profile-link {
  align-items: center;
  display: flex;
  position: relative
}

.akira-header .account-dropdown-button .profile-name, .pinning-header .account-dropdown-button .profile-name {
  font-weight: 500;
  margin-left: 6px;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre
}

@media screen and (max-width: 950px) {
  .akira-header .account-dropdown-button .profile-name, .pinning-header .account-dropdown-button .profile-name {
    display: none
  }
}

.akira-header .account-dropdown-button .callout-arrow, .pinning-header .account-dropdown-button .callout-arrow {
  border: 7px solid transparent;
  border-bottom-color: #e5e5e5;
  bottom: -19px;
  height: 0;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  width: 0
}

.akira-header .account-dropdown-button.direction-top .callout-arrow, .pinning-header .account-dropdown-button.direction-top .callout-arrow {
  border-color: #e5e5e5 transparent transparent;
  bottom: auto;
  top: -20px
}

.akira-header .account-drop-down, .pinning-header .account-drop-down {
  margin-left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 52px;
  width: 220px
}

@media screen and (max-width: 950px) {
  .akira-header .account-drop-down, .pinning-header .account-drop-down {
    top: 59px
  }
}

.akira-header .account-drop-down.sub-menu-top, .pinning-header .account-drop-down.sub-menu-top {
  bottom: 52px;
  top: auto
}

@media screen and (max-width: 950px) {
  .akira-header .account-drop-down.sub-menu-top, .pinning-header .account-drop-down.sub-menu-top {
    bottom: 59px;
    top: auto
  }
}

.akira-header .account-drop-down .sub-menu-list, .pinning-header .account-drop-down .sub-menu-list {
  display: block;
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%
}

.akira-header .account-drop-down .sub-menu-item, .pinning-header .account-drop-down .sub-menu-item {
  display: block;
  font-size: 13px;
  line-height: 16px;
  padding: 5px 10px
}

.akira-header .account-drop-down .sub-menu-item>a, .pinning-header .account-drop-down .sub-menu-item>a {
  display: inline-block;
  text-transform: none;
  width: 100%
}

.akira-header .account-drop-down .sub-menu-item>a:hover, .pinning-header .account-drop-down .sub-menu-item>a:hover {
  text-decoration: underline
}

.akira-header .account-drop-down .profiles, .pinning-header .account-drop-down .profiles {
  height: auto;
  overflow: hidden;
  padding: 10px 0 5px
}

.akira-header .account-drop-down .profiles .sub-menu-item, .pinning-header .account-drop-down .profiles .sub-menu-item {
  line-height: 32px
}

.akira-header .account-drop-down .profiles .sub-menu-item>div, .pinning-header .account-drop-down .profiles .sub-menu-item>div {
  align-items: center;
  display: flex
}

.akira-header .account-drop-down .profiles .profile-link, .pinning-header .account-drop-down .profiles .profile-link {
  cursor: pointer
}

.akira-header .account-drop-down .profiles .profile-icon, .pinning-header .account-drop-down .profiles .profile-icon {
  border-radius: 4px;
  height: 32px;
  margin-right: 10px;
  vertical-align: middle;
  width: 32px
}

.akira-header .account-drop-down .profiles .profile-name, .pinning-header .account-drop-down .profiles .profile-name {
  display: inline-block;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: pre;
  width: 100px
}

@media screen and (max-width: 950px) {
  .akira-header .account-drop-down .profiles .profile-name, .pinning-header .account-drop-down .profiles .profile-name {
    display: inline-block
  }
}

.akira-header .account-drop-down .profiles .svg-icon-profile-lock, .pinning-header .account-drop-down .profiles .svg-icon-profile-lock {
  color: #999;
  height: 16px;
  width: 16px
}

.akira-header .account-drop-down .profiles .profile-link:hover .profile-name, .pinning-header .account-drop-down .profiles .profile-link:hover .profile-name {
  text-decoration: underline
}

.akira-header .account-drop-down .account-links, .pinning-header .account-drop-down .account-links {
  padding-top: 10px;
  padding-bottom: 10px
}

.akira-header .account-drop-down .sign-out-links, .pinning-header .account-drop-down .sign-out-links {
  border-top: 1px solid hsla(0, 0%, 100%, .25);
  padding: 10px 0
}

.akira-header .account-drop-down .sign-out-links .sub-menu-link, .pinning-header .account-drop-down .sign-out-links .sub-menu-link {
  text-align: center
}

.akira-header .account-drop-down .responsive-links, .pinning-header .account-drop-down .responsive-links {
  border-top: 1px solid hsla(0, 0%, 100%, .25);
  display: none;
  padding: 10px 0
}

@media screen and (max-width: 860px) {
  .akira-header .account-drop-down .responsive-links, .pinning-header .account-drop-down .responsive-links {
    display: block
  }
}

.akira-header .account-drop-down .responsive-links .kids-responsive-link, .pinning-header .account-drop-down .responsive-links .kids-responsive-link {
  display: none
}

@media screen and (max-width: 650px) {
  .akira-header .account-drop-down .responsive-links .kids-responsive-link, .pinning-header .account-drop-down .responsive-links .kids-responsive-link {
    display: block
  }
}

.akira-header .account-drop-down .responsive-links .kids-responsive-link .kids-responsive-link-button, .pinning-header .account-drop-down .responsive-links .kids-responsive-link .kids-responsive-link-button {
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;
  width: 100%
}

.akira-header .account-drop-down .responsive-links .kids-responsive-link .kids-responsive-link-button:hover, .pinning-header .account-drop-down .responsive-links .kids-responsive-link .kids-responsive-link-button:hover {
  text-decoration: underline
}

.akira-header .account-drop-down .member-referral, .pinning-header .account-drop-down .member-referral {
  background: #333;
  color: #fff;
  padding: .5em
}

.akira-header .account-drop-down .member-referral .referral-container, .pinning-header .account-drop-down .member-referral .referral-container {
  display: flex;
  flex-basis: auto;
  flex-direction: column
}

.akira-header .account-drop-down .member-referral .referral-container .title, .pinning-header .account-drop-down .member-referral .referral-container .title {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25em;
  margin: 0;
  padding: 0
}

.akira-header .account-drop-down .member-referral .referral-container .message, .pinning-header .account-drop-down .member-referral .referral-container .message {
  font-size: 12px;
  line-height: 1.2em;
  margin-top: .5em
}

.akira-header .account-drop-down .member-referral .referral-container button, .pinning-header .account-drop-down .member-referral .referral-container button {
  cursor: pointer;
  font-weight: 500;
  padding: .25em
}

@media screen and (max-width: 860px) {
  .akira-header.search-focused .account-menu-item .responsive-links .kids-responsive-link {
    display: block
  }
}

.pinning-header .sub-header {
  height: 41px;
  position: relative
}

@media screen and (min-width: 950px) {
  .pinning-header .sub-header {
    height: 68px
  }
}

.pinning-header .sub-header .sub-header-wrapper {
  align-items: center;
  display: flex;
  height: 41px;
  left: 0;
  padding: 0 4%;
  position: absolute;
  right: 0;
  top: 0
}

@media screen and (min-width: 950px) {
  .pinning-header .sub-header .sub-header-wrapper {
    height: 68px
  }
}

@media screen and (min-width: 1500px) {
  .pinning-header .sub-header .sub-header-wrapper {
    padding: 0 60px
  }
}

.pinning-header .sub-header .aro-gallery-header, .pinning-header .sub-header .galleryHeader {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  margin: 0;
  min-height: 0;
  padding: 0
}

.pinning-header .sub-header .aro-gallery-header.languageFilter, .pinning-header .sub-header .galleryHeader.languageFilter {
  justify-content: space-between
}

.pinning-header .sub-header .title {
  font-size: 2.5rem
}

.pinning-header .sub-header .sort-gallery-sub-header {
  align-items: center;
  display: flex;
  width: 100%
}

.pinning-header .sub-header .sort-gallery-sub-header .title {
  flex-grow: 1
}

.pinning-header .sub-header .galleryHeader.assistiveAudio {
  align-self: baseline;
  background-color: #141414
}

.pinning-header .sub-header .galleryHeader.assistiveAudio h1, .pinning-header .sub-header .galleryHeader.assistiveAudio h2 {
  margin: 0 0 .3em
}

.pinning-header .sub-header .galleryHeader.assistiveAudio .subgenres {
  display: flex;
  justify-content: space-between
}

.pinning-header .sub-header .galleryHeader.assistiveAudio .sortGallery {
  min-width: 35%
}

.pinning-header .sub-header .galleryHeader.assistiveAudio ul.ad-subgenres {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0
}

.pinning-header .sub-header .galleryHeader.assistiveAudio ul.ad-subgenres li {
  border-right: 1px solid #999;
  margin: .2em 1em .2em 0;
  padding: 0 1em 0 0
}

.pinning-header .sub-header .galleryHeader.assistiveAudio button {
  background: none;
  border-radius: 5px;
  float: right;
  padding: 5px 10px
}

.mainView .aro-genre .lolomo, .mainView .aro-genre-loading {
  box-sizing: border-box;
  padding-top: 5rem
}

@media screen and (max-width: 499px) {
  .mainView .aro-genre .lolomo, .mainView .aro-genre-loading {
    margin-top: 5%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .mainView .aro-genre .lolomo, .mainView .aro-genre-loading {
    margin-top: 3.3333333%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .mainView .aro-genre .lolomo, .mainView .aro-genre-loading {
    margin-top: 2.5%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .mainView .aro-genre .lolomo, .mainView .aro-genre-loading {
    margin-top: 2%
  }
}

@media screen and (min-width: 1400px) {
  .mainView .aro-genre .lolomo, .mainView .aro-genre-loading {
    margin-top: 1.66666667%
  }
}

.mainView .aro-genre .lolomo .lolomoRow:first-child, .mainView .aro-genre-loading .lolomoRow:first-child {
  margin-top: 0
}

.mainView .aro-genre .lolomo.is-fullbleed, .mainView .aro-genre-loading.is-fullbleed {
  margin-top: -70px;
  padding-top: 0
}

.mainView .gallery, .mainView .rowListContainer {
  box-sizing: border-box;
  padding-top: 5rem
}

@media screen and (max-width: 499px) {
  .mainView .gallery, .mainView .rowListContainer {
    margin-top: 10.5%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .mainView .gallery, .mainView .rowListContainer {
    margin-top: 6.99999993%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .mainView .gallery, .mainView .rowListContainer {
    margin-top: 5.25%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .mainView .gallery, .mainView .rowListContainer {
    margin-top: 4.2%
  }
}

@media screen and (min-width: 1400px) {
  .mainView .gallery, .mainView .rowListContainer {
    margin-top: 3.5%
  }
}

.search .gallery {
  margin-top: 0;
  padding-top: 0
}

.mainView .gallery.assistiveAudio {
  padding-top: 8rem
}

.pinning-header {
  height: 70px
}

.pinning-header.hide {
  opacity: 0;
  pointer-events: none
}

.pinning-header .pinning-header-container {
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  z-index: 1
}

.pinning-header .pinning-header-container .main-header {
  height: 41px;
  z-index: 2
}

@media screen and (min-width: 950px) {
  .pinning-header .pinning-header-container .main-header {
    height: 68px
  }
}

.pinning-header .pinning-header-container .sub-header {
  z-index: 1
}

.pinning-header .exit-kids-toolkit {
  height: 32px;
  margin-left: 1em
}

.pinning-header .exit-kids-toolkit.color-red {
  background-color: #009779;
  color: #fff
}

.pinning-header .exit-kids-toolkit.color-red:not(:disabled):hover {
  background-color: #bf0b14;
  color: #fff
}

.pinning-header .exit-kids-toolkit>span {
  font-size: 14px
}

.gallery {
  margin: 0;
  min-height: 1000px;
  overflow: hidden
}

.gallery.has-open-jaw {
  padding: 0 0 37vw
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .gallery.has-open-jaw {
    padding: 0 0 42vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .gallery.has-open-jaw {
    padding: 0 0 37vw
  }
}

@media screen and (min-width: 1400px) {
  .gallery.has-open-jaw {
    padding: 0 0 32vw
  }
}

.gallery.search {
  padding-top: 3%
}

.gallery .galleryHeader {
  margin: 0 4%;
  min-height: 44px;
  padding: 36px 0 72px
}

@media screen and (min-width: 1500px) {
  .gallery .galleryHeader {
    margin: 0 60px
  }
}

.gallery .personHeader {
  align-items: center;
  display: flex;
  margin: 0 4%;
  min-height: 44px;
  padding: 36px 0
}

@media screen and (min-width: 1500px) {
  .gallery .personHeader {
    margin: 0 60px
  }
}

.gallery .galleryContent {
  position: relative;
  z-index: 0
}

.gallery .title {
  font-size: 36px
}

.gallery .galleryMessage {
  color: #666;
  font-size: 18px;
  padding-top: 100px;
  text-align: center
}

.gallery .gallerySpinLoader {
  text-align: center
}

.gallery .galleryOptions {
  float: right;
  font-size: 14px;
  line-height: 44px
}

.gallery .galleryOptions .changeOrderLink {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #000;
  border: 1px solid #333;
  border-radius: 0;
  color: #fff;
  margin-left: 5px;
  padding: 10px 15px
}

.gallery .galleryOptions .changeOrderLink:hover {
  border-color: grey
}

.gallery .rowContainer {
  margin: 0 0 4vw
}

@media screen and (max-width: 499px) {
  .gallery .rowContainer {
    margin-bottom: 7.5vw
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .gallery .rowContainer {
    margin-bottom: 7.5vw
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .gallery .rowContainer {
    margin-bottom: 5.5vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .gallery .rowContainer {
    margin-bottom: 4.5vw
  }
}

@media screen and (min-width: 1400px) {
  .gallery .rowContainer {
    margin-bottom: 4vw
  }
}

.gallery .rowContainer:hover {
  z-index: 1
}

.gallery .creator-home-banner {
  width: 200%
}

.gallery .creator-home-banner .boxart-container {
  padding-right: 2px
}

.gallery .creator-home-banner .boxart-container .boxart-image {
  background-position: 50%;
  background-size: cover;
  height: 100%
}

@keyframes popInAnimation {
  0% {
    opacity: 0;
    transform: scale(.97)
  }

  to {
    opacity: .99;
    transform: scale(1)
  }
}

.animate-in {
  animation-duration: .8s;
  animation-fill-mode: forwards;
  animation-name: popInAnimation;
  animation-timing-function: cubic-bezier(0, 0, .5, 1);
  opacity: 0
}

.headshot {
  padding-right: 10px;
  vertical-align: top
}

.galleryLoader {
  padding: 0 4%
}

@media screen and (min-width: 1500px) {
  .galleryLoader {
    padding: 0 60px
  }
}

.dropdownsContainer {
  align-items: center;
  display: flex
}

.dropdownsLabel {
  font-size: 14px;
  margin-right: 1rem
}

.dropdowns {
  align-items: center;
  display: flex;
  margin: 10px 0
}

.categoryDropDown, .languageDropDown {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top
}

.languageDropDown .nfDropDown.theme-lakira {
  min-width: 240px
}

.loadingTitle {
  box-sizing: border-box;
  display: inline-block
}

.loadingTitle.fullWidth {
  width: 100%
}

.loadingTitle .ratio-16x9 {
  border-radius: 4px;
  padding: 27.25% 0
}

.loadingTitle .ratio-7x10 {
  border-radius: 4px;
  padding: 35% 0
}

@media screen and (max-width: 499px) {
  .loadingTitle {
    width: 50%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .loadingTitle {
    width: 33.333333%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .loadingTitle {
    width: 25%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .loadingTitle {
    width: 20%
  }
}

@media screen and (min-width: 1400px) {
  .loadingTitle {
    width: 16.66666667%
  }
}

@keyframes pulsateAnimation {
  0% {
    background-color: #1a1a1a
  }

  25% {
    background-color: #333
  }

  50% {
    background-color: #1a1a1a
  }

  to {
    background-color: #1a1a1a
  }
}

@keyframes pulsateTransparentAnimation {
  0% {
    background-color: transparent
  }

  25% {
    background-color: #333
  }

  50% {
    background-color: transparent
  }

  to {
    background-color: transparent
  }
}

.pulsate, .pulsate-transparent {
  animation-duration: 3.6s;
  animation-iteration-count: infinite;
  animation-name: pulsateAnimation;
  animation-timing-function: ease-in-out
}

.pulsate-transparent {
  animation-name: pulsateTransparentAnimation
}

.no-pulsate {
  background-color: #333
}

.pulsateTransition-enter {
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition-duration: .2s;
  transition-property: opacity;
  transition-timing-function: ease-out;
  z-index: 10000
}

.pulsateTransition-enter.pulsateTransition-enter-active {
  opacity: 1
}

.pulsateTransition-leave {
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition-duration: .5s;
  transition-property: opacity;
  transition-timing-function: ease-out
}

.pulsateTransition-leave.pulsateTransition-leave-active {
  opacity: 0
}

.lolomo {
  overflow: hidden;
  padding: 0 0 50px;
  z-index: 0
}

.lolomo.has-open-jaw {
  padding: 0 0 39vw
}

@media screen and (max-width: 499px) {
  .lolomo.has-open-jaw {
    padding: 0 0 44vw
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .lolomo.has-open-jaw {
    padding: 0 0 42vw
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .lolomo.has-open-jaw {
    padding: 0 0 44vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .lolomo.has-open-jaw {
    padding: 0 0 39vw
  }
}

@media screen and (min-width: 1400px) {
  .lolomo.has-open-jaw {
    padding: 0 0 34vw
  }
}

.lolomoSpinLoader {
  top: 47%
}

.lolomoSpinLoader, .recently-watched-label {
  position: absolute;
  text-align: center;
  width: 100%
}

.recently-watched-label {
  top: 0
}

.recently-watched-label a {
  color: #fff
}

.lolomoRow {
  outline: 0;
  position: relative
}

.lolomoRow.lolomoRow_billboard {
  margin: 0
}

.lolomoRow.lolomoRow_billboard>.slick-slider {
  min-height: 500px
}

.lolomoRow.lolomoRow_medium_card {
  margin: 0 0 20px 20px;
  position: relative
}

.lolomoRow.lolomoRow_medium_card .slick-arrow {
  bottom: 40px;
  top: 40px
}

.lolomoRow.lolomoRow_medium_card>.slick-slider {
  min-height: 305px;
  padding: 40px 0
}

.lolomoRow.lolomoRow_title_card {
  box-sizing: border-box;
  margin: 3vw 0;
  padding: 0
}

.lolomoRow.lolomoRow_title_card:hover {
  z-index: 3
}

.lolomoRow.lolomoPreview .rowContent {
  overflow: hidden;
  white-space: nowrap
}

.lolomoRow.lolomoPreview .loadingTitle {
  border-left: 2px solid #141414;
  border-right: 2px solid #141414;
  box-sizing: border-box;
  display: inline-block
}

.lolomoRow .pagination-indicator {
  display: none !important;
  list-style-type: none;
  margin: -24px 0 12px;
  padding: 0;
  position: absolute;
  right: 4%;
  top: 0
}

@media screen and (min-width: 1500px) {
  .lolomoRow .pagination-indicator {
    right: 62px
  }
}

.lolomoRow .pagination-indicator li {
  background-color: #4d4d4d;
  display: inline-block;
  height: 2px;
  margin-left: 1px;
  width: 12px
}

.lolomoRow .pagination-indicator .slick-active {
  background-color: #aaa
}

.lolomoRow .slick-slider:hover .pagination-indicator {
  display: block !important
}

.lolomoRow.lolomoRow_medium_card .rowTitle {
  position: absolute;
  top: 8px
}

.lolomoRow.lolomoRow_title_card .rowTitle {
  color: #e5e5e5;
  display: inline-block;
  font-size: 1.4vw;
  font-weight: 500;
  margin: 0 4% .5em;
  min-width: 6em;
  text-decoration: none
}

@media screen and (min-width: 1500px) {
  .lolomoRow.lolomoRow_title_card .rowTitle {
    margin-left: 60px
  }
}

@media screen and (max-width: 800px) {
  .lolomoRow.lolomoRow_title_card .rowTitle {
    font-size: 12px
  }
}

.lolomoRow.lolomoRow_title_card .rowTitle .row-header-title {
  display: table-cell;
  font-size: 1.4vw;
  line-height: 1.25vw;
  vertical-align: bottom
}

@media screen and (max-width: 800px) {
  .lolomoRow.lolomoRow_title_card .rowTitle .row-header-title {
    font-size: 12px
  }
}

.lolomoRow.lolomoRow_title_card .rowTitle .rowChevron {
  display: none;
  font-size: .9vw;
  font-weight: 500;
  margin-left: .7em
}

.lolomoRow.lolomoRow_title_card .rowTitle .aro-row-chevron {
  display: none;
  font-size: .9vw;
  transition: transform .75s;
  vertical-align: bottom
}

.lolomoRow.lolomoRow_title_card .rowTitle .aro-row-header {
  display: table-cell;
  vertical-align: bottom
}

.lolomoRow.lolomoRow_title_card .rowTitle .aro-row-header .see-all-link {
  cursor: pointer;
  display: inline-block;
  font-size: .9vw;
  line-height: .8vw;
  margin-right: 4px;
  max-width: 0;
  opacity: 0;
  transition: max-width 1s, opacity 1s, transform .75s;
  vertical-align: bottom;
  white-space: nowrap
}

@media screen and (max-width: 800px) {
  .lolomoRow.lolomoRow_title_card .rowTitle .aro-row-header .see-all-link {
    font-size: 8px
  }
}

.lolomoRow.lolomoRow_title_card .rowTitle .aro-row-header.more-visible .aro-row-chevron, .lolomoRow.lolomoRow_title_card .rowTitle .aro-row-header.more-visible .see-all-link {
  color: #54b9c5
}

.lolomoRow.lolomoRow_title_card a.rowTitle:focus, .lolomoRow.lolomoRow_title_card:hover a.rowTitle:hover {
  color: #fff
}

.lolomoRow.lolomoRow_title_card a.rowTitle:focus .aro-row-header .see-all-link, .lolomoRow.lolomoRow_title_card:hover a.rowTitle:hover .aro-row-header .see-all-link {
  max-width: 200px;
  opacity: 1;
  transform: translate(1vw)
}

.lolomoRow.lolomoRow_title_card a.rowTitle:focus .aro-row-header .aro-row-chevron, .lolomoRow.lolomoRow_title_card:hover a.rowTitle:hover .aro-row-header .aro-row-chevron {
  font-size: .65vw;
  line-height: .8vw;
  transform: translate(1vw)
}

@media screen and (max-width: 800px) {
  .lolomoRow.lolomoRow_title_card a.rowTitle:focus .aro-row-header .aro-row-chevron, .lolomoRow.lolomoRow_title_card:hover a.rowTitle:hover .aro-row-header .aro-row-chevron {
    font-size: 6px
  }
}

.lolomoRow.lolomoRow_title_card a.rowTitle:focus .rowChevron, .lolomoRow.lolomoRow_title_card:hover a.rowTitle .rowChevron {
  display: inline-block
}

.lolomoRow.lolomoRow_title_card a.rowTitle:focus .aro-row-chevron, .lolomoRow.lolomoRow_title_card:hover a.rowTitle .aro-row-chevron {
  display: inline-block;
  font-size: .9vw;
  vertical-align: bottom
}

.lolomoRow.lolomoRow_title_card .originals-row-title {
  color: #e5e5e5;
  font-size: 1.2vw
}

@media screen and (max-width: 800px) {
  .lolomoRow.lolomoRow_title_card .originals-row-title {
    font-size: 12px
  }
}

.lolomoRow.lolomoPreview .rowTitle {
  background-color: #1a1a1a
}

.lolomoRow .rowHeader {
  line-height: 1.3;
  margin: 0
}

.lolomoRow .onboarding-row-header-title {
  color: #e5e5e5;
  display: table-cell;
  font-size: 1.4vw;
  font-weight: 500;
  line-height: 1.25vw;
  margin: 0 0 .5em .5em;
  width: 100%
}

@media screen and (max-width: 800px) {
  .lolomoRow .onboarding-row-header-title {
    font-size: 12px
  }
}

.lolomoHeader {
  min-height: 44px;
  padding: 36px 0 24px 60px
}

.lolomoHeader .title {
  font-size: 36px
}

.bigRow {
  background-size: cover;
  padding: 34.6875vw 0 0;
  position: relative
}

.bigRow .bigRowItem {
  padding: 0 4%
}

@media screen and (min-width: 1500px) {
  .bigRow .bigRowItem {
    padding: 0 60px
  }
}

.bigRow .bigRowItem .nf-flat-button .nf-flat-button-icon.svg-icon {
  flex-shrink: 0;
  height: 1.4vw;
  margin-bottom: 0;
  width: 1.4vw
}

.bigRow .bigRowItem .Billboard__replay-trailer-button.svg-icon {
  background: rgba(0, 0, 0, .25);
  border: .1vw solid hsla(0, 0%, 100%, .5);
  border-radius: 50%;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 2.4vw;
  padding: .3vw;
  pointer-events: auto;
  transition: transform .15s ease;
  width: 2.4vw
}

.bigRow .bigRowItem .Billboard__replay-trailer-button.svg-icon:hover {
  border-color: #fff;
  outline: 0;
  transform: scale(1.13)
}

.bigRow .bigRowItem .maturity-rating {
  align-items: center;
  background-color: rgba(51, 51, 51, .6);
  border: 3px #dcdcdc;
  border-left-style: solid;
  box-sizing: border-box;
  display: flex;
  font-size: 1.1vw;
  height: 2.4vw;
  padding: .5vw 3.5vw .5vw .8vw
}

.bigRow .bigRowItem .maturity-rating .maturity-number {
  border-width: 0;
  padding: 0
}

.bigRow .bigRowItem .maturity-rating:empty {
  visibility: hidden
}

.bigRow .bigRowItem .background-image {
  background-position: top;
  background-size: cover;
  height: 40.078125vw;
  left: 0;
  opacity: .1;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%
}

.bigRow .bigRowItem .background-image:after, .bigRow .bigRowItem .background-image:before {
  content: "";
  height: 5.46875vw;
  left: 0;
  position: absolute;
  width: 100%
}

.bigRow .bigRowItem .background-image:before {
  background-image: linear-gradient(0deg, hsla(0, 0%, 8%, 0) 0, #141414);
  top: 0
}

.bigRow .bigRowItem .background-image:after {
  background-image: linear-gradient(180deg, hsla(0, 0%, 8%, 0) 0, #141414);
  bottom: 0
}

.bigRow .video-component-container {
  border-radius: 8px;
  height: 30.3125vw;
  overflow: hidden;
  position: absolute;
  top: 3.125vw;
  width: 53.90625vw
}

.bigRow .video-component-container .foreground-image {
  height: auto;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity .25s ease-in-out;
  width: 100%;
  z-index: 3
}

.bigRow .video-component-container .foreground-image.show-foreground {
  opacity: 1
}

.bigRow .video-component-container .nfp.nf-player-container, .bigRow .video-component-container .nfp.nf-player-container .VideoContainer video {
  position: relative
}

.bigRow .video-component-container .button-container {
  align-items: center;
  bottom: 2.734375vw;
  display: flex;
  position: absolute;
  right: 0;
  z-index: 4
}

.bigRow .video-component-container .button-container .Billboard__replay-trailer-button {
  margin: 0 1.1vw 0 0;
  position: relative
}

.bigRow .video-component-container .button-container .audio-btn {
  margin-right: 1.1vw;
  opacity: 0;
  transition: opacity .2s linear
}

.bigRow .video-component-container .button-container .audio-btn.video-playing {
  opacity: 1
}

.bigRow .button-container>a {
  align-items: center;
  display: flex;
  justify-content: center
}

.bigRow .more-info-button, .bigRow .mylist-button {
  background-color: rgba(0, 0, 0, .4);
  background-image: none;
  border: 1px solid hsla(0, 0%, 100%, .4);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
  color: #fff;
  float: left;
  font-size: 1.1vw;
  font-weight: 500;
  margin-right: .75em;
  padding: .57em 1.35em
}

.bigRow .more-info-button .nf-icon-button-icon, .bigRow .more-info-button .playRing, .bigRow .mylist-button .nf-icon-button-icon, .bigRow .mylist-button .playRing {
  background-color: transparent;
  font-size: inherit;
  margin-right: .71em;
  transition: none
}

.bigRow .more-info-button .icon-button-mylist-added, .bigRow .more-info-button .icon-button-mylist-added-reverse, .bigRow .more-info-button .icon-button-remindme-added, .bigRow .more-info-button .icon-button-remindme-added-reverse, .bigRow .mylist-button .icon-button-mylist-added, .bigRow .mylist-button .icon-button-mylist-added-reverse, .bigRow .mylist-button .icon-button-remindme-added, .bigRow .mylist-button .icon-button-remindme-added-reverse {
  transform: scale(1.5)
}

.bigRow .more-info-button .nf-icon-button-label, .bigRow .mylist-button .nf-icon-button-label {
  margin: 0;
  padding: 0;
  top: auto
}

.bigRow .more-info-button:hover, .bigRow .mylist-button:hover {
  background-color: rgba(51, 51, 51, .4)
}

.bigRow .more-info-button:hover .nf-icon-button-icon, .bigRow .mylist-button:hover .nf-icon-button-icon {
  transform: scale(1)
}

.bigRow .more-info-button:hover .nf-icon-button-icon.icon-button-mylist-added, .bigRow .more-info-button:hover .nf-icon-button-icon.icon-button-mylist-added-reverse, .bigRow .more-info-button:hover .nf-icon-button-icon.icon-button-remindme-added, .bigRow .more-info-button:hover .nf-icon-button-icon.icon-button-remindme-added-reverse, .bigRow .mylist-button:hover .nf-icon-button-icon.icon-button-mylist-added, .bigRow .mylist-button:hover .nf-icon-button-icon.icon-button-mylist-added-reverse, .bigRow .mylist-button:hover .nf-icon-button-icon.icon-button-remindme-added, .bigRow .mylist-button:hover .nf-icon-button-icon.icon-button-remindme-added-reverse {
  transform: scale(1.5)
}

.bigRow .more-info-button, .bigRow .playLink {
  float: left;
  opacity: 1
}

.bigRow .more-info-button.hide, .bigRow .playLink.hide {
  display: none
}

.bigRow .billboard-links {
  display: flex;
  flex-wrap: wrap
}

.bigRow .billboard-links .icon-button-mylist-add-reverse:before, .bigRow .billboard-links .icon-button-mylist-add:before {
  content: "\e641"
}

.bigRow .billboard-links a {
  align-items: center;
  display: flex;
  justify-content: center
}

.bigRow .info {
  left: 60.9375vw;
  position: absolute;
  top: 3.125vw
}

.bigRow .info .title-logo {
  letter-spacing: -1px;
  width: 27.03125vw
}

.bigRow .info .title-logo.isRtl {
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 56%
}

.bigRow .info .billboard-title .title {
  font-size: 3.1rem;
  font-weight: 900;
  width: 29.6875vw
}

.bigRow .info .billboard-links.button-layer.forward-leaning {
  margin-bottom: 1.25vw;
  margin-top: 1.875vw
}

.bigRow .info .billboard-links.button-layer.forward-leaning .nf-flat-button.nf-flat-button-primary.playLink {
  display: none
}

.bigRow .info .supplemental-message {
  font-size: 1.7vw;
  margin-top: .46875vw;
  width: 27.03125vw
}

.bigRow .info .synopsis {
  font-size: 1.36vw;
  margin-top: .46875vw;
  width: 33.125vw
}

.billboard-row+.lolomoBigRow .bigRow .bigRowItem .background-image {
  background-color: #141414;
  background-image: none !important;
  opacity: 1
}

.billboard-row+.lolomoBigRow .bigRow .bigRowItem .background-image:before {
  display: none
}

.categoryRow .section-divider {
  height: 0;
  margin: 0 4%;
  width: auto
}

@media screen and (min-width: 1500px) {
  .categoryRow .section-divider {
    margin: 0 60px
  }
}

.categoryRow .slick-slider .slick-list .slick-track .slider-item {
  padding: 0 4px
}

@media screen and (max-width: 499px) {
  .categoryRow .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 33.333333%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .categoryRow .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 25%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .categoryRow .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 20%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .categoryRow .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 16.66666667%
  }
}

@media screen and (min-width: 1400px) {
  .categoryRow .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 14.28571429%
  }
}

.categoryRowCollapsible {
  max-height: 600px;
  overflow: hidden;
  transition: all .7s ease-out
}

.categoryRowCollapsible.collapsed {
  max-height: 45px
}

.categoryRowCollapsible .rowHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-right: 4%
}

@media screen and (min-width: 1500px) {
  .categoryRowCollapsible .rowHeader {
    padding-right: 60px
  }
}

.categoryRowCollapsible .rowHeader .rowTitle {
  margin-bottom: 0;
  margin-right: 4.2%
}

.dismiss-button {
  background: #3d3d3d;
  font-size: 12px
}

.categoryLabel {
  color: #fff;
  font-size: 16px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3
}

.categoryBanner, .categoryLabel {
  font-weight: 500;
  position: absolute
}

.categoryBanner {
  align-items: center;
  background: #3d3d3d;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  height: 70%;
  justify-content: space-between;
  margin-left: 3.8%;
  margin-right: 4%;
  opacity: 0;
  overflow: hidden;
  padding: 0 30px;
  transition: all .6s ease-out .2s;
  width: 89%
}

@media screen and (min-width: 1500px) {
  .categoryBanner {
    margin-left: 60px;
    padding: 0 60px;
    width: 87%
  }
}

@media screen and (min-width: 1800px) {
  .categoryBanner {
    margin-left: 60px;
    padding: 0 60px;
    width: 89%
  }
}

.categoryBanner .undo-button {
  cursor: pointer
}

.categoryBanner.visible {
  height: 45px;
  opacity: 1;
  z-index: 2
}

.collapsibleSection {
  animation: unshrinkRow 533ms cubic-bezier(.21, 0, .07, 1) forwards;
  margin-bottom: 30px;
  max-height: 60px;
  opacity: .2;
  overflow: hidden
}

@keyframes unshrinkRow {
  to {
    max-height: 400px;
    opacity: 1
  }
}

.collapsibleSection.collapsed {
  animation: shrinkRow 533ms cubic-bezier(.21, 0, .07, 1) forwards;
  margin-bottom: 0;
  max-height: 400px;
  opacity: 1;
  overflow: hidden
}

@keyframes shrinkRow {
  to {
    max-height: 60px;
    opacity: .2
  }
}

.dvds, .people, .suggestions {
  line-height: 1.6
}

.noResultsWrapper {
  text-align: center
}

.noResultsWrapper .noResults {
  display: inline-block;
  text-align: left
}

.rail {
  display: inline-block;
  font-size: 1.25vw;
  margin: 1em 0 0;
  min-height: 10px;
  width: 100%
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .rail {
    font-size: 1.0625vw
  }
}

@media screen and (min-width: 1400px) {
  .rail {
    font-size: .9375vw
  }
}

@media (max-width: 799px) {
  .rail {
    font-size: 11px
  }
}

.title-group-collection {
  display: inline-block;
  height: 1.1vw;
  margin-right: .25em;
  vertical-align: sub;
  width: 1.1vw
}

.people, .suggestions {
  display: flex;
  margin: 10px 0 20px 4%;
  overflow: hidden
}

@media screen and (min-width: 1500px) {
  .people, .suggestions {
    margin: 10px 0 20px 60px
  }
}

.people ul, .suggestions ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0
}

.people ul li, .suggestions ul li {
  border-right: 1px solid grey;
  flex: 0 auto;
  list-style: none;
  padding: 0 .5em
}

.people ul li a, .suggestions ul li a {
  color: #fff
}

.people ul li a:hover, .suggestions ul li a:hover {
  color: #009779;
  text-decoration: none
}

.people ul li:last-child, .suggestions ul li:last-child {
  border: none
}

.people ul li.selected a, .suggestions ul li.selected a {
  color: grey
}

.people .peopleLabel, .people .suggestionsLabel, .suggestions .peopleLabel, .suggestions .suggestionsLabel {
  color: grey;
  flex: 0 auto;
  margin-right: 5px;
  white-space: nowrap
}

.suggestionHeader {
  margin: 0 4%;
  padding: 36px 0
}

@media screen and (min-width: 1500px) {
  .suggestionHeader {
    margin: 0 60px
  }
}

.suggestionHeader .title {
  display: inline-block;
  font-size: 30px;
  line-height: 36px;
  margin-right: 20px
}

.suggestionRailContainer {
  display: flex
}

.search-title-header {
  min-height: 65px
}

.notifications {
  white-space: normal
}

.notifications .sub-menu-list {
  margin: 0
}

.notifications .notifications-menu {
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  line-height: 1;
  margin-top: .2em;
  padding: 2px 6px 3px;
  position: relative
}

.notifications .notifications-menu:focus {
  transform: scale(1.2)
}

.notifications .callout-arrow {
  border: 7px solid transparent;
  border-bottom-color: #e5e5e5;
  bottom: -17px;
  height: 0;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  width: 0
}

.notifications .notification-pill {
  font-size: .5em;
  line-height: 1;
  position: absolute;
  right: -.1em;
  top: -.25em;
  z-index: 2
}

.notifications .sub-menu {
  background-color: inherit;
  font-size: 16px;
  padding: 0;
  right: 0;
  top: 54px
}

.notifications .sub-menu .sub-menu-list {
  padding: 0
}

.notifications .notifications-container {
  display: block;
  list-style: none;
  max-height: 80vh;
  max-height: calc(100vh - 280px);
  overflow: auto;
  padding: 0;
  width: 408px
}

.notifications .notification {
  background-color: rgba(0, 0, 0, .85);
  border-bottom: 1px solid hsla(0, 0%, 100%, .25);
  color: #fff;
  line-height: 1;
  position: relative
}

.notifications .notification:hover {
  background: #000
}

.notifications .notification.is-new:before {
  background-color: #b9090b;
  bottom: 12px;
  content: "";
  left: 0;
  position: absolute;
  top: 12px;
  width: 4px
}

.notifications.direction-top .callout-arrow {
  border-color: #e5e5e5 transparent transparent;
  bottom: auto;
  top: -23px
}

.notifications.direction-top .sub-menu {
  bottom: 56px;
  top: auto
}

.image-text-notification {
  display: flex;
  flex-direction: row
}

.image-text-notification .element {
  flex: auto;
  padding: 16px
}

.image-text-notification .element+.element {
  padding-left: 0
}

.image-text-notification .text {
  color: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2;
  max-width: 264px
}

.image-text-notification .text:hover {
  color: #fff
}

.image-text-notification .age {
  color: grey;
  font-size: 12px;
  line-height: 1.3;
  margin-top: 3px
}

.image-text-notification .image {
  align-items: center;
  display: flex;
  flex-grow: 0;
  position: relative
}

.image-text-notification .image .title-card {
  border-radius: 4px;
  width: 112px
}

.image-text-notification .multi-landing-stack-space-holder {
  height: 63px;
  position: relative;
  width: 112px
}

.image-text-notification .multi-landing-stack-space-holder .multi-landing-stack {
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 2px 0 rgba(0, 0, 0, .12);
  height: 56px;
  position: absolute;
  width: 103px
}

.image-text-notification .multi-landing-stack-space-holder .multi-landing-stack-img {
  left: 0;
  top: 0;
  z-index: 3
}

.image-text-notification .multi-landing-stack-space-holder .multi-landing-stack-1 {
  background-color: #dbdbd7;
  left: 3px;
  top: 2px;
  z-index: 2
}

.image-text-notification .multi-landing-stack-space-holder .multi-landing-stack-2 {
  background-color: #c2c2be;
  left: 7px;
  top: 5px;
  z-index: 1
}

.empty-notification {
  align-content: center;
  color: #6d6d6d;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  min-height: 100px;
  padding: 50px 0;
  text-align: center
}

.play-action {
  background-color: rgba(0, 0, 0, .6);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .15s ease
}

.play-action:focus, .play-action:hover {
  opacity: 1
}

.play-action .playable-icon {
  border-radius: 50%;
  box-sizing: border-box;
  color: #fff;
  font-size: 44px;
  height: 48.4px;
  left: 50%;
  margin-left: -23.1px;
  margin-top: -23.1px;
  opacity: 1;
  outline: none;
  position: absolute;
  top: 50%;
  transition: all .15s ease;
  width: 48.4px;
  z-index: 100
}

.play-action .playable-icon:focus, .play-action .playable-icon:hover {
  opacity: 1;
  text-decoration: none;
  transform: scale(1)
}

.play-action .playable-icon:focus .playRing, .play-action .playable-icon:hover .playRing {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .5)
}

.play-action .playable-icon:focus .play, .play-action .playable-icon:hover .play {
  color: #009779
}

.play-action .playable-icon .annotation {
  bottom: -12.32px;
  font-size: 18%;
  font-weight: 500;
  left: -50%;
  position: absolute;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .7);
  white-space: nowrap;
  width: 200%
}

.play-action .playable-icon .playRing {
  border: 2.2px solid #fff;
  border-radius: 50%;
  height: 44px;
  position: absolute;
  width: 44px
}

.play-action .playable-icon .playRing:after {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .2);
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1
}

.play-action .playable-icon .play {
  color: #fff;
  font-size: 46%;
  left: 6%
    /*!rtl:ignore*/
  ;
  line-height: 44px;
  position: absolute;
  text-align: center;
  width: 100%
}

.play-action .playable-icon .playRing {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .5)
}

.play-action .playable-icon .play {
  color: #009779;
  line-height: 40px
}

.extended-diacritics-language .image-text-notification .text {
  line-height: 1.4
}

.notification-landing {
  margin-top: 20px;
  padding: 0 0 37vw
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .notification-landing {
    padding: 0 0 42vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .notification-landing {
    padding: 0 0 37vw
  }
}

@media screen and (min-width: 1400px) {
  .notification-landing {
    padding: 0 0 32vw
  }
}

.notification-landing-title {
  color: #fff;
  font-size: 32px;
  margin-top: 1vw;
  padding: 0 4%
}

@media screen and (min-width: 1500px) {
  .notification-landing-title {
    padding: 0 60px
  }
}

.notification-landing-summary {
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  margin-top: 1.5vw;
  padding: 0 4%
}

@media screen and (min-width: 1500px) {
  .notification-landing-summary {
    padding: 0 33vw 0 60px
  }
}

@media screen and (min-width: 1200px) {
  .notification-landing-summary {
    padding-right: 33vw
  }
}

.notification-landing-row.lolomoRow.lolomoRow_title_card {
  margin-top: 1.5vw
}

.notification-landing-back-to-home-container {
  text-align: center
}

.t_0fe09b .survey {
  position: relative
}

.t_0fe09b .survey .survey-header, .t_0fe09b .survey .survey-question-body, .t_0fe09b .survey .survey-question-header {
  font-weight: 500
}

.t_0fe09b .survey .survey-header {
  color: #999;
  font-size: 12px;
  margin: 18px 0 15px
}

.t_0fe09b .survey .survey-question-header {
  color: #ccc;
  font-size: 18px;
  margin: 3px 0
}

.t_0fe09b .survey .survey-question-body {
  color: #fff;
  font-size: 24px;
  margin: 3px 0
}

.t_0fe09b .survey .survey-options {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 45px auto;
  max-width: 500px
}

.t_0fe09b .survey .survey-label {
  color: #999;
  flex: 0 0 auto;
  font-size: 12px;
  width: 100px
}

.t_0fe09b .survey .survey-option-wrapper {
  background: none;
  flex: 1 1 auto
}

.t_0fe09b .survey .survey-option {
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px 0 3px
}

.t_0fe09b .survey .survey-option:after {
  background: #333;
  border-radius: 9999px;
  content: "";
  display: block;
  height: 42px;
  margin: 0 auto;
  transition: all .3s;
  width: 42px
}

.t_0fe09b .survey .survey-option:focus, .t_0fe09b .survey .survey-option:hover {
  outline: none
}

.t_0fe09b .survey .survey-option:focus:after, .t_0fe09b .survey .survey-option:hover:after {
  background: #e7ae39;
  transform: scale(1.2)
}

.t_0fe09b .survey .survey-option-label {
  display: block
}

.t_0fe09b .survey .survey-buttons {
  padding: 15px 0
}

.t_0fe09b .survey .survey-button {
  background: none;
  border: 1px solid #666;
  color: #959595;
  font-size: 15px;
  font-weight: 500;
  padding: 8px 25px;
  text-decoration: none
}

.t_0fe09b .survey .survey-button:focus, .t_0fe09b .survey .survey-button:hover {
  background: #141414;
  border-color: #fff;
  color: #fff;
  outline: none
}

.t_0fe09b .survey .survey-thanks {
  align-items: center;
  bottom: 0;
  display: flex;
  font-size: 24px;
  font-weight: 500;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0
}

.t_0fe09b .survey .survey-container, .t_0fe09b .survey .survey-thanks {
  transition: opacity .3s ease .3s
}

.t_0fe09b .survey .survey-container {
  opacity: 1
}

.t_0fe09b .survey .survey-thanks {
  opacity: 0;
  visibility: hidden
}

.t_0fe09b .survey.survey-complete .survey-container {
  opacity: 0
}

.t_0fe09b .survey.survey-complete .survey-thanks {
  opacity: 1;
  visibility: visible
}

.nf-modal.rating-modal .nf-modal-content {
  border-top: 0;
  max-height: none;
  width: 65%
}

.nf-modal.rating-modal .nf-modal-content .nf-modal-content-wrapper {
  max-height: none
}

.nf-modal.rating-modal .nf-modal-hr {
  display: none
}

.nf-modal.rating-modal .nf-modal-body {
  overflow: hidden
}

.rating {
  flex-direction: column
}

.rating, .rating-overlay {
  display: flex
}

.rating-description {
  border-top: 2px solid #b9090b;
  color: #b3b3b3;
  margin-top: 1em;
  padding-bottom: 5px;
  width: 100%
}

.rating-description h2 {
  color: #fff;
  font-size: 1.9em;
  line-height: 1.1;
  margin: 0;
  padding-top: 4%
}

.rating-description p {
  margin: .5em 0 60px
}

.rating-description .rating-portion {
  padding-left: 3.5em
}

.rating-description .rating-headline-body {
  font-size: 1.6em
}

.rating-description h3 {
  color: #fff;
  margin: 0;
  position: relative
}

.rating-description .thumb {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 1em;
  left: -3em;
  padding: 10px;
  position: absolute;
  width: 1em
}

.extended-diacritics-language .rating-description h2 {
  line-height: 1.3
}

@media screen and (max-width: 1000px) {
  .nf-modal.rating-modal .nf-modal-content {
    width: 75%
  }
}

@media screen and (max-width: 600px) {
  .rating-overlay {
    flex-direction: column-reverse
  }

  .rating-description {
    border-bottom: 1px solid #333;
    border-left: 0;
    margin: 0 0 8%;
    padding: 0 0 8%;
    text-align: center
  }

  .rating-welcome-description {
    border-top: 0
  }

  .nf-modal.rating-modal .nf-modal-content {
    border-top: 4px solid #b9090b;
    width: 100%
  }
}

@media screen and (min-aspect-ratio: 5/2) {
  .rating-details {
    font-size: .7em;
    margin-bottom: 2%
  }

  .rating-header {
    font-size: 1em;
    margin: .3em 0
  }

  .rating-description .rating-headline-body, .rating-description h2 {
    font-size: 1.3em
  }
}

.nf-modal.ix-restart-modal .nf-modal-content .nf-modal-content-wrapper {
  text-align: center
}

.nf-modal.ix-restart-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-body p {
  color: #b3b3b3;
  margin-top: 0;
  padding-top: 0
}

.nf-modal.ix-restart-modal .nf-modal-content .nf-modal-content-wrapper .nf-modal-footer {
  border-top: 1px solid #333;
  margin-top: 2%;
  padding-top: 4%
}

.nf-modal.ix-restart-modal .nf-modal-hr {
  display: none
}

.payment-hold-modal {
  text-align: center
}

.payment-hold-modal .nf-modal-hr {
  display: none
}

.payment-hold-modal .nf-modal-heading {
  font-size: 1.8em;
  margin: 0
}

.payment-hold-modal .nf-modal-body {
  margin-bottom: 1.5em;
  overflow: visible;
  padding: 0 1.5em
}

.payment-hold-modal .nf-modal-footer {
  border-bottom: 1px solid #333;
  border-top: 1px solid #333;
  padding: 1.5em 0
}

.payment-hold-modal .simple-notification {
  top: 0
}

.payment-hold-body {
  margin: 1.2em 0
}

.payment-hold-footer {
  align-items: center;
  display: flex;
  padding: 0 1.5em;
  text-align: left
}

@media screen and (max-width: 800px) {
  .payment-hold-footer {
    flex-direction: column;
    text-align: center
  }
}

.payment-hold-tease {
  color: #b3b3b3;
  margin: 0 0 0 1.5em
}

@media screen and (max-width: 800px) {
  .payment-hold-tease {
    margin: 1em 0 0
  }
}

.show-all-plans {
  cursor: pointer;
  display: block;
  margin-top: 20px;
  text-decoration: underline
}

.plan-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 15px;
  text-align: center
}

@media only screen and (min-width: 500px) {
  .plan-list {
    flex-direction: row
  }
}

.plan-list .plan-icon-container {
  align-self: center;
  height: 22px;
  width: 22px
}

.plan-list .plan-name-container {
  margin-left: 15px;
  text-align: left
}

@media only screen and (min-width: 500px) {
  .plan-list .plan-name-container {
    margin-left: 0;
    margin-top: 10px;
    text-align: center
  }
}

.plan-list .plan-element-container {
  align-items: center;
  background-color: #333;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  margin: 0 3px;
  overflow: hidden;
  padding: 5px 10px 10px;
  word-break: break-word
}

@media only screen and (min-width: 500px) {
  .plan-list .plan-element-container {
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 10px 30px
  }
}

.plan-list .plan-element-container .plan-name {
  margin-top: 5px;
  text-transform: uppercase
}

.plan-list .plan-element-container .plan-price {
  margin-top: 10px
}

.plan-list .plan-element-container.selected, .plan-list .plan-element-container:hover {
  background-color: #4d4d4d
}

.plan-video-artwork-container {
  display: flex
}

.plan-video-artwork {
  flex: 1;
  height: 50px;
  margin: 0 3px
}

@media only screen and (min-width: 500px) {
  .plan-video-artwork {
    height: 100px
  }
}

.plan-video-artwork-ph {
  background: linear-gradient(270deg, #333, #000);
  flex: 1;
  margin: 0 5px;
  width: 100px
}

.plan-video-artwork-ph.last {
  background: linear-gradient(90deg, #333, #000)
}

.nf-modal-body a {
  text-decoration: underline
}

.nf-modal-body a.plan-show-all {
  font-size: 1em
}

.nf-modal-body .btn-bar a {
  text-decoration: none
}

.plan-disclaimer {
  margin-top: 10px
}

.plan-disclaimer, .plan-disclaimer a {
  color: #999;
  font-size: 1em
}

.plan-disclaimer.white, .plan-disclaimer.white a {
  color: #fff
}

.plan-show-all {
  display: block;
  margin: 20px 0 40px
}

.modal-btn, .plan-title {
  margin: 20px 0
}

.extended-diacritics-language .eog-container {
  line-height: 1.4
}

.gallery-modal .sortGallery {
  float: none
}

@media screen and (max-width: 499px) {
  .gallery-modal .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 100%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .gallery-modal .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 50%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .gallery-modal .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 33.333333%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .gallery-modal .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 25%
  }
}

@media screen and (min-width: 1400px) {
  .gallery-modal .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 20%
  }
}

.profiles-gate-container .profile-lolopi-header {
  background: hsla(0, 0%, 8%, .9);
  display: flex;
  justify-content: space-between;
  left: 0;
  margin: 0 auto 1vw;
  max-width: 87vw;
  padding: 0 4vw 1vw;
  position: absolute;
  right: 0;
  top: 7vw;
  width: 100%;
  z-index: 3
}

.profiles-gate-container .profile-lolopi-header .lolopi-go-back, .profiles-gate-container .profile-lolopi-header .lolopi-profile {
  align-items: center;
  display: flex
}

.profiles-gate-container .profile-lolopi-header .lolopi-profile {
  font-size: 2vw
}

.profiles-gate-container .profile-lolopi-header .lolopi-profile-name {
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.profiles-gate-container .profile-lolopi-header img {
  border-radius: 4px;
  height: 6vw;
  margin-left: 20px;
  max-height: 90px;
  max-width: 90px;
  min-height: 40px;
  min-width: 40px;
  width: 6vw
}

.profiles-gate-container .profile-lolopi-header .nf-modal-dismiss-btn {
  background: none;
  border: none;
  font-size: 2vw;
  padding: 0
}

.profiles-gate-container .profile-lolopi-header .nf-modal-dismiss-btn:focus {
  outline: auto
}

.profiles-gate-container .profile-lolopi-header .icon-reverse {
  display: block;
  transform: rotate(180deg)
}

.profiles-gate-container .profile-lolopi-header .profile-button {
  margin: 0 20px 0 0
}

.profiles-gate-container .profile-lolopi-header .lolopi-header {
  margin-left: 2vw;
  text-align: left
}

.profiles-gate-container .profile-lolopi-header .lolopi-header .header-text, .profiles-gate-container .profile-lolopi-header .lolopi-header .sub-header-text {
  margin: 0;
  padding: 0
}

.profiles-gate-container .profile-lolopi-header .lolopi-header .header-text {
  font-size: 2.5vw
}

.profiles-gate-container .profile-lolopi-header .lolopi-header .sub-header-text {
  font-size: 2vw
}

.profiles-gate-container .profile-lolopi-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 9vw;
  overflow: hidden;
  overflow-y: scroll;
  padding: 7vw 0;
  width: 100%
}

.profiles-gate-container .profile-lolopi-container .list-container {
  list-style: none;
  margin: 0;
  max-height: 100%;
  padding: 0;
  width: 100%
}

.profiles-gate-container .profile-lolopi-container .slick-slider {
  margin: 0 auto;
  max-width: 1320px;
  overflow: hidden;
  width: 88vw
}

.profiles-gate-container .profile-lolopi-container .slick-slider .slick-arrow {
  z-index: 2
}

.profiles-gate-container .profile-lolopi-container .slick-slider .slick-next {
  background: linear-gradient(270deg, #141414 0, hsla(0, 0%, 8%, .4))
}

.profiles-gate-container .profile-lolopi-container .slick-slider .slick-prev {
  background: linear-gradient(90deg, #141414 0, hsla(0, 0%, 8%, .4))
}

.profiles-gate-container .lolopi-row {
  margin-bottom: 3vw;
  width: 100%
}

.profiles-gate-container .lolopi-row .row-header {
  font-size: 2.4vw;
  margin: 0 auto 1vw;
  max-width: 1310px;
  padding: 0;
  text-align: left;
  width: 87%
}

.profiles-gate-container .lolopi-row .slick-track {
  display: flex
}

.profiles-gate-container .lolopi-row-icon {
  background-position-x: left;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 4vw;
  max-height: 60px;
  max-width: 20vw
}

.profiles-gate-container .lolopi-icon {
  align-items: center;
  background-color: transparent;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 10vw;
  margin: 0 .5vw;
  max-height: 200px;
  max-width: 200px;
  min-height: 40px;
  min-width: 40px;
  position: relative;
  width: 10vw
}

.profiles-gate-container .lolopi-icon:focus {
  outline: none
}

.profiles-gate-container .lolopi-icon:focus:after, .profiles-gate-container .lolopi-icon:hover:after {
  border: 2px solid #fff;
  border-radius: 4px;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.profiles-gate-container .lolopi-icon-disabled {
  cursor: default;
  filter: grayscale(100%);
  opacity: .3
}

.profiles-gate-container .lolopi-icon-disabled:hover {
  border: 2px solid transparent
}

.profiles-gate-container .lolopi-row-medium {
  margin-bottom: 4vw
}

.profiles-gate-container .lolopi-row-medium .row-header {
  font-size: 3.8vw;
  margin: 0 auto 1.5vw
}

.profiles-gate-container .lolopi-row-medium .lolopi-icon {
  height: 13.66vw;
  width: 13.66vw
}

.profiles-gate-container .lolopi-row-medium .lolopi-row-icon {
  height: 6vw;
  max-width: 25vw
}

.profiles-gate-container .lolopi-row-narrow {
  margin-bottom: 5vw
}

.profiles-gate-container .lolopi-row-narrow .row-header {
  font-size: 5vw;
  margin: 0 auto 2vw
}

.profiles-gate-container .lolopi-row-narrow .lolopi-icon {
  height: 21vw;
  width: 21vw
}

.profiles-gate-container .lolopi-row-narrow .lolopi-row-icon {
  height: 8vw;
  max-width: 40vw
}

.profiles-gate-container .profile-lolopi-confirm .lolopi-confirm-buttons, .profiles-gate-container .profile-lolopi-confirm .lolopi-confirm-icons {
  display: flex;
  justify-content: center
}

.profiles-gate-container .profile-lolopi-confirm .lolopi-confirm-icons {
  align-items: center;
  border-bottom: 1px solid #333;
  border-top: 1px solid #333;
  margin: 1vw 0;
  padding: 3vw 0
}

.profiles-gate-container .profile-lolopi-confirm .profile-button {
  margin: 2em .5em 1em
}

.profiles-gate-container .profile-lolopi-confirm .lolopi-icon {
  margin-bottom: 1em
}

.profiles-gate-container .profile-lolopi-confirm .indicator-icon {
  font-size: 2vw;
  margin: 0 1vw 2vw
}

@media screen and (min-width: 1500px) {
  .profiles-gate-container .profile-lolopi-header {
    max-width: 1370px;
    padding: 0 4vw 1vw 0
  }

  .profiles-gate-container .profile-lolopi-header .lolopi-profile {
    font-size: 30px
  }

  .profiles-gate-container .profile-lolopi-header .lolopi-header {
    margin-left: 30px
  }

  .profiles-gate-container .profile-lolopi-header .lolopi-header .header-text {
    font-size: 37.5px
  }

  .profiles-gate-container .profile-lolopi-header .lolopi-header .sub-header-text {
    font-size: 30px
  }

  .profiles-gate-container .lolopi-row-icon {
    height: 60px;
    max-width: 300px
  }

  .profiles-gate-container .lolopi-icon {
    height: 150px;
    margin: 0 7.5px;
    width: 150px
  }
}

@media screen and (max-width: 500px) {
  .profiles-gate-container .profile-lolopi-header {
    top: 50px
  }

  .profiles-gate-container .profile-lolopi-header .lolopi-profile {
    font-size: 16px
  }

  .profiles-gate-container .profile-lolopi-header .lolopi-header .header-text {
    font-size: 20px
  }

  .profiles-gate-container .profile-lolopi-header .lolopi-header .sub-header-text {
    font-size: 16px
  }

  .profiles-gate-container .profile-lolopi-header .nf-modal-dismiss-btn {
    font-size: 15px
  }

  .profiles-gate-container .profile-lolopi-container {
    margin-top: 75px;
    padding: 50px 0
  }
}

.profile-pin-dismiss {
  position: absolute;
  right: 30px;
  top: 100px
}

.profile-pin-dismiss svg {
  color: #ccc;
  height: 2vw;
  width: 2vw
}

.profile-pin-dismiss a {
  display: block
}

.profile-pin-prompt-status {
  color: #aaa;
  font-size: 1.3vw
}

.profile-pin-prompt-label {
  color: #fff;
  font-size: 2.5vw;
  margin: .5em 0 1em;
  opacity: 1;
  transition: opacity .4s ease-out;
  width: 100%
}

@media screen and (max-width: 800px) {
  .profile-pin-prompt-label {
    font-size: 30px
  }
}

.profile-pin-prompt-validating {
  opacity: 0
}

.profile-pin-prompt-container {
  bottom: 0;
  right: 0
}

.profile-pin-prompt, .profile-pin-prompt-container {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.profile-pin-prompt {
  align-items: center;
  background: rgba(0, 0, 0, .8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity .3s ease-out
}

.profile-pin-prompt .pin-input-container {
  border: none;
  color: #000;
  direction: ltr
    /*!rtl:ignore*/
  ;
  display: flex;
  flex: 0 1 auto;
  margin: .5em
}

.profile-pin-prompt .player-pin-reset-link-container {
  bottom: 0;
  display: flex;
  flex: 0 1 auto;
  font-size: 1em;
  justify-content: center;
  left: 0;
  padding: .75em 0;
  position: absolute;
  width: 100%;
  z-index: 1
}

.profile-pin-prompt .player-pin-reset-actions {
  display: flex;
  flex: 0 1 auto;
  height: 2em;
  padding: .75em 0 1em
}

.profile-pin-prompt .pin-number-input {
  background: transparent;
  border: .05em solid #fff;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  font-size: 2.5em;
  height: 2.5em;
  margin: .2em;
  padding: .2em;
  text-align: center;
  transition: transform .1s ease-out;
  width: 2.5em
}

.profile-pin-prompt .pin-number-input:focus {
  outline: none;
  transform: scale(1.1)
}

.profile-pin-prompt-forgot {
  bottom: 5vw;
  font-size: 1.3vw;
  position: absolute
}

.profile-pin-prompt-forgot .nf-flat-button {
  color: #ccc
}

.profile-pin-prompt-title {
  flex: 0 1 auto;
  font-size: 1.7em;
  font-weight: 400;
  margin: .5em 0;
  text-align: center
}

.profile-pin-prompt-invalid .profile-pin-prompt-pad-wrapper {
  animation: shakeit .3s 1;
  animation-delay: .3s
}

.profile-pin-prompt-invalid .profile-pin-prompt-label {
  color: #e6b209
}

.pin-input-error {
  color: #ea3841;
  font-size: 1.3vw;
  min-height: 30px
}

.profile-pin-prompt-pad-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column
}

@keyframes animateProfileGate {
  0% {
    opacity: 0;
    transform: scale(1.1)
  }

  to {
    opacity: 1;
    transform: scale(1)
  }
}

.body-profiles-gate-overflow {
  overflow-x: hidden
}

.profiles-gate-container {
  background: #141414;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 0
}

.profiles-gate-container .centered-div {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100
}

.profiles-gate-container--scroll {
  position: static
}

.profiles-gate-container .centered-div.list-profiles-container:not(.js-transition-node) {
  animation: animateProfileGate .45s forwards
}

.profiles-gate-container .centered-div.loading-wrapper {
  opacity: 1
}

@media screen and (max-width: 400px) {
  .profiles-gate-container {
    max-width: none
  }
}

.profiles-gate-container ul {
  padding: 0
}

.profiles-gate-container .avatar-container {
  padding: 5px
}

.profiles-gate-container .profile-icon {
  background-color: #333;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  height: 10vw;
  max-height: 200px;
  max-width: 200px;
  min-height: 84px;
  min-width: 84px;
  position: relative;
  text-decoration: none;
  width: 10vw
}

.profiles-gate-container .profile-icon:after {
  border: .3em solid transparent;
  border-radius: 4px;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.profiles-gate-container .activeProfile {
  border-color: #e5e5e5
}

.profiles-gate-container .profile-edit-mode {
  opacity: .5
}

.profiles-gate-container .profile-name {
  color: grey;
  display: block;
  font-size: 1.3vw;
  line-height: 1.2em;
  margin: .6em 0;
  min-height: 1.8em;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis
}

@media screen and (min-width: 1666px) {
  .profiles-gate-container .profile-name {
    font-size: 24px
  }
}

@media screen and (max-width: 800px) {
  .profiles-gate-container .profile-name {
    font-size: 12px
  }
}

.profiles-gate-container li {
  display: inline-block;
  position: relative;
  vertical-align: top
}

.profiles-gate-container li.profile {
  max-width: 200px;
  min-width: 84px;
  width: 10vw
}

.profiles-gate-container li.profile:not(:last-child) {
  margin: 0 2vw 0 0
}

.profiles-gate-container li.profile .svg-icon-profile-lock {
  color: #666;
  height: 20px;
  width: 20px
}

.profiles-gate-container li a {
  text-decoration: none
}

.profiles-gate-container li a.focused, .profiles-gate-container li a:focus, .profiles-gate-container li a:hover {
  cursor: pointer
}

.profiles-gate-container li a.focused .profile-icon, .profiles-gate-container li a.focused .profile-icon:after, .profiles-gate-container li a:focus .profile-icon, .profiles-gate-container li a:focus .profile-icon:after, .profiles-gate-container li a:hover .profile-icon, .profiles-gate-container li a:hover .profile-icon:after {
  border-color: #e5e5e5
}

.profiles-gate-container li a.focused .profile-name, .profiles-gate-container li a:focus .profile-name, .profiles-gate-container li a:hover .profile-name {
  color: #e5e5e5
}

.profiles-gate-container li a.focused .addProfileIcon, .profiles-gate-container li a:focus .addProfileIcon, .profiles-gate-container li a:hover .addProfileIcon {
  background-color: #e5e5e5;
  border-radius: 4px
}

.profiles-gate-container .addProfileIcon {
  align-items: center;
  color: grey;
  display: flex;
  font-size: 5vw;
  height: 10vw;
  justify-content: center;
  max-height: 200px;
  max-width: 200px;
  min-height: 84px;
  min-width: 84px;
  text-align: center;
  text-decoration: none;
  width: 10vw
}

@media screen and (min-width: 1666px) {
  .profiles-gate-container .addProfileIcon {
    font-size: 100px
  }
}

@media screen and (max-width: 865px) {
  .profiles-gate-container .addProfileIcon {
    font-size: 50px
  }
}

.profiles-gate-container .profiles-gate__games-handle__modal {
  top: -70px
}

.profiles-gate-container .profile-edit-parent {
  max-width: 500px
}

.extended-diacritics-language .profiles-gate-container .profile-name {
  line-height: 1.5
}

.profile-button {
  background-color: transparent;
  border: 1px solid grey;
  color: grey;
  cursor: pointer;
  display: block;
  font-size: 1.2vw;
  letter-spacing: 2px;
  margin: 2em 0 1em;
  padding: .5em 1.5em
}

@media screen and (max-width: 800px) {
  .profile-button {
    font-size: 13px
  }
}

.profile-button:focus, .profile-button:hover {
  border: 1px solid #fff;
  color: #fff
}

.profile-button.preferred-action {
  background: #fff;
  border: 1px solid #fff;
  color: #000;
  font-weight: 500
}

.profile-button.preferred-action.preferred-active, .profile-button.preferred-action:focus, .profile-button.preferred-action:hover {
  background: #c00;
  border: 1px solid #c00;
  color: #fff
}

.kids-profile-tooltip {
  background: #fff;
  bottom: 3em;
  color: #000;
  font-size: 1vw;
  left: -6em;
  opacity: 0;
  padding: .8em;
  position: absolute;
  text-align: center;
  transition: opacity .3s linear 50ms, z-index 1ms linear .35s;
  width: 18em;
  z-index: -1
}

.kids-profile-tooltip.show-tooltip {
  opacity: 1;
  transition: opacity .3s linear .4s, z-index 1ms linear;
  z-index: 1
}

@media screen and (max-width: 800px) {
  .kids-profile-tooltip {
    bottom: 35px;
    font-size: 9px;
    right: -49px;
    width: 160px
  }
}

.kids-profile-tooltip:after {
  border: .7em solid transparent;
  border-top-color: #fff;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -.7em;
  pointer-events: none;
  position: absolute;
  top: 100%;
  width: 0
}

.svg-icon-edit {
  fill: #fff;
  height: 3rem;
  width: 3rem
}

.extended-diacritics-language .profile-button a, .extended-diacritics-language .profile-button span {
  letter-spacing: normal
}

.animation-confetti {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1
}

.list-profiles {
  max-width: 80%
}

.list-profiles .choose-profile, .list-profiles .profile-gate-label {
  opacity: 1;
  transition: opacity .4s ease-out
}

.list-profiles .choose-profile {
  margin: 2em 0
}

.list-profiles .profile-gate-label {
  color: #fff;
  font-size: 3.5vw;
  font-weight: unset;
  width: 100%
}

@media screen and (max-width: 800px) {
  .list-profiles .profile-gate-label {
    font-size: 30px
  }
}

.list-profiles .avatar-wrapper {
  position: relative
}

.list-profiles .svg-edit-overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.list-profiles .svg-edit-overlay .svg-icon-edit {
  height: 3rem;
  max-height: 70px;
  max-width: 70px;
  width: 3rem
}

.loading-profile-wrapper {
  opacity: 0
}

.loading-profile-wrapper .akira-spinner {
  font-size: 28.8vw;
  left: 50%;
  margin-left: -14.4vw;
  margin-top: -14.4vw;
  position: absolute;
  top: 50%
}

@media screen and (max-width: 865px) {
  .loading-profile-wrapper .akira-spinner {
    font-size: 249.6px;
    margin-left: -124.8px;
    margin-top: -124.8px
  }
}

@media screen and (min-width: 1666px) {
  .loading-profile-wrapper .akira-spinner {
    font-size: 480px;
    margin-left: -240px;
    margin-top: -240px
  }
}

.loading-profile-wrapper .errorLoading {
  opacity: 0;
  transition: opacity .2s linear
}

.profile-load-error {
  color: grey;
  font-size: 1.4vw;
  margin-top: 4vw;
  opacity: 0;
  transition: opacity .2s cubic-bezier(.5, 0, .1, 1)
}

.profile-load-error.show-error {
  opacity: 1
}

@media screen and (max-width: 865px) {
  .profile-load-error {
    font-size: 14px;
    margin-top: 30px
  }
}

@media screen and (min-width: 1666px) {
  .profile-load-error {
    margin-top: 50px
  }
}

.profile-actions-container {
  position: relative;
  text-align: left
}

.profile-actions-container h1, .profile-actions-container h2, .profile-actions-container h3 {
  font-weight: 400
}

.profile-actions-container h1 {
  font-size: 4vw;
  margin: 0
}

@media screen and (max-width: 800px) {
  .profile-actions-container h1 {
    font-size: 40px
  }
}

.profile-actions-container h2 {
  color: #666;
  font-size: 1.3vw
}

@media screen and (max-width: 800px) {
  .profile-actions-container h2 {
    font-size: 13px
  }
}

.profile-actions-container h3 {
  color: #ccc;
  font-size: 1.2vw;
  margin-bottom: .4em
}

.profile-actions-container h2.profile-entry-header {
  color: #ccc;
  margin: 0 0 7px
}

.profile-actions-container h2.profile-entry-header span, .profile-actions-container h2.profile-entry-header svg {
  vertical-align: middle
}

.profile-actions-container .profile-button {
  display: inline-block;
  margin-right: 20px
}

.profile-entry {
  border-bottom: 1px solid #333;
  border-top: 1px solid #333
}

.profile-entry input[type=text] {
  background: #666;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: #fff;
  font-size: 1.3vw;
  height: 2em;
  margin: 0 .8em 0 0;
  padding: .2em .6em;
  text-indent: .1vw;
  width: 18em
}

@media screen and (max-width: 800px) {
  .profile-entry input[type=text] {
    font-size: 13px
  }
}

.profile-entry input[type=text].name-error {
  border: 1px solid #ea3841
}

.profile-entry input[type=text]::-ms-clear {
  display: none
}

.profile-entry input[type=text]::-webkit-input-placeholder {
  color: #ccc
}

.profile-entry input[type=text]::-moz-placeholder {
  color: #ccc
}

.profile-entry input[type=text]:-ms-input-placeholder {
  color: #ccc
}

.profile-entry input[type=text]:focus {
  outline: none
}

.profile-entry .profile-edit-parent input[type=text] {
  margin: 0;
  width: 100%
}

.profile-entry label {
  display: inline
}

.profile-entry input[type=checkbox] {
  display: none
}

.profile-entry input[type=checkbox]+label, .profile-entry input[type=checkbox]+span {
  border: 1px solid #333;
  border-radius: 0;
  display: inline-block;
  font-size: .8vw;
  height: 2.5em;
  margin-right: .5em;
  position: relative;
  width: 2.5em
}

@media screen and (max-width: 800px) {
  .profile-entry input[type=checkbox]+label, .profile-entry input[type=checkbox]+span {
    font-size: 7px
  }
}

.profile-entry input[type=checkbox]:checked+label, .profile-entry input[type=checkbox]:checked+span {
  color: #99a1a7
}

.profile-entry.is-kids-profile input[type=checkbox]:checked+label {
  border: none
}

.profile-entry-error {
  color: #ea3841;
  font-size: 1vw;
  margin: 3px 0 0;
  width: 24rem
}

@media screen and (max-width: 800px) {
  .profile-entry-error {
    font-size: 13px;
    margin-left: 15px
  }
}

.profile-kids-warning {
  margin-bottom: 0;
  width: 24rem
}

@media screen and (max-width: 800px) {
  .profile-kids-warning {
    font-size: 13px;
    margin-left: 15px
  }
}

.profile-kids-warning.profile-kids-change-warning {
  color: #ea3841
}

.profile-metadata {
  display: flex;
  padding: 2em 0
}

.profile-metadata .pulsate, .profile-metadata img {
  border-radius: 4px;
  height: 8vw;
  max-height: 180px;
  max-width: 180px;
  min-height: 80px;
  min-width: 80px;
  width: 8vw
}

.profile-metadata img {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

.profile-metadata .profile-entry-inputs {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  vertical-align: middle
}

@media screen and (max-width: 800px) {
  .profile-metadata .profile-entry-inputs {
    margin-left: 15px
  }
}

.profile-metadata .main-profile-avatar {
  margin-right: 1.5vw;
  max-width: 180px;
  min-width: 80px;
  white-space: nowrap;
  width: 8vw
}

.profile-metadata .main-profile-avatar:last-child {
  margin-right: 0
}

.profile-metadata .main-profile-avatar .profile-name {
  margin-bottom: 0
}

.profile-metadata .main-profile-avatar img {
  border-radius: 4px
}

.profile-metadata .add-adult-option, .profile-metadata .add-kids-option {
  align-items: center;
  display: flex;
  margin: 5px 0;
  position: relative
}

.profile-metadata .add-adult-marker, .profile-metadata .add-kids-marker {
  font-size: 1.3vw;
  font-weight: 400
}

@media screen and (max-width: 800px) {
  .profile-metadata .add-adult-marker, .profile-metadata .add-kids-marker {
    font-size: 13px
  }
}

.profile-edit-inputs {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative
}

@media screen and (max-width: 800px) {
  .profile-edit-inputs {
    margin-left: 15px
  }
}

.profile-edit-inputs span {
  align-items: center;
  display: flex
}

.profile-entry-dropdowns {
  display: flex;
  flex-direction: column
}

.profile-entry-dropdowns .profileDropDown {
  margin-top: 1rem
}

.profile-entry-dropdowns .profileDropDown .nfDropDown.theme-lakira {
  min-width: 18rem
}

.profile-entry-dropdowns .profileDropDown .profileDropDown-label {
  color: #ccc;
  font-size: 1.3vw;
  margin-bottom: 7px
}

@media screen and (max-width: 1000px) {
  .profile-entry-dropdowns .profileDropDown .profileDropDown-label {
    font-size: 13px
  }
}

.profile-entry-dropdowns .profileDropDown .sub-menu-item a:hover {
  text-decoration: underline
}

@media screen and (max-width: 800px) {
  .profile-entry-dropdowns {
    margin-left: 15px
  }
}

.profile-entry-autoplay .autoplay-option {
  align-items: center;
  display: flex;
  font-size: .9vw;
  margin: 5px 0
}

.profile-entry-autoplay h2 {
  color: #ccc
}

.profile-entry-restrictions {
  border-top: 1px solid #333;
  font-size: 1vw;
  margin: 1.5vw 0 0;
  padding: 1.5vw 0 0
}

.profile-entry-restrictions .profile-maturity-label {
  background: #333;
  border-radius: 2px;
  font-weight: 500;
  margin-right: 5px;
  padding: 7px 10px;
  unicode-bidi: normal
    /*!rtl:plaintext*/
}

.profile-entry-restrictions .profile-button {
  font-size: 1vw;
  margin: 1em 0
}

.profile-entry-autoplay, .profile-entry-lock {
  border-top: 1px solid #333;
  margin: 1.5vw 0 0;
  padding: 1.5vw 0 0
}

.profile-entry-lock svg {
  height: 1.3vw;
  margin-right: 1vw;
  width: 1.3vw
}

.profile-delete-warning {
  font-size: 1.1vw;
  text-align: center;
  width: 25rem
}

@media screen and (max-width: 1000px) {
  .profile-delete-warning {
    font-size: 13px
  }
}

.grey-scale {
  filter: grayscale(1);
  transition: .5s cubic-bezier(.5, 0, .1, 1)
}

.avatar-container {
  background-color: #141414;
  border: 1px solid #666;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
  position: absolute;
  top: 13.9rem;
  width: 200px;
  z-index: 2
}

.avatar-container li {
  height: 100%;
  width: 25%
}

.avatar-container ul {
  padding: .3vw
}

.avatar-container img {
  border: 2px solid #141414;
  margin: 6%;
  vertical-align: top;
  width: 80%
}

.avatar-container img:hover {
  border: 2px solid #e5e5e5;
  cursor: pointer
}

.avatar-box {
  position: relative
}

.avatar-box .avatar-edit-icon {
  background-color: transparent;
  border: none;
  bottom: 7%;
  cursor: pointer;
  left: 7%;
  position: absolute
}

.avatar-box .avatar-edit-icon svg {
  background-color: rgba(0, 0, 0, .7);
  border-radius: 5rem;
  filter: drop-shadow(1px 1px 2px #000);
  height: 2rem;
  max-height: 45px;
  max-width: 45px;
  width: 2rem
}

.avatar-box>img {
  border-radius: 4px
}

.profile-delete-warning {
  align-items: center;
  display: flex;
  text-align: initial;
  width: 25vw
}

.profileSavingMask {
  align-items: center;
  background-color: #141414;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: .75;
  position: absolute;
  width: 100%;
  z-index: 10
}

.profileSavingMask .akira-spinner {
  font-size: 2.5em
}

.profile-add-parent {
  display: flex;
  flex-direction: column;
  justify-content: center
}

.profile-add-parent .profile-entry-error {
  margin-top: .5em
}

.t_33e568 .ProfileWarning--container {
  align-items: center;
  background: rgba(0, 0, 0, .85);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 50000
}

.t_33e568 .ProfileWarning--message-container {
  padding: 1rem
}

.t_33e568 .ProfileWarning--title {
  font-size: 3.6rem;
  font-weight: 400;
  margin: 0;
  padding: 0
}

.t_33e568 .ProfileWarning--message {
  font-size: 1.8rem;
  margin: 2rem 0;
  padding: 0
}

.t_33e568 .ProfileWarning--action-container {
  display: flex
}

.t_33e568 .ProfileWarning--action-button-refresh {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #bf1315;
  border: 1px solid transparent;
  color: #fff;
  font-size: 1.8rem;
  outline: none;
  padding: .75rem 1rem
}

.t_33e568 .ProfileWarning--action-button-refresh:focus, .t_33e568 .ProfileWarning--action-button-refresh:hover {
  border-color: #fff
}

.boxart-rounded {
  border-radius: .2vw
}

.boxart-rounded .fallback-text-container {
  border-radius: 4px
}

.boxart-size-16x9 {
  padding: 28.125% 0
}

.boxart-size-16x9, .boxart-size-32x9 {
  height: 0;
  overflow: hidden;
  position: relative;
  width: 100%
}

.boxart-size-32x9 {
  padding: 14.0625% 0
}

.boxart-size-1x2 {
  height: 0;
  overflow: hidden;
  padding: 200% 0 0;
  position: relative;
  width: 100%
}

.boxart-size-onboarding-title-in-row-header {
  height: 0;
  overflow: hidden;
  padding: 70% 0;
  position: relative;
  width: 100%
}

.boxart-size-2x1 {
  padding: 49% 0 0
}

.boxart-size-1x1, .boxart-size-2x1 {
  height: 0;
  overflow: hidden;
  position: relative;
  width: 100%
}

.boxart-size-1x1 {
  padding: 99% 0 0
}

.boxart-size-7x10 {
  height: 0;
  overflow: hidden;
  padding: 35.714285714% 0;
  position: relative;
  width: 100%
}

.boxart-size-7x10 .boxart-image-in-padded-container {
  bottom: 0;
  height: 100%;
  left: auto;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%
}

.boxart-size-7x10 .top-10-rank {
  bottom: 0;
  left: 0;
  position: absolute;
  right: auto;
  top: 0;
  width: 50%
}

.boxart-size-7x10 .fallback-text-container {
  align-items: center;
  display: flex;
  justify-content: center;
  left: auto;
  width: 50%
}

.boxart-size-7x10 .fallback-text {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  display: -webkit-box;
  font-size: 1.3em;
  margin: 5%;
  padding: 0;
  position: static;
  white-space: normal
}

.fallback-text-container {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
}

.fallback-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
  bottom: 0;
  box-sizing: border-box;
  left: 8%;
  margin: 0;
  overflow: hidden;
  padding: 0 5% 18% 0;
  position: absolute;
  right: 8%;
}

.new-label {
  font-size: 10px;
  font-weight: 700;
  color: white;
  background-color: #009779;
  position: absolute;
  bottom: 0;
  padding: 3px 10px;
  border-radius: 3px 3px 0 0;
  text-align: center;
  left: 20%;
  right: 20%;
  margin-bottom: 4px;
}

.boxart-image-in-padded-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.selected-onboarding-title {
  width: auto
}

.title-card {
  position: relative;
  z-index: 1
}

.title-card:not(.loadingTitle) {
  display: block
}

.title-card:focus {
  outline: none
}

.title-card.is-jaw-open {
  opacity: 1;
  visibility: visible;
  z-index: 2
}

.title-card.is-dimmed .boxart-container {
  opacity: .6
}

.title-card.is-disliked .bob-background, .title-card.is-disliked .bob-title-art, .title-card.is-disliked .boxart-container {
  filter: grayscale(100%)
}

.title-card.is-disliked .bob-title-art {
  opacity: .3;
  transition: all .35s cubic-bezier(.86, 0, .07, 1)
}

.title-card .title-card-play {
  border-radius: 50%;
  box-sizing: border-box;
  color: #fff;
  display: none;
  font-size: 4.3vw;
  height: 4.73vw;
  left: 50%;
  margin-left: -2.2575vw;
  margin-top: -2.2575vw;
  opacity: .7;
  outline: none;
  position: absolute;
  top: 50%;
  transition: all .15s ease;
  width: 4.73vw;
  z-index: 100
}

.title-card .title-card-play:focus, .title-card .title-card-play:hover {
  opacity: 1;
  text-decoration: none;
  transform: scale(1.08)
}

.title-card .title-card-play:focus .playRing, .title-card .title-card-play:hover .playRing {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .5)
}

.title-card .title-card-play:focus .play, .title-card .title-card-play:hover .play {
  color: #009779
}

.title-card .title-card-play .annotation {
  bottom: -1.204vw;
  font-size: 18%;
  font-weight: 500;
  left: -50%;
  position: absolute;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .7);
  white-space: nowrap;
  width: 200%
}

.title-card .title-card-play .playRing {
  border: .215vw solid #fff;
  border-radius: 50%;
  height: 4.3vw;
  position: absolute;
  width: 4.3vw
}

.title-card .title-card-play .playRing:after {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .2);
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1
}

.title-card .title-card-play .play {
  color: #fff;
  font-size: 46%;
  left: 6%
    /*!rtl:ignore*/
  ;
  line-height: 4.3vw;
  position: absolute;
  text-align: center;
  width: 100%
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .title-card .title-card-play {
    border-radius: 50%;
    box-sizing: border-box;
    color: #fff;
    font-size: 6vw;
    height: 6.6vw;
    margin-left: -3.15vw;
    margin-top: -3.15vw;
    opacity: .7;
    outline: none;
    position: absolute;
    transition: all .15s ease;
    width: 6.6vw;
    z-index: 100
  }

  .title-card .title-card-play:focus, .title-card .title-card-play:hover {
    opacity: 1;
    text-decoration: none;
    transform: scale(1.08)
  }

  .title-card .title-card-play:focus .playRing, .title-card .title-card-play:hover .playRing {
    background: none repeat scroll 0 0 rgba(0, 0, 0, .5)
  }

  .title-card .title-card-play:focus .play, .title-card .title-card-play:hover .play {
    color: #009779
  }

  .title-card .title-card-play .annotation {
    bottom: -1.68vw;
    font-size: 18%;
    font-weight: 500;
    left: -50%;
    position: absolute;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .7);
    white-space: nowrap;
    width: 200%
  }

  .title-card .title-card-play .playRing {
    border: .3vw solid #fff;
    border-radius: 50%;
    height: 6vw;
    position: absolute;
    width: 6vw
  }

  .title-card .title-card-play .playRing:after {
    background: none repeat scroll 0 0 rgba(0, 0, 0, .2);
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1
  }

  .title-card .title-card-play .play {
    color: #fff;
    font-size: 46%;
    left: 6%
      /*!rtl:ignore*/
    ;
    line-height: 6vw;
    position: absolute;
    text-align: center;
    width: 100%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .title-card .title-card-play {
    border-radius: 50%;
    box-sizing: border-box;
    color: #fff;
    font-size: 4.7vw;
    height: 5.17vw;
    margin-left: -2.4675vw;
    margin-top: -2.4675vw;
    opacity: .7;
    outline: none;
    position: absolute;
    transition: all .15s ease;
    width: 5.17vw;
    z-index: 100
  }

  .title-card .title-card-play:focus, .title-card .title-card-play:hover {
    opacity: 1;
    text-decoration: none;
    transform: scale(1.08)
  }

  .title-card .title-card-play:focus .playRing, .title-card .title-card-play:hover .playRing {
    background: none repeat scroll 0 0 rgba(0, 0, 0, .5)
  }

  .title-card .title-card-play:focus .play, .title-card .title-card-play:hover .play {
    color: #009779
  }

  .title-card .title-card-play .annotation {
    bottom: -1.316vw;
    font-size: 18%;
    font-weight: 500;
    left: -50%;
    position: absolute;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .7);
    white-space: nowrap;
    width: 200%
  }

  .title-card .title-card-play .playRing {
    border: .235vw solid #fff;
    border-radius: 50%;
    height: 4.7vw;
    position: absolute;
    width: 4.7vw
  }

  .title-card .title-card-play .playRing:after {
    background: none repeat scroll 0 0 rgba(0, 0, 0, .2);
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1
  }

  .title-card .title-card-play .play {
    color: #fff;
    font-size: 46%;
    left: 6%
      /*!rtl:ignore*/
    ;
    line-height: 4.7vw;
    position: absolute;
    text-align: center;
    width: 100%
  }
}

@media screen and (min-width: 1400px) {
  .title-card .title-card-play {
    border-radius: 50%;
    box-sizing: border-box;
    color: #fff;
    font-size: 3.8vw;
    height: 4.18vw;
    margin-left: -1.995vw;
    margin-top: -1.995vw;
    opacity: .7;
    outline: none;
    position: absolute;
    transition: all .15s ease;
    width: 4.18vw;
    z-index: 100
  }

  .title-card .title-card-play:focus, .title-card .title-card-play:hover {
    opacity: 1;
    text-decoration: none;
    transform: scale(1.08)
  }

  .title-card .title-card-play:focus .playRing, .title-card .title-card-play:hover .playRing {
    background: none repeat scroll 0 0 rgba(0, 0, 0, .5)
  }

  .title-card .title-card-play:focus .play, .title-card .title-card-play:hover .play {
    color: #009779
  }

  .title-card .title-card-play .annotation {
    bottom: -1.064vw;
    font-size: 18%;
    font-weight: 500;
    left: -50%;
    position: absolute;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .7);
    white-space: nowrap;
    width: 200%
  }

  .title-card .title-card-play .playRing {
    border: .19vw solid #fff;
    border-radius: 50%;
    height: 3.8vw;
    position: absolute;
    width: 3.8vw
  }

  .title-card .title-card-play .playRing:after {
    background: none repeat scroll 0 0 rgba(0, 0, 0, .2);
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1
  }

  .title-card .title-card-play .play {
    color: #fff;
    font-size: 46%;
    left: 6%
      /*!rtl:ignore*/
    ;
    line-height: 3.8vw;
    position: absolute;
    text-align: center;
    width: 100%
  }
}

.title-card .title-card-play-toolkit {
  align-items: center;
  display: none;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%
}

.title-card:hover .title-card-play {
  display: block
}

.title-card:hover .title-card-play-toolkit {
  display: flex
}

.title-card .title-card-jawbone-focus {
  opacity: 0
}

.title-card .title-card-focus-ring {
  border: 4px solid #fff;
  border-radius: 4px;
  bottom: -4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .2);
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.title-card .title-card-focus-ring:before {
  border-color: rgba(0, 0, 0, .15) transparent transparent;
  margin-top: 4px
}

.title-card .title-card-focus-ring:after, .title-card .title-card-focus-ring:before {
  border-style: solid;
  border-width: 7px 13px 0;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  top: 100%;
  width: 0
}

.title-card .title-card-focus-ring:after {
  border-color: #fff transparent transparent;
  margin-top: 3px
}

.title-card .boxart-container img {
  cursor: pointer
}

.title-card .slider-refocus {
  display: block
}

.title-card .slider-refocus :focus {
  outline: none
}

.title-card .slider-refocus:focus-visible {
  outline: auto
}

.title-card.title-card-top-10 .title-card-focus-ring {
  left: auto;
  width: calc(50% - 6px)
}

.title-card.title-card-top-10 .title-card-play {
  left: 75%
}

.title-card.title-card-top-10 .title-card-play-toolkit {
  left: 25%
}

.title-card.title-card-top-10 .click-to-change-JAW-indicator {
  left: 75%
}

.title-card-container>.progress {
  left: 0;
  padding: 3% 20% 0;
  position: absolute;
  right: 0;
  transition: opacity .35s
}

.title-card-container>.progress .progress-bar, .title-card-container>.progress .progress-completed {
  height: 3px
}

.title-card-synopsis {
  color: #e0e0e0;
  font-size: .8vw;
  margin-top: 5px;
  width: 80%
}

@media screen and (max-width: 499px) {
  .title-card-synopsis {
    font-size: 2vw
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .title-card-synopsis {
    font-size: 1.6vw
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .title-card-synopsis {
    font-size: 1.28vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .title-card-synopsis {
    font-size: 1.12vw
  }
}

@media screen and (min-width: 1400px) {
  .title-card-synopsis {
    font-size: .96vw
  }
}

.jawBoneOpen .title-card-synopsis {
  display: none
}

.title-card-duration {
  background-color: #fff;
  border-radius: 2px;
  bottom: 5px;
  color: #000;
  font-size: .72vw;
  left: 5px;
  padding: 1px 3px;
  position: absolute
}

@media screen and (max-width: 499px) {
  .title-card-duration {
    font-size: 1.8vw
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .title-card-duration {
    font-size: 1.44vw
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .title-card-duration {
    font-size: 1.152vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .title-card-duration {
    font-size: 1.008vw
  }
}

@media screen and (min-width: 1400px) {
  .title-card-duration {
    font-size: .864vw
  }
}

.title-card-container .click-to-change-JAW-indicator {
  bottom: .4vw;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -10px);
  transition: transform .4s, opacity .4s;
  transition-delay: .2s;
  z-index: 1
}

.title-card-container:hover .click-to-change-JAW-indicator.is-another-JAW-open {
  opacity: 1;
  transform: translate(-50%)
}

.lolomoRow.jawBoneOpen .title-card-container>.progress {
  opacity: 0
}

.previews-toggle select {
  color: #000;
  margin-right: 20px;
  width: 10vw
}

.lolomoRow.lolomoRow_title_card.has-info-density {
  margin-bottom: 10px
}

.lolomoRow.lolomoRow_title_card.has-info-density+.lolomoRow {
  margin-top: 10px
}

.has-info-density .title-card-container .bob-overview .bob-synopsis {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 14px
}

.has-info-density .title-card-container .bob-overview .evidence-separator:before, .has-info-density .title-card-container .bob-overview .evidence-text {
  color: hsla(68, 31%, 95%, .8);
  font-size: 14px;
  font-weight: 500
}

.has-info-density .title-card-container .bob-overview .video-meta--bob-overview {
  color: #f5f5f5;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  line-height: 20px;
  margin-bottom: 6px
}

.has-info-density .title-card-container .info-density-wrapper {
  opacity: 1;
  transform: translate(0);
  transition: opacity .3s cubic-bezier(.86, 0, .07, 1), transform .5s cubic-bezier(.86, 0, .07, 1)
}

.has-info-density .title-card-container .info-density-wrapper.open-bob {
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity .2s linear 33ms, transform 733ms cubic-bezier(.21, 0, .07, 1), background-color .2s linear 33ms
}

.has-info-density .title-card-container .info-density-wrapper.open-bob .vignette {
  background-color: transparent;
  transform: scaleX(2.5) translateY(-30px);
  transform-origin: center;
  transition: transform .5s cubic-bezier(.86, 0, .07, 1), background-color .2s linear .1s
}

.has-info-density .title-card-container .vignette {
  border-radius: 0 0 4px 4px;
  opacity: 0;
  transform: scale(1) translate(0);
  transform-origin: center;
  transition: transform .5s cubic-bezier(.86, 0, .07, 1), opacity .15s linear
}

.has-info-density .title-card-container:hover .vignette {
  opacity: .07;
  transition: opacity .15s linear
}

.previewModal--container.mini-modal.has-info-density {
  border-radius: 4px
}

.previewModal--container.mini-modal.has-info-density .previewModal--info, .previewModal--container.mini-modal.has-info-density .previewModal--metadatAndControls {
  background-color: #222;
  font-size: 14px
}

.previewModal--container.mini-modal.has-info-density.inline-mini-modal .previewModal--info {
  background-color: rgba(34, 34, 34, .97);
  z-index: -1
}

.previewModal--container.mini-modal.has-info-density.inline-mini-modal .previewModal--metadatAndControls {
  background-color: transparent
}

.previewModal--container.mini-modal.has-info-density .previewModal--metadatAndControls-info {
  margin: .8em 0 .5em
}

.previewModal--container.mini-modal.has-info-density .videoMetadata--container {
  color: #b3b3b3;
  font-weight: 500
}

.previewModal--container.mini-modal.has-info-density .previewModal--metadatAndControls-synopsis {
  color: #e6e6e6;
  line-height: 18px;
  margin-bottom: 12px
}

.previewModal--container.mini-modal.has-info-density .previewModal--metadatAndControls-tags-container, .previewModal--container.mini-modal.has-info-density .previewModal--metadatAndControls-tags-container .evidence-text {
  color: #e6e6e6
}

.previewModal--container.mini-modal.has-info-density .previewModal-episodeDetail-and-badge {
  margin: 1.5em 0 .5em
}

.info-density-wrapper {
  display: block;
  padding: 8px;
  position: relative
}

.info-density-wrapper .video-meta>span, .info-density-wrapper .video-meta>svg {
  margin-right: .6em
}

.info-density-wrapper .video-meta>span:last-child, .info-density-wrapper .video-meta>svg:last-child {
  margin-right: 0
}

.info-density-wrapper .info-density-synopsis {
  color: #a1a19b;
  font-size: 12px;
  height: 36px;
  -webkit-hyphens: auto;
  hyphens: auto;
  line-height: 18px;
  margin-bottom: 6px;
  overflow: hidden;
  position: relative
}

.info-density-wrapper .info-density-synopsis:after {
  background: linear-gradient(90deg, hsla(0, 0%, 8%, 0), #141414 60%);
  border-radius: 2px;
  bottom: 0;
  content: " \2026";
  display: block;
  padding: 0 5px 0 30px;
  position: absolute;
  right: 0
}

.info-density-wrapper .video-meta--info-density {
  color: #a1a19b;
  font-size: 12px;
  height: 20px;
  justify-items: flex-start;
  line-height: 20px;
  overflow: hidden
}

.info-density-wrapper .video-meta--info-density .maturity-rating {
  font-size: 10px
}

.info-density-wrapper .evidence-list {
  height: 18px;
  overflow: hidden
}

.info-density-wrapper .evidence-list .evidence-separator:before, .info-density-wrapper .evidence-list .evidence-text {
  color: #a1a19b
}

.info-density-wrapper .evidence-list .evidence-text {
  font-size: 12px
}

.info-density-wrapper .evidence-list .evidence-separator:before {
  font-size: 10px;
  line-height: 18px
}

.info-density-wrapper .evidence-list .evidence-item {
  padding-left: .5vw;
  padding-right: 0
}

.info-density-wrapper .evidence-list .evidence-item:first-child {
  padding-left: 0
}

.info-density-wrapper.info-density-medium .video-meta--info-density, .info-density-wrapper.info-density-small .video-meta--info-density {
  align-items: center;
  justify-content: center
}

.info-density-wrapper.info-density-medium .evidence-list, .info-density-wrapper.info-density-small .evidence-list {
  justify-content: center
}

.info-density-wrapper .vignette {
  background: #fff;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.has-large-density .info-density-wrapper {
  height: 59px
}

.has-large-density .info-density-wrapper .video-meta--info-density {
  font-size: 12px
}

.has-large-density .info-density-wrapper .video-meta--info-density .duration {
  font-weight: 500
}

.has-large-density .info-density-wrapper .video-meta--info-density .duration:after {
  color: #54564d;
  content: "\2022";
  display: inline-block;
  font-size: 12px;
  padding-left: 8px
}

.has-medium-density .info-density-wrapper {
  height: 37px;
  padding: 8px
}

.has-medium-density .info-density-wrapper .evidence-list .evidence-text {
  font-size: 12px
}

.has-medium-density .info-density-wrapper .evidence-list .evidence-separator:before {
  color: #54564d;
  font-size: 16px;
  line-height: 16px
}

.has-small-density .info-density-wrapper {
  height: 20px;
  padding: 8px
}

.has-small-density .info-density-wrapper .video-meta--info-density {
  color: #a1a19b
}

.has-small-density .info-density-wrapper .evidence-list .evidence-separator:before, .has-small-density .info-density-wrapper .evidence-list .evidence-text {
  color: #9d9d9d;
  font-weight: 400
}

.has-info-density .title-card.is-dimmed {
  background-color: #000
}

.has-info-density .title-card.is-dimmed .boxart-container {
  opacity: .5;
  transition: opacity .2s cubic-bezier(.86, 0, .07, 1)
}

.has-info-density .title-card .title-card-focus-ring {
  border-radius: 8px;
  left: -4px;
  right: -4px;
  top: -4px;
  transition: .4s cubic-bezier(.86, 0, .07, 1)
}

.has-info-density.has-large-density .bob-card-adult .bob-overlay {
  background-color: transparent;
  background-image: linear-gradient(180deg, transparent 0, transparent 20%, rgba(0, 0, 0, .85) 70%)
}

.has-info-density.has-large-density .title-card .title-card-focus-ring {
  bottom: -40px
}

.has-info-density.has-large-density .has-expanded-info-density .title-card .title-card-focus-ring {
  bottom: -95px
}

.has-info-density.has-medium-density .title-card .title-card-focus-ring {
  bottom: -56px
}

.has-info-density.has-small-density .title-card .title-card-focus-ring {
  bottom: -40px
}

.jawBoneOpen.has-info-density .title-card .title-card-focus-ring {
  transition: .4s cubic-bezier(.86, 0, .07, 1)
}

.jawBoneOpen.has-info-density .has-expanded-info-density .title-card.is-dimmed {
  background-color: #141414
}

.jawBoneOpen.has-info-density .has-expanded-info-density .title-card.is-dimmed .boxart-container {
  opacity: 1;
  transition: opacity .2s cubic-bezier(.86, 0, .07, 1)
}

.jawBoneOpen.has-info-density.has-large-density .jawBoneContent {
  top: 100%;
  transition: .4s cubic-bezier(.86, 0, .07, 1)
}

.jawBoneOpen.has-info-density.has-large-density .info-density-wrapper {
  height: 22px;
  opacity: .6;
  transition: .4s cubic-bezier(.86, 0, .07, 1)
}

.jawBoneOpen.has-info-density.has-large-density .title-card .title-card-focus-ring {
  bottom: -42px;
  transition: .4s cubic-bezier(.86, 0, .07, 1)
}

.jawBoneOpen.has-info-density.has-large-density .info-density-synopsis {
  height: 0;
  margin-bottom: 0;
  transition: .4s cubic-bezier(.86, 0, .07, 1)
}

.jawBoneOpen.has-info-density.has-large-density .has-expanded-info-density .jawBoneContent {
  margin-top: -5px;
  top: 101%;
  transition: .4s cubic-bezier(.86, 0, .07, 1)
}

.jawBoneOpen.has-info-density.has-large-density .has-expanded-info-density .title-card .title-card-focus-ring {
  bottom: -87px
}

.jawBoneOpen.has-info-density.has-large-density .has-expanded-info-density .info-density-wrapper {
  height: 71px;
  opacity: 1;
  transition: .4s cubic-bezier(.86, 0, .07, 1)
}

.jawBoneOpen.has-info-density.has-large-density .has-expanded-info-density .info-density-synopsis {
  height: 36px;
  margin-bottom: 10px;
  transition: .4s cubic-bezier(.86, 0, .07, 1)
}

.jawBoneOpen.has-info-density.has-medium-density .title-card .title-card-focus-ring {
  bottom: -56px
}

.jawBoneOpen.has-info-density.has-small-density .title-card .title-card-focus-ring {
  bottom: -40px
}

.watched-title--no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.watched-title--bob-overview {
  font-size: .9vw;
  margin-bottom: .6em
}

@media screen and (max-width: 499px) {
  .watched-title--bob-overview {
    font-size: 2.25vw
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .watched-title--bob-overview {
    font-size: 1.8vw
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .watched-title--bob-overview {
    font-size: 1.44vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .watched-title--bob-overview {
    font-size: 1.26vw
  }
}

@media screen and (min-width: 1400px) {
  .watched-title--bob-overview {
    font-size: 1.08vw
  }
}

.video-meta {
  align-items: center;
  display: flex;
  flex-wrap: wrap
}

.video-meta>span, .video-meta>svg {
  margin-bottom: .3vw;
  margin-right: .5em
}

.video-meta .maturity-rating .svg-icon {
  height: 1.5em;
  width: 1.5em
}

.video-meta .audio-description-badge {
  line-height: inherit !important;
  position: relative
}

.video-meta .audio-description-badge svg {
  border: 1px solid hsla(0, 0%, 100%, .4);
  border-radius: 2px;
  height: .8em;
  padding: .1em;
  width: 2em
}

.video-meta .audio-description-badge:before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #e6e6e6;
  content: " ";
  font-size: 0;
  line-height: 0;
  top: -6px;
  width: 0;
  z-index: 3
}

.video-meta .audio-description-badge:after, .video-meta .audio-description-badge:before {
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%) translateY(-2px);
  transition: opacity .8s cubic-bezier(1, -.11, .65, .93), transform .8s cubic-bezier(1, -.11, .65, .93);
  visibility: hidden
}

.video-meta .audio-description-badge:after {
  background: #e6e6e6;
  border-radius: 4px;
  bottom: 2.1em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, .6);
  color: #181818;
  content: attr(data-tooltip);
  font-size: .8em;
  padding: 7px 10px;
  white-space: nowrap;
  z-index: 2
}

.video-meta .audio-description-badge:hover:after, .video-meta .audio-description-badge:hover:before {
  display: block;
  opacity: 1;
  transform: translateX(-50%) translateY(0);
  visibility: visible
}

.video-meta--bob-overview {
  font-size: .8vw;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .7)
}

@media screen and (max-width: 499px) {
  .video-meta--bob-overview {
    font-size: 2vw
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .video-meta--bob-overview {
    font-size: 1.6vw
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .video-meta--bob-overview {
    font-size: 1.28vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .video-meta--bob-overview {
    font-size: 1.08vw
  }
}

@media screen and (min-width: 1400px) {
  .video-meta--bob-overview {
    font-size: .96vw
  }
}

.progress--bob-overview {
  font-size: .8vw;
  padding: 0 0 .45em
}

@media screen and (max-width: 499px) {
  .progress--bob-overview {
    font-size: 1.6vw
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .progress--bob-overview {
    font-size: 1.44vw
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .progress--bob-overview {
    font-size: 1.28vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .progress--bob-overview {
    font-size: 1vw
  }
}

@media screen and (min-width: 1400px) {
  .progress--bob-overview {
    font-size: .96vw
  }
}

.content-warning--bob-overview {
  font-size: 1.15em;
  line-height: 1.2em;
  padding: .15em 0 .1em
}

.content-warning--bob-overview .content-warning-text {
  transition: font-weight .1s ease
}

.content-warning--bob-overview .content-warning-text:hover {
  font-weight: 500
}

.content-warning-icon {
  display: inline-block;
  height: 1.35em;
  padding-right: .2em;
  position: relative;
  top: .325em;
  width: 1.35em
}

.tooltip-inner {
  font-family: NKufi, Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif !important;
  max-width: inherit !important;
  color: inherit !important;
  padding: inherit !important;
  background-color: inherit !important;
  border-radius: inherit !important;
}

.tooltip .tooltip-arrow {
  width: 10px !important;
  height: 5px !important;
  bottom: calc(-1 * 5px) !important;
  display: flex !important;
  justify-content: center;
}

.tooltip .tooltip-arrow::before {
  top: 5px;
  border-width: 10px calc(20px * .5) 0 !important;
  border-top-color: white !important;
}

.match-score-wrapper {
  color: #fff
}

.match-score-wrapper .rating-inner {
  display: flex;
  transition: margin-right .55s cubic-bezier(.86, 0, .07, 1) 1.65s
}

.match-score-wrapper .show-thumb-up .match-score {
  height: 0;
  max-width: 0;
  opacity: 0;
  transition: max-width .55s cubic-bezier(.86, 0, .07, 1) 1.1s, opacity .15s linear .95s
}

.match-score-wrapper .show-thumb-up .thumb-down svg {
  height: 0 !important;
  opacity: 0;
  transition: width .55s cubic-bezier(.86, 0, .07, 1) 1.1s, opacity .15s linear .95s;
  width: 0 !important
}

.match-score-wrapper .show-thumb-up .thumb-up svg {
  opacity: 1;
  transition: width .55s cubic-bezier(.86, 0, .07, 1) 1.65s, opacity .15s linear 2.2s
}

.match-score-wrapper .show-thumb-down .match-score {
  height: 0 !important;
  max-width: 0 !important;
  opacity: 0;
  transition: max-width .55s cubic-bezier(.86, 0, .07, 1) 1.1s, opacity .15s linear .95s
}

.match-score-wrapper .show-thumb-down .thumb-up svg {
  height: 0 !important;
  opacity: 0;
  transition: width .55s cubic-bezier(.86, 0, .07, 1) 1.1s, opacity .15s linear .95s;
  width: 0 !important
}

.match-score-wrapper .show-thumb-down .thumb-down svg {
  opacity: 1;
  transition: width .55s cubic-bezier(.86, 0, .07, 1) 1.65s, opacity .15s linear 2.2s
}

.match-score-wrapper .show-match-score .thumb-down svg, .match-score-wrapper .show-match-score .thumb-up svg {
  height: 0 !important;
  opacity: 0;
  transition: width .55s cubic-bezier(.86, 0, .07, 1) 1.1s, opacity .15s linear .95s;
  width: 0 !important
}

.match-score-wrapper .show-match-score .match-score {
  max-width: 300px;
  opacity: 1;
  transition: max-width .55s cubic-bezier(.86, 0, .07, 1) 1.65s, opacity .15s linear 2.2s
}

.match-score-wrapper .match-score {
  color: #46d369;
  display: inline-block;
  font-weight: 500;
  white-space: nowrap
}

.match-score-wrapper .meta-thumb-container {
  display: inline-block;
  margin-bottom: -2px
}

.match-score-wrapper .meta-thumb-container svg {
  height: .9em !important;
  width: .9em !important
}

.svg-icon.svg-icon-dolby-vision-atmos {
  width: 5em
}

.svg-icon.svg-icon-dolby-atmos, .svg-icon.svg-icon-dolby-vision {
  width: 3.5em
}

.player-feature-badge {
  border: 1px solid hsla(0, 0%, 100%, .4);
  border-radius: 3px;
  color: hsla(0, 0%, 100%, .9);
  font-size: .7em;
  padding: 0 .5em;
  white-space: nowrap
}

.supplemental-message {
  align-items: center;
  display: flex;
  font-weight: 500
}

.supplemental-message>.svg-icon {
  height: 1.5em;
  margin-right: .5em;
  width: 1.5em
}

.ActionButtons {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  font-size: 1.35rem
}

.ActionButtons div button {
  margin: .35rem 0
}

.ActionButtons .global-supplemental-audio-toggle .nf-svg-button svg {
  height: 1.5rem;
  width: 1.5rem
}

.billboard-row .embedded-components .ActionButtons div button {
  margin: 0
}

.ButtonTransition-fade-enter, .ButtonTransition-fade-leave.ButtonTransition-fade-leave-active {
  opacity: .01;
  transition: opacity .2s linear
}

.ButtonTransition-fade-enter.ButtonTransition-fade-enter-active, .ButtonTransition-fade-leave {
  opacity: 1
}

.progress {
  align-items: center;
  display: flex
}

.progress .summary {
  color: hsla(0, 0%, 100%, .3);
  font-weight: 500;
  margin: 0 0 0 8px
}

.progress-bar {
  background-color: hsla(0, 0%, 100%, .3);
  display: block;
  flex-grow: 1;
  height: 2px;
  position: relative
}

.progress-completed {
  background-color: #009779;
  height: 2px;
  left: 0;
  position: absolute;
  top: 0
}

.progress-summary {
  margin-left: .5em
}

.common-sense .common-sense-age {
  color: #fff;
  margin-left: .4em
}

.common-sense .common-sense-title {
  margin-bottom: 0
}

.common-sense .common-sense-copyright {
  line-height: 1;
  margin-top: .3em
}

.common-sense .common-sense-icon {
  background-image: url(https://assets.nflxext.com/ffe/siteui/akira/JawBone/common_sense_icon.png);
  background-repeat: no-repeat;
  display: inline-block;
  height: 24px;
  vertical-align: middle;
  width: 27px
}

.nfDropDown.theme-lakira {
  position: relative;
  text-align: left
}

.nfDropDown.theme-lakira.open {
  z-index: 1
}

.nfDropDown.theme-lakira .label {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #000;
  border: 1px solid hsla(0, 0%, 100%, .9);
  border-radius: 0;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 500;
  height: 2.5rem;
  letter-spacing: 1px;
  line-height: 2.5rem;
  padding-left: 10px;
  padding-right: 50px;
  position: relative
}

.nfDropDown.theme-lakira .label:focus, .nfDropDown.theme-lakira .label:hover {
  background-color: hsla(0, 0%, 100%, .1);
  cursor: pointer;
  outline: none
}

.nfDropDown.theme-lakira .label .arrow {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  height: 0;
  position: absolute;
  right: 10px;
  top: 44%;
  width: 0
}

.nfDropDown.theme-lakira .label.loading {
  visibility: hidden
}

.nfDropDown.theme-lakira .sub-menu {
  font-size: 14px;
  left: 0;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  top: 2.5rem;
  z-index: 2
}

.nfDropDown.theme-lakira .sub-menu .sub-menu-list, .nfDropDown.theme-lakira .sub-menu .sub-menu-list-special {
  margin: 0;
  padding: 5px 0
}

.nfDropDown.theme-lakira .sub-menu .sub-menu-item a {
  padding: 1px 20px 1px 10px
}

.nfDropDown.theme-lakira .sub-menu .sub-menu-link {
  display: inline-block;
  padding: 1px 0;
  width: 100%
}

.nfDropDown.theme-lakira .sub-menu::-webkit-scrollbar {
  background-color: #333;
  width: 10px
}

.nfDropDown.theme-lakira .sub-menu::-webkit-scrollbar:hover {
  background-color: #4d4d4d
}

.nfDropDown.theme-lakira .sub-menu::-webkit-scrollbar-thumb {
  background-color: grey;
  width: 6px
}

.nfDropDown.theme-lakira .sub-menu::-webkit-scrollbar-thumb:hover {
  background-color: #ccc
}

.nfDropDown.theme-lakira .sub-menu::-webkit-scrollbar-corner {
  background-color: #333
}

.nfDropDown.theme-lakira.widthRestricted {
  display: inline-block;
  position: relative
}

.nfDropDown.theme-lakira.widthRestricted .label {
  width: 100%
}

.nfDropDown.theme-lakira.widthRestricted .sub-menu {
  min-width: 100%;
  white-space: nowrap
}

.nfDropDown.theme-lakira.widthRestricted .sub-menu .sub-menu-list, .nfDropDown.theme-lakira.widthRestricted .sub-menu .sub-menu-list-special {
  width: 100%
}

.evidence-list {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-start;
  margin: 0;
  padding: 0
}

.evidence-list .evidence-item {
  align-items: center;
  display: flex;
  padding-right: .5vw
}

.evidence-list .evidence-separator {
  display: inline-block
}

.evidence-list .evidence-separator:before {
  color: #646464;
  content: "\2022";
  display: inline-block;
  font-size: 1vw;
  padding-right: .5vw
}

@media screen and (max-width: 499px) {
  .evidence-list .evidence-separator:before {
    font-size: 2.5vw
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .evidence-list .evidence-separator:before {
    font-size: 2vw
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .evidence-list .evidence-separator:before {
    font-size: 1.6vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .evidence-list .evidence-separator:before {
    font-size: 1.4vw
  }
}

@media screen and (min-width: 1400px) {
  .evidence-list .evidence-separator:before {
    font-size: 1.2vw
  }
}

.evidence-custom-text, .evidence-sims, .evidence-synopsis {
  position: relative
}

.evidence-custom-text img {
  max-height: .96vw;
  max-width: .96vw
}

.evidence-text {
  color: #fff;
  font-size: .8vw;
  line-height: 1.3;
  position: relative;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .7)
}

@media screen and (max-width: 499px) {
  .evidence-text {
    font-size: 2vw
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .evidence-text {
    font-size: 1.6vw
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .evidence-text {
    font-size: 1.28vw
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .evidence-text {
    font-size: 1.12vw
  }
}

@media screen and (min-width: 1400px) {
  .evidence-text {
    font-size: .96vw
  }
}

.extended-diacritics-language .evidence-text {
  line-height: 1.4
}

.player-evidence-tags {
  margin-bottom: 0
}

.player-evidence-tags .evidence-list {
  max-height: 2.2em;
  overflow: hidden
}

.player-evidence-tags .evidence-list .evidence-text {
  font-size: 1.4em
}

.player-evidence-tags .evidence-list .evidence-separator:before {
  color: #fff;
  font-size: 1.75em;
  opacity: .25
}

.detail-modal-container {
  padding: 0 3em
}

.previewModal--player_container {
  background-color: #000;
  cursor: pointer;
  position: relative
}

.previewModal--player_container.not-playable {
  cursor: auto
}

.previewModal--player_container .videoMerchPlayer--boxart-wrapper {
  height: 100%;
  padding-top: 56.3925%;
  width: 100%
}

.previewModal--player_container .videoMerchPlayer--boxart-wrapper.is-disliked img {
  filter: grayscale(100%)
}

.previewModal--player_container .videoMerchPlayer--boxart-wrapper .videoMerch-playIcon {
  align-items: center;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .2s ease-in;
  z-index: 1
}

.previewModal--player_container .videoMerchPlayer--boxart-wrapper .videoMerch-playIcon .videoMerch-playSVG {
  background-color: rgba(30, 30, 20, .5);
  border: 2px solid #fff;
  border-radius: 3em;
  height: 4em;
  padding: .5em;
  width: 4em
}

.previewModal--player_container .previewModal--boxart {
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.previewModal--player_container .previewModal-audioToggle, .previewModal--player_container .previewModal-viewMerchOptions {
  border-radius: 50%;
  position: absolute;
  z-index: 1
}

.previewModal--player_container .previewModal-audioToggle button.button-secondary.iconOnly, .previewModal--player_container .previewModal-viewMerchOptions button.button-secondary.iconOnly {
  border-width: 2px
}

.previewModal--player_container .previewModal-audioToggle button.button-secondary.iconOnly svg, .previewModal--player_container .previewModal-viewMerchOptions button.button-secondary.iconOnly svg {
  width: 2rem
}

.previewModal--player_container .previewModal-audioToggle.detail-modal, .previewModal--player_container .previewModal-viewMerchOptions.detail-modal {
  bottom: 10%;
  margin-bottom: 1em;
  right: 3em
}

.previewModal--player_container .previewModal-viewMerchOptions {
  bottom: 5%;
  right: 1.1em
}

.previewModal--player_container .previewModal-audioToggle {
  bottom: 5%;
  overflow: hidden;
  right: 1.25em
}

.previewModal--player_container .storyArt {
  overflow: hidden;
  padding-top: 56.3925%;
  width: 100%
}

.previewModal--player_container .storyArt .playerModel--player__storyArt {
  display: block;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  transition: height .2s ease-in-out
}

.previewModal--player_container .storyArt .playerModel--player__storyArt.detail-modal, .previewModal--player_container .storyArt .playerModel--player__storyArt.mini-modal {
  min-width: 100%
}

.previewModal--player_container.detail-modal {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden
}

.previewModal--player_container.detail-modal .previewModal--player-titleTreatmentWrapper {
  background: linear-gradient(0deg, #181818, transparent 50%)
}

.previewModal--player-titleTreatmentWrapper h1 {
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
}

.previewModal--player-titleTreatmentWrapper .modal-title h1 {
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}

.previewModal--player-titleTreatmentWrapper .modal-title h5 {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px !important;
  margin: 0 0 0 15px;
}

.previewModal--player_container.detail-modal .previewModal--player-titleTreatment-logo {
  margin-bottom: 1.5em
}

.previewModal--player_container.detail-modal .image-based-subtitles, .previewModal--player_container.detail-modal .player-timedtext-text-container {
  z-index: 1
}

.previewModal--player_container.mini-modal .previewModal--player-titleTreatmentWrapper {
  background: linear-gradient(45deg, rgba(0, 0, 0, .6), rgba(0, 0, 0, .4) 15%, transparent 40%)
}

.previewModal--player_container.mini-modal .previewModal--player-titleTreatmentWrapper .previewModal--player-titleTreatment-left {
  left: 1em
}

.previewModal--player_container .previewModal--player-titleTreatmentWrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 90%
}

.previewModal--player_container .previewModal--player-titleTreatmentWrapper .previewModal--player-titleTreatment-left {
  bottom: 15%;
  left: 3em;
  position: absolute;
  width: 100%
}

.previewModal--player_container .previewModal--player-titleTreatmentWrapper .previewModal--player-titleTreatment-left .previewModal-title-progress, .previewModal--player_container .previewModal--player-titleTreatmentWrapper .previewModal--player-titleTreatment-left .previewModal-title-progress .progress-bar {
  margin: .5em 0
}

.previewModal--player_container .previewModal--player-titleTreatmentWrapper .previewModal--player-titleTreatment-left .previewModal-title-progress .summary {
  color: hsla(0, 0%, 100%, .7)
}

.image-based-subtitles {
  display: block;
  transform: translateZ(0)
}

.image-based-subtitles, .image-based-subtitles>svg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.previewModal-contentWarning {
  align-items: center
}

.previewModal--metadatAndControls {
  background-color: #181818;
  display: grid;
  position: relative;
  width: 100%
}

.previewModal--metadatAndControls.mini-modal {
  grid-template-columns: 100%
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container>:not(.new-badge) {
  margin-bottom: .5em
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .new-badge {
  margin-bottom: 0;
  margin-top: 0
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal-progress {
  margin: 1em 0
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal-progress .progress-bar {
  margin: .5em 0
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal-progress .summary {
  color: hsla(0, 0%, 100%, .7)
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal-contentWarning {
  align-items: flex-end;
  margin: 0
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal-contentWarning .content-warning-icon {
  align-self: flex-start;
  margin-right: .5em
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .preview-modal-synopsis {
  font-size: 16px;
  line-height: 27px
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-info {
  margin: .8em 0
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal-episodeDetail-and-badge {
  display: flex
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal-episodeDetail-and-badge .previewModal-episodeDetails {
  font-size: inherit
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container {
  display: flex;
  flex-wrap: wrap
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-custom-text, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-sims, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-synopsis, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-tags .evidence-list {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-start;
  margin: 0;
  padding: 0
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-custom-text .evidence-item, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-sims .evidence-item, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-synopsis .evidence-item, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-tags .evidence-list .evidence-item {
  display: flex;
  padding-right: .5vw
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-custom-text .evidence-separator, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-sims .evidence-separator, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-synopsis .evidence-separator, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-tags .evidence-list .evidence-separator {
  display: inline-block
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-custom-text .evidence-separator:before, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-sims .evidence-separator:before, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-synopsis .evidence-separator:before, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-tags .evidence-list .evidence-separator:before {
  color: #646464;
  content: "\2022";
  display: inline-block;
  padding-right: .5vw
}

.previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-custom-text .evidence-text, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-sims .evidence-text, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-synopsis .evidence-text, .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container .evidence-tags .evidence-list .evidence-text {
  font-size: inherit
}

@media (min-aspect-ratio: 1/3) {
  .previewModal--metadatAndControls .previewModal--metadatAndControls-container .previewModal--metadatAndControls-tags-container :last-child(.previewModal--tags) {
    display: none
  }
}

.previewModal--text {
  font-size: 16px;
  line-height: 24px
}

.previewModal--small-text {
  font-size: 14px;
  line-height: 20px
}

.previewModal-contentWarning {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 2em .75em 0
}

.previewModal-contentWarning .content-warning-icon {
  margin-right: .75em
}

.previewModal-contentWarning .content-warning-icon svg {
  color: #777;
  width: 1.25em
}

.previewModal--detailsMetadata {
  background-color: #181818;
  display: grid;
  position: relative;
  width: 100%
}

.previewModal--detailsMetadata.detail-modal {
  -moz-column-gap: 2em;
  column-gap: 2em;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr)
}

.previewModal--detailsMetadata.detail-modal .previewModal--detailsMetadata-right {
  display: flex;
  flex-direction: column
}

.previewModal--detailsMetadata .previewModal--detailsMetadata-left>:not(.new-badge) {
  margin-bottom: .5em
}

.previewModal--detailsMetadata .previewModal--detailsMetadata-left .new-badge {
  margin-bottom: 0;
  margin-top: 0
}

.previewModal--detailsMetadata .previewModal--detailsMetadata-left .previewModal-contentWarning {
  align-items: flex-end;
  margin: 0
}

.previewModal--detailsMetadata .previewModal--detailsMetadata-left .previewModal-contentWarning .content-warning-icon {
  align-self: flex-start;
  margin-right: .5em
}

.previewModal--detailsMetadata .previewModal--detailsMetadata-left .preview-modal-synopsis {
  font-size: 16px;
  line-height: 26px
}

@media screen and (max-width: 1439px) {
  .previewModal--detailsMetadata .previewModal--detailsMetadata-left .preview-modal-synopsis {
    font-size: 14px;
    line-height: 24px
  }
}

@media screen and (min-width: 2500px) {
  .previewModal--detailsMetadata .previewModal--detailsMetadata-left .preview-modal-synopsis {
    font-size: 18px;
    line-height: 28px
  }
}

.previewModal--detailsMetadata .previewModal--detailsMetadata-left .previewModal--detailsMetadata-info {
  margin: .8em 0
}

.previewModal--detailsMetadata .previewModal--detailsMetadata-left .previewModal-episodeDetails {
  font-size: 20px;
  margin-top: 1.25em
}

.previewModal--tags {
  font-size: 14px;
  line-height: 20px;
  margin: .5em .5em .5em 0;
  word-break: break-word
}

.previewModal--tags .previewModal--tags-label {
  color: #777
}

.previewModal--tags .tag-item, .previewModal--tags .tag-more {
  color: #ddd;
  cursor: pointer;
  margin: 0;
  outline-color: #fff
}

.previewModal--tags .tag-item:hover, .previewModal--tags .tag-more:hover {
  text-decoration: underline
}

.previewModal--tags .tag-more {
  font-style: italic
}

.previewModal--info-container {
  padding: 1em;
}

.previewModal--info-container .small {
  height: 1.8em;
  width: 1.8em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.episodeSelector {
  background-color: #181818;
  padding: 1em 0 0
}

.episodeSelector .episodeSelector-label.show-single-season {
  margin-bottom: 16px;
  margin-top: 32px
}

.episodeSelector .episodeSelector-label.show-all-episodes {
  margin-bottom: 24px;
  margin-top: 32px
}

.episodeSelector .section-divider, .episodeSelector .section-divider.collapsed {
  height: 0;
  margin-top: 0
}

.episodeSelector .episodeSelector-header {
  grid-gap: 2rem;
  align-items: baseline;
  display: grid;
  grid-template-columns: 2fr 1fr
}

.episodeSelector .episodeSelector-dropdown {
  font-size: 12px;
  font-weight: 500;
  justify-self: end
}

.episodeSelector .episodeSelector-season-label {
  font-size: 20px;
  font-weight: 500;
  padding: 2em 0 1em
}

.episodeSelector .episodeSelector-season-label:first-child {
  padding: 1em 0
}

.episodeSelector .episodeSelector-season-name {
  font-size: 18px;
  justify-self: end;
  margin-bottom: .5rem
}

.episodeSelector .episodeSelector--option {
  align-items: center;
  display: flex
}

.episodeSelector .episodeSelector--option-label-numEpisodes {
  font-size: 14px;
  font-weight: 400
}

.episodeSelector .episodeSelector-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start
}

.episodeSelector .episodeSelector-container .episode-item.current {
  background-color: #333
}

.episodeSelector .episodeSelector--row-placeholder {
  background-color: #222;
  border-radius: .5em;
  height: 8em;
  margin: .25em 0
}

.buttonControls--container {
  align-items: center;
  display: flex;
  margin-bottom: 1em;
  min-height: 2em;
  position: relative;
  z-index: 2
}

.buttonControls--container .visually-hidden+* {
  margin-left: 0
}

.buttonControls--container>* {
  margin: .25em
}

.buttonControls--expand-button {
  margin-left: auto
}

.buttonControls--messaging {
  height: 20px
}

.buttonControls--messaging .mylist-message {
  color: #999;
  font-size: 14px;
  line-height: 20px
}

.titleCard--container {
  border-radius: .25em;
  cursor: pointer;
  margin: .5em;
  min-height: 22em;
  overflow: hidden;
  position: relative
}

.titleCard--container.disabled {
  pointer-events: none
}

.titleCard--container.disabled:after {
  background-color: rgba(0, 0, 0, .5);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

@media (min-aspect-ratio: 1/3) {
  .titleCard--container {
    flex: 0 0 47%;
    min-height: 22em
  }
}

@media (min-aspect-ratio: 7/9) {
  .titleCard--container {
    flex: 0 0 31%;
    min-height: 22em
  }
}

@media (min-aspect-ratio: 20/9) {
  .titleCard--container {
    flex: 0 0 22%;
    min-height: 20em
  }
}

.titleCard--container .titleCard-progress {
  background-color: grey;
  bottom: 0;
  color: #009779;
  height: .25em;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%
}

.titleCard--container .titleCard-progress::-webkit-progress-value {
  background: #009779
}

.titleCard--container .titleCard-progress::-moz-progress-bar {
  background: #009779
}

.titleCard--container .titleCard-progress::-webkit-progress-bar {
  background: hsla(0, 0%, 50%, .5)
}

.titleCard--container .titleCard-title_index {
  color: #d2d2d2;
  font-size: 1.5em;
  left: .25em;
  position: absolute;
  top: .25em
}

.titleCard--container .titleCard-imageWrapper {
  overflow: hidden;
  position: relative
}

.titleCard--container .titleCard-imageWrapper.grayOutBoxart img {
  filter: grayscale(100%)
}

.titleCard--container .titleCard-imageWrapper.has-duration:before {
  background-image: linear-gradient(198deg, rgba(0, 0, 0, .9), hsla(0, 0%, 9%, .5) 20%, transparent 28%);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.titleCard--container .titleCard-imageWrapper .titleCard-playIcon {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .2s ease-in
}

.titleCard--container .titleCard-imageWrapper .titleCard-playIcon .titleCard-playSVG {
  background-color: rgba(30, 30, 20, .5);
  border: 1px solid #fff;
  border-radius: 2em;
  height: 3em;
  padding: .5em;
  width: 3em
}

.titleCard--container .titleCard-imageWrapper img {
  display: block;
  width: 100%
}

.titleCard--container .titleCard-imageWrapper .duration {
  position: absolute;
  right: 5%;
  top: 5%;
  white-space: nowrap
}

.titleCard--container .titleCard--metadataWrapper {
  background-color: #2f2f2f;
  min-height: 100%
}

.titleCard--container .titleCard--metadataWrapper .titleCard-title {
  padding: 1em
}

.titleCard--container .titleCard--metadataWrapper .titleCard-title .titleCard-title_text {
  color: #fff;
  font-size: 1em;
  font-weight: 500
}

.titleCard--container .titleCard--metadataWrapper .titleCard-synopsis {
  color: #d2d2d2;
  margin: 0;
  padding: 0 1em 1em
}

.titleCard--container .titlecard-videoMetadata .year {
  order: 2
}

.titleCard--container .titleCard-availability {
  padding: 0 1em 1em
}

.titleCard--container:hover .titleCard-imageWrapper .titleCard-playIcon {
  opacity: 1
}

.titleCard--container:focus {
  outline-color: #fff
}

.titleCard--container:focus .titleCard-imageWrapper .titleCard-playIcon {
  opacity: 1
}

.titleCardList--container {
  align-items: center;
  border-bottom: 1px solid #404040;
  border-radius: .25em;
  cursor: pointer;
  display: flex;
  min-height: 10em;
  overflow: hidden;
  padding: 1em;
  position: relative
}

.titleCardList--container:first-of-type {
  border-top: 1px solid #404040
}

.titleCardList--container.disabled {
  pointer-events: none
}

.titleCardList--container.disabled:after {
  background-color: rgba(0, 0, 0, .5);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

@media (min-aspect-ratio: 1/3) {
  .titleCardList--container {
    min-height: 10em
  }
}

@media (min-aspect-ratio: 7/9) {
  .titleCardList--container {
    min-height: 8em
  }
}

@media (min-aspect-ratio: 20/9) {
  .titleCardList--container {
    min-height: 10em
  }
}

.titleCardList--container .titleCard-progress {
  background-color: grey;
  bottom: 0;
  color: #009779;
  height: .25em;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%
}

.titleCardList--container .titleCard-progress::-webkit-progress-value {
  background: #009779
}

.titleCardList--container .titleCard-progress::-moz-progress-bar {
  background: #009779
}

.titleCardList--container .titleCard-progress::-webkit-progress-bar {
  background: hsla(0, 0%, 50%, .5)
}

.titleCardList--container .titleCard-title_index {
  color: #d2d2d2;
  display: flex;
  flex: 0 0 7%;
  font-size: 1.5em;
  justify-content: center
}

.titleCardList--container .titleCard-imageWrapper {
  border-radius: 4px;
  flex: 0 0 18%;
  overflow: hidden;
  position: relative
}

.titleCardList--container .titleCard-imageWrapper .titleCard-lockIcon, .titleCardList--container .titleCard-imageWrapper .titleCard-playIcon {
  align-items: center;
  background-image: linear-gradient(195deg, hsla(0, 0%, 9%, .7), hsla(0, 0%, 9%, .5) 10%, transparent 25%);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.titleCardList--container .titleCard-imageWrapper .titleCard-lockIcon .titleCard-lockSVG, .titleCardList--container .titleCard-imageWrapper .titleCard-lockIcon .titleCard-playSVG, .titleCardList--container .titleCard-imageWrapper .titleCard-playIcon .titleCard-lockSVG, .titleCardList--container .titleCard-imageWrapper .titleCard-playIcon .titleCard-playSVG {
  background-color: rgba(30, 30, 20, .5);
  border: 1px solid #fff;
  border-radius: 2em;
  height: 3em;
  opacity: 0;
  padding: .5em;
  transition: opacity .2s ease-in;
  width: 3em
}

.titleCardList--container .titleCard-imageWrapper img {
  display: block;
  max-width: 100%
}

.titleCardList--container .titleCard-imageWrapper .duration {
  position: absolute;
  right: 5%;
  top: 5%;
  white-space: nowrap
}

.titleCardList--container .titleCardList--metadataWrapper {
  flex: 0 0 70%;
  font-size: 1em;
  min-height: 100%
}

.titleCardList--container .titleCardList--metadataWrapper.no-image {
  flex: 0 0 88%
}

.titleCardList--container .titleCardList--metadataWrapper .titleCardList-title {
  display: flex;
  justify-content: space-between;
  padding: 1em 1em .5em
}

.titleCardList--container .titleCardList--metadataWrapper .titleCardList-title .titleCard-title_text {
  color: #fff;
  font-size: 1em;
  font-weight: 500;
  overflow-wrap: anywhere
}

.titleCardList--container .titleCardList--metadataWrapper .titleCardList-title .titleCard-title_text .new-badge {
  margin-top: 0
}

.titleCardList--container .titleCardList--metadataWrapper .titleCardList-title .duration {
  padding-left: 10px
}

.titleCardList--container .titleCardList--metadataWrapper .titleCardList-title .titleCardList--availability-date-messaging {
  margin-right: 1em
}

.titleCardList--container .titleCardList--metadataWrapper .titleCard-synopsis {
  color: #d2d2d2;
  margin: 0;
  padding: 0 1em 1em
}

.titleCardList--container .titlecard-videoMetadata .year {
  order: 2
}

.titleCardList--container .titleCard-availability {
  padding: 0 1em 1em
}

.titleCardList--container:hover .titleCard-imageWrapper .titleCard-lockSVG, .titleCardList--container:hover .titleCard-imageWrapper .titleCard-playSVG {
  opacity: 1
}

.titleCardList--container:focus {
  outline-color: #fff
}

.titleCardList--container:focus .titleCard-imageWrapper .titleCard-playSVG {
  opacity: 1
}

.titleCardList--container--notplayable:focus .titleCard-imageWrapper .titleCard-playSVG, .titleCardList--container--notplayable:hover .titleCard-imageWrapper .titleCard-lockSVG, .titleCardList--container--notplayable:hover .titleCard-imageWrapper .titleCard-playSVG {
  opacity: 0
}

.moreLikeThis--wrapper {
  background-color: #181818
}

.moreLikeThis--container {
  grid-gap: 1em;
  align-items: stretch;
  display: grid;
  justify-items: stretch
}

@media (min-aspect-ratio: 1/3) {
  .moreLikeThis--container {
    grid-template-columns: repeat(2, 1fr)
  }
}

@media (min-aspect-ratio: 7/9) {
  .moreLikeThis--container {
    grid-template-columns: repeat(3, 1fr)
  }
}

.moreLikeThis--container .titleCard--container {
  height: 100%;
  margin: .1em
}

.moreLikeThis--container .videoMetadata--container {
  padding: 1em
}

.moreLikeThis--container .videoMetadata--container-container .videoMetadata--container {
  min-width: unset;
  padding: 0
}

.titleGroup--wrapper {
  background-color: #181818
}

.titleGroup--wrapper .title-group-collection {
  height: 1.2em;
  margin-right: .66em;
  width: 1.2em
}

.titleGroup--container {
  grid-gap: 1em;
  align-items: stretch;
  display: grid;
  justify-items: stretch
}

@media (min-aspect-ratio: 1/3) {
  .titleGroup--container {
    grid-template-columns: repeat(2, 1fr)
  }
}

@media (min-aspect-ratio: 7/9) {
  .titleGroup--container {
    grid-template-columns: repeat(3, 1fr)
  }
}

.titleGroup--container .titleCard--container {
  height: 100%;
  margin: .1em
}

.titleGroup--container .title-group-item .titlecard-videoMetadata {
  padding: 1em
}

.videoMetadata--container-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1em
}

.videoMetadata--container-container .videoMetadata--container {
  min-width: 85%;
  padding: 0
}

.videoMetadata--container {
  align-items: center;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.videoMetadata--container .videoMetadata--first-line {
  margin: .25em .5em .25em 0;
  max-width: 100%
}

.videoMetadata--container .videoMetadata--second-line {
  align-items: center;
  color: #bcbcbc;
  display: flex;
  flex-wrap: wrap
}

.videoMetadata--container .videoMetadata--second-line>* {
  margin-right: .5em
}

.videoMetadata--container .videoMetadata--second-line>.player-feature-badge {
  margin-right: .715em
}

.videoMetadata--container .videoMetadata--second-line .duration {
  white-space: nowrap
}

.videoMetadata--container .video-metadata--adBadge-container {
  height: 2em;
  width: 2em
}

.videoMetadata--container .video-metadata--adBadge-container .audio-description-badge {
  align-items: center;
  display: flex;
  height: 2em;
  justify-content: center;
  width: 2em
}

.videoMetadata--container .match-score {
  color: #46d369;
  white-space: unset
}

.videoMetadata--container.videoMetadata--two-lines {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center
}

.trailersAndMore--wrapper {
  background-color: #181818
}

.trailersAndMore--wrapper .trailersAndMore--container {
  grid-gap: 1em;
  align-items: stretch;
  display: grid;
  justify-items: stretch
}

@media (min-aspect-ratio: 1/3) {
  .trailersAndMore--wrapper .trailersAndMore--container {
    grid-template-columns: repeat(2, 1fr)
  }
}

@media (min-aspect-ratio: 7/9) {
  .trailersAndMore--wrapper .trailersAndMore--container {
    grid-template-columns: repeat(3, 1fr)
  }
}

.trailersAndMore--wrapper .trailersAndMore--container .titleCard--container {
  height: 100%;
  margin: .1em;
  min-height: unset
}

.trailersAndMore--wrapper .trailersAndMore--container .titleCard-imageWrapper {
  max-height: 70%
}

.trailersAndMore--wrapper .trailersAndMore--container .trailers-and-more-item .titleCard-playIcon {
  background-image: none
}

.trailersAndMore--wrapper .trailersAndMore--container .trailers-and-more-item .titleCard--metadataWrapper {
  background-color: inherit
}

.about-wrapper {
  background-color: #181818;
  padding-bottom: 2em
}

.about-wrapper p {
  margin: 0
}

.about-wrapper .about-header .previewModal--section-header {
  font-weight: 400
}

.about-wrapper .maturity-rating-wrapper {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-top: .5em
}

.about-wrapper .maturity-rating-wrapper span.title {
  color: #777;
  margin-right: 1em;
  white-space: nowrap
}

.about-wrapper .maturity-rating>* {
  display: inline-block;
  vertical-align: middle
}

.about-wrapper .maturity-rating>*+* {
  margin-left: 1em
}

.about-wrapper .maturity-rating .maturity-graphic {
  margin-left: 0
}

.about-wrapper .available-until {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-top: .6em
}

.about-wrapper .available-until .available-until-title {
  color: #777;
  margin-right: 1em
}

.about-wrapper .content-warning {
  align-items: flex-start;
  display: flex;
  margin-bottom: .5em
}

.about-wrapper .content-warning .content-warning-icon {
  margin-right: 1em
}

.about-wrapper .content-warning .content-warning-icon svg {
  color: #777;
  width: 1.5em
}

.about-wrapper .broadcaster {
  font-size: 14px;
  line-height: 20px
}

.about-wrapper .descriptive-transcript {
  font-size: 14px;
  margin-bottom: .5em
}

.about-wrapper .descriptive-transcript a:hover {
  text-decoration: underline
}

.section-container.collapsed {
  max-height: 50em;
  overflow: hidden
}

.section-divider {
  border-bottom: 2px solid #404040;
  box-shadow: none;
  display: flex;
  height: 6em;
  justify-content: center;
  margin: auto;
  position: relative;
  width: 100%
}

.section-divider .section-collapseButton, .section-divider .section-expandButton {
  bottom: 0;
  position: absolute;
  transform: translateY(50%)
}

.section-divider .section-collapseButton.color-supplementary, .section-divider .section-expandButton.color-supplementary {
  background-color: #2a2a2a
}

.section-divider .section-collapseButton.color-supplementary:not(:disabled):focus, .section-divider .section-collapseButton.color-supplementary:not(:disabled):hover, .section-divider .section-expandButton.color-supplementary:not(:disabled):focus, .section-divider .section-expandButton.color-supplementary:not(:disabled):hover {
  background-color: #545454
}

.section-divider.collapsed {
  background-image: linear-gradient(0deg, #181818 0, hsla(0, 0%, 9%, .7) 20%, hsla(0, 0%, 9%, .4) 30%, transparent 50%);
  margin-top: -6em
}

.titleCard-placeholder {
  background-color: #222;
  border-radius: .3em;
  margin: .5em;
  min-height: 22em;
  overflow: hidden
}

@media (min-aspect-ratio: 1/3) {
  .titleCard-placeholder {
    flex: 0 0 47%;
    min-height: 24em
  }
}

@media (min-aspect-ratio: 7/9) {
  .titleCard-placeholder {
    flex: 0 0 31%;
    min-height: 24em
  }
}

@media (min-aspect-ratio: 20/9) {
  .titleCard-placeholder {
    flex: 0 0 22%;
    min-height: 24em
  }
}

.spatial-audio {
  align-items: center;
  display: flex
}

.spatial-audio svg {
  height: 2em;
  width: 4em
}

.spatial-audio.spatial-audio-icon-de svg {
  height: 2em;
  width: 3.5em
}

.spatial-audio.spatial-audio-icon-fi svg, .spatial-audio.spatial-audio-icon-ko svg, .spatial-audio.spatial-audio-icon-ru svg {
  height: 1.5em;
  width: 3em
}

.spatial-audio.spatial-audio-icon-el svg, .spatial-audio.spatial-audio-icon-es svg, .spatial-audio.spatial-audio-icon-ja svg, .spatial-audio.spatial-audio-icon-pt svg {
  height: 2.5em;
  width: 5em
}

.spatial-audio.spatial-audio-icon-da svg, .spatial-audio.spatial-audio-icon-hi svg, .spatial-audio.spatial-audio-icon-th svg {
  height: 2.5em;
  width: 4.5em
}

.spatial-audio.spatial-audio-icon-nl svg, .spatial-audio.spatial-audio-icon-uk svg {
  height: 2em;
  width: 7em
}

.spatial-audio.spatial-audio-icon-cs svg {
  height: 2em;
  width: 6em
}

.spatial-audio.spatial-audio-icon-hu svg, .spatial-audio.spatial-audio-icon-id svg, .spatial-audio.spatial-audio-icon-it svg, .spatial-audio.spatial-audio-icon-sv svg {
  height: 2em;
  width: 5em
}

.spatial-audio.spatial-audio-icon-fr-ca svg, .spatial-audio.spatial-audio-icon-hr svg, .spatial-audio.spatial-audio-icon-tr svg, .spatial-audio.spatial-audio-icon-vi svg {
  height: 2em;
  width: 5.5em
}

.bd {
  width: 100%
}

.previewModal--wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 200px;
  will-change: scroll-position
}

.previewModal--wrapper *, .previewModal--wrapper :after, .previewModal--wrapper :before {
  box-sizing: inherit
}

.previewModal--wrapper.detail-modal {
  height: 100%;
  width: 100%
}

.previewModal--wrapper button.color-primary:not(.hasLabel) {
  background-color: #fff;
  border-width: 0
}

.previewModal--wrapper button.color-primary:not(.hasLabel) svg {
  width: 2rem
}

.previewModal--wrapper button.color-primary:hover {
  background-color: #e6e6e6 !important;
  border-color: #fff
}

.previewModal--wrapper button.color-supplementary:not(.hasLabel) {
  background-color: rgba(42, 42, 42, .6);
  border: 2px solid hsla(0, 0%, 100%, .5);
}

.previewModal--wrapper button.color-supplementary:not(.hasLabel) svg {
  width: 2rem
}

.previewModal--wrapper.has-smaller-buttons button.color-primary:not(.hasLabel) svg, .previewModal--wrapper.has-smaller-buttons button.color-supplementary:not(.hasLabel) svg {
  width: auto
}

.previewModal--wrapper.has-smaller-buttons button.hasLabel {
  max-height: 42px;
  min-height: 32px
}

.previewModal--wrapper.has-smaller-buttons button:not(.hasLabel):not(.dropdown-toggle) {
  color: hsla(0, 0%, 100%, .5);
  max-height: 42px;
  max-width: 42px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  appearance: none;
}

.previewModal--info .icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewModal--info .icon-wrap svg, .previewModal--info .icon-wrap img {
  width: 20px !important;
}

.previewModal--wrapper.has-smaller-buttons.double-thumbs button:not(.hasLabel):not(.dropdown-toggle) {
  min-height: 25px !important;
  min-width: 25px !important
}

.previewModal--wrapper button.color-supplementary:not(.hasLabel):hover {
  background-color: #2a2a2a;
  border-color: #fff
}

.previewModal--wrapper button.color-supplementary:not(.hasLabel):focus {
  background-color: #2a2a2a
}

.previewModal--backDrop {
  background-color: #000;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%
}

.previewModal--backDrop:focus {
  outline: none
}

.previewModal--container {
  width: 320px;
  background-color: transparent;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  position: absolute;
  will-change: transform;
  opacity: 0;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 3px 10px;
  transition: ease-in-out .3s;
}

.slick-slide:hover .title-card-container {
  scale: 1;
  opacity: 1;
  transition: ease-in-out .3s;
}

.slick-slide:hover .previewModal--container {
  opacity: 1;
}

.slick-slide:hover .title-card-container {
  scale: 1.8;
  opacity: 0;
}

.previewModal--container:focus {
  outline: none
}

.previewModal--container:focus-visible {
  outline: #fff
}

.previewModal--container .previewModal-back, .previewModal--container .previewModal-close {
  cursor: pointer;
  display: none;
  margin: 1em;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2
}

.previewModal--container .previewModal-back svg, .previewModal--container .previewModal-close svg {
  background-color: #181818;
  border-radius: 50%;
  height: 36px;
  padding: 8px;
  width: 36px
}

.previewModal--container .previewModal-back svg:focus, .previewModal--container .previewModal-close svg:focus {
  outline-color: #fff
}

.previewModal--container .previewModal-back {
  display: inline-block;
  left: 0;
  right: auto
}

.previewModal--container .previewModal-back:focus {
  outline-color: #fff
}

.previewModal--container .previewModal--info {
  background-color: #181818;
  position: relative
}

.previewModal--container .previewModal--section-header {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 48px
}

.previewModal--container .previewModal--supplemental-message {
  font-size: 20px
}

.previewModal--container.mini-modal {
  opacity: 0;
}

.previewModal--container.detail-modal .previewModal-back, .previewModal--container.detail-modal .previewModal-close {
  display: block
}

.previewModal--popup {
  z-index: 3
}

.previewModal--popup>div:first-child {
  overflow: visible
}

.previewModal--tooltip-content {
  align-items: center;
  background-color: hsla(0, 0%, 100%, .8);
  box-shadow: 0 3px 10px rgba(0, 0, 0, .75);
  color: #181818;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 4px 8px;
  white-space: nowrap
}

.previewModal--tooltip-content:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid hsla(0, 0%, 100%, .8);
  content: "";
  display: block;
  height: 0;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0
}

.error-title-card {
  background: radial-gradient(ellipse at center, #2b2b2b 0, #000 100%);
  background-color: #000;
  cursor: default;
  margin: 2px
}

@keyframes bob-burnsEffectLeft {
  0% {
    opacity: 0;
    transform: translateZ(0) scale(1)
  }

  10% {
    opacity: 1
  }

  90% {
    opacity: 1;
    transform: translate3d(-8px, 6px, 0) scale(1.06)
  }
}

@keyframes bob-burnsEffectRight {
  0% {
    opacity: 0;
    transform: translateZ(0) scale(1)
  }

  10% {
    opacity: 1
  }

  90% {
    opacity: 1;
    transform: translate3d(8px, 6px, 0) scale(1.06)
  }
}

@keyframes jaw-burnsEffectLeft {
  0% {
    opacity: 0;
    transform: translateZ(0) scale(1)
  }

  10% {
    opacity: 1
  }

  90% {
    opacity: 1;
    transform: translate3d(-14px, 8px, 0) scale(1.05)
  }
}

@keyframes jaw-burnsEffectRight {
  0% {
    opacity: 0;
    transform: translateZ(0) scale(1)
  }

  10% {
    opacity: 1
  }

  90% {
    opacity: 1;
    transform: translate3d(14px, 8px, 0) scale(1.05)
  }
}

.image-rotator {
  overflow: hidden;
  position: relative
}

.image-rotator .image-rotator-image {
  background-size: cover;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0
}

.image-rotator .image-rotator-image.preload-image {
  opacity: .35
}

.maturity-rating {
  display: inline-block
}

.maturity-rating .maturity-number {
  border: 1px solid hsla(0, 0%, 100%, .4);
  font-family: Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif;
  overflow: hidden;
  padding: 0 .4em;
  text-overflow: ellipsis;
  text-transform: uppercase;
  unicode-bidi: normal
    /*!rtl:plaintext*/
  ;
  white-space: nowrap
}

.maturity-rating .maturity-custom-styling, .maturity-rating .maturity-reason {
  vertical-align: middle
}

.maturity-rating .maturity-custom-styling:not(:last-child), .maturity-rating .maturity-reason:not(:last-child) {
  margin: 0 .2em 0 0
}

.maturity-rating .maturity-custom-styling:before, .maturity-rating .maturity-reason:before {
  vertical-align: 8%
}

.maturity-rating.inline-rating {
  align-items: center;
  display: flex
}

.maturity-rating .svg-icon {
  height: 2em;
  margin: 0 .4em 0 0;
  vertical-align: middle;
  width: 2em
}

.maturity-rating .svg-icon-maturity-rating-SouthAfrica-35 {
  height: 2.5em;
  width: 3.2em
}

.maturity-rating .svg-icon-maturity-rating-SouthAfrica-41 {
  height: 2.5em;
  width: 3.7em
}

.maturity-rating .svg-icon-maturity-rating-SouthAfrica-1000, .maturity-rating .svg-icon-maturity-rating-SouthAfrica-110, .maturity-rating .svg-icon-maturity-rating-SouthAfrica-80 {
  height: 2.5em;
  width: 3.5em
}

.maturity-rating .svg-icon-maturity-rating-SouthAfrica-70 {
  height: 2.5em;
  width: 5.5em
}

.complex-maturity-advisories {
  display: flex;
  flex-wrap: wrap
}

.complex-maturity-advisories .maturity-reason-container {
  align-items: center;
  display: flex;
  flex: 0 0 50%;
  padding-top: 5px
}

.complex-maturity-advisories .svg-icon {
  height: 2em;
  margin: 0 .4em 0 0;
  vertical-align: middle;
  width: 2em
}

.maturity-rating-BR {
  border-radius: .15em;
  color: #fff;
  display: inline-block;
  font-family: Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif;
  font-weight: 500;
  letter-spacing: -.05em;
  padding: .15em .1em .15em 0;
  text-align: center;
  width: 1.3em
}

.maturity-rating-BR.maturity-rating-BR-L {
  background-color: #00af51
}

.maturity-rating-BR.maturity-rating-BR-10 {
  background-color: #0cf
}

.maturity-rating-BR.maturity-rating-BR-12 {
  background-color: #fc0
}

.maturity-rating-BR.maturity-rating-BR-14 {
  background-color: #f60
}

.maturity-rating-BR.maturity-rating-BR-16 {
  background-color: #fe0000
}

.maturity-rating-BR.maturity-rating-BR-18 {
  background-color: #000
}

.maturity-rating-BR.maturity-rating-BR-ER {
  background-color: #fff;
  border: .1em solid #000;
  color: #000
}

.maturity-rating-BBFC, .maturity-rating-GB {
  background: transparent url(https://assets.nflxext.com/en_us/icons/maturity_ratings/bbfc_gbguidance_ratings_icons_sprite.png) no-repeat 0 0;
  background-position: 0 -320px;
  border: none;
  display: inline-block;
  height: 25px;
  margin-bottom: -6px;
  padding: 0;
  width: 33px
}

.maturity-rating-BBFC.maturity-rating-BBFC-U, .maturity-rating-BBFC.maturity-rating-GB-U, .maturity-rating-GB.maturity-rating-BBFC-U, .maturity-rating-GB.maturity-rating-GB-U {
  background-position: 0 -240px
}

.maturity-rating-BBFC.maturity-rating-BBFC-PG, .maturity-rating-BBFC.maturity-rating-GB-PG, .maturity-rating-GB.maturity-rating-BBFC-PG, .maturity-rating-GB.maturity-rating-GB-PG {
  background-position: 0 -160px
}

.maturity-rating-BBFC.maturity-rating-BBFC-12, .maturity-rating-BBFC.maturity-rating-GB-12, .maturity-rating-GB.maturity-rating-BBFC-12, .maturity-rating-GB.maturity-rating-GB-12 {
  background-position: 0 -40px
}

.maturity-rating-BBFC.maturity-rating-BBFC-15, .maturity-rating-BBFC.maturity-rating-GB-15, .maturity-rating-GB.maturity-rating-BBFC-15, .maturity-rating-GB.maturity-rating-GB-15 {
  background-position: 0 -80px
}

.maturity-rating-BBFC.maturity-rating-BBFC-18, .maturity-rating-BBFC.maturity-rating-GB-18, .maturity-rating-GB.maturity-rating-BBFC-18, .maturity-rating-GB.maturity-rating-GB-18 {
  background-position: 0 -120px
}

.maturity-rating-BBFC.maturity-rating-BBFC-R18, .maturity-rating-BBFC.maturity-rating-GB-R18, .maturity-rating-GB.maturity-rating-BBFC-R18, .maturity-rating-GB.maturity-rating-GB-R18 {
  background-position: 0 -200px
}

.maturity-rating-BBFC.maturity-rating-BBFC-G, .maturity-rating-BBFC.maturity-rating-GB-G, .maturity-rating-GB.maturity-rating-BBFC-G, .maturity-rating-GB.maturity-rating-GB-G {
  background-position: 0 -280px
}

.maturity-rating--no-border .maturity-number {
  border: 0 solid #000;
  padding: 0
}

.maturity-rating--tag .maturity-number {
  background-color: #414141;
  border: 0 solid #000;
  border-radius: .25em;
  color: hsla(0, 0%, 100%, .7);
  font-size: .875em;
  font-weight: 500;
  line-height: 1.25em;
  padding: .125em .25em
}

.t_5c6351 .thumbs-overview-container {
  display: flex
}

.t_5c6351 .thumbs-component {
  display: flex;
  justify-content: center;
  pointer-events: none
}

.t_5c6351 .thumbs-component .nf-svg-button svg {
  height: 1em;
  width: 1em
}

.t_5c6351 .thumbs-component .thumbs button {
  margin: 0 .25rem
}

.t_5c6351 .thumbs-component .thumb use {
  pointer-events: none
}

.t_5c6351 .thumbs-component .thumb-container {
  color: #fff;
  cursor: pointer;
  display: flex;
  pointer-events: auto;
  position: relative
}

.t_5c6351 .thumbs-component .clear-rating {
  font-family: Times, serif;
  font-size: 24pt;
  margin-left: 8px;
  margin-top: -2px
}

.t_5c6351 .thumbs-component.thumbs-vertical {
  flex-direction: column
}

.t_5c6351 .thumbs-component.thumbs-vertical.animated {
  transition: margin-top .55s cubic-bezier(.86, 0, .07, 1) .4s
}

.t_5c6351 .thumbs-component.thumbs-vertical.animated.rated-down, .t_5c6351 .thumbs-component.thumbs-vertical.animated.rated-up {
  margin-top: -2.4em
}

.t_5c6351 .thumbs-component.thumbs-vertical.rated.rated-down .thumb-up-container.toolkit-thumbs, .t_5c6351 .thumbs-component.thumbs-vertical.rated.rated-up .thumb-down-container.toolkit-thumbs {
  display: none
}

.t_5c6351 .thumbs-component.thumbs-vertical.rated.rated-up .thumb-up-container.toolkit-thumbs {
  margin-bottom: 2.4em
}

.t_5c6351 .thumbs-component.thumbs-vertical.rated.rated-down .thumb-down-container.toolkit-thumbs {
  margin-top: 2.4em
}

.t_5c6351 .thumbs-component.animated.thumbs-vertical {
  display: block
}

.t_5c6351 .thumbs-component.animated.thumbs-vertical.rated .thumb-up-container {
  transform: translateY(2.4em);
  transition: transform .55s cubic-bezier(.86, 0, .07, 1) .4s, opacity .15s linear .55s
}

.t_5c6351 .thumbs-component.animated.thumbs-vertical.unrated .thumb-up-container {
  transform: translate(0);
  transition: transform .55s cubic-bezier(.86, 0, .07, 1) .4s, opacity .15s linear .65s
}

.t_5c6351 .thumbs-component.animated.thumbs-horizontal.rated .thumb-down-container {
  transform: translate(-2.4em);
  transition: transform .55s cubic-bezier(.86, 0, .07, 1) .4s, opacity .15s linear .55s
}

.t_5c6351 .thumbs-component.animated.thumbs-horizontal.unrated .thumb-down-container {
  transform: translate(0);
  transition: transform .55s cubic-bezier(.86, 0, .07, 1) .4s, opacity .15s linear .65s
}

.t_5c6351 .thumbs-component.animated.thumbs-horizontal-align-right.rated .thumb-up-container {
  transform: translate(2.4em);
  transition: transform .55s cubic-bezier(.86, 0, .07, 1) .4s, opacity .15s linear .55s
}

.t_5c6351 .thumbs-component.animated.thumbs-horizontal-align-right.unrated .thumb-up-container {
  transform: translate(0);
  transition: transform .55s cubic-bezier(.86, 0, .07, 1) .4s, opacity .15s linear .65s
}

.t_5c6351 .thumbs-component.animated.rated-down .thumb-up-container, .t_5c6351 .thumbs-component.animated.rated-up .thumb-down-container {
  opacity: 0;
  transition: opacity .15s linear .55s;
  z-index: -1
}

.t_5c6351 .thumbs-component.animated.unrated .thumb-container {
  opacity: 1;
  transition: opacity .15s linear .65s
}

.t_5c6351 .ratingCTA .label {
  transition: opacity .15s linear .5s
}

.t_5c6351 .ratingCTA .unratedLabel {
  left: 0;
  position: absolute
}

.t_5c6351 .rated .ratingCTA {
  transform: translate(-2.4em);
  transition: transform .55s cubic-bezier(.86, 0, .07, 1) .4s
}

.t_5c6351 .rated .ratingCTA .ratedLabel {
  opacity: 1
}

.t_5c6351 .rated .ratingCTA .unratedLabel {
  opacity: 0
}

.t_5c6351 .unrated .ratingCTA {
  transform: translate(0);
  transition: transform .55s cubic-bezier(.86, 0, .07, 1) .4s
}

.t_5c6351 .unrated .ratingCTA .ratedLabel {
  opacity: 0
}

.t_5c6351 .unrated .ratingCTA .unratedLabel {
  opacity: 1
}

.mylist-button:focus, .mylist-button:hover, .remindme-button:focus, .remindme-button:hover {
  outline: none;
  transform: scale(1.05);
  transition: transform .35s cubic-bezier(.86, 0, .07, 1)
}

.NFPlayer {
  height: 100%;
  transition: width .3s, height .3s, top .3s, left .3s, opacity .3s;
  width: 100%
}

.NFPlayer.postplay {
  border: .15em solid #eee;
  height: 14.16666667em;
  left: 1.5em;
  top: 1.5em;
  width: 22.22222222em;
  z-index: 2
}

.NFPlayer.ended .VideoContainer {
  opacity: 0
}

.NFPlayer.can-resume:hover {
  border: .15em solid #fff;
  cursor: pointer
}

.NFPlayer button:focus, .NFPlayer div:focus, .NFPlayer li:focus, .NFPlayer span:focus {
  outline: none
}

.NFPlayer.container-large {
  font-size: 24px
}

.NFPlayer.container-small {
  font-size: 18px
}

.NFPlayer.nf-player-container {
  background: #000;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 1
}

.NFPlayer.nf-player-container:focus {
  outline: none
}

.NFPlayer.nf-player-container:after {
  content: "\e641";
  font-family: nf-icon;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  visibility: hidden
}

.NFPlayer.nf-player-container.active {
  cursor: default
}

.NFPlayer.nf-player-container.inactive {
  cursor: none
}

.NFPlayer.nf-player-container .player-view-childrens {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.NFPlayer.nf-player-container .player-view-childrens-interactive {
  z-index: 2
}

.NFPlayer.nf-player-container .svg-icon use {
  pointer-events: none
}

.NFPlayer.fast-forward .VideoContainer {
  opacity: 0
}

.NFPlayer.fast-forward .scrubber-head {
  transition: left .5s ease
}

.NFPlayer.fast-forward .current-progress {
  transition: width .5s ease
}

.NFPlayer--scrubbing-surface {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.nf-kb-nav-wrapper.kb-nav .nfp-button-control:focus, .nf-kb-nav-wrapper.kb-nav .scrubber-head:focus, .nf-kb-nav-wrapper.kb-nav .speed-controller .value-container:focus .dot {
  outline: 3px solid #fff
}

.nf-kb-nav-wrapper.kb-nav .audio-subtitle-controller .track:focus {
  box-shadow: inset 0 0 0 3px #fff
}

.nf-kb-nav-wrapper.kb-nav .episode-list .header:focus .focus-rect, .nf-kb-nav-wrapper.kb-nav .nfp-episode-expander:focus .focus-rect, .nf-kb-nav-wrapper.kb-nav .nfp-season-preview:focus .focus-rect {
  border: 3px solid #fff
}

.nf-kb-nav-wrapper.kb-nav .episode-list .header:focus .focus-rect, .nf-kb-nav-wrapper.kb-nav .nfp-season-preview:focus .focus-rect {
  box-sizing: border-box;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%
}

.touchable {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: none
}

.nfp .svg-icon, .nfp-player-upsell-view .svg-icon {
  stroke-width: 0;
  stroke: #fff;
  border: 1px solid transparent;
  font-size: 1.1em;
  height: 1.6em;
  margin: 0 -1px;
  overflow: visible;
  width: 1.6em
}

.nfp .svg-icon.svg-icon-nfplayerMdx, .nfp .svg-icon.svg-icon-nfplayerMdxFull, .nfp-player-upsell-view .svg-icon.svg-icon-nfplayerMdx, .nfp-player-upsell-view .svg-icon.svg-icon-nfplayerMdxFull {
  transform: scale(.84)
}

.ellipsize-text {
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.PollingToggle--popup {
  cursor: pointer;
  padding: 1em
}

.PollingToggle--popup:hover {
  text-decoration: underline
}

.PollingToggle--popup-text {
  direction: ltr;
  white-space: nowrap
}

.ReportAProblem--popup {
  cursor: pointer;
  padding: 1em
}

.ReportAProblem--popup:hover {
  text-decoration: underline
}

.ReportAProblem--popup-text {
  direction: ltr;
  white-space: nowrap
}

.ReportAProblemPopupContainer {
  position: relative
}

.ReportAProblemDialog--container, .ReportAProblemDialog--focus-trap {
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.ReportAProblemDialog--container {
  align-items: center;
  animation: fadeIn .25s forwards;
  background: rgba(0, 0, 0, .8);
  justify-content: center
}

.ReportAProblemDialog--container.ReportAProblemDialog--container-leaving {
  animation: fadeOut .25s forwards
}

.ReportAProblemDialog--dialog-box {
  background-color: #141414;
  border: 2px solid #404040;
  border-top: none;
  box-shadow: 0 -4px #bf1315;
  box-sizing: border-box;
  color: #fff;
  font-size: 1.4rem;
  max-height: 100%;
  overflow-y: auto;
  padding: 2rem;
  position: relative;
  width: 50rem
}

.ReportAProblemDialog--submit-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #0080ff;
  background-color: #bf1315;
  background-image: linear-gradient(180deg, #0080ff, #0277ec);
  border: 1px solid transparent;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55);
  color: #fff;
  display: flex;
  margin: 1.5rem 0 0;
  padding: .75rem
}

.ReportAProblemDialog--submit-button.hover, .ReportAProblemDialog--submit-button:hover {
  background: #2490fd;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55)
}

.ReportAProblemDialog--submit-button.active, .ReportAProblemDialog--submit-button:active {
  background: #0166c9
}

.ReportAProblemDialog--submit-button.disabled {
  background-color: #0080ff;
  background-image: linear-gradient(180deg, #0080ff, #0272e2);
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .3), 0 1px 0 rgba(0, 0, 0, .55)
}

.ReportAProblemDialog--submit-button:focus, .ReportAProblemDialog--submit-button:hover {
  border-color: #fff
}

.ReportAProblemDialog--form-container {
  display: flex;
  flex-direction: column;
  min-height: 15rem;
  overflow: hidden;
  position: relative
}

.ReportAProblemDialog--form-container.ReportAProblemDialog--request-complete {
  height: 15rem
}

.ReportAProblemDialog--form-contents {
  max-height: 56rem;
  overflow: hidden;
  transition: max-height .25s ease-out, opacity .25s ease-out
}

.ReportAProblemDialog--form-contents.ReportAProblemDialog--request-sent {
  max-height: 0;
  opacity: 0
}

.ReportAProblemDialog--completion-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity .25s ease-out
}

.ReportAProblemDialog--completion-container.ReportAProblemDialog--request-complete {
  max-height: 50rem;
  opacity: 1
}

.ReportAProblemDialog--close-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 2rem;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 2rem;
  z-index: 1
}

.ReportAProblemDialog--close-button .svg-icon.svg-icon-nfplayerExit {
  fill: #666;
  height: 100%;
  width: 100%
}

.ReportAProblemDialog--close-button:focus .svg-icon.svg-icon-nfplayerExit, .ReportAProblemDialog--close-button:hover .svg-icon.svg-icon-nfplayerExit {
  fill: #fff
}

.ReportAProblemDialog--header, .ReportAProblemDialog--sub-header {
  margin: 0;
  padding: 0
}

.ReportAProblemDialog--header {
  font-size: 2.2rem
}

.ReportAProblemDialog--sub-header {
  color: #b3b3b3;
  font-size: 1.4rem;
  font-weight: 400;
  padding: .2rem 0
}

.ReportAProblemDialog--form {
  margin: 2rem 0 0
}

.ReportAProblemDialog--fieldset {
  align-items: center;
  display: flex;
  margin: 0 0 1rem
}

.ReportAProblemDialog--fieldset:hover .ReportAProblemDialog--checkbox {
  border-color: #fff
}

.ReportAProblemDialog--message-fieldset {
  flex-direction: column;
  max-height: 0;
  opacity: 0;
  transition: max-height .25s ease-out, opacity .25s ease-out
}

.ReportAProblemDialog--message-fieldset.ReportAProblemDialog--message-fieldset-visible {
  max-height: 20rem;
  opacity: 1
}

.ReportAProblemDialog--label-container {
  display: flex;
  flex: 1 0;
  flex-direction: column
}

.ReportAProblemDialog--checkbox-label {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 500
}

.ReportAProblemDialog--checkbox-label-definition {
  color: #b3b3b3;
  display: block;
  font-size: 1.2rem;
  font-weight: 400
}

.ReportAProblemDialog--checkbox-container {
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: 0 1rem 0 0;
  position: relative;
  width: 2rem
}

.ReportAProblemDialog--checkbox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

.ReportAProblemDialog--checkbox-input:focus {
  outline: none
}

.ReportAProblemDialog--checkbox-input:focus+.ReportAProblemDialog--checkbox {
  border-color: #fff
}

.ReportAProblemDialog--checkbox-input:checked+.ReportAProblemDialog--checkbox:before {
  color: #bf1315;
  content: "✔"
}

.ReportAProblemDialog--checkbox {
  align-items: center;
  background: #000;
  border: 1px solid #979797;
  display: flex;
  font-size: 1.6rem;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.ReportAProblemDialog--textarea {
  background-color: #000;
  border: 1px solid #979797;
  color: #fff;
  resize: none;
  width: 50%
}

.ReportAProblemDialog--details-header {
  font-weight: 400;
  margin: 2rem 0 .5rem;
  padding: 0
}

.ReportAProblemDialog--details-question, .ReportAProblemDialog--details-question-optional {
  display: inline-block
}

.ReportAProblemDialog--details-question-optional {
  color: #b3b3b3;
  padding-left: .5rem
}

.ReportAProblemDialog--confirmation-title {
  display: block;
  flex: 1;
  font-size: 1.8rem;
  font-weight: 500;
  margin: .25rem 0 1rem
}

.ReportAProblemDialog--confirmation-subtext {
  color: #b3b3b3;
  display: block;
  font-size: 1.2rem
}

.ReportAProblemDialog--button-row {
  align-items: center;
  display: flex;
  margin: 3rem 0 0
}

.ReportAProblemDialog--finish-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #0080ff;
  background-color: #bf1315;
  background-image: linear-gradient(180deg, #0080ff, #0277ec);
  border: 1px solid transparent;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55);
  color: #fff;
  display: flex;
  margin: 0 3rem 0 0;
  padding: .75rem 2rem
}

.ReportAProblemDialog--finish-button.hover, .ReportAProblemDialog--finish-button:hover {
  background: #2490fd;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .55)
}

.ReportAProblemDialog--finish-button.active, .ReportAProblemDialog--finish-button:active {
  background: #0166c9
}

.ReportAProblemDialog--finish-button.disabled {
  background-color: #0080ff;
  background-image: linear-gradient(180deg, #0080ff, #0272e2);
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .3), 0 1px 0 rgba(0, 0, 0, .55)
}

.ReportAProblemDialog--finish-button:focus, .ReportAProblemDialog--finish-button:hover {
  border-color: #fff
}

.ReportAProblemDialog--help-link {
  display: flex;
  text-decoration: none
}

.ReportAProblemDialog--help-link:focus {
  outline: none
}

.ReportAProblemDialog--help-link:focus, .ReportAProblemDialog--help-link:hover {
  text-decoration: underline
}

.ReportAProblemDialog--chevron {
  align-items: center;
  display: flex;
  justify-content: center
}

.ReportAProblemDialog--chevron:before {
  border-style: solid;
  border-width: .1em .1em 0 0;
  content: "";
  display: block;
  height: .45em;
  left: 0;
  margin-left: .5rem;
  position: relative;
  transform: rotate(45deg);
  width: .45em
}

.ReportAProblemDialog--error-message {
  color: #b3b3b3;
  flex: 1;
  margin: 1rem 0 0
}

.nfp-button-control {
  fill: #fff;
  align-items: center;
  background: transparent;
  border: 0;
  box-sizing: content-box;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  transition: opacity .3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.nfp-button-control:focus {
  outline: none
}

.nfp-control-row {
  display: flex;
  flex-direction: column
}

.nfp-control-row.top-right-controls {
  align-content: flex-start;
  align-items: flex-end;
  align-self: flex-start
}

.nfp-control-row.top-left-controls {
  align-content: flex-start;
  align-items: flex-start;
  align-self: flex-start
}

.nfp-popup-control {
  box-sizing: border-box;
  display: flex;
  position: relative
}

.nfp-popup-control .popup-content-wrapper {
  bottom: 3.5em;
  margin: -.5em;
  opacity: 0;
  padding: .5em;
  pointer-events: none;
  position: absolute;
  right: .5em
    /*!rtl:ignore*/
  ;
  transform: scale(.95) translateZ(0);
  transition: opacity .16s ease, transform .2s ease, visibility 0s linear .16s;
  visibility: hidden;
  will-change: transform, opacity
}

.nfp-popup-control .popup-content-wrapper.popup-content-wrapper--centered-content {
  left: auto
    /*!rtl:ignore*/
  ;
  right: 0
    /*!rtl:ignore*/
  ;
  width: 100%
}

.nfp-popup-control .popup-content-wrapper.popup-content-wrapper--centered-content .popup-content {
  margin: 0 auto
}

.nfp-popup-control .popup-content-wrapper.active {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
  transition-delay: 0s;
  visibility: visible;
  z-index: 1
}

.nfp-popup-control .popup-content-wrapper.keep-right {
  left: auto
    /*!rtl:ignore*/
  ;
  right: 0
    /*!rtl:ignore*/
}

.nfp-popup-control .popup-content-wrapper.no-padding {
  padding: 0
}

.nfp-popup-control .popup-content {
  background: #262626;
  border-radius: .3em;
  font-size: .8em;
  overflow: hidden
}

.nfp-popup-control--static-position {
  position: static
}

.popup-content-wrapper--top-placement {
  bottom: 100%
}

.nfp-control-row.top-left-controls .popup-content-wrapper.popup-content-wrapper--fill-screen, .nfp-control-row.top-right-controls .popup-content-wrapper.popup-content-wrapper--fill-screen {
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.nfp .scrubber-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 3.3em;
  position: relative;
  touch-action: none;
  width: 100%
}

.nfp .scrubber-container:focus {
  outline: none
}

.nfp .scrubber-container .scrubber-bar {
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  width: auto
}

.nfp .scrubber-container .track {
  background: #5b5b5b;
  height: .5em;
  position: relative;
  transition: height .2s ease;
  will-change: height
}

.nfp .scrubber-container .buffered, .nfp .scrubber-container .current-progress {
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.nfp .scrubber-container .current-progress {
  background: #009779
}

.nfp .scrubber-container .buffered {
  background: hsla(0, 0%, 100%, .2)
}

.nfp .scrubber-container .scrubber-head {
  background: #009779;
  border-radius: 50%;
  cursor: pointer;
  height: 2.3em;
  margin: -1.15em;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: scale(1) translateZ(0);
  transform-origin: 50% 50%;
  transition: transform .2s ease;
  width: 2.3em
}

.nfp .scrubber-container .scrubber-head:hover {
  transform: scale(1.2) translateZ(0)
}

.nfp .scrubber-container .scrubber-head:focus {
  outline: none
}

.nfp .scrubber-container .play-head {
  border-left: 1px solid hsla(0, 0%, 100%, .5);
  border-right: 1px solid hsla(0, 0%, 100%, .5);
  display: none;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  transform: translateZ(0)
}

.nfp .is-scrubbing .scrubber-head {
  transform: scale(1.2) translateZ(0)
}

.nfp .is-scrubbing .scrubber-container .track, .nfp .scrubber-container:hover .track {
  height: 1em
}

.nfp .trickplay {
  border-radius: .3em;
  bottom: 2.5em;
  display: none;
  overflow: hidden;
  pointer-events: none;
  position: absolute
}

.nfp .trickplay:focus {
  outline: none
}

.nfp .trickplay.trickplay-visible {
  display: block
}

.nfp .trickplay.trickplay-text-and-image {
  background: #262626;
  box-shadow: 0 0 1em rgba(0, 0, 0, .5);
  box-sizing: border-box;
  padding-bottom: 0
}

.nfp .trickplay.trickplay-text-and-image img {
  display: block;
  width: 100%
}

.nfp .trickplay.trickplay-text-and-image .tp-text {
  font-size: 1.8em;
  padding: .5em 0;
  text-align: center
}

.nfp .trickplay.trickplay-text-only {
  overflow: visible;
  text-align: center
}

.nfp .trickplay.trickplay-text-only .tp-text {
  background: #262626;
  border-radius: .3em;
  box-shadow: 0 0 1em rgba(0, 0, 0, .5);
  font-size: 1.8em;
  left: -50%
    /*!rtl:ignore*/
  ;
  margin: 0 auto;
  padding: 1em;
  position: relative;
  text-align: center
}

.time-remaining__time {
  font-size: 1.8em
}

.time-remaining--modern {
  align-items: flex-end;
  display: flex;
  flex-direction: column
}

.nfp-episode-selector .pane {
  position: absolute
}

.nfp-season-preview {
  align-items: center;
  padding: .5em 0
}

.nfp-season-preview .title {
  font-size: .72222222em;
  font-weight: 500
}

.nfp-episode-selector .of-hidden {
  transform: translateZ(0)
}

.nfp-episode-selector .show-title {
  font-size: .94444444em
}

.nfp-episode-preview {
  box-sizing: border-box;
  padding: 1em
}

.nfp-episode-preview .thumbnail {
  margin: 0 0 0 1em
}

.nfp-episode-preview .thumbnail-image {
  background-repeat: no-repeat
}

.controls-full-hit-zone {
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.center-controls {
  align-items: center;
  display: flex;
  font-size: 4em;
  height: 100%;
  justify-content: center;
  opacity: 0;
  width: 100%
}

.center-controls.active {
  opacity: 1
}

.audio-subtitle-controller .track-list li .video-controls-check {
  margin-top: -.9em;
  top: 50%
}

.audio-subtitle-controller .track-list li .video-controls-check.scroll-gradient {
  margin-left: .8em
}

.audio-subtitle-controller .track-list li .video-controls-check.scroll-gradient .svg-icon-nfplayerCheck {
  width: 2vh
}

.nfp .nfp-episode-selector .header-title {
  font-weight: 500
}

.PlayerControlsNeo__all-controls, .PlayerControlsNeo__layout {
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.PlayerControlsNeo__layout {
  background-color: transparent;
  transition: background-color .3s ease
}

.PlayerControlsNeo__layout--dimmed {
  background-color: rgba(0, 0, 0, .5)
}

.PlayerControlsNeo__layout--dimmed .PlayerControlsNeo__core-controls, .PlayerControlsNeo__layout--inactive .PlayerControlsNeo__core-controls, .PlayerControlsNeo__progress-control-row--row-hidden {
  opacity: 0
}

.PlayerControlsNeo__layout--active .recap-lower, .PlayerControlsNeo__layout--timedout .PlayerControlsNeo__core-controls {
  opacity: 1
}

.PlayerControlsNeo__layout--active .PlayerControlsNeo__gradient-bottom, .PlayerControlsNeo__layout--active .PlayerControlsNeo__gradient-top {
  opacity: .75
}

.PlayerControlsNeo__bottom-controls {
  bottom: 0;
  box-sizing: border-box;
  direction: ltr
    /*!rtl:ignore*/
  ;
  left: 0;
  position: absolute;
  right: 0;
  transition-delay: 0s, 0s;
  transition-duration: .4s, 0s;
  transition-property: opacity, visibility;
  transition-timing-function: ease-out;
  visibility: visible;
  width: 100%
}

.PlayerControlsNeo__bottom-controls .nfp-popup-control {
  display: flex;
  flex: 0 1 auto;
  font-size: 2.8em;
  height: 3.6em;
  width: 3.4em
}

.PlayerControlsNeo__bottom-controls .nfp-popup-control .nfp-button-control {
  font-size: inherit;
  height: 100%;
  width: 100%
}

.PlayerControlsNeo__bottom-controls .nfp-button-control {
  border: 3px solid transparent;
  border-radius: .3em;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  font-size: 2.8em;
  height: 3.6em;
  padding: 0 0 .6em;
  width: 3.4em
}

.PlayerControlsNeo__bottom-controls .nfp-button-control.badge-text:before {
  background: red;
  border-radius: .1em;
  content: attr(data-badge-text);
  font-size: .5em;
  font-weight: 500;
  padding: .125em .5em;
  position: absolute;
  right: -.85em;
  top: .6125em
}

.PlayerControlsNeo__bottom-controls .tooltip-button-pos-bottom:after {
  background: #000;
  border: .15em solid #414141;
  border-radius: .2em;
  font-size: .45em;
  font-weight: 500;
  padding: .2em .45em;
  top: 70%
}

.PlayerControlsNeo__bottom-controls--faded {
  opacity: 0;
  transition-delay: 0s, .4s;
  visibility: hidden
}

.PlayerControlsNeo__bottom-controls--remove-full-width {
  right: inherit;
  width: auto
    /*!rtl:ignore*/
}

.nf-kb-nav-wrapper.kb-nav .PlayerControlsNeo__bottom-controls .nfp-button-control:focus {
  border: 3px solid #fff;
  outline: 0
}

.PlayerControlsNeo__button-control-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: .9em
}

.PlayerControlsNeo__button-control-row-left, .PlayerControlsNeo__button-control-row-right {
  align-items: center;
  display: flex;
  padding: 0 4em
}

.PlayerControlsNeo__button-control-row-left {
  justify-content: flex-start
}

.PlayerControlsNeo__button-control-row-right {
  justify-content: flex-end
}

.PlayerControlsNeo__bottom-controls .promotional-metadata, .PlayerControlsNeo__progress-control-row {
  transition: opacity .2s ease
}

.PlayerControlsNeo__progress-control-row {
  position: relative
}

.PlayerControlsNeo__progress-container {
  align-items: center;
  display: flex;
  padding-left: 3em;
  padding-right: 3em;
  transition: opacity .2s ease
}

.PlayerControlsNeo__bottom-controls .promotional-metadata {
  margin-bottom: 1.8em;
  padding: .4em 6.6em .4em 7.6em
}

.PlayerControls--control-element {
  transform: translateZ(0) scale(1);
  transition: transform .25s cubic-bezier(.5, 0, .1, 1), opacity .25s
}

.PlayerControls--control-element.progress-control {
  align-items: center;
  display: flex;
  margin: 0 2.5em 0 0
    /*!rtl:ignore*/
  ;
  width: 100%
}

.PlayerControls--control-element.video-title {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  margin: 0 1.5em;
  overflow: hidden;
  padding: 0 0 1.68em
}

.PlayerControls--control-element.video-title .ellipsize-text {
  direction: ltr;
  width: 100%
}

.PlayerControls--control-element.video-title h4, .PlayerControls--control-element.video-title span {
  display: inline-block
}

.PlayerControls--control-element.video-title h4 {
  font-size: 2.2em;
  margin: 0 .5em 0 0
    /*!rtl:ignore*/
  ;
  padding: 0;
  white-space: nowrap
}

.PlayerControls--control-element.video-title span {
  color: #adadad;
  display: inline;
  font-size: 2em;
  padding-right: .5em
    /*!rtl:ignore*/
}

.PlayerControls--control-element--active {
  transform: scale(1.2)
}

.PlayerControls--control-element-blurred.nfp-button-control {
  opacity: .5
}

.PlayerControlsNeo__all-controls--low-power {
  display: none
}

.PlayerControlsNeo__core-controls {
  transition: opacity .3s ease
}

.PlayerControlsNeo__core-controls .top-left-controls {
  margin: 4em
}

.PlayerControlsNeo__core-controls .button-nfplayerBack {
  font-size: 2.8em
}

.PlayerControlsNeo__core-controls .button-nfplayerSpeed .PlayerControls__button-label {
  font-size: .85em
}

.PlayerControlsNeo__gradient-bottom, .PlayerControlsNeo__gradient-top {
  height: 20%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity .3s ease;
  will-change: opacity
}

.PlayerControlsNeo__gradient-bottom.promotional-metadata-gradient {
  height: 45%
}

.PlayerControlsNeo__gradient-bottom {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .7) 0, transparent);
  bottom: 0
}

.PlayerControlsNeo__gradient-top {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .7) 0, transparent);
  top: 0
}

@keyframes buttonFaderWithTranslate {
  0% {
    opacity: 1;
    transform: translateY(-50%) translateX(-50%) scale(1)
  }

  to {
    opacity: 0;
    transform: translateY(-50%) translateX(-50%) scale(1.3)
  }
}

.nfp .episode-list .nfp-episode-preview.expanded:hover .PlayIcon {
  opacity: 1
}

.nfp .episode-list .nfp-episode-preview .PlayIcon {
  transition: opacity .2s ease-out
}

.nfp.mdx-mount-point .svg-icon-nfplayerStop {
  transform: scale(.6)
}

.nfp.mdx-mount-point .controls .main-controls .popup-content:not(.volume-controller) {
  background: #1e1e1e
}

.nfp.mdx-mount-point .PlayerControls--control-element .svg-icon {
  font-size: 1.2em
}

.nfp.mdx-mount-point .popup-content-wrapper {
  bottom: 2.5em;
  font-size: 1.2rem
}

.nfp.mdx-mount-point .controls .main-controls .controls-container {
  padding: 0
}

.nfp.mdx-mount-point .scrubber-container .track {
  height: .2em
}

.nfp.mdx-mount-point .scrubber-container .scrubber-head {
  height: 1.5em;
  margin: -.75em;
  width: 1.5em
}

.nfp.mdx-mount-point .progress-control {
  margin: 0 1.5rem
}

.nfp.mdx-mount-point .trickplay.trickplay-text-only .tp-text {
  bottom: -.75rem;
  font-size: 1rem
}

.nfp.mdx-mount-point .time-remaining {
  align-items: center;
  display: flex;
  margin: 0 1rem 0 0
    /*!rtl:ignore*/
}

.nfp .volume-controller {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  pointer-events: auto
}

.nfp .volume-controller.popup-content {
  background: transparent
}

.nfp .volume-controller .slider-container {
  align-content: center;
  align-items: center;
  background: #262626;
  border-radius: .3em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 8em;
  padding: 1em 0;
  transition: opacity .3s ease;
  width: 1.5em
}

.nfp .volume-controller .slider-container:focus {
  outline: none
}

.nfp .volume-controller .slider-bar-container {
  background: #5b5b5b;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: .4em
}

.nfp .volume-controller .slider-bar-percentage {
  background: #009779;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0
}

.nfp .volume-controller .scrubber-target {
  height: 1em;
  left: 50%
    /*!rtl:ignore*/
  ;
  margin: -.5em;
  position: absolute;
  transform: scale(1);
  transition: transform .2s ease;
  width: 1em
}

.nfp .volume-controller .scrubber-target:hover {
  transform: scale(1.2)
}

.nfp .volume-controller .scrubber-head {
  background: #009779;
  border-radius: 50%;
  height: 100%;
  position: absolute;
  width: 100%
}

.nfp .volume-controller .scrubber-head:focus {
  outline: none
}

.nfp .recap-lower {
  bottom: 9.5em;
  font-size: 1.6em;
  right: 4em
}

@keyframes flash {
  0% {
    background-color: #ccc
  }

  50%, to {
    background-color: transparent
  }
}

.nfp .skip-credits {
  bottom: 14.5em;
  position: absolute;
  right: 6em;
  z-index: 1
}

.nfp .skip-credits .nf-icon-button {
  bottom: 0;
  display: inline-block;
  margin: 0;
  opacity: 1;
  overflow: hidden;
  padding: 0;
  position: relative;
  right: 0;
  transition: width .65s cubic-bezier(.23, 1, .32, 1), right .65s cubic-bezier(.23, 1, .32, 1), opacity 325ms linear;
  width: 100%
}

.nfp .skip-credits .nf-flat-button-text {
  display: inline-block;
  padding: 10px;
  transform: translateX(0);
  transition: transform .65s cubic-bezier(.23, 1, .32, 1);
  white-space: nowrap
}

.nfp .skip-credits.skip-credits-hidden {
  cursor: none;
  pointer-events: none
}

.nfp .skip-credits.skip-credits-hidden .nf-icon-button {
  opacity: 0;
  right: -90%;
  transition: width .65s cubic-bezier(.55, .055, .675, .19), right .65s cubic-bezier(.55, .055, .675, .19), opacity 325ms linear 325ms;
  width: 20%
}

.nfp .skip-credits.skip-credits-hidden .nf-flat-button-text {
  transform: translateX(-60%);
  transition: transform .65s cubic-bezier(.55, .055, .675, .19)
}

.nfp .skip-credits.skip-credits-flash .nf-icon-button {
  animation: flash 1s
}

.button-nfplayerBack.tooltip-button:after {
  top: 50%;
  transform: translateY(-50%)
}

.nfp.AkiraPlayer .player-loading .top-left-controls, .nfp.AkiraPlayer .player-loading .top-right-controls, .watch-video .player-loading .top-left-controls, .watch-video .player-loading .top-right-controls {
  margin: 4.5em
}

.nfp.AkiraPlayer .player-loading .top-right-controls, .watch-video .player-loading .top-right-controls {
  position: absolute;
  right: 0;
  top: 0
}

.nfp.AkiraPlayer .player-loading .gradient, .watch-video .player-loading .gradient {
  height: 22em
}

.nfp.AkiraPlayer .player-loading .player-title-evidence, .watch-video .player-loading .player-title-evidence {
  text-shadow: 0 .1em .3em rgba(0, 0, 0, .3);
  transform: translateZ(0)
}

.nfp.AkiraPlayer .player-loading .playable-title, .nfp.AkiraPlayer .player-loading .title, .watch-video .player-loading .playable-title, .watch-video .player-loading .title {
  word-wrap: break-word;
  font-weight: 500;
  margin: 0;
  padding: 0
}

.nfp.AkiraPlayer .player-loading .title, .watch-video .player-loading .title {
  font-size: 4em
}

.nfp.AkiraPlayer .player-loading .playable-title, .watch-video .player-loading .playable-title {
  font-size: 3.3em
}

.nfp.AkiraPlayer .player-loading .button-nfplayerExit, .watch-video .player-loading .button-nfplayerExit {
  font-size: 1.6em;
  height: 3em;
  opacity: .5;
  transition: opacity .2s ease-out;
  width: 100%
}

.nfp.AkiraPlayer .player-loading .button-nfplayerExit:hover, .watch-video .player-loading .button-nfplayerExit:hover {
  opacity: 1
}

.nfp.AkiraPlayer .advisory-content {
  font-size: 1.6em
}

.nfp-fatal-error-view .button-nfplayerBack {
  font-size: 1.5em;
  left: 0;
  margin: 2.2em;
  position: absolute;
  top: 0
}

.AkiraPlayer .adult-verification-prompt-content, .AkiraPlayer .player-pin-entry {
  font-size: 2em
}

.button-nfplayerBack .svg-icon-nfplayerBack {
  transform: rotate(0)
    /*!rtl: rotate(180deg) */
}

.WatchNext-more-container .nfp-popup-control .popup-content-wrapper {
  bottom: 2em;
  position: absolute;
  right: 0;
  transform: translateZ(0) translateX(0) scale(1)
}

.svg-icon.svg-icon-nfplayerBackTen, .svg-icon.svg-icon-nfplayerFastForward, .svg-icon.svg-icon-nfplayerReportAProblem {
  direction: ltr
    /*!rtl:ignore*/
}

.audio-subtitle-controller .track-list .track, .nfp .episode-list .header, .nfp .next-episode.popup-content>.header>.header-title, .nfp-episode-selector .season-list {
  direction: ltr
}

.tooltip-button {
  position: relative
}

.tooltip-button:after {
  content: attr(data-tooltip);
  font-size: .7em;
  opacity: 0;
  position: absolute;
  transition: visibility 0s linear .1s, opacity .1s linear, transform .1s linear, margin-left .1s linear, margin-right .1s linear;
  visibility: hidden;
  white-space: nowrap
}

.tooltip-button:hover:after {
  opacity: 1;
  transition-delay: 0s;
  visibility: visible
}

.tooltip-button-before-element-active:before {
  opacity: 0;
  transition: visibility 0s linear .1s, opacity .1s linear, transform .1s linear, margin-left .1s linear, margin-right .1s linear;
  visibility: hidden
}

.tooltip-button-before-element-active:hover:before {
  opacity: 1;
  transition-delay: 0s;
  visibility: visible
}

.tooltip-button-align-center:after {
  left: 50%
    /*!rtl:ignore*/
  ;
  transform: translateX(-50%)
    /*!rtl:ignore*/
}

.tooltip-button-align-left:after {
  left: 0
    /*!rtl:ignore*/
}

.tooltip-button-align-right:after {
  right: 0
    /*!rtl:ignore*/
}

.tooltip-button-align-left.tooltip-button-pos-center:after {
  left: auto
    /*!rtl:ignore*/
  ;
  right: 100%
    /*!rtl:ignore*/
}

.tooltip-button-align-right.tooltip-button-pos-center:after {
  left: 100%
    /*!rtl:ignore*/
  ;
  right: auto
    /*!rtl:ignore*/
}

.tooltip-button-pos-bottom:after {
  top: 85%
}

.tooltip-button-pos-top:after {
  bottom: 85%
}

.PlayIcon {
  background-image: url(https://assets.nflxext.com/en_us/buttons/play-button-114.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 100%
}

.PlayIcon-visibility-hidden {
  visibility: hidden
}

.PlayIcon-transparent {
  opacity: 0
}

.PlayIcon-semi-opaque {
  opacity: .15
}

.PlayIcon-opaque {
  opacity: 1
}

.PlayIcon-fade-on-hover {
  transition: opacity .2s ease-out
}

.PlayIcon-fade-on-hover:hover {
  opacity: 1
}

.PlayIcon-grow-on-hover {
  transition: transform .2s ease-out
}

.PlayIcon-grow-on-hover:hover {
  transform: scale(1.2)
}

.PlayIcon-size-medium {
  height: 2.5em;
  width: 2.5em
}

.PlayIcon-size-large {
  height: 3em;
  width: 3em
}

.PlayIcon-centered {
  left: 50%;
  position: absolute;
  top: 50%
}

.PlayIcon-centered.PlayIcon-size-medium {
  margin-left: -1.25em;
  margin-top: -1.25em
}

.PlayIcon-centered.PlayIcon-size-large {
  margin-left: -1.5em;
  margin-top: -1.5em
}

.nfp-episode-preview {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: .77777778em 0;
  width: 100%
}

.nfp-episode-preview.can-play, .nfp-episode-preview.collapsed {
  cursor: pointer
}

.nfp-episode-preview:hover {
  background: hsla(0, 0%, 100%, .1)
}

.nfp-episode-preview:hover .nf-big-play-pause {
  opacity: 1;
  transform: scale(1.1)
}

.nfp-episode-preview.expanded {
  background: rgba(0, 0, 0, .5)
}

.nfp-episode-preview.expanded .thumbnail .Progress {
  bottom: .27777778em
}

.nfp-episode-preview .number, .nfp-episode-preview .thumbnail {
  flex: none
}

.nfp-episode-preview .title-and-synopsis {
  flex: 1 1 auto
}

.nfp-episode-preview .synopsis, .nfp-episode-preview .title {
  font-size: .72222222em
}

.nfp-episode-preview .number {
  font-size: .72222222em;
  font-weight: 500;
  padding: 0 1.23076923em 0 .84615385em;
  text-align: right;
  width: 1.30769231em
}

.nfp-episode-preview .title {
  line-height: normal;
  margin: 0
}

.nfp-episode-preview .synopsis {
  margin: .16666667em 0
}

.nfp-episode-preview .thumbnail {
  margin: 0 .77777778em 0 .55555556em;
  min-height: .44444444em;
  position: relative;
  width: 8.33333333em
}

.nfp-episode-preview .thumbnail .thumbnail-image {
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 4px;
  padding-top: 56%;
  width: 100%
}

.nfp-episode-preview .thumbnail .gradient {
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .6));
  bottom: 0;
  height: 40%;
  left: 0;
  position: absolute;
  right: 0
}

.nfp-episode-preview .thumbnail .nf-big-play-pause {
  bottom: 0;
  left: 0;
  margin: auto;
  opacity: .6;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform .2s, opacity .2s
}

.nfp-episode-preview .thumbnail .nf-big-play-pause button {
  background-color: rgba(0, 0, 0, .6)
}

.nfp-episode-preview .thumbnail .nf-big-play-pause .svg-icon {
  opacity: 1
}

.nfp-episode-preview.nfp-episode-preview--modern {
  flex-direction: column
}

.nfp-episode-preview.nfp-episode-preview--modern.nfp-episode-preview--is-next-episode .number {
  padding: 0 1em 0 0;
  text-align: initial;
  width: auto
}

.nfp-episode-preview.nfp-episode-preview--modern .thumbnail {
  margin: 0 1em 0 0
}

.nfp-episode-preview.nfp-episode-preview--modern .metadata-container {
  margin: .9em 0 0;
  width: 100%
}

.nfp-episode-preview.nfp-episode-preview--modern .Progress.Progress--modern {
  bottom: auto;
  flex: 0 1 auto;
  left: auto;
  margin: 0 .8em 0 .5em;
  position: static;
  right: auto
}

.nfp-episode-preview.nfp-episode-preview--modern .title {
  flex: 1
}

.hiddenEpisodeNumbers .number {
  padding-left: .22222222em;
  width: 0
}

@keyframes bigPlayPause {
  0% {
    opacity: 1;
    transform: scale(1)
  }

  to {
    opacity: 0;
    transform: scale(1.25)
  }
}

.nf-big-play-pause {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.nf-big-play-pause-primary .svg-icon {
  fill: #009779
}

.nf-big-play-pause-secondary .svg-icon {
  fill: #fff
}

.nf-big-play-pause button {
  animation-duration: .75s;
  animation-timing-function: ease-out;
  background-color: rgba(0, 0, 0, .4);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1em;
  height: 2.5em;
  padding: 0;
  position: relative;
  transform: scale(1);
  transition: transform .2s;
  width: 2.5em
}

.nf-big-play-pause button:focus {
  outline: none
}

:hover.grow.nf-big-play-pause button {
  transform: scale(1.1)
}

.animate.nf-big-play-pause button {
  cursor: auto;
  opacity: 0
}

.pause.animate.nf-big-play-pause button, .play.animate.nf-big-play-pause button {
  animation-name: bigPlayPause
}

.nf-big-play-pause button .svg-icon-nfplayerOpticalCenterPause, .nf-big-play-pause button .svg-icon-nfplayerOpticalCenterPlay {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  opacity: .7;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1);
  width: 50%
}

.nf-big-play-pause.size-small {
  font-size: 1em
}

.nf-big-play-pause.size-medium {
  font-size: 2em
}

.nf-big-play-pause.size-large {
  font-size: 3em
}

.nf-big-play-pause.cursor-pointer {
  cursor: pointer
}

.Progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  direction: ltr
    /*!rtl:ignore*/
}

.Progress.Progress--modern {
  background-color: hsla(0, 0%, 50%, .5);
  bottom: -1px;
  color: #009779;
  height: .11111111em;
  left: .27777778em;
  position: absolute;
  right: .27777778em;
  width: 7.77777778em
}

.Progress.Progress--modern::-webkit-progress-value {
  background: #009779
}

.Progress.Progress--modern::-moz-progress-bar {
  background: #009779
}

.Progress.Progress--modern::-webkit-progress-bar {
  background: hsla(0, 0%, 50%, .5)
}

.Progress.Progress--classic {
  background-color: #000;
  color: gray;
  margin: 0 0 0 1em;
  width: 5.5em
}

.Progress.Progress--classic::-webkit-progress-value {
  background: gray
}

.Progress.Progress--classic::-moz-progress-bar {
  background: gray
}

.Progress.Progress--classic::-webkit-progress-bar {
  background: #000
}

.extended-diacritics-language .nfp-episode-preview .synopsis {
  line-height: 1.6
}

.nfp .next-episode.popup-content {
  cursor: pointer;
  position: relative;
  width: 22.22222222em
}

.nfp .next-episode.popup-content>.header {
  padding: .75em .83333333em
}

.nfp .next-episode.popup-content>.header>.header-title {
  color: #fff;
  font-size: .94444444em;
  font-weight: 500;
  margin: 0
}

.nfp .next-episode.popup-content .episode {
  position: relative
}

.nfp .next-episode.popup-content .episode img {
  width: 150px
}

.nfp-episode-selector {
  color: #fff;
  position: relative;
  transform: translateZ(0)
}

.nfp-episode-selector.transitioning {
  transition: height .2s, width .2s
}

.nfp-episode-selector .season-list {
  display: flex;
  flex-direction: column;
  height: 22.94444444em;
  width: 25.55555556em
}

.nfp-episode-selector .season-list .body {
  flex: 1 1 auto;
  overflow-y: auto;
  position: relative;
  width: 100%
}

.nfp-episode-selector.top-aligned .episode-list, .nfp-episode-selector.top-aligned .season-list {
  height: auto;
  max-height: 22.94444444em
}

.nfp-episode-selector .pane {
  display: flex;
  opacity: 0;
  transition: transform .2s, opacity .2s
}

.nfp-episode-selector .pane.active {
  opacity: 1;
  transform: translateX(0);
  z-index: 1
}

.nfp-episode-selector>.out-of-flow {
  bottom: 0;
  position: absolute
}

.nfp-episode-selector.top-aligned>.out-of-flow {
  bottom: auto;
  top: 0
}

.nfp-episode-selector .seasons-pane {
  transform: translateX(-33%)
}

.nfp-episode-selector .episodes-pane {
  transform: translateX(100%)
}

.nfp-episode-selector .of-hidden {
  height: 22.94444444em;
  overflow: hidden;
  width: 25.55555556em
}

.nfp-episode-selector .show-title {
  margin: 0;
  padding: .8em
}

.nfp-episode-expander {
  overflow: hidden;
  position: relative
}

.nfp-episode-expander:focus {
  outline: 0
}

.nfp-episode-expander.transitioning {
  transition: height .2s
}

.nfp-episode-expander>.episode-row {
  opacity: 0;
  transition: opacity .2s;
  width: 100%
}

.nfp-episode-expander>.episode-row.out-of-flow {
  position: absolute;
  top: 0
}

.nfp-episode-expander>.episode-row.visible {
  opacity: 1;
  z-index: 1
}

.nfp-episode-expander .focus-rect {
  box-sizing: border-box;
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%
}

.nfp-season-preview {
  cursor: pointer;
  display: flex;
  padding: .66666667em 0;
  position: relative
}

.nfp-season-preview:focus {
  outline: 0
}

.nfp-season-preview:hover {
  background: hsla(0, 0%, 100%, .1)
}

.nfp-season-preview:hover .arrow {
  visibility: visible
}

.nfp-season-preview .arrow, .nfp-season-preview .check {
  fill: #fff;
  align-items: center;
  display: flex;
  flex: none;
  justify-content: center;
  width: 2.44444444em
}

.nfp-season-preview .check {
  transform: scale(.9)
}

.nfp-season-preview .arrow {
  transform: scale(.7) rotate(180deg)
    /*!rtl:scale(0.7) rotate(0)*/
  ;
  visibility: hidden
}

.nfp-season-preview .title {
  flex: 1 1 auto;
  font-size: .94444444em;
  font-weight: 400
}

.episode-list {
  display: flex;
  flex-direction: column;
  height: 22.94444444em;
  width: 25.55555556em
}

.episode-list:focus {
  outline: 0
}

.episode-list .header {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  height: 2.44444444em;
  position: relative;
  width: 100%
}

.episode-list .header:hover {
  background: hsla(0, 0%, 100%, .1)
}

.episode-list .header.disable-back {
  background: none;
  cursor: auto;
  padding-left: 1.11111111em
}

.episode-list .header.disable-back .back {
  display: none
}

.episode-list .back {
  fill: #fff;
  align-items: center;
  display: flex;
  flex: none;
  justify-content: center;
  transform: scale(.7) rotate(0)
    /*!rtl:scale(0.7) rotate(180deg)*/
  ;
  width: 2.44444444em
}

.episode-list .header-title {
  flex: 1 1 auto;
  font-size: .94444444em;
  font-weight: 400;
  margin: 0 0 0 -1px
}

.episode-list .body {
  direction: ltr;
  flex: 1 1 auto;
  overflow-y: auto;
  position: relative;
  width: 100%
}

.audio-subtitle-controller {
  background: #282828;
  direction: ltr;
  display: flex
}

.audio-subtitle-controller.scroll-gradient {
  max-height: 72vh
}

.audio-subtitle-controller .track-list {
  flex-grow: 1;
  margin: 0;
  padding: 0 0 .5em
}

.audio-subtitle-controller .track-list.scroll-gradient-list {
  display: flex;
  flex-direction: column;
  position: relative
}

.audio-subtitle-controller .track-list.disabled {
  opacity: .25
}

.audio-subtitle-controller .track-list h3 {
  margin: 0
}

.audio-subtitle-controller .track-list ul {
  list-style: none;
  margin: 0;
  max-height: 70vh;
  min-width: 100px;
  overflow: auto;
  padding: 0;
  position: relative
}

.audio-subtitle-controller .track-list li {
  color: #b3b3b3;
  cursor: pointer;
  font-size: .72222222em;
  outline: none;
  padding: 1em 3.4em;
  position: relative;
  white-space: nowrap
}

.audio-subtitle-controller .track-list li:hover {
  background: hsla(0, 0%, 100%, .1);
  color: #fff
}

.audio-subtitle-controller .track-list li .video-controls-check {
  fill: #fff;
  left: 0;
  margin-left: 1.3em;
  position: absolute
}

.audio-subtitle-controller .track-list li.scroll-gradient-list-item {
  padding: 2vh 5.5vh;
  pointer-events: auto
}

.audio-subtitle-controller .track-list .selected {
  color: #fff;
  font-weight: 500
}

.audio-subtitle-controller .track-list .list-header {
  color: #fff;
  cursor: default;
  font-size: .94444444em;
  font-weight: 500;
  padding: .75em 2.6em;
  white-space: nowrap
}

.audio-subtitle-controller .track-list .list-header:hover {
  background: none
}

.audio-subtitle-controller .track-list .list-header.scroll-gradient-list-heading {
  font-size: 2.3vh;
  line-height: 2.3vh;
  padding: 2vh 5.5vh
}

.bottom-controls .audio-subtitle-controller .track-list {
  padding: .5em 0
}

.speed-controller {
  padding: 1em 1.4em
}

.speed-controller .label {
  direction: ltr;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5em
}

.speed-controller .values {
  display: flex
}

.speed-controller .values .value-container {
  cursor: pointer;
  padding-top: 1.5em
}

.speed-controller .values .value-container:first-child .value {
  margin-left: .8387em
    /*!rtl:ignore*/
  ;
  width: 3.05em
}

.speed-controller .values .value-container:first-child .value .inactive-dot {
  left: -.25805em
    /*!rtl:ignore*/
  ;
  margin-left: auto
    /*!rtl:ignore*/
}

.speed-controller .values .value-container:first-child .value .active-dot {
  left: -.8387em
    /*!rtl:ignore*/
  ;
  margin-left: auto
    /*!rtl:ignore*/
}

.speed-controller .values .value-container:first-child .value .text {
  margin-left: -.8387em
    /*!rtl:ignore*/
  ;
  text-align: left
    /*!rtl:ignore*/
}

.speed-controller .values .value-container:last-child .value {
  margin-right: .8387em
    /*!rtl:ignore*/
  ;
  width: 3.05em
}

.speed-controller .values .value-container:last-child .value .inactive-dot {
  left: auto
    /*!rtl:ignore*/
  ;
  right: -.25805em
    /*!rtl:ignore*/
}

.speed-controller .values .value-container:last-child .value .active-dot {
  left: auto
    /*!rtl:ignore*/
  ;
  right: -.8387em
    /*!rtl:ignore*/
}

.speed-controller .values .value-container:last-child .value .text {
  margin-right: -.8387em
    /*!rtl:ignore*/
  ;
  text-align: right
    /*!rtl:ignore*/
}

.speed-controller .values .value {
  border-top: .05em solid #979797;
  padding-top: 1em;
  position: relative;
  width: 6.1em
}

.speed-controller .values .value .inactive-dot {
  height: .5161em;
  left: 50%
    /*!rtl:ignore*/
  ;
  margin-left: -.25805em
    /*!rtl:ignore*/
  ;
  margin-top: -.25805em;
  position: absolute;
  top: 0;
  width: .5161em
}

.speed-controller .values .value .active-dot {
  height: 1.6774em;
  left: 50%
    /*!rtl:ignore*/
  ;
  margin-left: -.8387em
    /*!rtl:ignore*/
  ;
  margin-top: -.8387em;
  position: absolute;
  top: 0;
  width: 1.6774em
}

.speed-controller .values .value .text {
  color: #e6e6e6;
  font-size: 1em;
  line-height: 1.5em;
  text-align: center
}

.speed-controller .values .value .text.active {
  color: #fff;
  font-weight: 500
}

.PlayerControlsNeo__bottom-controls .nfp-popup-control.speed-button-with-label {
  width: 4.5em
}

.KeyboardNotification__container {
  align-content: center;
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  height: 40em;
  justify-content: center;
  left: 0;
  left: 50%;
  margin-left: -30em;
  margin-top: -20em;
  position: absolute;
  right: 0;
  top: 0;
  top: 50%;
  width: 100%;
  width: 60em
}

.KeyboardNotification__container .KeyboardNotification__icon {
  align-content: center;
  align-items: center;
  animation: buttonFader .75s ease-out normal forwards;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.KeyboardNotification__container .KeyboardNotification__icon:before {
  background: rgba(0, 0, 0, .4);
  border-radius: 50%;
  content: "";
  font-size: 1.4em;
  height: 8em;
  left: 50%
    /*!rtl:ignore*/
  ;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%)
    /*!rtl:ignore*/
  ;
  width: 8em
}

.KeyboardNotification__container .KeyboardNotification__icon .svg-icon {
  fill: #fff;
  font-size: 3.5em;
  left: 50%
    /*!rtl:ignore*/
  ;
  position: absolute;
  top: 50%;
  transform: translateZ(0) translateY(-50%) translateX(-50%)
    /*!rtl:ignore*/
}

.KeyboardNotification__container .KeyboardNotification__icon.KeyboardNotification--pause:before, .KeyboardNotification__container .KeyboardNotification__icon.KeyboardNotification--play:before {
  height: 10em;
  width: 10em
}

.KeyboardNotification__container .KeyboardNotification__icon.KeyboardNotification--pause .svg-icon, .KeyboardNotification__container .KeyboardNotification__icon.KeyboardNotification--play .svg-icon {
  font-size: 6em
}

.KeyboardNotification__container .KeyboardNotification__icon.KeyboardNotification--seek-forward {
  left: auto
    /*!rtl:ignore*/
  ;
  right: 0
    /*!rtl:ignore*/
  ;
  width: 33.33%
}

.KeyboardNotification__container .KeyboardNotification__icon.KeyboardNotification--seek-back {
  left: 0
    /*!rtl:ignore*/
  ;
  right: auto
    /*!rtl:ignore*/
  ;
  width: 33.33%
}

.promotional-metadata {
  align-items: flex-end;
  display: flex;
  justify-content: space-between
}

.promotional-metadata .promotional-metadata--evidence {
  width: 32%
}

.promotional-metadata .promotional-metadata--evidence .promotional-metadata--logo {
  margin-bottom: 1em;
  max-height: 9.5em;
  max-width: 100%
}

.promotional-metadata .promotional-metadata--evidence .promotional-metadata--supplemental-message {
  font-size: 1.7em;
  font-weight: 700;
  margin: .4em 0
}

.promotional-metadata .promotional-metadata--buttons .nf-flat-button {
  font-size: 1.6em;
  margin-left: .75em;
  margin-right: 0;
  padding: .5em
}

.promotional-metadata .promotional-metadata--buttons .nf-flat-button.mylist-button .svg-icon-mylist-add {
  height: 1.2em;
  margin-right: .25em;
  vertical-align: middle;
  width: 1.2em
}

@media (max-aspect-ratio: 1/1) {
  .promotional-metadata .promotional-metadata--evidence {
    width: 45%
  }
}

.nf-loading-spinner {
  animation: load_spinner .9s linear infinite;
  background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/site-spinner.png);
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 1px;
  left: 1px;
  margin: auto;
  position: absolute;
  right: 1px;
  text-align: center;
  top: 1px
}

.PlayerSpinner--container, .nf-loading-spinner {
  height: 7vh;
  min-height: 5rem;
  min-width: 5rem;
  width: 7vh
}

.PlayerSpinner--container {
  align-items: center;
  display: flex;
  justify-content: center
}

.PlayerSpinner--percentage {
  animation: none;
  color: #fff;
  font-size: 1.4em;
  font-weight: 500;
  text-shadow: 0 1px 0 #000
}

@keyframes load_spinner {
  0% {
    transform: rotate(0deg)
      /*!rtl:ignore*/
  }

  50% {
    transform: rotate(180deg)
      /*!rtl:ignore*/
  }

  to {
    transform: rotate(1turn)
      /*!rtl:ignore*/
  }
}

.nfp-aspect-wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0)
}

.nfp-aspect-container {
  display: inline-block;
  max-height: 100%;
  position: relative;
  width: 100%
}

.nfp-aspect-inner {
  content: "";
  display: block;
  padding-top: 56.25%
}

.VideoContainer {
  height: 100%;
  transition: opacity .5s linear;
  width: 100%
}

.VideoContainer video {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.VideoContainer .player-info, .VideoContainer .player-streams {
  z-index: 3
}

.VideoContainer--hide-subtitles .player-timedtext {
  display: none !important
}

.VideoContainer--use-element-dimensions {
  height: auto
}

.VideoContainer--use-element-dimensions video {
  position: relative
}

.VideoContainer--use-video-width video {
  height: auto
}

.VideoContainer--maximize-height video {
  bottom: auto;
  height: 100%;
  left: 50%
    /*!rtl:ignore*/
  ;
  right: auto;
  top: auto;
  transform: translateX(-50%)
    /*!rtl:ignore*/
  ;
  width: auto
}

[dir=rtl] .VideoContainer--maximize-height video {
  transform: none
}

.PlayView {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  box-sizing: border-box;
  font-size: 1rem;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.PlayView-top {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 5.07rem 4.17rem;
  position: relative
}

.PlayView-exit {
  font-size: 2.7rem;
  padding: .39rem 1.29rem 0 0;
  position: relative
}

.PlayView-play {
  box-sizing: border-box;
  font-size: 1rem;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%
}

.PlayView-info {
  box-sizing: border-box;
  font-size: 3.62rem;
  font-weight: 500;
  padding-top: .54rem;
  position: relative
}

.PlayView-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, .651), transparent);
  height: 11.11111111em;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

@keyframes PlayView-playbutton-enter {
  0% {
    opacity: 0;
    transform: scale(1)
  }

  to {
    opacity: 1;
    transform: scale(1.3)
  }
}

.PlayView .button-nfplayerExit {
  height: 3.26rem;
  opacity: .5;
  padding: 0;
  transition: opacity .2s ease-out;
  width: 3.26rem
}

.PlayView .button-nfplayerExit:before {
  background: none;
  content: none;
  height: auto;
  width: auto
}

.PlayView .button-nfplayerExit:hover {
  opacity: 1
}

.PlayView .svg-icon-nfplayerExit {
  height: 2.72rem;
  padding: 0;
  width: 3.1rem
}

.PlayView .nf-big-play-pause {
  animation: PlayView-playbutton-enter;
  animation-delay: 50ms;
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.5, 0, .1, 1);
  box-sizing: border-box;
  opacity: 0
}

.PlayView .nf-big-play-pause .svg-icon-nfplayerOpticalCenterPlay path {
  fill: #fff;
  transition: fill .25s cubic-bezier(.5, 0, .1, 1)
}

.PlayView .nf-big-play-pause:hover .svg-icon-nfplayerOpticalCenterPlay path {
  fill: #009779
}

.PlayView .nf-big-play-pause button {
  border: 4px solid #fff
}

.nfp.nf-player-container.show-spinner video {
  opacity: .99
}

.t_510ca7 .mdx-button.hidden {
  display: none
}

.t_510ca7 .mdx-button .video-controls-mdx {
  background: transparent;
  border: 0
}

.t_510ca7 .mdx-button .video-controls-mdx:focus {
  outline: none
}

.t_510ca7 .mdx-button .video-controls-mdx svg {
  height: 1em;
  width: 1.30769231em
}

.t_510ca7 .mdx-mount-point.nfp {
  bottom: 0;
  color: #ddd;
  position: fixed;
  width: 100%;
  z-index: 50000
}

.t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container {
  background: #000;
  box-shadow: 0 -1px 0 hsla(0, 0%, 100%, .2);
  display: flex;
  flex-direction: column-reverse;
  height: 0;
  position: relative;
  transition: height .5s
}

.t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container:focus {
  outline: none
}

.t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container.mdx-connected-state, .t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container.mdx-play-state, .t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container.mdx-playback-unavailable, .t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container.mdx-postplay-state {
  height: auto
}

.t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container .mdx-controls-trans-appear, .t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container .mdx-controls-trans-enter {
  opacity: .01
}

.t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container .mdx-controls-trans-appear.mdx-controls-trans-appear-active, .t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container .mdx-controls-trans-enter.mdx-controls-trans-enter-active {
  display: block;
  opacity: 1;
  transition: opacity .3s ease-out
}

.t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container .mdx-controls-trans-leave {
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0
}

.t_510ca7 .mdx-mount-point.nfp .mdx-controls-bar-container .mdx-controls-trans-leave.mdx-controls-trans-leave-active {
  opacity: .01;
  transition: opacity .3s ease-in
}

.t_510ca7 .mdx-mount-point.nfp .controls {
  opacity: 1;
  position: relative
}

.t_510ca7 .mdx-mount-point.nfp .controls .main-controls {
  display: block;
  padding: 0;
  position: relative
}

.t_510ca7 .mdx-mount-point.nfp .controls .main-controls .controls-container {
  direction: ltr
    /*!rtl:ignore*/
  ;
  display: flex;
  margin-bottom: .25em;
  margin-top: 0;
  padding: 0 1rem 0 .4rem
    /*!rtl:ignore*/
  ;
  position: relative;
  width: auto
}

.t_510ca7 .mdx-mount-point.nfp .controls .main-controls .mdx-status-appear, .t_510ca7 .mdx-mount-point.nfp .controls .main-controls .mdx-status-enter {
  opacity: .01;
  transform: translateY(1em)
}

.t_510ca7 .mdx-mount-point.nfp .controls .main-controls .mdx-status-appear.mdx-status-appear-active, .t_510ca7 .mdx-mount-point.nfp .controls .main-controls .mdx-status-enter.mdx-status-enter-active {
  display: block;
  opacity: 1;
  transform: translateY(0);
  transition: transform .3s ease-out, opacity .3s ease-out
}

.t_510ca7 .mdx-mount-point.nfp .controls .main-controls .mdx-status-leave {
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(0)
}

.t_510ca7 .mdx-mount-point.nfp .controls .main-controls .mdx-status-leave.mdx-status-leave-active {
  opacity: .01;
  transform: translateY(-1em);
  transition: transform .3s ease-in, opacity .3s ease-in
}

.t_510ca7 .mdx-mount-point.nfp .controls .main-controls .title-name-container {
  color: #fff;
  display: flex;
  justify-content: center;
  padding-top: 1em;
  text-align: center;
  white-space: nowrap
}

.t_510ca7 .mdx-mount-point.nfp .controls .main-controls .title-name-container strong {
  padding: 0 .5rem 0 0
}

.t_510ca7 .mdx-mount-point.nfp .controls .main-controls .title-name-container a:hover {
  text-decoration: underline
}

.t_510ca7 .mdx-mount-point.nfp .mdx-postplay-container {
  display: flex;
  flex-direction: row;
  height: 0;
  justify-content: center;
  opacity: 0;
  overflow: hidden;
  transition: opacity .3s, height .3s
}

.t_510ca7 .mdx-mount-point.nfp .mdx-postplay-container.mdx-postplay-showing {
  height: auto;
  margin: .5rem 0 0;
  opacity: 1
}

.t_510ca7 .mdx-mount-point.nfp.hidden {
  display: none
}

.t_510ca7 .mdx-mount-point.nfp .connected-status h3 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding-top: .75em;
  text-align: center
}

.t_510ca7 .mdx-mount-point.nfp .connected-status h4 {
  font-weight: 400;
  margin: 0;
  padding-bottom: .75em;
  padding-top: .25em;
  text-align: center
}

.t_510ca7 .mdx-mount-point.nfp .video-controls-mdx {
  fill: #40bcea
}

.t_510ca7 .mdx-mount-point.nfp .adult-verification-prompt, .t_510ca7 .mdx-mount-point.nfp .player-pin-entry-container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 70px;
  width: 100%
}

.t_510ca7 .mdx-controls-actions-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 .5rem
}

.t_510ca7 .MdxControls__button {
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #333;
  border: 1px solid transparent;
  border-radius: .1em;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: .8rem;
  font-weight: 500;
  margin: .5em;
  padding: .5em 1.25em
}

.t_510ca7 .MdxControls__button:focus {
  outline: 0
}

.t_510ca7 .MdxControls__button:focus, .t_510ca7 .MdxControls__button:hover {
  border: 1px solid hsla(0, 0%, 100%, .75)
}

.t_510ca7 .MdxControls__button .svg-icon {
  fill: #fff;
  margin-right: .5em
}

.t_510ca7 .MdxControls__button--primary {
  background: #009779
}

.nfa-dir-ltr {
  direction: ltr
}

.nfa-ta-center {
  text-align: center
}

.nfa-bgc-40-black {
  background-color: rgba(0, 0, 0, .4)
}

.nfa-bgc-red-med {
  background-color: #009779
}

.nfa-bgc-red-light {
  background-color: #f61c27
}

.nfa-bgc-red-dark {
  background-color: #c30811
}

.nfa-z-idx-1 {
  z-index: 1
}

.nfa-pos-abs {
  position: absolute
}

.nfa-pos-rel {
  position: relative
}

.nfa-overflow-hidden {
  overflow: hidden
}

.nfa-opacity-0 {
  opacity: 0
}

.nfa-opacity-1 {
  opacity: 1
}

.nfa-top-0 {
  top: 0
}

.nfa-left-0 {
  left: 0
}

.nfa-right-0 {
  right: 0
}

.nfa-right-5-em {
  right: 5em
}

.nfa-bot-0 {
  bottom: 0
}

.nfa-bot-6-em {
  bottom: 6em
}

.nfa-d-flex {
  display: flex
}

.nfa-d-inline-flex {
  display: inline-flex
}

.nfa-w-60 {
  width: 60%
}

.nfa-w-90 {
  width: 90%
}

.nfa-w-100 {
  width: 100%
}

.nfa-h-100 {
  height: 100%
}

.nfa-h-12-em {
  height: 12em
}

.nfa-min-h-5-rem {
  min-height: 5rem
}

.nfa-flx-dir-col {
  flex-direction: column
}

.nfa-as-end {
  align-self: flex-end
}

.nfa-ai-center {
  align-items: center
}

.nfa-ai-flex-end {
  align-items: flex-end
}

.nfa-ai-stretch {
  align-items: stretch
}

.nfa-ac-center {
  align-content: center
}

.nfa-bs-bb {
  box-sizing: border-box
}

.nfa-jc-center {
  justify-content: center
}

.nfa-jc-flex-end {
  justify-content: flex-end
}

.nfa-text-shadow-light {
  text-shadow: 0 1px 0 #000
}

.nfa-fs-1-em {
  font-size: 1em
}

.nfa-fs-1-2-em {
  font-size: 1.2em
}

.nfa-fs-1-6-em {
  font-size: 1.6em
}

.nfa-fs-2-em {
  font-size: 2em
}

.nfa-fs-2-4-em {
  font-size: 2.4em
}

.nfa-fs-2-8-em {
  font-size: 2.8em
}

.nfa-fs-4-8-em {
  font-size: 4.8em
}

.nfa-fs-16-px {
  font-size: 16px
}

.nfa-fw-400, .nfa-fw-normal {
  font-weight: 400
}

.nfa-c-gray-80 {
  color: #ccc
}

.nfa-c-gray-e6 {
  color: #e6e6e6
}

.nfa-c-white-faded {
  color: hsla(0, 0%, 100%, .65)
}

.nfa-m-0 {
  margin: 0
}

.nfa-p-0 {
  padding: 0
}

.nfa-pb-0 {
  padding-bottom: 0
}

.nfa-pb-02-em {
  padding-bottom: .2em
}

.nfa-pb-05-em {
  padding-bottom: .5em
}

.nfa-pb-1-em {
  padding-bottom: 1em
}

.nfa-pt-0 {
  padding-top: 0
}

.nfa-pt-02-em {
  padding-top: .2em
}

.nfa-pt-05-em {
  padding-top: .5em
}

.nfa-pt-1-em {
  padding-top: 1em
}

.nfa-pl-1-em {
  padding-left: 1em
}

.nfa-pl-2-25-em {
  padding-left: 2.25em
}

.nfa-pl-3-em {
  padding-left: 3em
}

.nfa-pr-1-em {
  padding-right: 1em
}

.nfa-pr-2-25-em {
  padding-right: 2.25em
}

.nfa-pr-3-em {
  padding-right: 3em
}

.nfa-bot-10 {
  bottom: 10%
}

.nfa-right-10 {
  right: 10%
}

.nfa-ls-0-25-em {
  letter-spacing: .25em
}

.nfa-br-2-px {
  border-radius: 2px
}

.nfa-bos-solid {
  border-style: solid
}

.nfa-bow-1-px {
  border-width: 1px
}

.nfa-boc-transparent {
  border-color: transparent
}

.nfa-cursor-pointer {
  cursor: pointer
}

.nfa-us-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.nfa-lh-1 {
  line-height: 1
}

.nfa-minw-10-5-em {
  min-width: 10.5em
}

.content-preview-watermark {
  color: #fff;
  font-size: 4em;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  text-shadow: 0 2px #000;
  white-space: nowrap
}

.content-preview-watermark.top-center {
  top: 60px
}

.content-preview-watermark.bottom-center {
  bottom: 60px
}

.adult-verification-prompt {
  background: rgba(0, 0, 0, .8);
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.adult-verification-prompt .adult-verification-header {
  flex: 0 1 auto;
  font-size: 2em;
  font-weight: 400;
  margin: 1em;
  text-align: center
}

.adult-verification-prompt .adult-verification-actions {
  display: flex;
  flex: 0 1 auto;
  padding: 1.5em 0 2em
}

.adult-verification-prompt-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

@keyframes shakeit {
  0% {
    transform: translateX(-4em)
  }

  25% {
    transform: translateX(4em)
  }

  50% {
    transform: translateX(-4em)
  }

  to {
    transform: translateX(0)
  }
}

.player-pin-entry-validating {
  opacity: 0
}

.player-pin-entry-container {
  bottom: 0;
  right: 0
}

.player-pin-entry, .player-pin-entry-container {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.player-pin-entry {
  align-items: center;
  background: rgba(0, 0, 0, .8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity .3s ease-out
}

.player-pin-entry .pin-input-container {
  color: #000;
  direction: ltr
    /*!rtl:ignore*/
  ;
  display: flex;
  flex: 0 1 auto;
  margin: .5em
}

.player-pin-entry .player-pin-reset-link-container {
  bottom: 0;
  display: flex;
  flex: 0 1 auto;
  font-size: 1em;
  justify-content: center;
  left: 0;
  padding: .75em 0;
  position: absolute;
  width: 100%;
  z-index: 1
}

.player-pin-entry .player-pin-reset-actions {
  display: flex;
  flex: 0 1 auto;
  height: 2em;
  padding: .75em 0 1em
}

.player-pin-entry .pin-number-input {
  background: transparent;
  border: .05em solid #fff;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  font-size: 2.5em;
  height: 2.5em;
  margin: .2em;
  padding: .2em;
  text-align: center;
  transition: transform .1s ease-out;
  width: 2.5em
}

.player-pin-entry .pin-number-input:focus {
  outline: none;
  transform: scale(1.1)
}

.player-pin-entry-title {
  flex: 0 1 auto;
  font-size: 1.7em;
  font-weight: 400;
  margin: .5em 0;
  text-align: center
}

.player-pin-entry-invalid {
  animation: shakeit .3s 1;
  animation-delay: .3s
}

.player-pin-entry-pad-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column
}

.pinLoadingTransition-appear, .pinLoadingTransition-enter {
  opacity: .01
}

.pinLoadingTransition-appear.pinLoadingTransition-appear-active, .pinLoadingTransition-enter.pinLoadingTransition-enter-active {
  opacity: 1;
  transition-duration: .15s;
  transition-duration: .3s;
  transition-property: opacity;
  transition-timing-function: ease-out
}

.pinLoadingTransition-leave {
  opacity: 1
}

.pinLoadingTransition-leave.pinLoadingTransition-leave-active {
  opacity: .01;
  transition-duration: .15s;
  transition-duration: .3s;
  transition-property: opacity;
  transition-timing-function: ease-out
}

.nfp-player-upsell-view {
  background: #000;
  color: #fff;
  font-size: 1.5em;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2
}

.nfp-player-upsell-view .button-nfplayerExit {
  margin: .55555556em .83333333em;
  position: absolute;
  right: 0;
  top: 0
}

.nfp-player-upsell-view .information {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 4em;
  width: 100%;
  z-index: 1
}

.nfp-player-upsell-view .information .upsell-heading-wrapper {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1
}

.nfp-player-upsell-view .information-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1
}

.nfp-player-upsell-view .upsell-title {
  align-self: flex-end;
  font-size: 1.5em;
  margin: 0;
  padding: 0
}

.nfp-player-upsell-view .error-code {
  cursor: text;
  display: block;
  font-size: .88888889em;
  font-weight: 400;
  margin: 0;
  -webkit-user-select: initial;
  -moz-user-select: initial;
  user-select: auto
}

.nfp-player-upsell-view .error-code span {
  color: #009779
}

.nfp-player-upsell-view .upsell-text {
  flex-grow: 1;
  font-size: .88888889em;
  margin: 20px 0 0
}

.nfp-player-upsell-view .upsell-text a {
  color: #fff;
  font-weight: 500
}

.nfp-player-upsell-view .upsell-text a:hover {
  text-decoration: underline
}

.nfp-player-upsell-view .player-upsell-view-secondary-info {
  flex-basis: 0;
  flex-grow: 1
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .upsell-streams {
  color: #999;
  margin: 40px 0 0
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .upsell-streams strong {
  color: #fff
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-buttons, .nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-footer, .nfp-player-upsell-view .player-upsell-view-secondary-info .offer-buttons {
  margin-top: 40px
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .offer-buttons {
  align-items: flex-start;
  display: flex
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .nf-flat-button {
  font-size: .9em
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .upgrade-details {
  background: #191919;
  color: #999;
  margin: 10px 10px 0 0;
  padding: 10px;
  text-align: left;
  width: -moz-fit-content;
  width: fit-content
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-details {
  width: 50%
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-detail {
  display: flex
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-detail>div {
  flex-grow: 1
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-detail .price {
  flex-shrink: 0;
  padding-left: 16px;
  text-align: right
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-detail .date {
  color: #999;
  display: block;
  font-size: .8em;
  font-weight: 400;
  margin-top: 5px
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-detail .date-hidden {
  visibility: hidden
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-current-plan {
  color: #999
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-current-plan h2 {
  font-weight: 400
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-new-plan {
  font-weight: 500
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-footer {
  width: 50%
}

.nfp-player-upsell-view .player-upsell-view-secondary-info .confirmation-legal-disclaimer {
  color: #b3b3b3
}

.nfp-player-upsell-view .player-upsell-view-secondary-info h2 {
  font-size: 1em;
  margin: 40px 0 6px
}

.advisory-container {
  height: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: 1
}

.advisory-background {
  background: radial-gradient(100% 100% at top left, rgba(0, 0, 0, .25) 0, transparent 100%)
    /*!rtl:radial-gradient(100% 100% at top right, rgba(0,0,0,.25) 0%, rgba(0,0,0,0) 100%)*/
  ;
  bottom: -50px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 50vw
}

.advisory-background:before {
  background: radial-gradient(100% 100% at bottom left, rgba(0, 0, 0, .25) 0, transparent 100%)
    /*!rtl:radial-gradient(100% 100% at bottom right, rgba(0,0,0,.25) 0%, rgba(0,0,0,0) 100%)*/
  ;
  bottom: 100%;
  content: "";
  left: 0;
  opacity: 1;
  position: absolute;
  top: -10%;
  width: 50vw
}

.advisory {
  color: #fff;
  left: 2em;
  margin: 1em;
  opacity: 1;
  padding: .2em .6em;
  position: absolute;
  top: 2em
}

.advisory-bar {
  background: #009779;
  height: 90%;
  margin: 0;
  position: absolute;
  right: 100%;
  top: 5%;
  width: .15em
}

.advisory-header {
  font-size: 1em;
  margin: .3em 0;
  padding: 0
}

.advisory-body {
  font-size: 1em;
  margin: 0;
  max-width: 50vw;
  opacity: .85;
  padding: 0
}

.advisory-certification-id {
  line-height: 3em;
  margin-left: 1em;
  white-space: nowrap
}

.advisory-has-icons .advisory-header {
  align-items: center;
  display: flex;
  font-weight: 400
}

.advisory-has-icons .advisory-body {
  align-items: flex-start;
  box-sizing: content-box;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;
  margin-bottom: .3em
}

.advisory-has-icons .advisory-level-icon {
  display: flex;
  justify-content: flex-end;
  margin: 0 .5em
}

.advisory-has-icons .advisory-level-icon .svg-icon {
  height: 2.5em;
  width: 2.5em
}

.advisory-has-icons .advisory-level-icon .svg-icon-maturity-rating-9376 {
  width: 4.5em
}

.advisory-has-icons .advisory-icon {
  margin-right: .5em;
  text-align: center;
  width: 2.5em
}

.advisory-has-icons .advisory-icon .svg-icon {
  height: 2.5em;
  width: 2.5em
}

.advisory-has-icons .advisory-icon-text {
  font-size: .6em
}

.advisory-has-icons .icon-nicam {
  font-size: 3vw;
  padding: 0 .5vw;
  vertical-align: middle
}

.advisory-has-icons .advisory-icon.icon-board-14, .advisory-has-icons .advisory-icon.icon-board-20, .advisory-has-icons .advisory-icon.icon-board-8602, .advisory-has-icons .advisory-level-icon.icon-board-14, .advisory-has-icons .advisory-level-icon.icon-board-20, .advisory-has-icons .advisory-level-icon.icon-board-8602 {
  margin-right: 0
}

.advisory-has-icons .advisory-icon.icon-board-14 .svg-icon, .advisory-has-icons .advisory-icon.icon-board-20 .svg-icon, .advisory-has-icons .advisory-icon.icon-board-8602 .svg-icon, .advisory-has-icons .advisory-level-icon.icon-board-14 .svg-icon, .advisory-has-icons .advisory-level-icon.icon-board-20 .svg-icon, .advisory-has-icons .advisory-level-icon.icon-board-8602 .svg-icon {
  height: 1.5em;
  padding: 0 .5vw;
  vertical-align: middle;
  width: 1.5em
}

.advisory-has-icons .advisory-icon.icon-board-8602 .svg-icon, .advisory-has-icons .advisory-level-icon.icon-board-8602 .svg-icon {
  width: 2.5em
}

.advisory-has-icons .advisory-icon.icon-board-41, .advisory-has-icons .advisory-level-icon.icon-board-41 {
  margin-right: 0;
  width: auto
}

.advisory-has-icons .advisory-icon.icon-board-41 .svg-icon, .advisory-has-icons .advisory-level-icon.icon-board-41 .svg-icon {
  height: 1.5em;
  padding: 0;
  vertical-align: middle;
  width: 1.5em
}

.advisory-has-icons.kmrb-2021 .advisory-header {
  margin: 1em 0 .3em
}

.advisory-has-icons.kmrb-2021 .advisory-icon {
  width: 3.5em
}

.advisory-has-icons.kmrb-2021 .advisory-icon.icon-board-79 .svg-icon, .advisory-has-icons.kmrb-2021 .advisory-level-icon.icon-board-79 .svg-icon {
  height: 3em;
  width: 3em
}

.advisory-transition-appear, .advisory-transition-enter {
  transform: translateY(1em)
}

.advisory-transition-appear .advisory-background, .advisory-transition-enter .advisory-background {
  opacity: 0
}

.advisory-transition-appear .advisory-bar, .advisory-transition-enter .advisory-bar {
  transform: scaleY(0);
  transform-origin: bottom left
}

.advisory-transition-appear .advisory-body, .advisory-transition-appear .advisory-header, .advisory-transition-enter .advisory-body, .advisory-transition-enter .advisory-header {
  opacity: 0;
  transform: translateY(1em)
}

.advisory-transition-appear.advisory-transition-appear-active, .advisory-transition-enter.advisory-transition-enter-active {
  transform: translateY(0);
  transition: all .75s ease
}

.advisory-transition-appear.advisory-transition-appear-active .advisory-background, .advisory-transition-enter.advisory-transition-enter-active .advisory-background {
  opacity: 1;
  transition: opacity 1s linear
}

.advisory-transition-appear.advisory-transition-appear-active .advisory-body, .advisory-transition-appear.advisory-transition-appear-active .advisory-header, .advisory-transition-enter.advisory-transition-enter-active .advisory-body, .advisory-transition-enter.advisory-transition-enter-active .advisory-header {
  opacity: 1;
  transform: translateY(0)
}

.advisory-transition-appear.advisory-transition-appear-active .advisory-bar, .advisory-transition-enter.advisory-transition-enter-active .advisory-bar {
  transform: scaleY(1);
  transform-origin: bottom left;
  transition: all .75s ease
}

.advisory-transition-appear.advisory-transition-appear-active .advisory-header, .advisory-transition-enter.advisory-transition-enter-active .advisory-header {
  transition: all .6s ease .2s
}

.advisory-transition-appear.advisory-transition-appear-active .advisory-body, .advisory-transition-enter.advisory-transition-enter-active .advisory-body {
  transition: all .6s ease .3s
}

.advisory-transition-leave {
  transform: translateY(0)
}

.advisory-transition-leave .advisory-background {
  opacity: 1
}

.advisory-transition-leave .advisory-body, .advisory-transition-leave .advisory-header {
  opacity: 1;
  transform: translateY(0)
}

.advisory-transition-leave .advisory-bar {
  transform: scaleY(1);
  transform-origin: top left
}

.advisory-transition-leave.advisory-transition-leave-active {
  transform: translateY(-1em);
  transition: all .75s ease
}

.advisory-transition-leave.advisory-transition-leave-active .advisory-background {
  opacity: 0;
  transition: opacity 1s linear
}

.advisory-transition-leave.advisory-transition-leave-active .advisory-bar {
  transform: scaleY(0);
  transform-origin: top left;
  transition: all .75s ease
}

.advisory-transition-leave.advisory-transition-leave-active .advisory-body, .advisory-transition-leave.advisory-transition-leave-active .advisory-header {
  opacity: 0
}

.advisory-transition-leave.advisory-transition-leave-active .advisory-header {
  transition: all .4s ease .2s
}

.advisory-transition-leave.advisory-transition-leave-active .advisory-body {
  transition: all .4s ease .1s
}

.nf-screen-wrapper {
  align-content: center;
  align-items: center;
  animation: fade-in .5s;
  background-color: #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3
}

.nf-screen {
  display: block;
  height: 19.44444444em;
  position: relative;
  width: 100%
}

.nf-screen .nf-loading-spinner {
  top: 19.44444444em
}

.nf-screen-logo {
  animation: fade-in .7s .3s forwards, scale-in 2.5s .3s forwards;
  background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/logo_v3.svg);
  background-size: 100%
    /*!rtl:ignore*/
  ;
  height: 7.66666667em;
  opacity: 0;
  top: 0;
  transform: scale(.7);
  width: 28.44444444em;
  z-index: 1
}

.nf-screen-light, .nf-screen-logo {
  background-repeat: no-repeat
    /*!rtl:ignore*/
  ;
  left: 0
    /*!rtl:ignore*/
  ;
  margin: 0 auto
    /*!rtl:ignore*/
  ;
  position: absolute;
  right: 0
    /*!rtl:ignore*/
}

.nf-screen-light {
  animation: fade-in .5s;
  background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/directional-light.jpg);
  background-position: 50%
    /*!rtl:ignore*/
  ;
  background-size: contain
    /*!rtl:ignore*/
  ;
  height: 22.05555556em;
  top: 2.22222222em;
  width: 100%;
  z-index: 0
}

.nf-screen-shadow {
  animation: fade-in .7s .3s forwards, scale-in 2.5s .3s forwards;
  background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/shadow.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 2.33333333em;
  left: 0
    /*!rtl:ignore*/
  ;
  margin: 0 auto
    /*!rtl:ignore*/
  ;
  position: absolute;
  right: 0
    /*!rtl:ignore*/
  ;
  top: 13.33333333em;
  width: 32.55555556em;
  z-index: 1
}

@keyframes scale-in {
  0% {
    transform: scale(.7)
  }

  to {
    transform: scale(1)
  }
}

@keyframes fade-in {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.nfp .player-loading, .watch-video .player-loading {
  background: #000;
  bottom: 0;
  left: 0;
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0
}

.nfp .player-loading .gradient, .watch-video .player-loading .gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, .651), transparent);
  height: 11.11111111em;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.nfp .player-loading .metadata, .watch-video .player-loading .metadata {
  color: #e4e4e4;
  font-weight: 500;
  left: 2.5em;
  position: absolute;
  top: 3.5em
}

.nfp .player-loading .metadata.logo, .watch-video .player-loading .metadata.logo {
  background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/logo_v3.svg);
  background-repeat: no-repeat;
  background-size: 8em 2.33em;
  min-width: 8em;
  padding-top: 3.71em;
  top: 1.38em
}

.nfp .player-loading .title, .watch-video .player-loading .title {
  font-size: 2em
}

.nfp .player-loading .subtitle, .watch-video .player-loading .subtitle {
  font-size: 1.33em
}

.nfp .player-loading .player-exit, .watch-video .player-loading .player-exit {
  fill: #fff;
  color: #fff;
  height: 1.4em;
  opacity: .5;
  position: absolute;
  right: 2.78em;
  text-decoration: none;
  top: 2.78em;
  transition: opacity .2s ease-out;
  width: 1.4em
}

.nfp .player-loading .player-exit:hover, .watch-video .player-loading .player-exit:hover {
  opacity: 1
}

.loading-children-container {
  align-content: center;
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  width: 100%
}

.loadingTransition-appear, .loadingTransition-enter {
  opacity: .01
}

.loadingTransition-appear.loadingTransition-appear-active, .loadingTransition-enter.loadingTransition-enter-active {
  opacity: 1;
  transition-duration: .15s;
  transition-duration: .3s;
  transition-property: opacity;
  transition-timing-function: ease-out
}

.loadingTransition-leave {
  opacity: 1
}

.loadingTransition-leave.loadingTransition-leave-active {
  opacity: .01;
  transition-duration: .15s;
  transition-duration: .3s;
  transition-property: opacity;
  transition-timing-function: ease-out
}

.player-loading-background-image {
  background-position: 50% 50%;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .3s ease-out
}

.player-loading-background-image-loading {
  opacity: 0
}

.player-loading-background-image-loaded {
  opacity: 1
}

.nfp-fatal-error-view {
  background: #000;
  font-size: 18px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2
}

.nfp-fatal-error-view .button-nfplayerExit {
  margin: .55555556em .83333333em;
  position: absolute;
  right: 0;
  top: 0
}

.nfp-fatal-error-view .information {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 4em;
  width: 100%;
  z-index: 1
}

.nfp-fatal-error-view .information .error-heading-wrapper {
  align-items: center;
  display: flex;
  flex-grow: 1
}

.nfp-fatal-error-view .information-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1
}

.nfp-fatal-error-view .whoops {
  align-self: flex-end;
  color: #fff;
  font-size: 2em;
  font-weight: 300;
  margin: 0 0 1em;
  padding: 0
}

.nfp-fatal-error-view .error-title {
  font-size: 1.5em;
  font-weight: 500;
  margin: 0;
  padding: 0
}

.nfp-fatal-error-view .error-code {
  cursor: text;
  display: block;
  font-size: .88888889em;
  font-weight: 400;
  margin: 0;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text
}

.nfp-fatal-error-view .error-code span {
  color: #009779
}

.nfp-fatal-error-view .error-text, .nfp-fatal-error-view .player-help-center {
  color: #ddd;
  font-size: .88888889em;
  margin: 1.3em 0 0;
  max-width: 35em;
  -webkit-user-select: initial;
  -moz-user-select: initial;
  user-select: auto
}

.nfp-fatal-error-view .error-text a, .nfp-fatal-error-view .player-help-center a {
  color: #fff;
  font-weight: 500
}

.nfp-fatal-error-view .error-text a:hover, .nfp-fatal-error-view .player-help-center a:hover {
  text-decoration: underline
}

.nfp-fatal-error-view .error-text {
  flex-grow: 1
}

.nfp-fatal-error-view .player-help-center {
  max-width: 40em
}

.fatal-error-view-secondary-info {
  flex-grow: 1
}

.fatal-error-view-signout-link {
  background-color: rgba(0, 0, 0, .4);
  border: 1px solid hsla(0, 0%, 100%, .4);
  display: inline-block;
  font-weight: 500;
  margin-top: 1.7em;
  padding: .5em 2em;
  transition: background-color .1s ease-out
}

.fatal-error-view-signout-link:hover {
  background-color: rgba(77, 77, 77, .4)
}

@keyframes branching_locked_shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0)
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0)
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0)
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0)
  }
}

.BranchingTimeline--container {
  margin: 2em 0;
  opacity: 0;
  pointer-events: none;
  position: relative;
  text-align: center;
  transition: opacity .2s ease-out;
  width: 100%
}

.BranchingTimeline--container.show {
  opacity: 1
}

.BranchingTimeline--container.withHeader {
  margin-top: 5em
}

.BranchingTimeline--container.snapshots .BranchingTimeline--item-content {
  background-color: #000
}

.BranchingTimeline--container.snapshots .BranchingTimeline--item-content .label {
  text-shadow: .04em .04em 0 rgba(0, 0, 0, .6)
}

.BranchingTimeline--container.snapshots .BranchingTimeline--item-content .main-image {
  background-size: 100%;
  box-shadow: inset 0 0 0 .1em hsla(0, 0%, 100%, .35);
  opacity: .75;
  transition: opacity .1s ease-out
}

.BranchingTimeline--container.snapshots .BranchingTimeline--item-content .focus-ring {
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .18) 33%, rgba(0, 0, 0, .35) 66%, rgba(0, 0, 0, .5));
  border: .4em solid #fff;
  bottom: -.2em;
  box-shadow: none;
  left: -.2em;
  opacity: 0;
  position: absolute;
  right: -.2em;
  top: -.2em;
  transition: opacity .1s ease-out;
  z-index: 4
}

.BranchingTimeline--container.snapshots .BranchingTimeline--item-content:focus .focus-ring {
  opacity: 1
}

.BranchingTimeline--container.snapshots .BranchingTimeline--item-content.focused {
  transform: scale(1)
}

.BranchingTimeline--container.snapshots .BranchingTimeline--item-content.focused .main-image {
  box-shadow: none;
  opacity: 1
}

.BranchingTimeline--container.snapshots .BranchingTimeline--item-content.focused .focus-ring {
  opacity: 1
}

.BranchingTimeline--header-container {
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  left: 50%;
  position: absolute;
  text-shadow: .04em .04em 0 rgba(0, 0, 0, .6);
  transform: translateX(-50%) translateY(-140%);
  width: 50%
}

.BranchingTimeline--header-container p {
  margin: 0;
  padding: 0
}

.BranchingTimeline--header-container p.rtl {
  direction: rtl
}

.BranchingTimeline--row {
  display: flex;
  flex: 1 0 auto;
  flex-wrap: nowrap;
  width: 84%
}

.BranchingTimeline--row.animate {
  transition: transform .3s ease-out
}

.BranchingTimeline--column {
  box-sizing: border-box;
  direction: ltr;
  display: inline-block;
  position: relative
}

.BranchingTimeline--column:after {
  content: "";
  display: block;
  padding-top: 56.25%
}

.BranchingTimeline--item {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.BranchingTimeline--item-content {
  backface-visibility: hidden;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  filter: blur(0);
  height: 100%;
  position: relative;
  text-align: center;
  transform-origin: 50% 50%;
  transition: transform .2s ease-out;
  z-index: 0
}

.BranchingTimeline--item-content .choice-image {
  background-repeat: no-repeat;
  height: 100%;
  width: 50%
}

.BranchingTimeline--item-content .main-image, .BranchingTimeline--item-content .mixin-main-image {
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.BranchingTimeline--item-content .main-image {
  z-index: 2
}

.BranchingTimeline--item-content .main-image-selected {
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .4s ease-out;
  z-index: 3
}

.BranchingTimeline--item-content .focus-ring {
  border: 4px solid #fff;
  bottom: -8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
  left: -8px;
  opacity: 0;
  position: absolute;
  right: -8px;
  top: -8px;
  transition: opacity .2s ease-out;
  z-index: 4
}

.BranchingTimeline--item-content .label {
  bottom: .5em;
  color: #fff;
  direction: ltr;
  display: inline-block;
  font-size: 1.6rem;
  height: auto;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity .2s ease-out;
  width: 90%;
  z-index: 4
}

.BranchingTimeline--item-content .label.rtl {
  direction: rtl
}

.BranchingTimeline--item-content:focus .focus-ring, .BranchingTimeline--item-content:focus .label {
  opacity: 1
}

.BranchingTimeline--item-content.focused {
  transform: scale(1.1);
  z-index: 2
}

.BranchingTimeline--item-content.focused .label, .BranchingTimeline--item-content.selected .main-image-selected {
  opacity: 1
}

.BranchingTimeline--item-content.dimmed {
  opacity: .2
}

.BranchingTimeline--item-content.locked .locked-icon {
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.BranchingTimeline--item-content.locked .locked-icon.locked-animation {
  animation: branching_locked_shake .82s cubic-bezier(.36, .07, .19, .97) both;
  backface-visibility: hidden;
  perspective: 1000px;
  transform: translateZ(0)
}

.BranchingTimeline--navigate-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: #d7d7d7;
  cursor: pointer;
  display: block;
  height: 100%;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transition: opacity .1s ease-in-out;
  width: 8%;
  z-index: 4
}

.BranchingTimeline--navigate-button .svg-sizer {
  display: block;
  height: 46%;
  position: absolute;
  top: 50%;
  transition: transform .1s ease-in-out;
  width: 100%
}

.BranchingTimeline--navigate-button .svg-sizer svg {
  height: 100%;
  left: 50%;
  overflow: visible;
  position: absolute;
  top: 0;
  transform: translateX(-50%)
}

.BranchingTimeline--navigate-button .svg-sizer svg polyline {
  stroke: #d7d7d7;
  stroke-width: 8;
  stroke-linecap: square;
  stroke-linejoin: miter;
  transition: stroke .25s ease-in-out
}

.BranchingTimeline--navigate-button:hover {
  color: #fff
}

.BranchingTimeline--navigate-button:hover svg polyline {
  stroke: #fff
}

.BranchingTimeline--navigate-button:focus {
  color: #fff;
  outline: 0
}

.BranchingTimeline--navigate-button:focus svg polyline {
  stroke: #fff
}

.BranchingTimeline--navigate-button:active {
  color: #d7d7d7
}

.BranchingTimeline--navigate-button:active svg polyline {
  stroke: #d7d7d7
}

.BranchingTimeline--navigate-button.right {
  right: 0
}

.BranchingTimeline--navigate-button.right .svg-sizer {
  transform: scale(1) translateY(-50%)
}

.BranchingTimeline--navigate-button.right:hover .svg-sizer {
  transform: scale(1.1) translateY(-45%)
}

.BranchingTimeline--navigate-button.right:active .svg-sizer {
  transform: scale(1) translateY(-50%)
}

.BranchingTimeline--navigate-button.left {
  left: 0
}

.BranchingTimeline--navigate-button.left .svg-sizer {
  transform: scale(-1) translateY(50%)
}

.BranchingTimeline--navigate-button.left:hover .svg-sizer {
  transform: scale(-1.1) translateY(45%)
}

.BranchingTimeline--navigate-button.left:active .svg-sizer {
  transform: scale(-1) translateY(50%)
}

.BranchingTimeline--container.enabled {
  pointer-events: all
}

.BranchingTimeline--container.active .BranchingTimeline--navigate-button {
  opacity: 1
}

.BranchingTimeline--container.pib .BranchingTimeline--column:after {
  padding-top: 60%
}

.BranchingTimeline--container.pib .BranchingTimeline--item-content .focus-ring {
  bottom: -2px
}

.BranchingTimeline--container.stretch .BranchingTimeline--column:after {
  padding-top: 60%
}

.BranchingTimeline--container.stretch .BranchingTimeline--item-content .focus-ring {
  bottom: 6px
}

.mixin-choice-selection-focus {
  transition: opacity .3s cubic-bezier(.25, 1, .25, 1)
}

@keyframes branching_load_spinner {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg)
      /*!rtl:ignore*/
  }

  50% {
    transform: translateX(-50%) translateY(-50%) rotate(180deg)
      /*!rtl:ignore*/
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(1turn)
      /*!rtl:ignore*/
  }
}

@keyframes branching_input_added {
  0% {
    transform: translateY(-54%)
  }

  50% {
    transform: translateY(-59%)
  }

  to {
    transform: translateY(-54%)
  }
}

@keyframes branching_choice_select_scale {
  0% {
    transform: scale(1)
  }

  50% {
    transform: scale(.9)
  }

  to {
    transform: scale(.98)
  }
}

.BranchingInteractiveScene--loader {
  background-color: #000;
  height: 100%;
  position: relative;
  width: 100%
}

.BranchingInteractiveScene--loader.overlay {
  background-color: rgba(0, 0, 0, .5)
}

.BranchingInteractiveScene--loader .spinner {
  animation: branching_load_spinner .9s linear infinite;
  background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/site-spinner.png);
  background-size: 100%;
  display: inline-block;
  height: 7vh;
  left: 50%;
  min-height: 5rem;
  min-width: 5rem;
  position: absolute;
  top: 50%;
  width: 7vh
}

.BranchingInteractiveScene--container, .BranchingInteractiveScene--wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%
}

.BranchingInteractiveScene--container {
  direction: ltr;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative
}

.BranchingInteractiveScene--container.disabled {
  pointer-events: none
}

.BranchingInteractiveScene--container.animated .BranchingInteractiveScene--background, .BranchingInteractiveScene--container.animated .BranchingInteractiveScene--choices-container, .BranchingInteractiveScene--container.animated .BranchingInteractiveScene--horizontal-timer-container, .BranchingInteractiveScene--container.animated .BranchingInteractiveScene--input-display, .BranchingInteractiveScene--ipp.initialHide {
  opacity: 0
}

.BranchingInteractiveScene--choices-container {
  direction: ltr
}

.BranchingInteractiveScene--choice-selection-container, .BranchingInteractiveScene--choices-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%
}

.BranchingInteractiveScene--choice-selection-container {
  transform: translateX(-50%) translateZ(0)
}

.BranchingInteractiveScene--background-wrapper {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%
}

.BranchingInteractiveScene--background {
  background-size: 100%;
  height: 100%;
  position: absolute;
  width: 100%
}

.BranchingInteractiveScene--input-display {
  display: flex;
  justify-content: space-evenly;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateZ(0)
}

.BranchingInteractiveScene--input-display .input-item {
  box-sizing: border-box;
  position: relative;
  text-align: center
}

.BranchingInteractiveScene--input-display .input-item p {
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: .2;
  padding: 0;
  position: absolute;
  top: 54%;
  transform: translateY(-54%);
  transition: opacity .1s ease-out;
  width: 100%
}

.BranchingInteractiveScene--input-display .input-item.active p {
  opacity: 1
}

.BranchingInteractiveScene--input-display .input-item.pop p {
  animation: branching_input_added .32s ease-in-out
}

.BranchingInteractiveScene--header {
  direction: ltr;
  height: 20%;
  position: relative;
  text-align: center;
  text-shadow: .02em .02em 0 rgba(0, 0, 0, .2);
  width: 100%;
  z-index: auto
}

.BranchingInteractiveScene--header h1 {
  margin: .9em 0;
  white-space: nowrap
}

.BranchingInteractiveScene--choice-label {
  bottom: 19%;
  direction: ltr;
  display: inline-block;
  height: 2.2vw;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity .2s ease-out;
  z-index: auto
}

.BranchingInteractiveScene--choice-label p {
  display: inline-block;
  font-size: 2vw;
  margin: 0;
  padding: 0
}

.BranchingInteractiveScene--choice-label .point {
  height: 100%;
  position: absolute;
  top: 0;
  transition: all .2s ease-out;
  width: auto
}

.BranchingInteractiveScene--choice-label .point.left {
  left: 0;
  transform: translateX(-110%)
}

.BranchingInteractiveScene--choice-label .point.right {
  right: 0;
  transform: translateX(110%)
}

.BranchingInteractiveScene--choice-label .point.right img {
  transform: scaleX(-1)
}

.BranchingInteractiveScene--choice-label.show {
  opacity: 1
}

.BranchingInteractiveScene--choice-label.show .point.left {
  transform: translateX(-160%)
}

.BranchingInteractiveScene--choice-label.show .point.right {
  transform: translateX(160%)
}

.BranchingInteractiveScene--ipp-action-button {
  position: absolute
}

.BranchingInteractiveScene--ipp-action-button:focus {
  outline: none
}

.BranchingInteractiveScene--ipp-action-button .image {
  cursor: pointer;
  height: 100%;
  width: 100%
}

.BranchingInteractiveScene--ipp-action-button .image>div {
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.BranchingInteractiveScene--ipp-action-button .image .focus {
  transition: opacity .3s cubic-bezier(.25, 1, .25, 1)
}

.BranchingInteractiveScene--ipp-action-button .image .active, .BranchingInteractiveScene--ipp-action-button .image .focus, .BranchingInteractiveScene--ipp-action-button .image .selected {
  opacity: 0
}

.BranchingInteractiveScene--ipp-action-button .label {
  direction: ltr;
  pointer-events: none;
  position: absolute;
  text-align: center;
  white-space: nowrap
}

.BranchingInteractiveScene--ipp-action-button .label p {
  margin: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%)
}

.BranchingInteractiveScene--ipp-action-button.focus .image .focus {
  opacity: 1
}

.BranchingInteractiveScene--ipp-action-button.active .image .focus, .BranchingInteractiveScene--ipp-action-button.focus .image .active, .BranchingInteractiveScene--ipp-action-button.focus .image .selected {
  opacity: 0
}

.BranchingInteractiveScene--ipp-action-button.active .image .active {
  opacity: 1
}

.BranchingInteractiveScene--ipp-action-button.active .image .selected, .BranchingInteractiveScene--ipp-action-button.selected .image .active, .BranchingInteractiveScene--ipp-action-button.selected .image .focus {
  opacity: 0
}

.BranchingInteractiveScene--ipp-action-button.selected .image .selected {
  opacity: 1
}

.BranchingInteractiveScene--ipp-action-button.credits {
  right: 0;
  z-index: 1
}

.BranchingInteractiveScene--choice-selection {
  cursor: pointer;
  position: relative;
  z-index: auto
}

.BranchingInteractiveScene--choice-selection .container {
  height: 100%;
  width: 100%
}

.BranchingInteractiveScene--choice-selection .choiceImage {
  background-size: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: auto
}

.BranchingInteractiveScene--choice-selection .label {
  color: #fff;
  direction: ltr;
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%
}

.BranchingInteractiveScene--choice-selection .label p {
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  text-align: center;
  width: 100%
}

.BranchingInteractiveScene--choice-selection .label svg {
  display: block;
  height: auto;
  left: 0;
  max-height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%
}

.BranchingInteractiveScene--choice-selection .label svg text, .BranchingInteractiveScene--choice-selection .label svg tspan {
  font-size: 10px
}

.BranchingInteractiveScene--choice-selection .frame {
  pointer-events: none;
  z-index: auto
}

.BranchingInteractiveScene--choice-selection .frame, .BranchingInteractiveScene--choice-selection .frame>div {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  position: absolute;
  width: 100%
}

.BranchingInteractiveScene--choice-selection .frame .focus {
  transition: opacity .3s cubic-bezier(.25, 1, .25, 1)
}

.BranchingInteractiveScene--choice-selection .frame .active, .BranchingInteractiveScene--choice-selection .frame .focus, .BranchingInteractiveScene--choice-selection .frame .selected {
  opacity: 0
}

.BranchingInteractiveScene--choice-selection:focus {
  outline: none
}

.BranchingInteractiveScene--choice-selection.focus .frame .focus {
  opacity: 1
}

.BranchingInteractiveScene--choice-selection.active .frame .focus, .BranchingInteractiveScene--choice-selection.focus .frame .active, .BranchingInteractiveScene--choice-selection.focus .frame .selected {
  opacity: 0
}

.BranchingInteractiveScene--choice-selection.active .frame .active {
  opacity: 1
}

.BranchingInteractiveScene--choice-selection.active .frame .selected, .BranchingInteractiveScene--choice-selection.selected .frame .focus {
  opacity: 0
}

.BranchingInteractiveScene--choice-selection.selected .frame .active {
  opacity: 0;
  transition-delay: 10ms
}

.BranchingInteractiveScene--choice-selection.selected .frame .selected {
  opacity: 1;
  transition-delay: 10ms
}

.BranchingInteractiveScene--choice-selection.hide {
  opacity: 0;
  transition: opacity .34s ease-out
}

.BranchingInteractiveScene--choice-selection.dimOnIdle .choiceImage, .BranchingInteractiveScene--choice-selection.dimOnIdle .label {
  opacity: .5;
  transition: opacity .2s ease-out
}

.BranchingInteractiveScene--choice-selection.dimOnIdle.active .choiceImage, .BranchingInteractiveScene--choice-selection.dimOnIdle.active .label, .BranchingInteractiveScene--choice-selection.dimOnIdle.focus .choiceImage, .BranchingInteractiveScene--choice-selection.dimOnIdle.focus .label, .BranchingInteractiveScene--choice-selection.dimOnIdle.selected .choiceImage, .BranchingInteractiveScene--choice-selection.dimOnIdle.selected .label {
  opacity: 1
}

.BranchingInteractiveScene--choice-selection.toggleFrames.focus .frame .normal {
  opacity: 0
}

.BranchingInteractiveScene--choice-selection.toggleFrames.focus .frame .focus {
  transition: none
}

.BranchingInteractiveScene--choice-selection.toggleFrames.active .frame .normal, .BranchingInteractiveScene--choice-selection.toggleFrames.selected .frame .normal {
  opacity: 0
}

.BranchingInteractiveScene--choice-selection.toggleFrames.selected .frame .selected {
  transition: none
}

.BranchingInteractiveScene--choice-selection.bounceOnSelect.selected .container {
  animation: branching_choice_select_scale .15s cubic-bezier(.35, 0, .65, 1.13)
}

.BranchingInteractiveScene--pug {
  bottom: 10%;
  height: 20%;
  position: absolute;
  right: 4%;
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: .3s cubic-bezier(.4, 0, .2, 1);
  width: auto
}

.BranchingInteractiveScene--pug.show {
  transform: scale(1)
}

.BranchingInteractiveScene--timer-container {
  height: 100%;
  position: absolute;
  width: 100%
}

.BranchingInteractiveScene--horizontal-timer-container {
  bottom: 0;
  height: 13%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 64%
}

.BranchingInteractiveScene--horizontal-timer-container:focus {
  outline: none
}

.BranchingInteractiveScene--horizontal-timer-container .timer {
  background-repeat: no-repeat;
  height: 32%;
  opacity: 0;
  position: absolute;
  top: 120%;
  transform: translateZ(0);
  width: 100%
}

.BranchingInteractiveScene--horizontal-timer-container .timer:focus {
  outline: none
}

.BranchingInteractiveScene--horizontal-timer-container .timer .interactive-icon {
  position: absolute
}

.BranchingInteractiveScene--horizontal-timer-container .timer .countdown-meter {
  height: 100%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%
}

.BranchingInteractiveScene--horizontal-timer-container .timer .countdown-meter .timer-point {
  border: 0;
  height: 100%;
  left: 0;
  line-height: 0;
  position: absolute;
  z-index: 2
}

.BranchingInteractiveScene--horizontal-timer-container .timer .countdown-meter .timer-point:focus {
  outline: none
}

.BranchingInteractiveScene--horizontal-timer-container .timer .countdown-meter .timer-bar {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1
}

.BranchingInteractiveScene--horizontal-timer-container .timer .countdown-meter .bar-wrapper {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 50%
}

.BranchingInteractiveScene--horizontal-timer-container .timer .countdown-meter .bar-wrapper .bar-container {
  height: 100%;
  position: absolute;
  width: 100%
}

.BranchingInteractiveScene--horizontal-timer-container .timer .countdown-meter .bar-wrapper.left {
  left: 0
}

.BranchingInteractiveScene--horizontal-timer-container .timer .countdown-meter .bar-wrapper.right {
  right: 0;
  transform: scaleX(-1);
  will-change: transform
}

.BranchingInteractiveScene--horizontal-timer-container.animateIn .timer {
  opacity: 1;
  top: 0;
  transition: top .4s cubic-bezier(0, 0, .2, 1), opacity .2s ease-out
}

.BranchingInteractiveScene--horizontal-timer-container.animateOut .timer {
  opacity: 0;
  top: 120%;
  transition: top .4s cubic-bezier(.4, 0, 1, 1), opacity .3s ease-out;
  transition-delay: .5s
}

.BranchingInteractiveScene--horizontal-timer-container.show .timer {
  opacity: 1;
  top: 0
}

.BranchingInteractiveScene--horizontal-timer-container.dimmed .timer-bar {
  opacity: .5;
  transition: opacity .14s ease-out
}

.tmpl-branching-scene__wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%
}

.tmpl-branching-scene__wrapper--hidden:after {
  background: rgba(2, 2, 2, .75);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.tmpl-branching-scene__wrapper--hidden .tmpl-branching-scene__choice-selection, .tmpl-branching-scene__wrapper--hidden .tmpl-branching-scene__controls-icon, .tmpl-branching-scene__wrapper--hidden .tmpl-branching-scene__streak-indicator, .tmpl-branching-scene__wrapper--hidden .tmpl-branching-scene__triviaverse-header {
  visibility: hidden
}

.tmpl-branching-scene__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%
}

.tmpl-branching-scene__container--disabled {
  pointer-events: none
}

.tmpl-branching-scene__background:focus {
  outline: none
}

.tmpl-branching-scene__header {
  background-repeat: no-repeat;
  text-align: center
}

.tmpl-branching-scene__header p {
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  width: 100%
}

.tmpl-branching-scene__header:focus {
  outline: none
}

.tmpl-branching-scene__choice-selection {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  will-change: transform
}

.tmpl-branching-scene__choice-selection:focus {
  outline: none
}

.tmpl-branching-scene__choice-selection-mask {
  backface-visibility: hidden;
  background-repeat: no-repeat;
  transform: translateZ(0)
}

.tmpl-branching-scene__choice-selection-mask:focus {
  outline: none
}

.tmpl-branching-scene__choice-selection-background {
  background-repeat: no-repeat
}

.tmpl-branching-scene__choice-selection-background:focus {
  outline: none
}

.tmpl-branching-scene__choice-selection-foreground {
  background-repeat: no-repeat
}

.tmpl-branching-scene__choice-selection-foreground:focus {
  outline: none
}

.tmpl-branching-scene__choice-selection-scrim {
  background-repeat: no-repeat
}

.tmpl-branching-scene__choice-selection-scrim:focus {
  outline: none
}

.tmpl-branching-scene__choice-selection-icon {
  background-repeat: no-repeat
}

.tmpl-branching-scene__choice-selection-icon:focus {
  outline: none
}

.tmpl-branching-scene__triviaverse-header p {
  align-items: center;
  display: flex;
  justify-content: center
}

.tmpl-branching-scene__category, .tmpl-branching-scene__category-subtext, .tmpl-branching-scene__choice-selection-label, .tmpl-branching-scene__choice-selection-tooltip-subtitle-label, .tmpl-branching-scene__choice-selection-tooltip-title-label, .tmpl-branching-scene__generic-label, .tmpl-branching-scene__score-container_max-score, .tmpl-branching-scene__score-container_score, .tmpl-branching-scene__tutorial-string {
  backface-visibility: hidden;
  color: #fff;
  font-weight: 500;
  height: 100%;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transform: translateZ(0);
  width: 100%
}

.tmpl-branching-scene__category p, .tmpl-branching-scene__category-subtext p, .tmpl-branching-scene__choice-selection-label p, .tmpl-branching-scene__choice-selection-tooltip-subtitle-label p, .tmpl-branching-scene__choice-selection-tooltip-title-label p, .tmpl-branching-scene__generic-label p, .tmpl-branching-scene__score-container_max-score p, .tmpl-branching-scene__score-container_score p, .tmpl-branching-scene__tutorial-string p {
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  width: 100%
}

.tmpl-branching-scene__category-subtext:focus, .tmpl-branching-scene__category:focus, .tmpl-branching-scene__choice-selection-label:focus, .tmpl-branching-scene__choice-selection-tooltip-subtitle-label:focus, .tmpl-branching-scene__choice-selection-tooltip-title-label:focus, .tmpl-branching-scene__generic-label:focus, .tmpl-branching-scene__score-container_max-score:focus, .tmpl-branching-scene__score-container_score:focus, .tmpl-branching-scene__tutorial-string:focus {
  outline: none
}

.tmpl-branching-scene__choice-selection-tooltip-subtitle-label p, .tmpl-branching-scene__choice-selection-tooltip-title-label p {
  text-align: inherit
}

.tmpl-branching-scene__timer {
  backface-visibility: hidden;
  direction: ltr;
  transform-style: preserve-3d
}

.tmpl-branching-scene__timer-background, .tmpl-branching-scene__timer-overlay {
  background-repeat: no-repeat
}

.tmpl-branching-scene__timer-fill-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: center
}

.tmpl-branching-scene__timer-fill, .tmpl-branching-scene__timer-fill-container {
  backface-visibility: hidden;
  transform: translateZ(0);
  transform-style: preserve-3d
}

.tmpl-branching-scene__timer-fill {
  will-change: width
}

.tmpl-branching-scene__timer-cap {
  backface-visibility: hidden;
  background-repeat: no-repeat;
  transform: translateZ(0);
  transform-style: preserve-3d
}

.tmpl-branching-scene__timer-cap--left {
  margin-right: -1px
}

.tmpl-branching-scene__timer-cap--right {
  margin-left: -1px
}

.tmpl-branching-scene__score-container_score .score-container__current-score, .tmpl-branching-scene__score-container_score .score-container__last-score {
  display: inline-block;
  transition: transform .3s 1s, opacity .3s 1s
}

.tmpl-branching-scene__score-container_score .score-container__current-score {
  opacity: 0;
  position: relative;
  transform: scale(0)
}

.tmpl-branching-scene__score-container_score .score-container__last-score {
  opacity: 1;
  position: absolute;
  right: 0
}

.tmpl-branching-scene__score-container_score.animate-score .score-container__last-score {
  opacity: 0;
  transform: scale(0);
  transition-timing-function: cubic-bezier(.59, -.14, .76, -.24)
}

.tmpl-branching-scene__score-container_score.animate-score .score-container__current-score {
  opacity: 1;
  transform: scale(1);
  transition-delay: 1.32s;
  transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55)
}

.BranchingNotification--container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%
}

.BranchingNotification--container.hide {
  visibility: hidden
}

.BranchingNotification__label {
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%
}

.BranchingNotification__streak-counter {
  right: 2.8125%
}

.BranchingNotification--toast-container {
  display: inline-block;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%)
}

.BranchingNotification--toast-container:focus {
  outline: none
}

.BranchingNotification--toast-container .toast {
  background-repeat: no-repeat;
  bottom: 0;
  left: 50%;
  opacity: 1;
  position: absolute;
  transform: translateX(-50%)
}

.BranchingNotification--toast-container .toast .label {
  height: 100%
}

.BranchingNotification--toast-container .toast .label p {
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  text-align: center;
  width: 100%
}

.BranchingNotification--toast-container .toast .icon {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(-50%)
}

.BranchingNotification--animation-wrapper .animation-appear .toast, .BranchingNotification--animation-wrapper .animation-enter .toast {
  bottom: 100%;
  opacity: .01
}

.BranchingNotification--animation-wrapper .animation-appear-active .toast, .BranchingNotification--animation-wrapper .animation-enter-active .toast {
  bottom: 0;
  opacity: 1;
  transition: bottom .4s cubic-bezier(0, 0, .2, 1), opacity .2s ease-out
}

.BranchingNotification--animation-wrapper .animation-exit .toast, .BranchingNotification--animation-wrapper .animation-leave .toast {
  bottom: 0;
  opacity: 1
}

.BranchingNotification--animation-wrapper .animation-exit-active .toast, .BranchingNotification--animation-wrapper .animation-leave-active .toast {
  bottom: 100%;
  opacity: 0;
  transition: bottom .4s cubic-bezier(.4, 0, 1, 1), opacity .3s ease-out
}

.BranchingNotification--animation-wrapper.ftue .animation-appear-active .toast, .BranchingNotification--animation-wrapper.ftue .animation-enter-active .toast {
  transition-delay: 1.5s
}

.tmpl-branching-notification__toast-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%
}

.tmpl-branching-notification__toast-container--hide {
  visibility: hidden
}

.tmpl-branching-notification__toast {
  position: absolute
}

.tmpl-branching-notification__toast:focus {
  outline: none
}

.tmpl-branching-notification__toast-background-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative
}

.tmpl-branching-notification__toast-background, .tmpl-branching-notification__toast-cap, .tmpl-branching-notification__toast-icon, .tmpl-branching-notification__toast-label {
  position: absolute
}

.tmpl-branching-notification__tq-results-points-label, .tmpl-branching-notification__tq-results-score-container-max-score, .tmpl-branching-notification__tq-results-score-container-score, .tmpl-branching-notification__tq-results-unlock-container-label, .tmpl-branching-notification__tq-results-unlock-label, .tmpl-branching-notification__trivia_quest-toast-label {
  backface-visibility: hidden;
  color: #fff;
  font-weight: 500;
  height: 100%;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transform: translateZ(0);
  width: 100%
}

.tmpl-branching-notification__tq-results-points-label p, .tmpl-branching-notification__tq-results-score-container-max-score p, .tmpl-branching-notification__tq-results-score-container-score p, .tmpl-branching-notification__tq-results-unlock-container-label p, .tmpl-branching-notification__tq-results-unlock-label p, .tmpl-branching-notification__trivia_quest-toast-label p {
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  width: 100%
}

.tmpl-branching-notification__tq-results-points-label:focus, .tmpl-branching-notification__tq-results-score-container-max-score:focus, .tmpl-branching-notification__tq-results-score-container-score:focus, .tmpl-branching-notification__tq-results-unlock-container-label:focus, .tmpl-branching-notification__tq-results-unlock-label:focus, .tmpl-branching-notification__trivia_quest-toast-label:focus {
  outline: none
}

.ResizingAspect--wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.ResizingAspect--container {
  display: flex;
  position: relative
}

.evidence-overlay {
  font-size: 1.5em;
  padding: 12em
}

@media only screen and (max-width: 1024px) {
  .evidence-overlay {
    font-size: 1.1em;
    padding-left: 8em;
    padding-right: 8em
  }
}

.eo-transition-appear, .eo-transition-enter {
  opacity: .01;
  transform: scale(2.5)
}

.eo-transition-appear.eo-transition-appear-active, .eo-transition-appear.eo-transition-enter-active, .eo-transition-enter.eo-transition-appear-active, .eo-transition-enter.eo-transition-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: transform .1s ease-out, opacity .1s ease-out
}

.eo-transition-leave {
  opacity: 1;
  transform: scale(1)
}

.eo-transition-leave.eo-transition-leave-active {
  opacity: .01;
  transform: scale(2.5);
  transition: transform .1s ease-out, opacity .1s ease-out
}

.SeamlessControls--container {
  opacity: 0;
  transition-delay: 0s, .3s;
  transition-duration: .3s, 0s;
  transition-property: opacity, visibility;
  transition-timing-function: ease-out;
  visibility: hidden
}

.SeamlessControls--container .button-nfplayerBack {
  font-size: 2.2em;
  left: 1.5em;
  position: absolute;
  top: 1.5em
}

.SeamlessControls--container-visible {
  opacity: 1;
  transition-delay: 0s, 0s;
  visibility: visible
}

.SeamlessControls--container .nf-flat-button:focus {
  border-color: #fff
}

.SeamlessControls--background-artwork {
  background-position: 50% 50%;
  background-size: cover;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .5s ease-out
}

.SeamlessControls--background-artwork-visible {
  opacity: .7
}

.nfp-autoplay-interrupter {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.nfp-autoplay-interrupter>div {
  pointer-events: auto
}

.nfp-autoplay-interrupter .interrupter-text {
  font-size: 1.88888889em;
  font-weight: 300;
  margin-bottom: .70588235em;
  text-align: center
}

.nfp-autoplay-interrupter .interrupter-actions {
  display: flex;
  justify-content: center
}

.pp-stars-wrapper {
  justify-content: flex-start
}

.pp-stars-wrapper .starbar {
  font-size: 1.33333333em;
  margin-top: .33333333em
}

.pp-stars-wrapper {
  align-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: .77777778em;
  text-align: left;
  width: 100%
}

.pp-thumbs-container .thumbs-component {
  display: flex;
  justify-content: flex-end
}

.pp-thumbs-container .thumbs-component .nf-svg-button {
  height: 4em;
  width: 4em
}

.pp-thumbs-container .thumbs-component .nf-svg-button svg {
  height: 2.5em;
  width: 2.5em
}

.pp-thumbs-container .thumb-container {
  margin: 0 .44444444em
}

.pp-thumbs-container .thumb {
  border: .11111111em solid #fff;
  box-sizing: border-box;
  height: 2em;
  padding: .38888889em;
  width: 2em
}

.thumbs-selection-overlay-container {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) scale(1) translateZ(0px);
  transition: opacity 0.1s linear 0s, transform 0.3s cubic-bezier(0.5, 0, 0.1, 1) 0s, all 0.2s linear 0s;
}

.thumb-popover {
  width: 11rem;
  scale: 0;
  opacity: 0;
  background-color: rgba(35, 35, 35);
  border-radius: 3rem;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 2px 0px, rgba(0, 0, 0, 0.5) 0px 8px 16px 0px;
  display: flex;
  padding: 0.8rem;
  -webkit-box-pack: justify;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  transition: opacity 0.1s linear 0s, transform 0.3s cubic-bezier(0.5, 0, 0.1, 1) 0s, all 0.2s linear 0s;
}

.thumb-popover button {
  margin: 0 0.5rem;
}

.like-btn:hover .thumb-popover {
  opacity: 1;
  scale: 1;

}

.recap-lower {
  bottom: 4.11111111em;
  opacity: 0;
  position: absolute;
  right: 1.44444444em;
  transition: opacity .5s ease
}

.recap-lower .nf-flat-button {
  font-size: 1em;
  margin: 0
}

.AkiraPlayer .nfp.nf-player-container.NFPlayer.postplay {
  border: .3em solid transparent;
  cursor: default;
  height: 25em;
  left: 4em;
  min-height: 160px;
  top: 14em;
  width: 40em;
  z-index: 2
}

.AkiraPlayer .nfp.nf-player-container.NFPlayer.postplay:hover {
  border: .3em solid #fff
}

.postplay--episode-info-container {
  display: flex
}

.postplay--episode-maturity-rating {
  padding: 0 1em
}

.Recommendations {
  -webkit-font-smoothing: antialiased;
  align-content: flex-end;
  align-items: flex-end;
  background-color: #000;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-shadow: 1px 1px #000;
  top: 0;
  z-index: 0
}

.Recommendations-background {
  background-position: 50% 0;
  background-size: cover;
  z-index: -2
}

.Recommendations-background, .Recommendations-background-gradient {
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.Recommendations-background-gradient {
  background-color: transparent;
  background-image: linear-gradient(0deg, #000, rgba(0, 0, 0, .3) 40%, transparent);
  background-size: 100% 100%;
  z-index: -1
}

.Recommendations-footer {
  align-content: flex-end;
  align-items: flex-end;
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, .65));
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  text-shadow: 0 1px #000;
  width: 80%
}

.Recommendations-recommend-text {
  color: #fff;
  flex: 1;
  font-size: 3em;
  margin: 0 1.33em 1em;
  min-width: 12em
}

.Recommendations-recommend-header {
  color: #cd0007;
  font-size: 2em;
  line-height: 1;
  margin: 0 0 .2em
}

.Recommendations-recommend-subheader {
  color: #fff;
  line-height: 1;
  margin: 0
}

.Recommendations-action-buttons {
  font-size: 24px;
  margin: .5em 0
}

.Recommendations-recommendations {
  align-content: flex-end;
  align-items: flex-end;
  display: flex;
  font-size: 4em;
  justify-content: center;
  margin: 0 1em 1em 0;
  min-width: 12em
}

.Recommendations-action-buttons {
  color: #ccc;
  display: flex;
  font-size: 1em;
  margin: .5em 0 .1em
}

.Recommendations-action-buttons .nf-icon-button.nf-flat-button {
  font-size: .65em;
  text-transform: none
}

.Recommendations-experience {
  display: flex;
  flex-direction: column
}

.Recommendations-experience-title {
  align-self: flex-start;
  font-size: 4em;
  font-weight: 500;
  margin-bottom: 1em
}

.Recommendations-experience-actions .nf-icon-button.nf-flat-button {
  text-transform: none
}

.Recommendation {
  align-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  height: 9.23em;
  justify-content: center;
  position: relative;
  width: 6.5em
}

.Recommendation-boxshot, .Recommendation-boxshot-active {
  background-iosition: center bottom;
  background-iepeat: no-repeat;
  align-content: center;
  align-items: center;
  background-size: 100%;
  display: flex;
  height: 4.53em;
  justify-content: center;
  position: relative;
  transition: width .2s ease-out, height .2s ease-out;
  width: 3.25em
}

.Recommendation-boxshot-active {
  height: 9em;
  width: 6.5em
}

.Recommendation-boxshot-active .PlayIcon {
  opacity: .8;
  visibility: visible
}

.Recommendation-bob, .Recommendation-bob-visible {
  background-color: #222;
  bottom: 1.2em;
  color: #fff;
  font-size: 26px;
  left: -13.35em;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity .3s ease-in-out;
  width: 12.6em
}

.Recommendation-bob-visible:after {
  border: .75em solid rgba(34, 34, 34, 0);
  border-left-color: #222;
  content: " ";
  height: 0;
  left: 100%;
  margin-top: -.75em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 0
}

.Recommendation-bob-visible {
  opacity: 1
}

.Recommendation-bob-movie-header {
  background-color: #bb000d;
  display: flex;
  flex-direction: column;
  font-size: .5em;
  font-weight: 500;
  line-height: 1.8em;
  padding: .66em 1em
}

.Recommendation-bob-movie-title {
  display: block;
  font-size: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.Recommendation-bob-movie-attributes {
  display: flex;
  padding: .5em 0 0
}

.Recommendation-bob-movie-attributes-duration, .Recommendation-bob-movie-attributes-year {
  margin-right: 1em
}

.Recommendation-bob-movie-attributes-rating {
  border: 1px solid #fff;
  margin-right: 1em;
  padding: 0 .4em
}

.Recommendation-bob-movie-content {
  font-size: .66em;
  line-height: 1.25em;
  padding: .66em .75757576em
}

.Recommendation-bob-content-info>ul {
  list-style: none;
  margin: .66em 0 0;
  padding: 0
}

.Recommendation-bob-content-info>ul>li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.Recommendation-bob-movie-content-info>ul {
  list-style: none;
  margin: .66em 0 0;
  padding: 0
}

.Recommendation-bob-movie-content-info>ul>li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.Recommendation-bob-movie-content-info>ul>li>span {
  font-weight: 500;
  margin-right: .3em
}

.Recommendation-bob-movie-content-info>ul>li>span+span {
  font-weight: 400;
  margin-right: 0
}

.WatchNext {
  -webkit-font-smoothing: antialiased;
  align-content: flex-end;
  align-items: flex-end;
  background-color: #000;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  right: 0;
  text-shadow: 1px 1px #000;
  top: 0;
  z-index: 0
}

.WatchNext .nf-player-container:hover {
  border: .3em solid #fff
}

.WatchNext .svg-icon {
  vertical-align: middle
}

.WatchNext .svg-icon.svg-icon-nfplayerEpisodes {
  height: 1em;
  width: 1.5em
}

.WatchNext-background {
  background-position: 50% 0;
  background-size: cover;
  z-index: -2
}

.WatchNext-background, .WatchNext-background-gradient {
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.WatchNext-background-gradient {
  background-color: transparent;
  background-image: linear-gradient(0deg, #000, rgba(0, 0, 0, .3) 40%, transparent);
  background-size: 100% 100%;
  z-index: -1
}

.WatchNext-footer {
  align-content: flex-end;
  align-items: flex-end;
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, .65));
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  text-shadow: 0 1px #000;
  width: 80%
}

.WatchNext-show-info {
  color: #eee;
  flex: 1.1 0 0;
  font-size: 3em;
  margin-bottom: 2.6em;
  margin-right: 2em;
  min-width: 8em
}

.WatchNext-show-info-container {
  float: right
}

.WatchNext-show-title {
  font-weight: 500;
  margin: 0 0 .1em
}

.WatchNext-episode-title {
  font-size: .75em;
  font-weight: 500;
  margin: 0
}

.WatchNext-episode-info {
  font-size: .6em;
  margin: .4em 0
}

.WatchNext-episode-synopsis {
  font-size: .6em;
  font-weight: 500;
  margin-bottom: 1em
}

.WatchNext-autoplay {
  color: #eee;
  cursor: pointer;
  font-size: 3em;
  margin: 0 1em 1em 0
}

.WatchNext-still-container {
  box-sizing: border-box;
  margin-left: auto;
  position: relative;
  width: 17.5em
}

.WatchNext-still {
  align-content: center;
  align-items: center;
  border: .1em solid transparent;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  transition: border .1s ease-out;
  width: 100%
}

.WatchNext-still-container:hover .WatchNext-still {
  border: .1em solid #fff
}

.WatchNext-still-hover-container {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.WatchNext-still-hover-container .PlayIcon {
  opacity: .7;
  transition: opacity .2s ease-out, transform .2s ease-out
}

.WatchNext-still-hover-container:hover .PlayIcon {
  opacity: 1;
  transform: scale(1.2)
}

.WatchNext-nsre-info {
  bottom: 0;
  left: 0;
  padding: .3em;
  position: absolute
}

.WatchNext-nsre-info>h1 {
  font-size: 1em;
  margin: 0 0 .1em
}

.WatchNext-nsre-title {
  display: flex;
  margin: 0 0 .1em
}

.WatchNext-nsre-title>h2 {
  font-size: .6em;
  margin: 0 0 .1em
}

.WatchNext-badges {
  display: flex
}

.WatchNext-badge {
  background-color: #e22024;
  font-size: .45em;
  margin: .2em .2em .1em;
  padding: .1em .3em
}

.WatchNext-nsre-time {
  font-size: .5em;
  margin: 0 0 .1em
}

.WatchNext-nsre-time-separator {
  margin: 0 .3em
}

.WatchNext-action-buttons {
  align-content: flex-end;
  align-items: flex-end;
  color: #ccc;
  display: flex;
  font-size: 1em;
  justify-content: flex-end;
  margin: .5em 0 .1em;
  text-align: right;
  width: 100%
}

.WatchNext-action-buttons .nf-icon-button.nf-flat-button.no-icon {
  font-size: .65em;
  text-transform: none
}

.WatchNext-more-container {
  display: inline-block;
  position: relative
}

.WatchNext-autoplay .countdown-container {
  box-sizing: border-box;
  display: block;
  font-size: .8em;
  margin: .2em .2em .2em auto;
  width: 21.7em
}

.WatchNext-autoplay .countdown-container>strong {
  color: #b9090b
}

.WatchNext-more-container .popup-content-wrapper {
  text-align: left
}

.WatchNext-more-container.legacy-controls-styles .nf-flat-button.no-icon {
  margin-right: 0
}

.WatchNext-more-container.legacy-controls-styles .popup-content-wrapper {
  bottom: 137%;
  font-size: .2677em
}

.WatchNext-more-container.legacy-controls-styles .popup-content.rtl:after {
  left: 0
    /*!rtl:ignore*/
  ;
  margin-left: 11em
    /*!rtl:ignore*/
  ;
  right: auto
    /*!rtl:ignore*/
}

.extended-diacritics-language .WatchNext-episode-synopsis {
  line-height: 1.6
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.TwoUpChoice {
  -webkit-font-smoothing: antialiased;
  align-content: flex-end;
  align-items: flex-end;
  background-color: #000;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  text-shadow: 1px 1px #000;
  top: 0;
  transition: opacity .5s ease-in
}

.TwoUpChoice-hidden {
  opacity: 0
}

.TwoUpChoice-background {
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .5s ease-in;
  z-index: 2
}

.TwoUpChoice-background-hidden {
  opacity: 0
}

.TwoUpChoice-background-gradient {
  background-color: transparent;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .8), rgba(0, 0, 0, .5) 40%, transparent);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 40%;
  z-index: 2
}

.TwoUpChoice-footer {
  align-items: flex-end;
  animation: fadeIn .5s ease-in;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: .5em;
  text-shadow: 0 1px #000;
  transition: opacity .5s ease-in;
  width: 100%;
  z-index: 2
}

.TwoUpChoice-footer-hidden {
  animation: fadeOut ease-in .5
}

.TwoUpChoice-autoplay {
  color: #eee;
  cursor: pointer;
  display: flex;
  font-size: 3em;
  justify-content: space-between;
  margin: 0 3vw 3vw;
  width: 100%
}

.TwoUpChoice-autoplay .TwoUpChoice-nextEpisode-container {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  z-index: 2
}

.TwoUpChoice-autoplay .TwoUpChoice-nextEpisode-container .nf-flat-button-primary:focus {
  background-color: #009779;
  border-color: #00ae8b
}

.TwoUpChoice-autoplay .TwoUpChoice-nextEpisode-container .TwoUpChoice-nextEpisode-logo {
  margin: 1em .5em;
  max-width: 24vw
}

.TwoUpChoice-autoplay .TwoUpChoice-nextEpisode-container .TwoUpChoice-nextEpisode-logo-text {
  font-size: 1.5em;
  font-weight: 500;
  margin: .5em
}

.TwoUpChoice-autoplay .TwoUpChoice-nextEpisode-container .TwoUpChoice-nextEpisode-buttons-wrapper {
  display: flex
}

.TwoUpChoice-bob-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  z-index: 2
}

.TwoUpChoice-bob-container .TwoUpChoice-bob-title {
  font-size: 1.5vw;
  font-weight: 500;
  margin-bottom: .5vw;
  margin-left: .5vw;
  max-width: 24vw;
  text-overflow: ellipsis
}

.TwoUpChoice-bob-container .TwoUpChoice-bob-image-wrapper {
  border: 2px solid transparent;
  max-width: 24vw;
  position: relative
}

.TwoUpChoice-bob-container .TwoUpChoice-bob-image-wrapper:hover {
  border: 2px solid #fff
}

.TwoUpChoice-bob-container .TwoUpChoice-bob-image-wrapper .TwoUpChoice-bob-gradient {
  background-color: transparent;
  background-image: linear-gradient(180deg, transparent 0, transparent 33%, rgba(0, 0, 0, .85));
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.TwoUpChoice-bob-container .TwoUpChoice-bob-image-wrapper .TwoUpChoice-bob-background {
  background-color: #000;
  display: block;
  max-width: 100%;
  min-height: 13vw;
  min-width: 24vw
}

.TwoUpChoice-bob-container .TwoUpChoice-bob-image-wrapper .duration {
  background-color: #333;
  border-radius: .3vw;
  bottom: .5vw;
  font-size: 1.2vw;
  padding: .1vw .5vw;
  position: absolute;
  right: .7vw
}

.TwoUpChoice-bob-container .TwoUpChoice-bob-image-wrapper .duration.play-icon div {
  display: inline-block
}

.TwoUpChoice-bob-container .TwoUpChoice-bob-image-wrapper .duration.play-icon:before {
  content: "\e646";
  font-family: nf-icon;
  font-size: .8vw;
  padding-left: .2vw;
  padding-right: .5vw
}

.AkiraPlayer .nfp.nf-player-container.NFPlayer.postplay.twoUpChoicepoint {
  border: none;
  cursor: default;
  height: inherit;
  left: inherit;
  top: inherit;
  width: inherit;
  z-index: 1
}

.AkiraPlayer .nfp.nf-player-container.NFPlayer.postplay.twoUpChoicepoint:hover {
  border: none
}

.BackToBrowse {
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  left: 2.75em;
  opacity: 1;
  position: absolute;
  text-decoration: none;
  text-shadow: 1px 1px 1px #000
    /*!rtl:ignore*/
  ;
  top: 2.75em;
  transition: color .15s ease-in, opacity .4s ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 2
}

.BackToBrowse:hover>div {
  opacity: 1
}

.nfp .BackToBrowse>svg, .watch-video .BackToBrowse>svg {
  fill: #dadada;
  height: 6.75em;
  padding: .75em;
  transition: fill .15s ease-in;
  width: 6.75em
}

.BackToBrowse:hover>svg {
  fill: #fff
}

.BackToBrowse>div {
  align-content: center;
  align-items: center;
  background: none;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 2.2em;
  font-weight: 400;
  justify-content: center;
  left: 4.25em;
  margin: 0;
  opacity: .75;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 1.3em;
  transition: opacity .15s ease-in;
  white-space: nowrap
}

.season-renewal-container {
  background: #000;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw
}

.season-renewal-container .season-renewal-background {
  animation: backgroundTreatment 10s linear;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  left: 0;
  opacity: .4;
  position: absolute;
  top: 0;
  transform: scale(1.1) translateY(0);
  width: 100vw
}

.season-renewal-container .season-renewal-logo {
  animation: logoTreatment 3s linear;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 60vh;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transform: scale(1);
  width: 100vw
}

.season-renewal-container .season-renewal-message {
  animation: messageTreatment 3s linear;
  font-size: 4em;
  font-weight: 500;
  left: 25vw;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 62vh;
  width: 50vw
}

.season-renewal-container .season-renewal-actions {
  bottom: 3em;
  max-width: 32vw;
  position: absolute;
  right: 3em;
  text-align: left
}

.season-renewal-container .label {
  font-size: 1.5em;
  text-align: left
}

.season-renewal-container .promoted-title {
  font-size: 2em;
  font-weight: 500;
  padding: .25em 0 .75em;
  text-align: left
}

.season-renewal-container .nf-flat-button {
  font-size: 1vw
}

@keyframes backgroundTreatment {
  0% {
    opacity: 0;
    transform: scale(1)
  }

  to {
    opacity: .4;
    transform: scale(1.1)
  }
}

@keyframes logoTreatment {
  0% {
    opacity: 0;
    transform: scale(.94) translateY(1.5em)
  }

  25% {
    opacity: 0;
    transform: scale(.94) translateY(1.5em)
  }

  50% {
    transform: scale(.94) translateY(1.5em)
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0)
  }
}

@keyframes messageTreatment {
  0% {
    opacity: 0
  }

  75% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.EpisodicTeaser {
  -webkit-font-smoothing: antialiased;
  align-content: flex-end;
  align-items: flex-end;
  background-color: #000;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  right: 0;
  text-shadow: 1px 1px #000;
  top: 0;
  z-index: 0
}

.EpisodicTeaser.seasonRenewalComplete {
  animation: slideIn .5s ease
}

.EpisodicTeaser .nf-player-container:hover {
  border: .3em solid #fff
}

.EpisodicTeaser-background {
  background-position: 50% 0;
  background-size: cover;
  z-index: -2
}

.EpisodicTeaser-background, .EpisodicTeaser-background-gradient {
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.EpisodicTeaser-background-gradient {
  background-color: transparent;
  background-image: linear-gradient(0deg, #000, rgba(0, 0, 0, .3) 40%, transparent);
  background-size: 100% 100%;
  z-index: -1
}

.EpisodicTeaser-footer {
  align-content: flex-end;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin: 4em;
  text-shadow: 0 1px #000;
  width: 100%
}

.EpisodicTeaser-autoplay {
  color: #eee;
  cursor: pointer;
  font-size: 3em
}

.EpisodicTeaser-display-text {
  font-size: .8em;
  margin-top: .3em
}

.EpisodicTeaser-still-container {
  box-sizing: border-box;
  margin-left: auto;
  position: relative;
  width: 17.5em
}

.EpisodicTeaser-still {
  align-content: center;
  align-items: center;
  border: .1em solid transparent;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  transition: border .1s ease-out;
  width: 100%
}

.EpisodicTeaser-still-container:hover .EpisodicTeaser-still {
  border: .1em solid #fff
}

.EpisodicTeaser-action-buttons {
  color: #ccc;
  display: flex;
  font-size: 1em;
  margin: 1em 0
}

.EpisodicTeaser-action-buttons .nf-icon-button.nf-flat-button.no-icon {
  font-size: .65em;
  text-transform: none
}

.EpisodicTeaser-autoplay .countdown-container {
  box-sizing: border-box;
  display: block;
  font-size: 1em;
  font-weight: 400;
  margin: .2em .2em .2em 0;
  width: 100%
}

.EpisodicTeaser-autoplay .countdown-container>strong {
  font-weight: 400
}

@keyframes slideIn {
  0% {
    left: 110vw;
    right: -110vw
  }

  to {
    left: 0;
    right: 0
  }
}

.PromotedVideo {
  align-content: flex-end;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start
}

.PromotedVideo .supplemental-message {
  color: #fff;
  font-size: 2.8em;
  font-weight: 500;
  margin: .5em 0;
  width: 100%
}

.PromotedVideo .supplemental-message>.svg-icon {
  margin-right: .5em
}

.PromotedVideo-synopsis {
  color: #fff;
  font-size: 2.8em;
  font-weight: 400;
  margin: .5em 0;
  width: 100%
}

.PromotedVideo-moreinfo {
  color: #e50911;
  cursor: pointer;
  margin-left: .5em;
  text-decoration: none
}

.PromotedVideo-countdown {
  color: #eee;
  cursor: pointer;
  margin: 0
}

.PromotedVideo-countdown .countdown-container {
  box-sizing: border-box;
  display: block;
  font-size: 3em;
  margin: .2em .2em .2em auto
}

.PromotedVideo-countdown .countdown-container>strong {
  color: #b9090b
}

.PromotedVideo-actions {
  color: #ccc;
  display: flex;
  font-size: 2.4em;
  margin: .5em 0
}

.OriginalsLogo {
  border: 0;
  min-height: 0;
  width: 100%
}

.OriginalsLogo-text {
  font-size: 1.5em;
  font-weight: 500
}

.OriginalsPostPlay-BackgroundTrailer {
  -webkit-font-smoothing: antialiased;
  align-content: flex-end;
  align-items: flex-end;
  background-color: #000;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .5s ease-in-out
}

.OriginalsPostPlay-BackgroundTrailer.seasonRenewalComplete {
  animation: slideIn .5s ease
}

.OriginalsPostPlay-BackgroundTrailer-gradient-left {
  background-color: transparent;
  background-image: radial-gradient(circle at top left, rgba(0, 0, 0, .7) 0, transparent 33%);
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.OriginalsPostPlay-BackgroundTrailer-gradient-left.lighten {
  background-image: radial-gradient(circle at top left, rgba(0, 0, 0, .3) 0, transparent 33%)
}

.OriginalsPostPlay-BackgroundTrailer-gradient-right {
  background-color: transparent;
  background-image: radial-gradient(circle at bottom right, rgba(0, 0, 0, .7) 0, transparent 100%);
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: background-image .5s ease-in-out;
  width: 100%
}

.OriginalsPostPlay-BackgroundTrailer-gradient-right.lighten {
  background-image: radial-gradient(circle at bottom right, rgba(0, 0, 0, .3) 0, transparent 100%)
}

.OriginalsPostPlay-BackgroundTrailer.hide-credits+.NFPlayer {
  opacity: 0;
  transition: opacity .5s ease-in-out
}

.OriginalsPostPlay-BackgroundTrailer-promo-container {
  margin: 0 4em 4em 0;
  transition: width .75s ease-in-out .2s;
  width: 45%;
  z-index: 1
}

.OriginalsPostPlay-BackgroundTrailer-promo-container .PromotedVideo-actions {
  text-shadow: none
}

.OriginalsPostPlay-BackgroundTrailer-promo-container .PromotedVideo .OriginalsLogo img {
  max-width: 100%
}

.OriginalsPostPlay-BackgroundTrailer-promo-container .PromotedVideo .PromotedVideo-synopsis {
  font-size: 1.4vw;
  max-height: 5vw;
  overflow: hidden;
  transition: opacity .2s ease-in-out, max-height .75s ease-in-out .2s, margin .75s ease-in-out .2s
}

.OriginalsPostPlay-BackgroundTrailer-promo-container .PromotedVideo .PromotedVideo-actions a {
  border-width: 0;
  font-size: 1vw;
  transition: font-size .75s ease-in-out .2s, padding .75s ease-in-out .2s, color .3s ease-in-out, background-color .3s ease-in-out
}

.OriginalsPostPlay-BackgroundTrailer-promo-container .PromotedVideo .PromotedVideo-actions a:focus {
  background-color: rgba(51, 51, 51, .4);
  border-width: 0;
  color: #fff
}

.OriginalsPostPlay-BackgroundTrailer-promo-container .PromotedVideo:first-child {
  max-width: 100%
}

.OriginalsPostPlay-BackgroundTrailer-promo-container.minimize {
  width: 32%
}

.OriginalsPostPlay-BackgroundTrailer-promo-container.minimize .PromotedVideo-synopsis {
  margin: 0;
  max-height: 0;
  opacity: 0
}

.OriginalsPostPlay-BackgroundTrailer-promo-container.minimize .PromotedVideo-actions a {
  font-size: .8vw
}

.OriginalsPostPlay-BackgroundTrailer-promo-container:not(.minimize) .PromotedVideo-actions a:first-child {
  background-color: #e6e6e6;
  color: #000
}

.OriginalsPostPlay-BackgroundTrailer-promo-container:not(.minimize) .PromotedVideo-actions:hover a {
  background-color: rgba(51, 51, 51, .4);
  border-width: 0;
  color: #fff
}

.OriginalsPostPlay-BackgroundTrailer-promo-container:not(.minimize) .PromotedVideo-actions a:hover {
  background-color: #e6e6e6;
  color: #000
}

.BackgroundVideo__container .NFPlayer {
  align-content: center;
  display: flex;
  justify-content: center
}

.BackgroundVideo__container--cover-image {
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity .5s ease-in-out
}

.BackgroundVideo__container--cover-image, .BackgroundVideo__container--video {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.AkiraPlayer .nfp.nf-player-container.NFPlayer.postplay.betweenSupplemental {
  border: none;
  height: auto;
  left: 0;
  opacity: 1;
  top: 0;
  transition: opacity 1s;
  width: auto;
  z-index: 1
}

.AkiraPlayer .nfp.nf-player-container.NFPlayer.postplay.betweenSupplemental.ended {
  opacity: 0
}

.AkiraPlayer .nfp.nf-player-container.NFPlayer.postplay.betweenSupplemental.can-resume:hover {
  border: none;
  cursor: pointer
}

.BetweenSupplementalPostPlay-container {
  -webkit-font-smoothing: antialiased;
  align-content: flex-end;
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .5s ease-in-out;
  z-index: 2
}

.BetweenSupplementalPostPlay-container .BetweenSupplementalPostPlay-promo-container {
  margin: 0 4em 4em 0;
  z-index: 1
}

.BetweenSupplementalPostPlay-container.watchCredits {
  opacity: 0;
  pointer-events: none
}

.AkiraPlayer {
  background-color: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1
}

.AkiraPlayerSpinner--container {
  transition: opacity .3s ease-out
}

@font-face {
  font-display: optional;
  font-family: NKufi;
  font-weight: 400;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/kufi/v4/HandsetSansKufiArabic-Regular.woff2) format("woff2"), url(https://assets.nflxext.com/ffe/siteui/fonts/kufi/v4/HandsetSansKufiArabic-Regular.woff) format("woff")
}

@font-face {
  font-display: optional;
  font-family: NKufi;
  font-weight: 500;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/kufi/v4/HandsetSansKufiArabic-Bold.woff2) format("woff2"), url(https://assets.nflxext.com/ffe/siteui/fonts/kufi/v4/HandsetSansKufiArabic-Bold.woff) format("woff")
}

.nkufi-font-loaded {
  font-family: NKufi, Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif
}

@font-face {
  font-display: optional;
  font-family: GraphikTH;
  font-weight: 400;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/graphik/v2/GraphikTH-Medium.woff2) format("woff2"), url(https://assets.nflxext.com/ffe/siteui/fonts/graphik/v2/GraphikTH-Medium.woff) format("woff")
}

@font-face {
  font-display: optional;
  font-family: GraphikTH;
  font-weight: 500;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/graphik/v2/GraphikTH-Bold.woff2) format("woff2"), url(https://assets.nflxext.com/ffe/siteui/fonts/graphik/v2/GraphikTH-Bold.woff) format("woff")
}

.graphik-font-loaded {
  font-family: GraphikTH, Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif
}

.characterWrapper {
  border-radius: 250px;
  height: 100%;
  min-width: 100%;
  overflow: hidden;
  transition: all .3s ease-out;
  width: 100%
}

.characterWrapper:hover {
  transform: scale(1.2)
}

.characterWrapper img {
  width: 100%
}

.character-link:focus {
  outline: none
}

.character-link:focus .characterWrapper {
  transform: scale(1.02)
}

.character-index-0 {
  background: linear-gradient(0deg, #ffb400, #ffe25b)
}

.character-index-1 {
  background: linear-gradient(0deg, #ff6000, #ffae7d)
}

.character-index-2 {
  background: linear-gradient(0deg, #9fcd00, #cfed66)
}

.character-index-3 {
  background: linear-gradient(0deg, #00c6d8, #86f5ff)
}

.character-index-4 {
  background: linear-gradient(0deg, #d926ff, #eea1ff)
}

@media screen and (max-width: 499px) {
  .characterRow .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 33.333333%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .characterRow .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 25%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .characterRow .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 20%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .characterRow .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 16.66666667%
  }
}

@media screen and (min-width: 1400px) {
  .characterRow .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 14.28571429%
  }
}

.characterRow .slider-item {
  box-sizing: border-box;
  padding-right: 1.2em
}

.characterImage {
  background-size: 100% 100%;
  padding: 50% 0
}

.characterRow .loadingTitle {
  border-radius: 250px;
  height: 100%;
  min-width: 100%;
  overflow: hidden;
  width: 100%
}

.characterRow .loadingTitle .ratio-16x9 {
  padding: 48.125% 0
}

.t_64b453 .nf-overlay {
  font-size: 16px;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20000
}

.t_64b453 .nf-overlay #modalTitle {
  display: none
}

@media screen and (min-width: 1280px) {
  .t_64b453 .nf-overlay {
    font-size: 1.25vw
  }
}

@media screen and (min-width: 2560px) {
  .t_64b453 .nf-overlay {
    font-size: 32px
  }
}

.t_64b453 .nf-overlay .nf-overlay-dismiss {
  min-height: .5em;
  text-align: right;
  text-align: end
}

.t_64b453 .nf-overlay .nf-overlay-dismiss .nf-overlay-dismiss-btn {
  background: none;
  border: none;
  font-size: 1.2em;
  padding: .5em .5em 0
}

.t_64b453 .nf-overlay .nf-overlay-dismiss .nf-overlay-dismiss-btn:focus {
  outline: auto
}

.t_64b453 .nf-overlay .nf-overlay-background {
  background-color: hsla(0, 0%, 8%, .8);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1
}

.t_64b453 .nf-overlay .nf-overlay-content {
  height: 0
}

.t_64b453 .nf-overlay .nf-overlay-content>div {
  background-color: #141414;
  box-shadow: 0 0 10px 0 hsla(0, 0%, 39%, .5);
  height: 100%;
  left: 0;
  margin: 0 auto;
  overflow: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 70%
}

.t_64b453 .overlay-appear, .t_64b453 .overlay-enter, .t_64b453 .overlay-leave.overlay-leave-active {
  opacity: .01;
  transform: scale(.8);
  transition-duration: .45s;
  transition-property: opacity, transform
}

.t_64b453 .overlay-appear.overlay-appear-active, .t_64b453 .overlay-enter.overlay-enter-active, .t_64b453 .overlay-leave {
  opacity: 1;
  transform: scale(1)
}

.t_64b453 .simpleTitleCard {
  box-sizing: border-box;
  display: inline-block;
  font-size: .8rem;
  padding: 0 5px 15px 0;
  position: relative;
  width: 33.333333%
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_64b453 .simpleTitleCard {
    width: 50%
  }
}

@media screen and (max-width: 499px) {
  .t_64b453 .simpleTitleCard {
    width: 100%
  }
}

.t_64b453 .simpleTitleCard .video-artwork {
  background-size: cover;
  position: relative;
  z-index: 3
}

.t_64b453 .simpleTitleCard .title-artwork {
  background-color: #1a1a1a
}

.t_64b453 .simpleTitleCard .title-play {
  font-size: 4vw;
  height: 4.4vw;
  margin-left: -2.1vw;
  margin-top: -2.1vw;
  width: 4.4vw
}

.t_64b453 .simpleTitleCard .title-play .annotation {
  bottom: -1.12vw
}

.t_64b453 .simpleTitleCard .title-play .playRing {
  border: .2vw solid #fff;
  height: 4vw;
  width: 4vw
}

.t_64b453 .simpleTitleCard .title-play .play {
  line-height: 4vw
}

.t_64b453 .simpleTitleCard .mylist-button {
  bottom: 5px;
  opacity: 0;
  position: absolute;
  right: 5px
}

.t_64b453 .simpleTitleCard .mylist-button:focus {
  opacity: 1
}

.t_64b453 .simpleTitleCard .title-play {
  border-radius: 50%;
  box-sizing: border-box;
  color: #fff;
  font-size: 3.5vw;
  height: 3.85vw;
  margin-left: -1.8375vw;
  margin-top: -1.8375vw;
  opacity: 0;
  outline: none;
  position: absolute;
  transition: all .15s ease;
  width: 3.85vw;
  z-index: 100
}

.t_64b453 .simpleTitleCard .title-play:focus, .t_64b453 .simpleTitleCard .title-play:hover {
  opacity: 1;
  text-decoration: none;
  transform: scale(1.08)
}

.t_64b453 .simpleTitleCard .title-play:focus .playRing, .t_64b453 .simpleTitleCard .title-play:hover .playRing {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .5)
}

.t_64b453 .simpleTitleCard .title-play:focus .play, .t_64b453 .simpleTitleCard .title-play:hover .play {
  color: #009779
}

.t_64b453 .simpleTitleCard .title-play .annotation {
  bottom: -.98vw;
  font-size: 18%;
  font-weight: 500;
  left: -50%;
  position: absolute;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .7);
  white-space: nowrap;
  width: 200%
}

.t_64b453 .simpleTitleCard .title-play .playRing {
  border: .175vw solid #fff;
  border-radius: 50%;
  height: 3.5vw;
  position: absolute;
  width: 3.5vw
}

.t_64b453 .simpleTitleCard .title-play .playRing:after {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .2);
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1
}

.t_64b453 .simpleTitleCard .title-play .play {
  color: #fff;
  font-size: 46%;
  left: 6%
    /*!rtl:ignore*/
  ;
  line-height: 3.5vw;
  position: absolute;
  text-align: center;
  width: 100%
}

.t_64b453 .simpleTitleCard .title-play .playRing, .t_64b453 .simpleTitleCard .title-play .playRing:focus, .t_64b453 .simpleTitleCard .title-play .playRing:hover {
  background-color: #fff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .5)
}

.t_64b453 .simpleTitleCard .title-play .playRing .play, .t_64b453 .simpleTitleCard .title-play .playRing:focus .play, .t_64b453 .simpleTitleCard .title-play .playRing:hover .play {
  color: #009779;
  font-size: 56%
}

.t_64b453 .simpleTitleCard:hover .title-play {
  opacity: 1
}

.t_64b453 .simpleTitleCard .meta {
  margin: 0 0 4px
}

.t_64b453 .simpleTitleCard:focus {
  outline: none
}

.t_64b453 .simpleTitleCard a:focus .video-artwork {
  outline: 1px solid #6b6b6b;
  outline-offset: 2px
}

.t_64b453 .character-titles {
  margin: 30px 25px 0 30px
}

.t_64b453 .character-gallery {
  padding-top: 2rem
}

.t_64b453 .character-gallery .slider-item {
  box-sizing: border-box;
  padding: 0 .8rem
}

@media screen and (max-width: 499px) {
  .t_64b453 .character-gallery .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 33.333333%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_64b453 .character-gallery .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 25%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_64b453 .character-gallery .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 20%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_64b453 .character-gallery .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 16.66666667%
  }
}

@media screen and (min-width: 1400px) {
  .t_64b453 .character-gallery .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 14.28571429%
  }
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup {
  box-sizing: border-box;
  display: inline-block;
  padding: 0 5px 15px 0;
  position: relative;
  vertical-align: top;
  width: 33.333333%
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup {
    width: 50%
  }
}

@media screen and (max-width: 499px) {
  .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup {
    width: 100%
  }
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodeTitle {
  font-size: 1em;
  width: 100%
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodeTitle p, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodeTitle span {
  display: inline-block;
  margin: 3px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodeArt, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailer-artwork {
  background-color: #1a1a1a;
  background-size: cover;
  cursor: pointer;
  position: relative
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodeArt .progress, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailer-artwork .progress {
  bottom: 4%;
  left: 2%;
  position: absolute;
  right: 2%
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodeArt .progress .progress-bar, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailer-artwork .progress .progress-bar {
  margin: 1px 0 0
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodeArt:hover .episodePlay, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailer-artwork:hover .episodePlay {
  transform: scale(1.08)
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup.current .duration, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup.current .episodeSynopsis, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup.current .episodeTitle {
  color: #000
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay {
  border-radius: 50%;
  box-sizing: border-box;
  color: #fff;
  font-size: 3.5vw;
  height: 3.85vw;
  margin-left: -1.8375vw;
  margin-top: -1.8375vw;
  opacity: 0;
  outline: none;
  position: absolute;
  transition: all .15s ease;
  width: 3.85vw;
  z-index: 100
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay:focus, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay:hover, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay:focus, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay:hover {
  opacity: 1;
  text-decoration: none;
  transform: scale(1.08)
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay:focus .playRing, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay:hover .playRing, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay:focus .playRing, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay:hover .playRing {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .5)
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay:focus .play, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay:hover .play, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay:focus .play, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay:hover .play {
  color: #009779
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay .annotation, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay .annotation {
  bottom: -.98vw;
  font-size: 18%;
  font-weight: 500;
  left: -50%;
  position: absolute;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .7);
  white-space: nowrap;
  width: 200%
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay .playRing, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay .playRing {
  border: .175vw solid #fff;
  border-radius: 50%;
  height: 3.5vw;
  position: absolute;
  width: 3.5vw
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay .playRing:after, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay .playRing:after {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .2);
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .episodePlay .play, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup .trailerPlay .play {
  color: #fff;
  font-size: 46%;
  left: 6%
    /*!rtl:ignore*/
  ;
  line-height: 3.5vw;
  position: absolute;
  text-align: center;
  width: 100%
}

.t_64b453 .titleOverlay .overlayEpisodes .episodeLockup:hover .episodePlay, .t_64b453 .titleOverlay .overlayEpisodes .episodeLockup:hover .trailerPlay {
  opacity: 1
}

.t_64b453 .searchTab span {
  font-weight: 500;
  text-shadow: none
}

.t_64b453 .suggestions .suggestionsLabel {
  font-weight: 500
}

.t_64b453 .suggestionHeader {
  padding: 16px 0 36px
}

.t_64b453 .noResults {
  font-weight: 500;
  padding: 15%
}

.t_64b453 .noResultsWrapper {
  font-size: 1.3em;
  font-weight: 500
}

.t_64b453 .suggestionRailContainer {
  display: flex
}

.t_64b453 .search-title-header-with-suggestion {
  padding-bottom: 3%
}

.t_64b453 .character-hero, .t_64b453 .title-hero {
  position: relative
}

.t_64b453 .character-hero .image-rotator, .t_64b453 .title-hero .image-rotator {
  height: 38vw
}

.t_64b453 .character-hero .info, .t_64b453 .title-hero .info {
  background-color: transparent;
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, .6));
  bottom: 0;
  color: #fff;
  font-size: 1em;
  left: 0;
  padding: 0 4% 2%;
  position: absolute;
  width: 92%;
  z-index: 2
}

.t_64b453 .character-hero .info .maturity-rating, .t_64b453 .title-hero .info .maturity-rating {
  padding: 0 2% 0 0
}

.t_64b453 .character-hero .info .maturity-rating .maturity-rating-BBFC, .t_64b453 .character-hero .info .maturity-rating .maturity-rating-GB, .t_64b453 .title-hero .info .maturity-rating .maturity-rating-BBFC, .t_64b453 .title-hero .info .maturity-rating .maturity-rating-GB {
  margin-bottom: 0
}

.t_64b453 .character-hero .info .maturity-rating .maturity-custom-styling.maturity-rating-BR, .t_64b453 .title-hero .info .maturity-rating .maturity-custom-styling.maturity-rating-BR {
  font-size: 1em
}

.t_64b453 .character-hero .info .maturity-rating .maturity-number, .t_64b453 .title-hero .info .maturity-rating .maturity-number {
  border: 1px solid #fff;
  border-radius: 3px
}

.t_64b453 .character-hero .info .title-name, .t_64b453 .title-hero .info .title-name {
  vertical-align: middle
}

.t_64b453 .character-hero .info .title-treatment, .t_64b453 .title-hero .info .title-treatment {
  padding: 0 0 1.5%
}

.t_64b453 .character-hero .info .title-treatment img, .t_64b453 .title-hero .info .title-treatment img {
  width: 25%
}

.t_64b453 .character-hero .info .logo-branded-stacked, .t_64b453 .title-hero .info .logo-branded-stacked {
  padding: 0 0 1.5%
}

.t_64b453 .character-hero .info .logo-branded-stacked img, .t_64b453 .title-hero .info .logo-branded-stacked img {
  width: 35%
}

.t_64b453 .character-hero .info .progress, .t_64b453 .title-hero .info .progress {
  padding: 10px 0 0
}

.t_64b453 .character-hero .info .progress .progress-bar, .t_64b453 .title-hero .info .progress .progress-bar {
  margin-right: 0
}

.t_64b453 .lolomoRow .pagination-indicator {
  display: block
}

.t_64b453 .lolomoRow.big-peeks .slick-slider {
  padding-right: 10%
}

@media screen and (min-width: 1500px) {
  .t_64b453 .lolomoRow.big-peeks .slick-slider {
    padding-right: 120px
  }
}

.t_64b453 .lolomoRow.big-peeks .slick-slider .slick-arrow.slick-next {
  width: 10%
}

@media screen and (min-width: 1500px) {
  .t_64b453 .lolomoRow.big-peeks .slick-slider .slick-arrow.slick-next {
    width: 120px
  }
}

.t_64b453 .lolomoRow.big-peeks .slick-slider .slick-arrow.slick-next .indicator-icon {
  width: 40%
}

.t_64b453 .lolomoRow.big-peeks .pagination-indicator {
  right: 10%
}

@media screen and (min-width: 1500px) {
  .t_64b453 .lolomoRow.big-peeks .pagination-indicator {
    right: 120px
  }
}

.t_64b453 .more-like-this {
  margin: 30px 0 0
}

.t_64b453 .more-like-this .more-like-this-label {
  font-size: 19px;
  margin: 20px 30px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.t_64b453 .more-like-this .grid, .t_64b453 .more-like-this .unavailable {
  margin: 0 25px 0 30px
}

.t_64b453 .more-like-this .grid .maturity-rating .maturity-number, .t_64b453 .more-like-this .unavailable .maturity-rating .maturity-number {
  border: 1px solid hsla(0, 0%, 100%, .2);
  white-space: nowrap
}

.t_64b453 .more-like-this .grid .duration, .t_64b453 .more-like-this .unavailable .duration {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.t_64b453 .more-like-this .grid .simsSynopsis, .t_64b453 .more-like-this .unavailable .simsSynopsis {
  color: #999
}

.t_64b453 .extended-diacritics-language .more-like-this .more-like-this-label {
  line-height: 1.7
}

.t_64b453 .kidsPage a {
  cursor: pointer;
  text-decoration: none
}

.t_64b453 .kidsPage .titleOverlay .episodesContainer, .t_64b453 .kidsPage .titleOverlay .unavailable {
  margin: 30px 25px 0 30px
}

.t_64b453 .kidsPage .titleOverlay .episodesContainer .episodes-unavailable, .t_64b453 .kidsPage .titleOverlay .unavailable .episodes-unavailable {
  font-size: 2vw;
  padding-top: 8%;
  text-align: center
}

.t_64b453 .kidsPage .titleOverlay .episodesContainer .episodeWrapper, .t_64b453 .kidsPage .titleOverlay .unavailable .episodeWrapper {
  margin: 20px 0 0
}

.t_64b453 .kidsPage .character-hero .playLink {
  border-radius: 50%;
  box-sizing: border-box;
  color: #fff;
  font-size: 6vw;
  height: 6.6vw;
  left: 50%;
  margin: -3.15vw auto auto -3.15vw;
  opacity: 1;
  outline: none;
  position: absolute;
  top: 50%;
  transition: all .15s ease;
  width: 6.6vw;
  z-index: 100
}

.t_64b453 .kidsPage .character-hero .playLink:focus, .t_64b453 .kidsPage .character-hero .playLink:hover {
  opacity: 1;
  text-decoration: none;
  transform: scale(1.08)
}

.t_64b453 .kidsPage .character-hero .playLink:focus .playRing, .t_64b453 .kidsPage .character-hero .playLink:hover .playRing {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .5)
}

.t_64b453 .kidsPage .character-hero .playLink:focus .play, .t_64b453 .kidsPage .character-hero .playLink:hover .play {
  color: #009779
}

.t_64b453 .kidsPage .character-hero .playLink .annotation {
  bottom: -1.68vw;
  font-size: 18%;
  font-weight: 500;
  left: -50%;
  position: absolute;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .7);
  white-space: nowrap;
  width: 200%
}

.t_64b453 .kidsPage .character-hero .playLink .playRing {
  border: .3vw solid #fff;
  border-radius: 50%;
  height: 6vw;
  position: absolute;
  width: 6vw
}

.t_64b453 .kidsPage .character-hero .playLink .playRing:after {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .2);
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1
}

.t_64b453 .kidsPage .character-hero .playLink .play {
  color: #fff;
  font-size: 46%;
  left: 6%
    /*!rtl:ignore*/
  ;
  line-height: 6vw;
  position: absolute;
  text-align: center;
  width: 100%
}

.t_64b453 .kidsPage .title-hero .playLink {
  border-radius: 50%;
  box-sizing: border-box;
  color: #fff;
  font-size: 6vw;
  height: 6.6vw;
  left: 50%;
  margin: -3.15vw auto auto -3.15vw;
  opacity: 1;
  outline: none;
  position: absolute;
  top: 50%;
  transition: all .15s ease;
  width: 6.6vw;
  z-index: 100
}

.t_64b453 .kidsPage .title-hero .playLink:focus, .t_64b453 .kidsPage .title-hero .playLink:hover {
  opacity: 1;
  text-decoration: none;
  transform: scale(1.08)
}

.t_64b453 .kidsPage .title-hero .playLink:focus .playRing, .t_64b453 .kidsPage .title-hero .playLink:hover .playRing {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .5)
}

.t_64b453 .kidsPage .title-hero .playLink:focus .play, .t_64b453 .kidsPage .title-hero .playLink:hover .play {
  color: #009779
}

.t_64b453 .kidsPage .title-hero .playLink .annotation {
  bottom: -1.68vw;
  font-size: 18%;
  font-weight: 500;
  left: -50%;
  position: absolute;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .7);
  white-space: nowrap;
  width: 200%
}

.t_64b453 .kidsPage .title-hero .playLink .playRing {
  border: .3vw solid #fff;
  border-radius: 50%;
  height: 6vw;
  position: absolute;
  width: 6vw
}

.t_64b453 .kidsPage .title-hero .playLink .playRing:after {
  background: none repeat scroll 0 0 rgba(0, 0, 0, .2);
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1
}

.t_64b453 .kidsPage .title-hero .playLink .play {
  color: #fff;
  font-size: 46%;
  left: 6%
    /*!rtl:ignore*/
  ;
  line-height: 6vw;
  position: absolute;
  text-align: center;
  width: 100%
}

.t_64b453 .kidsPage .play-button-full-cover {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10
}

.t_64b453 .kidsPage .play-button-full-cover:hover .playLink {
  transform: scale(1.08)
}

.t_64b453 .kidsPage .titleOverlay .gallery {
  padding-top: 5.5vw
}

.t_64b453 .kidsPage .titleOverlay .slider-item {
  box-sizing: border-box;
  padding: 0
}

@media screen and (max-width: 499px) {
  .t_64b453 .kidsPage .titleOverlay .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 100%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_64b453 .kidsPage .titleOverlay .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 50%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_64b453 .kidsPage .titleOverlay .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 33.333333%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_64b453 .kidsPage .titleOverlay .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 25%
  }
}

@media screen and (min-width: 1400px) {
  .t_64b453 .kidsPage .titleOverlay .rowContainer .rowContent .slick-slider .row-with-x-columns .slider-item {
    width: 25%
  }
}

@media screen and (max-width: 499px) {
  .t_64b453 .kidsPage .titleOverlay .overlayEpisodes .episodeLockup {
    width: 100%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_64b453 .kidsPage .titleOverlay .overlayEpisodes .episodeLockup {
    width: 100%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_64b453 .kidsPage .titleOverlay .overlayEpisodes .episodeLockup {
    width: 33.333333%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_64b453 .kidsPage .titleOverlay .overlayEpisodes .episodeLockup {
    width: 25%
  }
}

@media screen and (min-width: 1400px) {
  .t_64b453 .kidsPage .titleOverlay .overlayEpisodes .episodeLockup {
    width: 25%
  }
}

.t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper {
  margin: 0
}

@media screen and (max-width: 499px) {
  .t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper .row-with-x-columns .slider-item {
    width: 100%
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper .row-with-x-columns .slider-item {
    width: 50%
  }
}

@media screen and (min-width: 800px) and (max-width:1099px) {
  .t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper .row-with-x-columns .slider-item {
    width: 33.333333%
  }
}

@media screen and (min-width: 1100px) and (max-width:1399px) {
  .t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper .row-with-x-columns .slider-item {
    width: 25%
  }
}

@media screen and (min-width: 1400px) {
  .t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .episodeWrapper .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .placeholderEpisodes .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .simsWrapper .row-with-x-columns .slider-item, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper .loadingTitle, .t_64b453 .kidsPage .titleOverlay .jawBonePane .trailerWrapper .row-with-x-columns .slider-item {
    width: 25%
  }
}

.t_64b453 .kidsPage .nf-overlay .nf-overlay-content>div {
  width: 85%
}

.t_64b453 .kidsPage .slider-hover-trigger-layer:hover .slick-slider .slick-arrow.active .indicator-icon {
  display: flex
}

.t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow .indicator-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2vw 0;
  width: 80%
}

@media screen and (min-width: 1500px) {
  .t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow .indicator-icon {
    padding: 1.2vw 0
  }
}

.t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow.slick-prev {
  justify-content: flex-start
}

.t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow.slick-prev .indicator-icon {
  border-bottom-right-radius: 60px;
  border-top-right-radius: 60px
}

.t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow.slick-prev .indicator-icon:before {
  margin-right: .75rem
}

.t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow.slick-next {
  justify-content: flex-end
}

.t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow.slick-next .indicator-icon {
  border-bottom-left-radius: 60px;
  border-top-left-radius: 60px
}

.t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow.slick-next .indicator-icon:before {
  margin-right: -.75rem
}

.t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow.active:focus .indicator-icon, .t_64b453 .kidsPage .rowContainer .rowContent .slider:hover .slick-arrow.active:hover .indicator-icon {
  display: flex;
  font-weight: 400;
  transform: none
}

.t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow.active:focus .indicator-icon:hover, .t_64b453 .kidsPage .rowContainer .rowContent .slider:hover .slick-arrow.active:hover .indicator-icon:hover {
  transform: none
}

.t_64b453 .kidsPage .rowContainer .rowContent .slick-slider .slick-arrow.active:focus .indicator-icon {
  transform: scale(1.05)
}

.t_64b453 .kidsPage .jawBoneContainer .listMeta p {
  font-weight: 500
}

.t_64b453 .kidsPage .jawBoneContainer .background:not(.fullbleed) .nav-shadow {
  background: url(https://assets.nflxext.com/ffe/siteui/akira/JawBone/kids-nav-shadow.png) no-repeat center 100%
}

@media screen and (max-width: 499px) {
  .t_64b453 .kidsPage .jawBoneContainer {
    height: 80vw
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_64b453 .kidsPage .jawBoneContainer {
    height: 48vw
  }
}

@media screen and (max-width: 499px) {
  .t_64b453 .kidsPage .lolomoRow.jawBoneOpen~.lolomoRow, .t_64b453 .kidsPage .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 80vw, 0)
  }
}

@media screen and (min-width: 500px) and (max-width:799px) {
  .t_64b453 .kidsPage .lolomoRow.jawBoneOpen~.lolomoRow, .t_64b453 .kidsPage .rowContainer.jawBoneOpen~.rowContainer {
    transform: translate3d(0, 48vw, 0)
  }
}

html.watch-video-root.t_17d527 {
  -webkit-text-size-adjust: none;
  font-size: 10px !important;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%
}

html.watch-video-root.t_17d527 body {
  font-size: 1em !important;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%
}

.t_17d527 .svg-icon {
  border: 1px solid transparent;
  margin: 0 -1px;
  overflow: visible;
  transform: scale(1)
}

.t_17d527 .svg-icon.svg-icon-nfplayerPause, .t_17d527 .svg-icon.svg-icon-nfplayerPlay {
  height: 1.22222222em;
  width: 1.05555556em
}

.t_17d527 .svg-icon.svg-icon-volumeLow, .t_17d527 .svg-icon.svg-icon-volumeMax, .t_17d527 .svg-icon.svg-icon-volumeMedium, .t_17d527 .svg-icon.svg-icon-volumeMuted {
  height: 1.11111111em;
  width: 1.33333333em
}

.t_17d527 .svg-icon.svg-icon-nfplayerFullscreen, .t_17d527 .svg-icon.svg-icon-nfplayerWindowed {
  height: 1em;
  width: 1em
}

.t_17d527 .svg-icon.svg-icon-nfplayerSubtitles {
  height: 1.11111111em;
  width: 1.33333333em
}

.t_17d527 .svg-icon.svg-icon-nfplayerEpisodes {
  height: 1em;
  width: 1.5em
}

.t_17d527 .svg-icon.svg-icon-nfplayerNextEpisode {
  height: 1em;
  width: .83333333em
}

.t_17d527 .svg-icon.svg-icon-nfplayerBackTen {
  height: 1.33333333em;
  width: 1.11111111em
}

.t_17d527 .svg-icon.svg-icon-nfplayerReplay {
  height: 1.5em;
  width: 1.5em
}

.t_17d527 .svg-icon.svg-icon-nfplayerStop {
  height: 1em;
  width: 1em
}

.t_17d527 .svg-icon.svg-icon-nfplayerMdx, .t_17d527 .svg-icon.svg-icon-nfplayerMdxFull {
  height: 1em;
  width: 1.22222222em
}

.t_17d527 .svg-icon.svg-icon-nfplayerCheck {
  height: .83333333em;
  width: .94444444em
}

.t_17d527 .svg-icon.svg-icon-nfplayerExit {
  height: 1em;
  width: 1em
}

.t_17d527 .svg-icon.svg-icon-nfplayerBack {
  height: 1em;
  width: .72222222em
}

.t_17d527 .svg-icon.svg-icon-nfplayerOpticalCenterPause, .t_17d527 .svg-icon.svg-icon-nfplayerOpticalCenterPlay {
  height: 1em;
  width: 1.18181818em
}

.t_17d527 .svg-icon.svg-icon-playerShare {
  height: 1.33333333em;
  width: 1.22222222em
}

.t_17d527 .nfp-button-control {
  fill: #fff;
  align-items: center;
  background: transparent;
  border: 0;
  box-sizing: content-box;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.t_17d527 .nfp-button-control.default-control-button {
  height: 3.22222222em;
  padding: 0 .83333333em
}

.t_17d527 .nfp-button-control.circle-control-button {
  height: 3.22222222em;
  padding: 0 .83333333em;
  position: relative;
  width: 2em
}

.t_17d527 .nfp-button-control.circle-control-button:before {
  background: rgba(65, 65, 65, .85);
  border-radius: 50%;
  content: "";
  height: 2em;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2em
}

.t_17d527 .nfp-button-control.circle-control-button .svg-icon {
  margin: 0 auto;
  width: 100%
}

.t_17d527 .watch-video {
  box-sizing: border-box;
  height: 100%;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.t_17d527 .watch-video--adsInfo-container, .t_17d527 .watch-video--back-container, .t_17d527 .watch-video--flag-container {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .3) 0, transparent 20%);
  box-sizing: border-box;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem
}

.t_17d527 .watch-video--adsInfo-container {
  padding-left: 0
}

.t_17d527 div.watch-video--seamless-back {
  height: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem
}

.t_17d527 .watch-video--evidence-overlay-container {
  background-color: rgba(0, 0, 0, .5);
  height: 100%;
  position: relative;
  width: 100%
}

.t_17d527 .watch-video--autoplay-blocked {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  height: 100%;
  padding: 2rem;
  position: relative;
  width: 100%
}

.t_17d527 .watch-video--autoplay-blocked .watch-video--autoplay-blocked-exit-button {
  padding: 0
}

.t_17d527 .watch-video--autoplay-blocked .svg-icon-nfplayerExit {
  height: 2.72rem;
  padding: 0;
  width: 3.1rem
}

.t_17d527 .watch-video--scrubber-volume-container {
  box-sizing: border-box;
  height: 20vh;
  max-height: 20rem;
  min-height: 10rem;
  padding-bottom: 1rem;
  padding-top: 1rem
}

.t_17d527 .watch-video--episode-preview-container {
  max-width: 70rem;
  position: relative
}

.t_17d527 .watch-video--selector-episode-container {
  height: 68rem;
  max-height: 75vh;
  position: relative;
  width: 76rem
}

.t_17d527 .watch-video--leaving-view, .t_17d527 .watch-video--loading-view {
  height: 100%;
  position: relative;
  width: 100%
}

.t_17d527 .watch-video--player-view {
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: opacity
}

.t_17d527 .watch-video--player-view-ended {
  opacity: 0
}

.t_17d527 .watch-video--player-view.watch-video--player-view-minimized {
  border: .3rem solid transparent;
  cursor: default;
  height: 25rem;
  left: 4rem;
  min-height: 16rem;
  top: 14rem;
  width: 40rem;
  z-index: 2
}

.t_17d527 .watch-video--player-view-minimized:hover {
  border: .3rem solid #fff
}

.t_17d527 .watch-video--bottom-controls-container {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .3) 0, transparent);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative
}

.t_17d527 .watch-video--advisories-container {
  bottom: 0;
  box-sizing: border-box;
  font-size: 1.5vw;
  left: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0
}

.t_17d527 .watch-video--interrupt-autoplay {
  background-color: rgba(0, 0, 0, .8)
}

.t_17d527 .watch-video--skip-content, .t_17d527 .watch-video--skip-preplay {
  padding: 1rem 5rem 1rem 1rem
}

.t_17d527 .watch-video--skip-content-button {
  box-shadow: 0 0 .5rem rgba(0, 0, 0, .25);
  padding: 1rem
}

.t_17d527 .watch-video--playback-restart {
  cursor: pointer;
  height: 100%;
  width: 100%
}

.t_17d527 .watch-video .nfp-button-control.circle-control-button {
  fill: #fff;
  height: 2rem;
  width: 2rem
}

.t_17d527 .watch-video .nfp-button-control.circle-control-button:before {
  background: inherit
}

.t_17d527 .watch-video .nfp-aspect-container {
  left: 0;
  position: absolute;
  top: 0
}

.t_17d527 .watch-video .nfp-aspect-inner {
  padding-top: 0
}

@keyframes buttonFader {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.t_17d527 .playback-notification {
  align-content: center;
  align-items: center;
  animation: buttonFader .65s ease-out normal forwards;
  box-sizing: border-box;
  height: 10rem;
  left: 50%
    /*!rtl:ignore*/
  ;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%)
    /*!rtl:ignore*/
  ;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 10rem
}

.t_17d527 .playback-notification-background {
  background: rgba(0, 0, 0, .4);
  border-radius: 50%;
  height: 100%;
  position: absolute;
  width: 100%
}

.t_17d527 .playback-notification-icon {
  left: 50%
    /*!rtl:ignore*/
  ;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%)
    /*!rtl:ignore*/
}

.t_17d527 .playback-notification--forward-10 {
  left: auto
    /*!rtl:ignore*/
  ;
  right: 4rem
    /*!rtl:ignore*/
}

.t_17d527 .playback-notification--back-10 {
  left: 16rem
    /*!rtl:ignore*/
  ;
  right: auto
    /*!rtl:ignore*/
}

.t_17d527 .watch-video--hide-controls {
  visibility: hidden
}

.t_17d527 .watch-video--question-msg-container {
  -webkit-font-smoothing: antialiased;
  font-family: Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif;
  font-size: 2.1rem;
  padding: 2.1rem
}

.t_17d527 .watch-video--question-msg-container>span {
  white-space: nowrap
}

@media only screen and (max-width: 1280px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 9px !important
  }
}

@media only screen and (max-height: 720px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 9px !important
  }
}

@media only screen and (max-width: 1152px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 8px !important
  }
}

@media only screen and (max-height: 648px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 8px !important
  }
}

@media only screen and (max-width: 1024px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 7.2px !important
  }
}

@media only screen and (max-height: 576px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 7.2px !important
  }
}

@media only screen and (max-width: 960px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 6.6px !important
  }
}

@media only screen and (max-height: 540px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 6.6px !important
  }
}

@media only screen and (max-width: 854px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 6px !important
  }
}

@media only screen and (max-height: 480px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 6px !important
  }
}

@media only screen and (max-width: 640px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 5.3px !important
  }
}

@media only screen and (max-height: 360px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 5.3px !important
  }
}

@media only screen and (max-width: 426px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 4.8px !important
  }
}

@media only screen and (max-height: 240px) {
  html.watch-video-root.t_17d527 {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    font-size: 4.8px !important
  }
}

.t_75f67f .js-focus-visible :focus:not([data-focus-visible-added]) {
  outline: none
}

.error-page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, .18);
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif;
  font-size: 10px;
  left: 0;
  line-height: 1.2;
  overflow: hidden;
  padding-top: 68px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000
}

@media screen and (min-width: 0) and (max-width:480px) and (max-aspect-ratio:4/3) {
  .error-page {
    font-size: 1.5vw
  }
}

@media screen and (min-width: 481px) and (max-width:840px) and (max-aspect-ratio:4/3) {
  .error-page {
    font-size: 1vw
  }
}

@media screen and (min-width: 841px) and (max-width:1280px) and (max-aspect-ratio:4/3) {
  .error-page {
    font-size: .75vw
  }
}

@media screen and (min-width: 1281px) and (max-width:1600px) and (max-aspect-ratio:4/3) {
  .error-page {
    font-size: .75vw
  }
}

@media screen and (min-width: 1601px) and (max-width:1920px) and (max-aspect-ratio:4/3) {
  .error-page {
    font-size: .75vw
  }
}

@media screen and (min-width: 1921px) and (max-aspect-ratio:4/3) {
  .error-page {
    font-size: 12px
  }
}

@media screen and (max-width: 480px) and (orientation:landscape) {
  .error-page {
    font-size: 1.5vw
  }
}

@media screen and (min-width: 481px) and (max-width:840px) and (orientation:landscape) {
  .error-page {
    font-size: 1.2vw
  }
}

@media screen and (min-width: 841px) and (max-width:1280px) and (orientation:landscape) {
  .error-page {
    font-size: .85vw
  }
}

@media screen and (min-width: 1281px) and (max-width:1600px) and (orientation:landscape) {
  .error-page {
    font-size: .75vw
  }
}

@media screen and (min-width: 1601px) and (max-width:1920px) and (orientation:landscape) {
  .error-page {
    font-size: .75vw
  }
}

@media screen and (min-width: 1921px) and (orientation:landscape) {
  .error-page {
    font-size: 14px
  }
}

.error-page:after, .error-page:before {
  content: "";
  display: flex;
  flex: 1;
  max-height: 25%
}

.error-page--header {
  background-color: #000;
  background-color: rgba(0, 0, 0, .97);
  height: 68px;
  left: 0;
  padding: 0 45px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20
}

.error-page--logo {
  left: 40px;
  position: absolute;
  top: 22px
}

.error-page--logo a {
  background-image: url(https://assets.nflxext.com/en_us/home/logo_v7.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  font-size: 0;
  height: 25px;
  line-height: 0;
  overflow: hidden;
  text-indent: -9999px;
  width: 93px
}

.error-page--logo a:focus {
  outline: none
}

.error-page--content {
  display: flex;
  flex: 2 0;
  flex-direction: column;
  margin: 0 1em;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.error-page--content h1 {
  font-size: 6em;
  font-weight: 500;
  margin-bottom: 2vw;
  z-index: 1
}

.error-page--content h1, .error-page--content p {
  color: inherit;
  margin-top: 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .57)
}

.error-page--content p {
  font-size: 2.2em;
  font-weight: 300
}

.error-page--content--body {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 2em;
  width: 50vw;
  z-index: 1
}

.error-page--content--buttons {
  display: flex;
  justify-content: center
}

.error-page--content--buttons>:not(:first-child) {
  margin-left: 1em
}

.error-page--content--buttons>:not(:first-child):dir(rtl) {
  margin-left: 0;
  margin-right: 1em
}

.error-page--content--buttons>a {
  text-decoration: none
}

.error-page--content--buttons>a:focus {
  outline: none
}

.error-page--content--buttons button.medium {
  padding: .6em
}

.error-page--content--buttons button.medium.hasLabel {
  border-radius: .3em;
  padding-left: 1.8em;
  padding-right: 1.8em
}

.error-page--content--buttons button.medium>span {
  font-size: 1.6em;
  line-height: 2.4em
}

.error-page--content--errorCode {
  align-items: flex-end;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 2em
}

.error-page--content--errorCode span {
  border-left: 2px solid #009779;
  font-size: 2.4em;
  font-weight: 100;
  letter-spacing: .1em;
  line-height: 2.2em;
  padding: 0 1vw
}

.error-page--content--errorCode span:dir(rtl) {
  border-left: none;
  border-right: 2px solid #009779
}

.error-page--content:before {
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, .2) 45%, rgba(0, 0, 0, .1) 55%, transparent 70%);
  bottom: -10vw;
  content: "";
  left: 10vw;
  position: absolute;
  right: 10vw;
  top: -10vw;
  z-index: 0
}

.error-page--content:after {
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  content: "";
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1
}

.error-page.nfse .error-page--content:after {
  background-image: url(https://assets.nflxext.com/ffe/siteui/pages/errors/bg-nailed-it.png)
}

.error-page.not-found .error-page--content:after {
  background-image: url(https://assets.nflxext.com/ffe/siteui/pages/errors/bg-lost-in-space.png)
}

.error-page.player .error-page--content:after {
  background-image: url(https://assets.nflxext.com/ffe/siteui/pages/errors/bg-mindhunter-v2.png)
}

.error-page--imageSource {
  bottom: 0;
  color: hsla(0, 0%, 90%, .57);
  font-size: 1.4em;
  font-weight: 100;
  letter-spacing: .1em;
  line-height: 1.5vw;
  margin: 2.5vw 4.8vw;
  position: fixed;
  right: 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .57)
}

.error-page--imageSource>strong {
  color: hsla(0, 0%, 90%, .77);
  letter-spacing: normal;
  margin-left: .2em
}

.error-page--requestInfo {
  bottom: 0;
  color: transparent;
  left: 0;
  margin: 2.5vw 4.8vw;
  position: fixed
}

.error-page--requestInfo ::-moz-selection, .error-page--requestInfo::-moz-selection {
  background-color: Highlight;
  color: #fff
}

.error-page--requestInfo ::selection, .error-page--requestInfo::selection {
  background-color: Highlight;
  color: #fff
}

.t_89d625 .pageTransition-enter, .t_89d625 .pageTransition-leave {
  transition-duration: .2s;
  transition-property: opacity;
  transition-timing-function: ease-out
}

.accessibility-nav {
  clip: rect(0 0 0 0);
  display: flex;
  height: 1px;
  list-style: none;
  padding-bottom: 25px;
  position: absolute;
  width: 1px;
  z-index: 3000
}

.accessibility-nav:focus-within {
  clip: auto;
  background: #000;
  border: 2px solid #000;
  border-left: none;
  border-right: none;
  height: auto;
  left: 0;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  top: 0;
  width: 100%
}

.accessibility-nav li {
  flex-grow: 1;
  text-align: center
}

.accessibility-nav a, .accessibility-nav button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  font-weight: 500;
  padding: 2px 4px
}

body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #141414 !important;
  color: #fff !important;
  cursor: default;
  font-family: Netflix Sans, Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif;
  font-size: 10px;
  line-height: 1.2;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

@media screen and (min-width: 0) and (max-width:480px) and (max-aspect-ratio:4/3) {
  body, html {
    font-size: 1.5vw
  }
}

@media screen and (min-width: 481px) and (max-width:840px) and (max-aspect-ratio:4/3) {
  body, html {
    font-size: 1vw
  }
}

@media screen and (min-width: 841px) and (max-width:1280px) and (max-aspect-ratio:4/3) {
  body, html {
    font-size: .75vw
  }
}

@media screen and (min-width: 1281px) and (max-width:1600px) and (max-aspect-ratio:4/3) {
  body, html {
    font-size: .75vw
  }
}

@media screen and (min-width: 1601px) and (max-width:1920px) and (max-aspect-ratio:4/3) {
  body, html {
    font-size: .75vw
  }
}

@media screen and (min-width: 1921px) and (max-aspect-ratio:4/3) {
  body, html {
    font-size: 12px
  }
}

@media screen and (max-width: 480px) and (orientation:landscape) {
  body, html {
    font-size: 1.5vw
  }
}

@media screen and (min-width: 481px) and (max-width:840px) and (orientation:landscape) {
  body, html {
    font-size: 1.2vw
  }
}

@media screen and (min-width: 841px) and (max-width:1280px) and (orientation:landscape) {
  body, html {
    font-size: .85vw
  }
}

@media screen and (min-width: 1281px) and (max-width:1600px) and (orientation:landscape) {
  body, html {
    font-size: .75vw
  }
}

@media screen and (min-width: 1601px) and (max-width:1920px) and (orientation:landscape) {
  body, html {
    font-size: .75vw
  }
}

@media screen and (min-width: 1921px) and (orientation:landscape) {
  body, html {
    font-size: 14px
  }
}

.svg-icon {
  height: 100%;
  pointer-events: none;
  width: 100%
}

.bd {
  overflow: hidden;
  z-index: 0
}

.on-profiles-gate~.kids-wordmark {
  display: none
}

.kids-wordmark {
  bottom: 0;
  height: 112px;
  pointer-events: none;
  position: fixed;
  right: 0;
  width: 200px
}

.kids-wordmark .fill {
  background-color: #000;
  filter: blur(40px);
  height: 159px;
  opacity: 50%;
  position: absolute;
  width: 229px
}

.kids-wordmark .logo {
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 32px;
  position: absolute;
  right: 4vw;
  top: 48px;
  width: 100%
}

@media screen and (min-width: 1500px) {
  .kids-wordmark .logo {
    right: 60px
  }
}

a {
  color: #fff;
  cursor: pointer;
  text-decoration: none !important
}

.mainView {
  padding-top: 68px;
  min-height: 1000px;
  position: relative;
  z-index: 0
}

.mainView .page-transition-loader {
  padding: 0 4%
}

.mainView .page-transition-loader .loadingTitle {
  margin: 20px 2px
}

@media screen and (min-width: 1500px) {
  .mainView .page-transition-loader {
    padding: 0 60px
  }
}

.title-artwork, .video-artwork {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 28.125% 0;
  width: 100%
}

.video-evidence {
  background-color: #141414;
  color: grey;
  font-size: 1rem;
  left: 10%;
  margin: 5px auto 0;
  position: absolute;
  text-align: center;
  width: 80%
}

.jawBoneOpen .video-evidence {
  display: none
}

.image-preloaders {
  margin-left: -9999px
}

.pageTransition-enter {
  bottom: 0;
  display: none;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000
}

.pageTransition-enter.pageTransition-enter-active {
  display: block;
  opacity: 1
}

.pageTransition-leave {
  opacity: 1
}

.pageTransition-leave.pageTransition-leave-active {
  opacity: 0
}

.modal-overlay {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000
}

.modal-overlay .modal-overlay-smoked-glass {
  background-color: rgba(0, 0, 0, .85);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0
}

.modal-overlay .modal-overlay-content {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}