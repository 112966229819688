#login-masflix .ui-binary-input {
    box-sizing: border-box;
    font-size: 16px;
    padding-left: 36px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

#login-masflix .ui-binary-input input:disabled~.helper, #login-masflix .ui-binary-input input[type=checkbox]:disabled+label {
    color: #b3b3b3;
}

#login-masflix .ui-binary-input .helper {
    color: grey;
    font-size: 14px;
    line-height: 1.3em;
}

#login-masflix .ui-binary-input input[type=checkbox] {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
}

#login-masflix .ui-binary-input input[type=checkbox]:focus+label:before {
    border-color: grey;
    box-shadow: 0 0 5px 2px rgba(150, 200, 255, .6);
}

#login-masflix .ui-binary-input input[type=checkbox]+label {
    color: #333;
    display: block;
    line-height: 1.2;
    padding: 6px 0;
    position: relative;
}

#login-masflix .ui-binary-input input[type=checkbox]+label:before {
    background-color: #fff;
    border: 1px solid #b3b3b3;
    content: "";
    display: block;
    left: -36px;
    padding: 0;
    position: absolute;
    top: 2px;
}

#login-masflix .ui-binary-input input[type=checkbox]+label:after {
    position: absolute;
}

#login-masflix .ui-binary-input input[type=checkbox]+label:before {
    height: 25px;
    width: 25px;
}

#login-masflix .ui-binary-input input[type=checkbox]:checked+label:after {
    color: #0080ff;
    content: "\e804";
    font-family: nf-icon;
    font-size: 28px;
    left: -36px;
    top: -2px;
}

#login-masflix .ui-binary-input input[type=checkbox]:disabled+label:after {
    color: #dcdcdc;
}

#login-masflix .nfTextField {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #8c8c8c;
    border-radius: 2px;
    box-sizing: border-box;
    color: #000;
    display: block;
    font-size: 16px;
    height: 44px;
    padding: 10px 11px;
    width: 100%;
}

#login-masflix .nfTextField:invalid, #login-masflix .nfTextField:valid {
    box-shadow: none;
}

#login-masflix .nfTextField:focus {
    border-color: #0071eb;
    outline: none;
}

#login-masflix .nfTextField:disabled {
    border-color: #ccc;
    color: #a6a6a6;
}

#login-masflix .nfInput {
    max-width: 500px;
}

#login-masflix .nfInput, #login-masflix .nfInput .nfInputPlacement {
    position: relative;
}

#login-masflix .nfInput .placeLabel {
    color: #8c8c8c;
    font-size: 14px;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: font .1s ease, top .1s ease, transform .1s ease;
}

@media only screen and (min-width:740px) {
    #login-masflix .nfInput .placeLabel {
        font-size: 16px;
    }
}

#login-masflix .nfInput .nfTextField {
    height: 48px;
    padding: 10px 10px 0;
    width: 100%;
}

#login-masflix .nfInput .nfTextField:focus+.placeLabel {
    font-size: 11px;
    top: 4px;
    transform: translateY(0);
}

#login-masflix .nfInput .nfTextField:-webkit-autofill+.placeLabel {
    font-size: 11px;
    top: 4px;
    transform: translateY(0);
}

#login-masflix .nfInput .nfTextField::-moz-placeholder {
    opacity: 0;
}

#login-masflix .nfInput .nfTextField:focus::-moz-placeholder {
    opacity: 1;
}

#login-masflix .nfEmailPhoneControls {
    position: relative;
}

#login-masflix .nfEmailPhoneControls .nfTextField::-webkit-credentials-auto-fill-button {
    background-color: #8c8c8c;
    margin-top: -11px;
}

#login-masflix .nfEmailPhoneControls .nfTextField::-ms-clear {
    background: transparent;
    color: #8c8c8c;
    height: 24px;
    margin-top: -10px;
    width: 24px;
}

#login-masflix .nfPasswordInput {
    position: relative;
    z-index: 0;
}

#login-masflix .nfPasswordInput .nfTextField {
    background: transparent;
}

#login-masflix .nfPasswordInput .nfTextField::-webkit-credentials-auto-fill-button {
    background-color: #8c8c8c;
    margin-top: -11px;
}

#login-masflix .nfPasswordInput .nfTextField::-webkit-caps-lock-indicator {
    -webkit-appearance: none;
    appearance: none;
    background-color: #8c8c8c;
    content: "";
    height: 14px;
    margin: 6px 2px 0 3px;
    -webkit-mask-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"><path d="M12.5.5a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4h-8a4 4 0 0 1-4-4v-8a4 4 0 0 1 4-4h8M8.5 2 4 7h2.25v3.25h4.5V7H13L8.5 2m2.25 10h-4.5v2.25h4.5V12"/></svg>');
    mask-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"><path d="M12.5.5a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4h-8a4 4 0 0 1-4-4v-8a4 4 0 0 1 4-4h8M8.5 2 4 7h2.25v3.25h4.5V7H13L8.5 2m2.25 10h-4.5v2.25h4.5V12"/></svg>');
    -webkit-mask-size: 14px 14px;
    mask-size: 14px 14px;
    -webkit-user-select: none;
    user-select: none;
    width: 14px;
}

#login-masflix .nfPasswordInput .nfTextField::-ms-clear, #login-masflix .nfPasswordInput .nfTextField::-ms-reveal {
    background: transparent;
    color: #8c8c8c;
    height: 24px;
    margin-top: -10px;
    width: 24px;
}

#login-masflix .nfPasswordControls {
    background: #fff;
    display: flex;
}

#login-masflix .nfPasswordControls>label {
    flex-grow: 1;
}

#login-masflix .nfPasswordToggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    color: #8c8c8c;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 14px;
}

#login-masflix .btn {
    background-color: #e6e6e6;
    background-image: linear-gradient(180deg, #e6e6e6, #ddd);
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .2);
    box-sizing: border-box;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    letter-spacing: .1px;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
}

#login-masflix .btn:hover {
    background: #eaeaea;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .2);
    text-decoration: none;
}

#login-masflix .btn:active {
    background: #c2c2c2;
}

#login-masflix .btn-submit {
    background-color: #0080ff;
    background-image: linear-gradient(180deg, #0080ff, #0277ec);
    box-shadow: 0 1px 0 rgba(0, 0, 0, .55);
    color: #fff !important;
}

#login-masflix .btn-submit:hover {
    background: #2490fd;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .55);
}

#login-masflix .btn-submit:active {
    background: #0166c9;
}

#login-masflix .btn {
    font-size: 1em;
    margin: .5em .5em .5em 0;
    min-height: 50px;
    min-width: 74px;
    padding: .75rem 1.33333333em;
    position: relative;
}

@media only screen and (max-width:500px) {
    #login-masflix .btn {
        margin: .5em auto;
        width: 100%;
    }
}

#login-masflix .btn.btn-small {
    display: block;
    line-height: 50px;
    margin: .5em auto;
    padding: 0;
    width: 100%;
}

@media only screen and (min-width:500px) {
    #login-masflix .btn.btn-small {
        display: inline-block;
        font-size: 13px;
        line-height: 1em;
        margin-right: .5em;
        min-height: 37px;
        min-width: 98px;
        padding: .75rem 1em;
        width: auto;
    }
}

#login-masflix .login-input {
    padding-bottom: 24px;
}

#login-masflix .login-form {
    margin-bottom: 10px;
}

#login-masflix .login-button.btn {
    background: #009779;
    width: 100%;
}

#login-masflix .login-button.btn:hover {
    background: #009779 !important;
}

@media only screen and (min-width:500px) {
    #login-masflix .login-button.btn {
        font-size: 18px;
        padding: 16px;
    }
}

@media only screen and (min-width:360px) {
    #login-masflix .login-button.btn {
        max-width: 100%;
        width: 100%;
    }
}

#login-masflix .hybrid-login-wrapper {
    background-color: #000;
    color: #fff;
}

#login-masflix .hybrid-login-wrapper .nfHeader {
    background: none;
    border: 0;
}

#login-masflix .hybrid-login-wrapper .login-wrapper-background {
    opacity: .5;
}

#login-masflix .hybrid-login-form {
    background-color: rgba(0, 0, 0, .75);
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0;
    min-height: 515px;
    padding: 20px 0 30px;
    width: 100%;
}

#login-masflix .hybrid-login-form.hybrid-login-form-signup {
    min-height: 550px;
}

@media only screen and (min-width:740px) {
    #login-masflix .hybrid-login-form {
        margin-bottom: 90px;
        min-height: 625px;
        padding: 60px 68px 40px
    }

    #login-masflix .hybrid-login-form.hybrid-login-form-signup {
        min-height: 500px
    }
}

#login-masflix .hybrid-login-form .hybrid-login-form-main {
    flex-grow: 1;
}

#login-masflix .hybrid-login-form h1 {
    color: #fff;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 28px;
}

#login-masflix .hybrid-login-form .nfInput {
    max-width: 100%;
}

#login-masflix .hybrid-login-form .nfTextField {
    border: 0;
    border-radius: 4px;
    color: #fff;
    height: 50px;
    line-height: 50px;
    padding: 16px 20px 0;
}

#login-masflix .hybrid-login-form .login-input {
    padding-bottom: 16px;
}

#login-masflix .hybrid-login-form .placeLabel {
    left: 20px;
}

#login-masflix .hybrid-login-form .nfTextField:-webkit-autofill+.placeLabel {
    color: #000;
    top: 7px;
}

#login-masflix .hybrid-login-form .nfTextField:focus+.placeLabel {
    top: 7px;
}

#login-masflix .hybrid-login-form .nfEmailPhoneControls {
    border: 0;
    border-radius: 4px;
}

#login-masflix .hybrid-login-form .nfEmailPhoneControls .nfTextField {
    background: #333;
}

#login-masflix .hybrid-login-form .nfEmailPhoneControls .nfTextField:focus {
    background: #454545;
}

#login-masflix .hybrid-login-form .nfEmailPhoneControls .nfTextField::-webkit-credentials-auto-fill-button {
    background-color: #8c8c8c;
    margin-top: -14px;
}

#login-masflix .hybrid-login-form .nfEmailPhoneControls .nfTextField::-ms-clear {
    color: #8c8c8c;
    margin-top: -15px;
}

#login-masflix .hybrid-login-form .nfPasswordControls {
    background: #333;
    border: 0;
    border-radius: 4px;
}

#login-masflix .hybrid-login-form .nfPasswordControls .nfTextField {
    height: 50px;
}

#login-masflix .hybrid-login-form .nfPasswordControls .nfTextField::-webkit-credentials-auto-fill-button {
    background-color: #8c8c8c;
    margin-top: -14px;
}

#login-masflix .hybrid-login-form .nfPasswordControls .nfTextField::-webkit-caps-lock-indicator {
    background-color: #8c8c8c;
    margin-top: 3px;
}

#login-masflix .hybrid-login-form .nfPasswordControls .nfTextField::-ms-clear, #login-masflix .hybrid-login-form .nfPasswordControls .nfTextField::-ms-reveal {
    color: #8c8c8c;
    margin-top: -15px;
}

#login-masflix .hybrid-login-form .nfPasswordControls .nfPasswordToggle {
    display: none;
    height: 50px;
    padding: 0 12px;
}

#login-masflix .hybrid-login-form .login-button {
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    margin: 24px 0 12px;
}

#login-masflix .hybrid-login-form .hybrid-login-form-help {
    display: flex;
}

#login-masflix .hybrid-login-form .login-remember-me {
    flex-grow: 1;
    padding-left: 20px;
}

#login-masflix .hybrid-login-form .login-remember-me input[type=checkbox]+label {
    padding: 0;
}

#login-masflix .hybrid-login-form .login-remember-me input[type=checkbox]+label:before {
    background: #737373;
    border: 0;
    border-radius: 2px;
    height: 16px;
    left: -20px;
    width: 16px;
}

#login-masflix .hybrid-login-form .login-remember-me input[type=checkbox]:active+label:before, #login-masflix .hybrid-login-form .login-remember-me input[type=checkbox]:focus+label:before {
    background: #e6e6e6;
    box-shadow: none;
}

#login-masflix .hybrid-login-form .login-remember-me input[type=checkbox]:checked+label:after {
    color: #000;
    font-size: 18px;
    left: -21px;
    top: 0;
}

#login-masflix .hybrid-login-form .login-help-link, #login-masflix .hybrid-login-form .login-remember-me-label-text {
    color: #b3b3b3;
    font-size: 13px;
    font-weight: 400;
}

#login-masflix .hybrid-login-form .login-help-link {
    cursor: pointer;
    flex: 0 0 1;
    padding-top: 2px;
}

#login-masflix .hybrid-login-form .hybrid-login-form-other {
    flex: 0 0 1;
}

#login-masflix .hybrid-login-form .login-form {
    margin-bottom: 0;
}

#login-masflix .hybrid-login-form .login-signup-now {
    color: #737373;
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
}

#login-masflix .hybrid-login-form .login-signup-now a {
    color: #fff;
}

#login-masflix ::-moz-focus-inner {
    border: 0;
    padding: 0;
}
